import React, { useEffect, useState } from "react";
import styles from "./Analysis.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {PageLayout, CardLayout, Information} from "5_components";
import {coreItem} from "0_variables/coreItem";
import { Analysis_card1, Analysis_card2, Analysis_card3 } from "./components";
import * as actionModal from "1_reduxs/actions/modalAction";
import { IPinUSE } from "2_services/IPs";
// import * as actionControl from "1_reduxs/actions/modalAction";
import * as utils from "0_variables/utils";
import {subRegions} from "0_variables/subRegions";
import axios from "axios";


export default function Analysis(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const username = sessionStorage.getItem('username');
  const productName = params.product.toLowerCase()
  const productCoreItem = coreItem[productName]; 
  const pageName = location.pathname.split('/')[2];
  const [selectedFile, setSelectedFile] = useState({});
  const topItems = productCoreItem[pageName].top;
  const bottomCards = productCoreItem[pageName].bottom;
  const subRegionByProduct = setting[productName].list;
  // const demoteContent1 = setting[productName].content1;
  const defaultAtlas = setting[productName].defaultAtlas
  const [focusTo, setFocusTo] = useState('')
  const [targetTracerRef, setTargetTracerRef] = useState({fullName:"", shortName:""});
  const settingOfProduct = setting[productName]
  // const [tracerName, setTracerName] = useState('')
  // const [refName, setRefName] = useState('')
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName]
  // console.log("targetTracerRef", targetTracerRef)
  // debugger;

  // const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
  const RF = utils.RefFactor1({productName, refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"], wmRF:selectedFile["ratio_gry2wm"], cgRF:selectedFile["ratio_gry2cg"], global:1/selectedFile['Global']})
  
  //  const RF = (() => {
  //   if (refName === "Pons") return selectedFile["ratio_gry2pons"];
  //   else if (refName === "Whole cerebellum") return selectedFile["ratio_gry2crbl"];
  //   else if (refName === "Cerebellar gray matter") return 1;
  //   else if (refName === "Cerebral white matter") return selectedFile["ratio_gry2pons"];
  //   else return 1;
  // })()

  
  useEffect(()=>{
    const selectedFile = fileList.find(({fileID})=>fileID == params.fileID);
    setSelectedFile(selectedFile);
    const shortName = utils.getShortName(refName)
    setTargetTracerRef({fullName:refName, shortName:shortName})
  },[params, refName])



  



  // ********************** 나중에 리덕스에서 받아줘야함 **********************
  // const [atlasFromRedux, setAtlasFromRedux] = useState(
  //   productName === "amyloid" ? "AAL3":
  //   productName === "dat" ? "Melbourne Subcortical Atlas":
  //   productName === "fdg" ? "Melbourne Subcortical Atlas":
  //   "Melbourne Subcortical Atlas"
  // )

  const burgerFuncs = {
    openReport: () => history.push(`/${productName}/report/${params.fileID}`),
    alert:()=>alert('hello'),
    downloadCSV_Individual: () => {
      if (productName === 'amyloid') return amyloidCSVFormat()
      else if (productName === 'dat') return datCSVFormat()
      else if (productName === 'fdg') return fdgCSVFormat()
      else if (productName === 'tau') return tauCSVFormat()
      else return amyloidCSVFormat()
    },
    
    downloadNifitiSUVR_Individaul : () =>{
      // debugger;
      const link = IPinUSE+"result/download/" + username + "/database/" + selectedFile.fileID + '/'+'surv_output_' + selectedFile.fileID + '.nii';
      // const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_intensity"+"_"+selectedFile.fileID+".nii";
      const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".nii";
      const getFile = async (link, fname) => {
        // debugger;
        // const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+'output_' + props.fileID + '.nii';
        // const link = IPinUSE+"result/download/" + 'licenseinfo.txt';
        const res = await axios.get(link, {responseType: 'blob'});
        // debugger;
        const file = new Blob([res.data]);
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      // debugger;
      return getFile(link,fname)
    },
    
    downloadNifitiIntensity_Individaul : () =>{
      const link = IPinUSE+"result/download/" + username + "/database/" + selectedFile.fileID + '/'+'output_' + selectedFile.fileID + '.nii';
      const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_intensity"+"_"+selectedFile.fileID+".nii";
      // debugger;
      const getFile = async (link, fname) => {
        // debugger;
        // const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+'output_' + props.fileID + '.nii';
        // const link = IPinUSE+"result/download/" + 'licenseinfo.txt';
        const res = await axios.get(link, {responseType: 'blob'});
        // debugger;
        const file = new Blob([res.data]);
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      // debugger;
      return getFile(link,fname)
    }

  }
  
  const amyloidCSVFormat = () => {
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const godThanksCentiloid = utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
    // console.log(godThanksSUVR)
    const structData = utils.structAmyloidCSV({ref:targetTracerRef, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const datCSVFormat = () => {
    const srcSBR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcSemiquant = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSBR = utils.analysisADATtable1({   settingOfProduct:setting['dat'    ], typeSelect: coreItem['dat'    ].type1Selector, tableItems:coreItem['dat'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const godThanksSemiquatification = utils.analysisDATcard2_2({settingOfProduct:setting['dat'], typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
    const structData = utils.structDATCSV({ref:targetTracerRef,atlas:defaultAtlas, srcData:srcData, srcSBR:srcSBR, srcSemiquant:srcSemiquant, godThanksSBR, godThanksSemiquatification}); 
    // debugger;
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SBR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const fdgCSVFormat = () => {
    // alert('fdgCSVFormat')
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['fdg'], typeSelect: coreItem['fdg'].type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const structData = utils.structFDGTauCSV({ref:targetTracerRef, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, godThanksSUVR}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const tauCSVFormat = () => {
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['tau'], typeSelect: coreItem['tau'].type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const structData = utils.structFDGTauCSV({ref:targetTracerRef, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, godThanksSUVR}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }
  // debugger;
  const analysisPageTop =(top)=>{
    const patientInfo = topItems.item1.map(item=>({title:item.title, content:selectedFile[item.var]}))
    const templateInfo = topItems.item2.map(item=>{
      if (item.title === 'Atlas'){
        if (productName ==='dat'){
          return (
            {title:item.title, content:setting[productName][item.var]}
          )
        }
        return {title:item.title, content:setting[productName][item.var]}
      }else{
        return {title:item.title, content:setting[productName][item.var][selectedFile.Tracer]}
      } 
    })
    const burgerProps = {
      itemList: topItems.item3.map(item3=>({title:item3.title, func: burgerFuncs[item3.var]}))
    }

    return (
      <Information patientInfo={patientInfo} templateInfo={templateInfo} burgerProps={burgerProps}/>
    )
  }

  const analysisPageBottom = (bottom) => {
    // console.log(subRegionByProduct);
    // console.log(bottomCards[1].cards[0].content)
    // console.log(bottomCards[1].cards[1].content)


    const type1Selector = {
      'amyloid': 'suvr',
      'dat': 'sbr',
    }

    const type2Selector = {
      'amyloid': 'centiloid',
      'dat': 'semiquantification',
    }

    // const AmyloidLobarSUVR = bottomCards[0].cards[0].content.map(item=>{
    //   return {
    //     ...item,
    //     Lval:selectedFile[item.Lvar],Rval:selectedFile[item.Rvar],
    //   }
    // })
    // TODO: RF 적용할곳
    const dataForCard1 = utils.dataFormatForBrainTable({columnList:bottomCards[0].cards[0].content, selectedFile, settingOfProduct:setting[productName], RF})
    // const dataForCard1 = bottomCards[0].cards[0].content.map(item=> {
    //   if (typeof(item.Lvar)==='string'){
    //     return {...item, Lval:(selectedFile[item.Lvar])*RF, Rval:(selectedFile[item.Rvar])*RF}
    //   } else {
    //     const ObjL = item.Lvar.reduce((obj,el1)=>{
    //       const targetRegion = setting['dat'].list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Left === el1)
    //       obj.sum += selectedFile[el1] * targetRegion.weight.Left
    //       obj.weight += targetRegion.weight.Left
    //       return obj
    //     },{sum:0, weight:0})
    //     const ObjR = item.Rvar.reduce((obj,el1)=>{
    //       const targetRegion = setting['dat'].list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Right === el1)
    //       obj.sum += selectedFile[el1] * targetRegion.weight.Right
    //       obj.weight += targetRegion.weight.Right
    //       return obj
    //     },{sum:0, weight:0})
    //     const MeanOfWeightedSumLeft = ObjL.sum/ObjL.weight
    //     const MeanOfWeightedSumRight = ObjR.sum/ObjR.weight
    //     return {...item, Lval:MeanOfWeightedSumLeft*RF, Rval:MeanOfWeightedSumRight*RF}
    //   }
    // })
    const dataForCard2_1 = {
      "amyloid":utils.analysisAmyloidtable1({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
      "dat": utils.analysisDATtable1({   settingOfProduct:setting['dat'    ], typeSelect: coreItem['dat'    ].type1Selector, tableItems:coreItem['dat'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
      "fdg": utils.analysisAmyloidtable1({   settingOfProduct:setting['fdg'    ], typeSelect: coreItem['fdg'    ].type1Selector, tableItems:coreItem['fdg'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
      "tau": utils.analysisAmyloidtable1({   settingOfProduct:setting['tau'    ], typeSelect: coreItem['tau'    ].type1Selector, tableItems:coreItem['tau'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF }),
    }

    const dataForCard2_2 = {
      "amyloid":utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas }),
      "dat":utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['dat'], typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas }),
      
    }

    const cardComponents = {
      Analysis_card1: <Analysis_card1 items={dataForCard1} legend={bottomCards[0].cards[0].legend} colorcode={bottomCards[0].cards[0].colorcode} refName={refName} RF={RF}/>,
      Analysis_card2_1: <Analysis_card2 tableHead={dataForCard2_1[productName].tableHead} items={dataForCard2_1[productName].items} data={setting['dat'].list} defaultAtlas={setting['dat'].defaultAtlas}/>,
      Analysis_card2_2: productName === "fdg"|| productName === "tau" ? "" : <Analysis_card2 tableHead={dataForCard2_2[productName].tableHead} items={dataForCard2_2[productName].items}/>,
      Analysis_card3: (productName !== 'dat') && <Analysis_card3 selectedFile={selectedFile} refName={refName} RF={RF}/>,
    }
    // const contentComponents = [
    //   [<Analysis_card1 items={AmyloidLobarSUVR} legend={bottomCards[0].cards[0].legend} colorcode={bottomCards[0].cards[0].colorcode}/>], 
    //   [
    //     <Analysis_card2 tableHead={dataForCard2_1[productName].tableHead} items={dataForCard2_1[productName].items}/>, 
    //     <Analysis_card2 tableHead={dataForCard2_2[productName].tableHead} items={dataForCard2_2[productName].items}/>
    //   ], 
    //   [<Analysis_card3 />]
    // ]

    const cardItem = productCoreItem[pageName].bottom.map((v, i1)=>({
      ...v,
      cards:v.cards.map((card, i2)=>{
        return {
          ...card, 
          content:cardComponents[card.componentName],
        }
      })
    }));
    // debugger;
    return (
      
      <>
        {cardItem.map((v,i)=>
          <CardLayout key={i} {...v} focusTo={focusTo} setFocusTo={setFocusTo}/>
          //  width={v.styles.width} height={v.styles.height}
        )}
      </>
    )
  }

  return (
    <>
      <PageLayout topContent={analysisPageTop} bottomContent={analysisPageBottom}/>
    </>
  )
}
