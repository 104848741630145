import React, { useEffect, useState, useRef } from "react";
import styles from "./ReportPage22.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {ReportLayout} from "..";
import {ImageViewer} from "5_components/7_ReportPages/components/ReportPage3/components";
import * as utils from "0_variables/utils";
import {QuantTable} from "5_components";
import * as media from "6_media";

// import ResizeObserver from '@juggle/resize-observer'

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { scale } from "chroma-js";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ReportPage22(props) {

  // const element = useRef();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const pageName = "report";
  const Direction = "axial";
  const productCoreItem = coreItem[productName]; 
  // const [selectedFile, setSelectedFile] = useState({});
  // const topItems = productCoreItem[pageName].top;
  // TODO: Analysis에서 사용하는 테이블 헤더, 컨텐트 정보를 그대로 가져와서 사용중 >> 나중에는 공통 변수로 묶어서 사용하면 좋을듯함.
  const bottomCards = productCoreItem["analysis"].bottom; 
  const page2Table1Items = productCoreItem["analysis"].bottom[1].cards[0]; 
  const page2Table2Items = productCoreItem["analysis"].bottom[1].cards[1]; 
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({});
  const [defaultAtlas, setDefaultAtlas] = useState(setting[productName].defaultAtlas)

  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);

  const [data, setData] = useState([24, 30, 45, 70, 26]);
  // const content_height = new ResizeObserver(entries =>{
  //   console.log(entries)
  // })
  

  console.log('report: ', props.RF);
  const table1Data = {
    "amyloid": utils.reportAmyloidtable1({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
    "dat": utils.reportDATtable1({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
    "fdg": utils.reportAmyloidtable1({     settingOfProduct:setting['fdg'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
    "tau": utils.reportAmyloidtable1({     settingOfProduct:setting['tau'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  }
  // const table1Data = {
  //   "amyloid": utils.reportAmyloidtable1({ subRegions:setting['amyloid'].list, typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, settingOfProduct: setting[productName], RF:props.RF }),
  //   "dat": utils.reportDATtable1({     subRegions:setting['dat'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "fdg": utils.reportAmyloidtable1({     subRegions:setting['fdg'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "tau": utils.reportAmyloidtable1({     subRegions:setting['tau'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  // }
  const table2Data = {
    "amyloid":utils.reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
    "dat":utils.reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  }
  // const table2Data = {
  //   "amyloid":utils.reportAmyloidDATtable2({ subRegions:setting['amyloid'].list, typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas }),
  //   "dat":utils.reportAmyloidDATtable2({     subRegions:setting['dat'].list,     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas }),
  // }
  const size = utils.getSizebyProduct(Direction);
  const ratio = size.height/size.width;
  const width = 15;
  const height = width*ratio;

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const containerRef = useRef(null);
  const ref = useRef(null);
  const svg0Ref = useRef(null);
  const svg1Ref = useRef(null);
  const svg2Ref = useRef(null);
  useEffect(()=>{
    if (productName === 'amyloid' || productName === 'tau' || productName === 'fdg'){
      setData1([
        {region:"Global / Composite",               value:  (props.selectedFile.Global                                                              )*props.RF},
        {region:"Frontal",                          value: ((props.selectedFile.Frontal_L          + props.selectedFile.Frontal_R)/2                )*props.RF},
        {region:"PCC-Precuneus",                    value: ((props.selectedFile.PCC_L              + props.selectedFile.PCC_R)/2                    )*props.RF},
        {region:"Lateral temporal",                 value: ((props.selectedFile.Lateral_temporal_L + props.selectedFile.Lateral_temporal_R)/2       )*props.RF},
        {region:"Lateral parietal",                 value: ((props.selectedFile.Lateral_parietal_L + props.selectedFile.Lateral_parietal_R)/2       )*props.RF},
        {region:"Medial temporal",                  value: ((props.selectedFile.Medial_temporal_L  + props.selectedFile.Medial_temporal_R)/2        )*props.RF},
        {region:"Occipital",                        value: ((props.selectedFile.Occipital_L        + props.selectedFile.Occipital_R)/2              )*props.RF},
        {region:"Basal ganglia",                    value: ((props.selectedFile.Basal_ganglia_L    + props.selectedFile.Basal_ganglia_R)/2          )*props.RF},
      ])
      setData2([
        {region:"Global / Composite",               value: ( props.selectedFile.Composite_C                                                      )*props.RF },
        {region:"Frontal",                          value: ((props.selectedFile.Frontal_L_C          + props.selectedFile.Frontal_R_C)/2         )*props.RF },
        {region:"PCC-Precuneus",                    value: ((props.selectedFile.PCC_L_C              + props.selectedFile.PCC_R_C)/2             )*props.RF },
        {region:"Lateral temporal",                 value: ((props.selectedFile.Lateral_temporal_L_C + props.selectedFile.Lateral_temporal_R_C)/2)*props.RF },
        {region:"Lateral parietal",                 value: ((props.selectedFile.Lateral_parietal_L_C + props.selectedFile.Lateral_parietal_R_C)/2)*props.RF },
        {region:"Medial temporal",                  value: ((props.selectedFile.Medial_temporal_L_C  + props.selectedFile.Medial_temporal_R_C)/2 )*props.RF },
        {region:"Occipital",                        value: ((props.selectedFile.Occipital_L_C        + props.selectedFile.Occipital_R_C)/2       )*props.RF },
        {region:"Basal ganglia",                    value: ((props.selectedFile.Basal_ganglia_L_C    + props.selectedFile.Basal_ganglia_R_C)/2   )*props.RF },
      ]);
    } else if (productName === "dat") {
      // debugger;
      setData1([
        {
          "Category": "Dorsal striatum",
          "Left": Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_R),
        },
        {
          "Category": "Caudate",
          "Left": Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_R),
        },
        {
          "Category": "Putamen A",
          "Left": Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_R),
        },
        {
          "Category": "Putamen P",
          "Left": Math.max(0.01, props.selectedFile.DAT_Post_Putamen_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Post_Putamen_R),
        },
      ])
    }
  },[props.selectedFile])

 

  return (
    <>
      {/* <div ref={el=>{debugger;if(props.innerRef) props.innerRef=el}} className={styles.A4pages}> */}
        <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
          <div ref={containerRef} className={styles.QuantContainer}
            // onMouseEnter={(e)=>{
            //   const contentHeight = e.target.offsetHeight
            //   console.log("height", contentHeight)
            // }}
          >
            <div className={styles.ReportTitle}>
              <img src={media.reportIcon2} alt=""/>&nbsp;{'Quantification Results'}
            </div>
            <div className={styles.typeTableWrapper}>
              <div className={styles.tableTitle}>
                {(()=>{
                  switch(productName){
                    case "amyloid":
                      return "Regional SUVR"
                    case "dat":
                      return ""
                    case "fdg":
                      return "Regional SUVR"
                    case "tau":
                      return "Regional SUVR"
                    default:
                      return "Regional SUVR"
                  }
                })()}
              </div>
              <div className={styles.tableContainer}>
                {(()=>{
                  console.log('report: ', table1Data[productName].items)
                  return <QuantTable eventEnabled={false} tableHead={table1Data[productName].tableHead} items={table1Data[productName].items} trClick={trClick} setTrClick={setTrClick} sortClick={sortClick} setSortClick={setSortClick}/>
                })()}
              </div>
            </div>
            <div className={styles.typeTableWrapper}>
              <div className={styles.tableTitle}>
                {(()=>{
                  switch(productName){
                    case "amyloid":
                      return "Regional Centiloid"
                    case "dat":
                      return "Semiquantification"
                      case "fdg":
                        return ""
                      case "tau":
                        return ""
                    default:
                      return ""
                  }
                })()}
              </div>
              <div className={styles.tableContainer}>
                {(productName === 'amyloid' || productName === 'dat') && <QuantTable eventEnabled={false} tableHead={table2Data[productName].tableHead} items={table2Data[productName].items} trClick={trClick} setTrClick={setTrClick} sortClick={sortClick} setSortClick={setSortClick}/>}
              </div>
            </div>
          </div>
        </ReportLayout>
      {/* </div> */}
    </>
  )
}
