import React, { useEffect, useState } from "react";
import styles from "./ReportPage1.module.scss";
import { Analysis_card1, Analysis_card2, Analysis_card3 } from "4_routers/4_Analysis/components";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {ReportLayout} from "../";
import amyloid_page1 from "./amyloid_page1.PNG";
import dat_page1 from "./dat_page1.PNG";
import tau_page1 from "./tau_page1.PNG";
import fdg_page1 from "./fdg_page1.PNG";
import { BrainTable, PatientInfoTable } from '5_components';
import * as utils from "0_variables/utils";
import * as media from "6_media";


export default function ReportPage1(props) {
  // debugger;
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  // const setting = useSelector((state) => state.setting);

  // const fileList = useSelector((state) => state.fileList);
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase()
  const pageName = 'analysis';
  const productCoreItem = coreItem[productName];
  const bottomCards = productCoreItem[pageName].bottom;
  const setting = useSelector((state) => state.setting);
  // const [selectedFile, setSelectedFile] = useState({});
  // const selectedFile = fileList.find(({fileID})=>fileID === params.fileID);
  // const [selectedFile, setSelectedFile] = useState({});
  const settingOfProduct = setting[productName]
  const tracerName = props.selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName]

  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  const tracerNames = {
    "[18F]FMM" :"[18F]Flutemetamol",
    "[18F]FBP" :"[18F]Florbetapir",
    "[18F]FBB" :"[18F]Florbetaben",
    "[18F]FPN" :"[18F]Florapronol",
    "[18F]FPCIT" :"[18F]FP-CIT",
    "[18F]FDG" :"[18F]FDG",
    "[18F]TAU" :"[18F]TAU",
  }

  
  const RF = utils.RefFactor1({productName, refName, ponsRF:props.selectedFile["ratio_gry2pons"], crblRF:props.selectedFile["ratio_gry2crbl"], wmRF:props.selectedFile["ratio_gry2wm"], cgRF:props.selectedFile["ratio_gry2cg"], global:1/props.selectedFile['Global']})
  
  // const RF = utils.RefFactor({refName, ponsRF:props.selectedFile["ratio_gry2pons"], crblRF:props.selectedFile["ratio_gry2crbl"]})
  // const RF = (() => {
  //   if (refName === "Pons") return props.selectedFile["ratio_gry2pons"];
  //   else if (refName === "Whole cerebellum") return props.selectedFile["ratio_gry2crbl"];
  //   else if (refName === "Cerebellar gray matter") return 1;
  //   else if (refName === "Cerebral white matter") return props.selectedFile["ratio_gry2pons"];
  //   else return 1;
  // })()

  // const [selectedFile, setSelectedFile] = useState(props.selectedFile);
  // const dataForCard1 = bottomCards[0].cards[0].content.map(item=>({...item, Lval:(props.selectedFile[item.Lvar])*props.RF, Rval:(props.selectedFile[item.Rvar])*props.RF}))
  const dataForCard1 = utils.dataFormatForBrainTable({columnList:bottomCards[0].cards[0].content, selectedFile:props.selectedFile, settingOfProduct:setting[productName], RF})
  // const dataForCard1 = bottomCards[0].cards[0].content.map(item=>{
  //   if (typeof(item.Lvar)==='string'){
  //     return {...item, Lval:(selectedFile[item.Lvar])*RF, Rval:(selectedFile[item.Rvar])*RF}
  //   } else {
  //     const ObjL = item.Lvar.reduce((obj,el1)=>{
  //       const targetRegion = setting['dat'].list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Left === el1)
  //       obj.sum += selectedFile[el1] * targetRegion.weight.Left
  //       obj.weight += targetRegion.weight.Left
  //       return obj
  //     },{sum:0, weight:0})
  //     const ObjR = item.Rvar.reduce((obj,el1)=>{
  //       const targetRegion = setting['dat'].list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Right === el1)
  //       obj.sum += selectedFile[el1] * targetRegion.weight.Right
  //       obj.weight += targetRegion.weight.Right
  //       return obj
  //     },{sum:0, weight:0})
  //     const MeanOfWeightedSumLeft = ObjL.sum/ObjL.weight
  //     const MeanOfWeightedSumRight = ObjR.sum/ObjR.weight
  //     return {...item, Lval:MeanOfWeightedSumLeft*RF, Rval:MeanOfWeightedSumRight*RF}
  //   }
  // })
  // debugger;

  //TODO : 재사용 해야함


  // useEffect(()=>{
  //   const selectedFile = fileList.find(({fileID})=>fileID == params.fileID);
  //   setSelectedFile(selectedFile);
  //   // debugger;
  // },[params])
  return (
    <>
      {/* <div ref={el=>{if(props.innerRef) props.innerRef=el}} className={styles.A4pages}> */}
        <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
          {/* <img style={{height:"100%", }} src={productCoreItem.productShortName==='Amyloid'? amyloid_page1 : productCoreItem.productShortName==='Dopamine' ? dat_page1 : productCoreItem.productShortName==='FDG' ? fdg_page1 : tau_page1}/> */}
          {/* <PateintInformationTable/> */}
          <div className={styles.PatientInfoContainer}>
            <div className={styles.ReportTitle}>
              <img src={media.reportIcon1} alt=""/>&nbsp;{'Patient Information'}
            </div>
            <div className={styles.PatientInfoTable}>
              <PatientInfoTable contents={{"Patient ID":props.selectedFile.PatientID, "Name":props.selectedFile.PatientName, "DOB":props.selectedFile.Age, "Sex":props.selectedFile.Sex, "Study Date":props.selectedFile.AcquisitionDateTime, "Study Description":tracerNames[props.selectedFile.Tracer]}}/>
            </div>
          </div>
          <div className={styles.BrainTableContainer}>
            <div className={styles.ReportTitle}>
              <img src={media.reportIcon2} alt=""/>&nbsp;{'Quantification Results'}
            </div>
            <div className={styles.BrainTable}>
              <BrainTable borderEnable={false} legend={bottomCards[0].cards[0].legend} colorcode={bottomCards[0].cards[0].colorcode} items={dataForCard1} refName={refName} RF={RF}/>
            </div>
          </div>
        </ReportLayout>
      {/* </div> */}
    </>
  )
}