import React, { useEffect, useState } from "react";
import styles from "./ReportFormats.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";

export default function ReportFormats(props) {
  // debugger;
  const location = useLocation();
  const checkList = ["Patient Name", "Patient ID", "DOB", "Sex", "Type & Date of Examination"]
  // const [checktedTotalItems, setChecktedTotalItems] = useState(false);
  const [pInfoCheckBox, setPInfoCheckBox ] = useState((props.reportFormatsProps.PInfoState.map(el=> el.state)).every(el => el===true));
  // debugger;
  useEffect(()=>{
    
  },[])
  

  const pInfoSet = (state)=>{
    setPInfoCheckBox(state);
    props.reportFormatsProps.setPInfoState([
      {name: "Patient Name",state: state},
      {name: "Patient ID",state: state},
      {name: "DOB",state: state},
      {name: "Sex",state: state},
      {name: "Type & Date of Examination",state: state},
    ])
  }


 
  // const PInfoState = props.reportFormatsProps.PInfoState
  // // debugger;
  // const allCheckPinfo = PInfoState.every(el=> el.state===true)
  // if (allCheckPinfo){
  //   setPInfoCheckBox(true)
  // }else{
  //   setPInfoCheckBox(false)
  // }

 

  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>
        <div
          onClick={()=>{
            // setChecktedTotalItems(!pInfoCheckBox)
            pInfoSet(!pInfoCheckBox)
          }}
        >
          <img src={pInfoCheckBox ? media.Check :  media.unCheck}/>
        </div>
        <div>Patient Information</div>
      </div>
      <div className={styles["checkList"]}>
        {props.reportFormatsProps.PInfoState.map((item,idx) => {
          // debugger;
          
          return(
            <div 
            key={idx}
            onClick={()=>{ 
              const updatedPInfoState = props.reportFormatsProps.PInfoState.map(obj=>obj.name===item.name ? {...obj, state:!item.state}:{...obj})
              props.reportFormatsProps.setPInfoState(updatedPInfoState)
              const allCheckPinfo = (updatedPInfoState.map(el=> el.state)).every(el => el===true)
              allCheckPinfo ? setPInfoCheckBox(true) : setPInfoCheckBox(false)
            }}
            >
              <div>{item.name}</div>
              <div style={{visibility : `${item.state ? "" : "hidden"}`}}><img src={media.justCheck}/></div>
            </div>
          )
        }
          
        )}
      </div>
    </div>
  )
}

