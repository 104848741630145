import * as utils from "0_variables/utils";
import { IPinUSE, SUPERUSER } from "2_services/IPs";
import * as loginApi from "2_services/loginApi";
import * as actionModal from "1_reduxs/actions/modalAction";
export const SETLOGINREDUX = "login/SETLOGINREDUX"
// Action types;

// Action creators

export function login_api(values) {
  return async (dispatch) => {
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Log in...'}));
      const res = await loginApi.Signin(values);
      const token = res.data.token;
      sessionStorage.setItem("username", values.username);
      sessionStorage.setItem("token", token);
      const is_active_email = res.data.user.is_active_email
      // debugger;
      if (!is_active_email){
        dispatch(setLoginRedux({ username: "", token: "", logged: false }));
        return {response: false, message: "We will check your information and provide you with demo cases shortly.", code: "Pending"};
      }
      // dispatch(setLoginRedux({ username: values.username, token: token, logged: true }));
      // debugger;
      return {response: true, message: "login: Success", code:res.status};
    } catch (err) {
      utils.removesessionStorage();
      // debugger;
      return {response: false, message:"login: Failed", code:err?.response?.status};
    } 
    finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
    }
  };
}

export function verify_token_api(values) {
  return async (dispatch) => {
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Verfiying user account...'}));
      const res = await loginApi.TokenVerification(values);
      const token = res.data.token;
      if (!token) throw new Error('token expired');
      const username = sessionStorage.getItem("username");
      dispatch(setLoginRedux({ username: username, token: token, logged: true }));
      return {response: true, message: "token: Verified"};
    } catch (err) {
      utils.removesessionStorage();
      dispatch(setLoginRedux({ username: "", token: "", logged: false }));
      return {response: false, message:"token: Expired"};
    } finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
    }
  };
}

export function register_api(values) {
  return async (dispatch) => {
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Signing up...'}));
      const res = await loginApi.Registration(values);
      const token = res.data.token;
      // debugger;
      sessionStorage.setItem("username", values.username);
      sessionStorage.setItem("token", token);
      dispatch(setLoginRedux({ username: values.username, token: token, logged: true }));
      return {response: true, message: "Registration: Success", code:res.status};
    } catch (err) {
      utils.removesessionStorage();
      const responseMessage = err?.response?.data;
      const msg = Object.keys(responseMessage).map((k,i)=>"("+(i+1)+") " + k + ": " + responseMessage[k]).join('\n');
      return {
        response: false, 
        message:`Registration: Failed, \n${msg}`, 
        code:err?.response?.status
      };
    } 
    finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
    }
  };
}

export function forgot_api(values) {
  return async (dispatch) => {
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Checkout account'}));
      const adminJWT = await (await loginApi.getAuthJWT({username:SUPERUSER.username, password:SUPERUSER.passwd})).data.token;
      const res = await loginApi.ResetPassword({"Authorization": "JWT "+adminJWT}, values);
      return {response: true, message: "Reset: Success", code:res.status};
    } catch (err) {
      const responseMessage = err?.response?.data;
      if (responseMessage == undefined) {
        return {
          response: false, 
          message:`Network Error: Please check if Btxbrain-backend is running.`, 
          code: undefined
        }
      } else {
        const msg = Object.keys(responseMessage).map((k,i)=>"("+(i+1)+") " + k + ": " + responseMessage[k]).join('\n');
        return {
          response: false, 
          message:`Reset: ${msg}`, 
          code:err?.response?.status
        };
      }
    } 
    finally {
      utils.removesessionStorage();
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
    }
  };
}

export const setLoginRedux = (obj) => {
  return {
    type: SETLOGINREDUX,
    obj
  };
};

