import React, { useEffect, useState } from "react";
import styles from "./ReportLayout.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";

export default function ReportLayout(props) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  const setting = useSelector((state) => state.setting);
  const tracerName = props.selectedFile['Tracer']
  // debugger;
  return (
    <div className={styles.ReportLayoutContainer}>
      {/* TODO: 환자정보의 내용이 길어서 줄바꿈이 생기면 페이지가 밑으로 늘어나는 상황 발견: 페이지 높이 고정 필요할듯... */}
      <div className={styles.top}>
        <span>{'This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.'}</span>
        <span>{'Last Printed'}<br/>{nDate}</span>
      </div>

      <div className={styles.head}>
        <div className={styles.title}>
          <span>{'BTXBrain-'}{productCoreItem.productShortName}</span>
          <span>{`AI-based Automatic ${productCoreItem.productFullName} PET Quantification Report`}</span>
        </div>
      </div>

      <div className={styles.body}>
        {props.children}
        <div className={styles.overlay}>

        </div>
      </div>

      <div className={styles.foot}>
        <span>{`Quantification analysis details`}</span>
        <span>{`Atlas used: ${setting[productName].defaultAtlas} / Reference region: ${setting[productName].defaultRef[tracerName]}`}</span>
      </div>

      <div className={styles.bottom}>
        <span><img src={media.logo}/></span>
        <div>
          <span>{`PatientID : ${props.PatientID}`}</span>
          <span>{`page ${props.pageNumber}`}</span>
        </div>
      </div>
    </div>
  )
}