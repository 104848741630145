import axios from "axios";
import { IPinUSE } from "./IPs";

export function fetchFiles(data) {
  return axios.get(IPinUSE + "testing/", {
    headers: {
      Authorization: "jwt " + data.token,
    },
  });
}

export function update_worklist(data) {
  return axios.put(IPinUSE+"testing/update_filelist/",
  {
    obj:data.obj,
    addToWorklist:data.addToWorklist
  },
  {
    headers:{
      'Authorization':'jwt '+data.token,
    }
  });
}

export function deleteSelection(data) {
  return axios.delete(IPinUSE + "testing/", {
    headers: {
      Authorization: "jwt " + data.token,
    },
    data: data.obj,
  });
}
