import React, { Fragment, useState, useRef } from 'react';
import styles from './QuantTable.module.scss';
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionsControl from "1_reduxs/actions/controllerAction";
import * as media from "6_media";
import { AiOutlinePlus, AiOutlineMinus, AiOutlinePercentage } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip'

export default function QuantTable({eventEnabled=true, tableHead, items, trClick, setTrClick, sortClick, setSortClick, data, defaultAtlas}) {
  const location = useLocation();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const [hoverSelect, setHoverSelect] = useState("")

  return <>
    <table className={styles.table}>
      <thead className={styles.thead}>
        <tr style={{height:"0.5vw"}}>
          {tableHead?.map((head, idx) => 
            <th key={idx} 
              className={`${styles[head]}`}
              onClick={()=>{
                if (eventEnabled){
                  // sortClick[head.title]==='ascend' ? setSortClick({[head.title]:'descend'}) : setSortClick({"Region":"ascend"})
                  // if(sortClick[head.title] !== 'Region'){
                    !Object.keys(sortClick).includes(head) ? setSortClick({[head]:'ascend'}):sortClick[head]==='ascend' ? setSortClick({[head]:'descend'}):setSortClick({'id':'ascend'})
                  // }else {
                  //   sortClick[head.title]==='ascend' ? setSortClick({[head.title]:'descend'}) : setSortClick({"Region":"ascend"})
                  // }
                  
                }
              }}
            > 
              <div>
                <span>{head}</span>
                {eventEnabled && <div style={{visibility : `${(sortClick[head] === 'ascend' || sortClick[head] === 'descend') ? "":"hidden"}`  }}>
                  {eventEnabled && <img src={!Object.keys(sortClick).includes(head) ? media.arrowSort : sortClick[head]==='ascend' ? media.arrow_down : media.arrow_up} alt=""/>}
                </div>}
              </div>
            </th>
          )}
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {items
        .sort((a,b)=>{
          if (Object.keys(sortClick).length!==0){
            if (Object.values(sortClick)[0] === 'ascend')
              return a[Object.keys(sortClick)[0]] < b[Object.keys(sortClick)[0]] ? -1:1
            else if (Object.values(sortClick)[0] === 'descend')
              return b[Object.keys(sortClick)[0]] < a[Object.keys(sortClick)[0]] ? -1:1
            else 
              return a.id-b.id
          } else {
            return a.id-b.id
          }
        })
        .map((item, idx) =>{
        return <Fragment key={item.id}>
          <tr
            className={styles.trMain}
            onClick={()=> {
              if (eventEnabled){
                if (trClick.includes(item.Region)){
                  setTrClick(trClick.filter(v => v !== item.Region))
                }else{
                  setTrClick([...trClick, item.Region])
                }
              }
            }}
          >
            {tableHead.map((el,idx)=>{
              return <td className={`${styles[el]}`} key={idx} 
                // style={{border:`${el==='Region'? "1px solid red": ""}`, position:`${el==='Region'? "relative": ""}`}}
                >
                {(()=>{
                  if (el==='Region'){
                    if(productName==='dat'){
                      return  <div 
                                onMouseEnter={(e)=>{
                                  const updateData = (setting['dat'].list).filter(v=> v.fullname ===item[el]).filter(data => data.multiAtlas === defaultAtlas)[0].atlas
                                  setHoverSelect(updateData)
                                  console.log(hoverSelect)
                                }}
                                data-tip data-for="tooltip"
                              >
                                {eventEnabled && (item.subItem.length !== 0 ? trClick.includes(item.Region) ? <AiOutlineMinus size={"0.5vw"}/>:<AiOutlinePlus size={"0.5vw"}/>:"\u00a0"+"\u00a0")} {item[el]}
                                  <ReactTooltip
                                     id="tooltip"
                                     effect="solid"
                                     place="left"
                                     type="dark"
                                   >
                                    {hoverSelect}
                                    {/* {(setting['dat'].list).filter(v=> v.fullname === "Locus coeruleus")[0].atlas} */}
                                  </ReactTooltip>
                              </div>;
                    }
                    return <>{eventEnabled && (item.subItem.length !== 0 ? trClick.includes(item.Region) ? <AiOutlineMinus size={"0.5vw"}/>:<AiOutlinePlus size={"0.5vw"}/>:"\u00a0"+"\u00a0")} {item[el]}</>
                  }
                  else if (el === 'Measure') return <>{eventEnabled && (item.subItem.length !== 0 ? trClick.includes(item.Region) ? <AiOutlineMinus size={"0.5vw"}/>:<AiOutlinePlus size={"0.5vw"}/>:"\u00a0"+"\u00a0")} {item[el]}{(()=>{if (item[el].includes("index")) return ", %"})()}</>;
                  else if (el === 'Total') return <>{item[el]?.toFixed(2)}</>
                  else if (el === 'Centiloid') return <>{
                          <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <div style={{height:"10%", backgroundColor:"#4d5055", borderRadius:"50px", overflow:"hidden", width:"95%"}}>
                              <div style={{height:"5px",width:`${Math.min(100, Math.max(0,item[el]?.toFixed(2)))}%`, backgroundColor:"red"}}></div>
                            </div>
                          </div>
                        }</>
                  // else if (el === 'Centiloid') return <>{item[el]?.toFixed(2)}</>
                  else return <>{(item.Left !== item.Right) ? item[el]?.toFixed(2):"-"}</>
                })()}
              </td>
            })}
          </tr>
          {eventEnabled && item.subItem.map((sub, idx)=>{
            return(
              <tr key={idx} className={`${styles.trSub} ${trClick.includes(item.Region) ? styles.active:styles.inactive}`} >
                {tableHead.map((el,idx)=>{
                  // debugger;
                  return <td key={idx}>
                    {(()=>{
                      if (el === 'Region') return <>&emsp;&nbsp;{sub[el]}</>
                      else if (el === 'Measure') return <>&emsp;&nbsp;{sub[el]}</>
                      else if (el === 'Centiloid') return <>{
                              <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <div style={{height:"10%", backgroundColor:"#4d5055", borderRadius:"50px", overflow:"hidden", width:"95%"}}>
                                  <div style={{height:"5px",width:`${Math.min(100, Math.max(0,sub[el]?.toFixed(2)))}%`, backgroundColor:"red"}}></div>
                                </div>
                              </div>
                      }</>
                      else return <>{(sub.Left !== sub.Right) ? sub[el]?.toFixed(2):"-"}</>
                    })()}
                  </td>
                })}
              </tr>
            )
          }  
          )}
        </Fragment>
        })}
      </tbody>
    </table>
  </>;
}
