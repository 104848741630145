import React, {useState} from 'react';
import styles from './FileTable.module.scss';
import { useSelector, useDispatch } from "react-redux";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionsControl from "1_reduxs/actions/controllerAction";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";
import { BsThreeDots } from 'react-icons/bs';
import { range, style } from 'd3';
import ReactTooltip from 'react-tooltip';
// import { dispatch } from 'd3';

export default function FileTable({identifier, LOCKSELECT=false, productName, tableHead, FileList, sortClick, setSortClick, clickHandler, doubleClickHandler}) {
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const [infoState, setInfoState] = useState(false);
  const tracerList = productCoreItem.tracers.map(el=>el.shortname);
  const productList = FileList.filter(el=>tracerList.includes(el.Tracer));
  // const selectedStatus = productList.every(el=>el.Select === true);
  const [slidingTarget, setSlidingTarget] = useState({});
  const [currPage, setCurrPage] = useState(1) 
  const [slicePageNum, setSlicePageNum] = useState(10)
  const [pageVolume, setPageVolume] = useState(10)
  const pagenationNum = [...Array(Math.ceil(FileList?.length/slicePageNum)).keys()].map(v=>v+1)
  const selectAllFileIndex = [...Array(slicePageNum).keys()].map(v=>v+((currPage-1)*slicePageNum))
  const fileArray = FileList.filter((el,idx)=>selectAllFileIndex.includes(idx))
  const fileIDArray = fileArray.map(el=>el.id)
  const selectedStatus = fileArray.every(el=>el.Select === true);
  // console.log(fileIDArray)
  // console.log(pagenationNum)
  // debugger;
  // console.log(sortClick)
  return <>
    <table className={styles.table}>
      <thead>
        <tr>
          {tableHead.filter(v=>CTN_Mode ? v.title !== 'Centiloid':true).map((head, idx)=>
          <th key={idx} className={`${styles[head.title.replaceAll(' ', '_')]}`}
            onClick={()=>{
              if (!Object.keys(sortClick).includes(head.title)){
                setSortClick({[head.title]:'ascend'})
              } else {
                if (head.title !== "Uploaded date"){
                  sortClick[head.title]==='ascend' ? setSortClick({[head.title]:'descend'}):setSortClick({"Uploaded date":"descend"})
                } else {
                  sortClick[head.title]==='descend' ? setSortClick({[head.title]:'ascend'}):setSortClick({"Uploaded date":"descend"})
                }
              }
            }}
          >
            <div >
              {/* {head.title === 'Select' && <img src={media.Check} alt=""/>} */}
              {head.title === 'Select' ? 
              <span style={{display:"flex", alignItems:"center", cursor:"pointer"}}
                onClick={(e)=>{
                  if (head.title === 'Select'){
                    e.stopPropagation();
                    // const selectedStatus = productList.every(el=>el.Select === true);
                    const groupSelector = identifier === "worklist" ? 1:0;
                    // debugger;
                    if(identifier==="worklist"){
                      dispatch(actionsFiles.selectPageAllFiles(groupSelector, productName, !selectedStatus, fileIDArray));
                    }else{
                      dispatch(actionsFiles.selectAllFiles(groupSelector, productName, !selectedStatus, ));
                    }
                  }
                }}
              >
                <img src={selectedStatus ? media.Check:media.unCheck} style={{width:'0.8vw'}} alt=""/>
              </span>
              :<span >
                {head.title==="Centiloid" ? 
                  <div 
                    className={styles.centiolodInfoWrapper}
                    onMouseOver={(e)=>{setInfoState(true)}}
                    onMouseLeave={(e)=>{setInfoState(false)}}
                  >
                    <img src={media.info} data-tip data-for="tooltip"/>
                    <div>{head.title}</div>
                    <ReactTooltip
                      id="tooltip"
                      effect="solid"
                      place="bottom"
                      type="dark"
                    >
                      {"Centiloid is calculated using whole cerebellum as a reference region."}
                    </ReactTooltip>
                    {/* <div 
                      className={styles.centiloidMouseOverInfo}
                      style={{visibility:`${infoState ? '': 'hidden'}`}}
                    >
                      Centiloid is calculated using whole cerebellum as a reference region.
                    </div> */}
                  </div> 
                  : head.title
                }
               </span>
              }
              <div
                style={{visibility:`${(sortClick[head.title] === 'ascend' || sortClick[head.title] === 'descend') && Object.keys(sortClick).includes(head.title) ? 
                                      "": 
                                      'hidden'
                                    }`}} 
              >
                <img src={!Object.keys(sortClick).includes(head.title) ? media.arrowSort : sortClick[head.title]==='ascend' ? media.arrow_down : media.arrow_up} alt=""/>
              </div>
            </div>
          </th>)}
        </tr>
      </thead>
      <tbody>
        {FileList
        .sort((a,b)=>{
          if (Object.keys(sortClick).length!==0){
            const targetKey = tableHead.find(v=>v.title===Object.keys(sortClick)[0]).fieldName;
            if (Object.values(sortClick)[0] === 'ascend')
              return a[targetKey] < b[targetKey] ? -1:1
            else if (Object.values(sortClick)[0] === 'descend'){
              return b[targetKey] < a[targetKey] ? -1:1}
            else 
              return b.id-a.id
          } else {
            return b.id-a.id
          }
        })
        .filter((v, idx)=>{
          if (identifier === 'worklist'){
            const tempPage = currPage-1
            const indexRangeFirst = tempPage*slicePageNum;
            const indexRangeLast = tempPage*slicePageNum+slicePageNum;
            // const selectedNum = 1;
            // const currPage
            return indexRangeFirst <= idx && idx < indexRangeLast; 
          } else {
            return true
          }
        })
        .map((v1,idx)=>
          <tr key={v1.fileID} style={{background: v1.Opened ? styles['opened-color']:"", color: control[productName].selectedFileID === v1.fileID ? styles['selected-color']:""}} 
              className={styles.trMain} 
              onMouseLeave={()=>setSlidingTarget({})}
              onClick={()=>{if(!LOCKSELECT || v1.Opened === false) clickHandler(v1)}} 
              onDoubleClick={()=>{doubleClickHandler(v1)}}>

            {tableHead.filter(v=>CTN_Mode ? v.title !== 'Centiloid':true).map((v2,idx)=><td key={idx} className={`${styles[v2.title.replaceAll(' ', '_')]}`} style={{color: v1.Complete ? "rgba(255,255,255,1)":"rgba(255,255,255,0.3)"}} onDoubleClick={(e)=>{if (v2.fieldName === 'Select') e.stopPropagation()}}>
              {(()=>{
                // debugger;
                const tracerInfo = coreItem[productName].tracers.find(el=>el.shortname===v1[v2.fieldName]);
                // debugger;
                if (v2.title === 'Select') {return <img src={v1['Select']===true ? media.Check : media.unCheck}/>}
                else if (v2.title === 'Tracer') {return <div className={styles.TracerMarker} style={{borderColor:tracerInfo?.color}}>
                  {v1[v2.fieldName].replaceAll("18","\u00B9\u2078")}
                </div>}
                else if (v2.title === 'Centiloid') {return <div style={{display:"flex", alignItems:'center', justifyContent:"space-between"}}>
                  {v1.Complete ? <>
                    <div style={{width:"40%", textAlign:"right", paddingRight:"4px"}}>{v1[v2.fieldName]?.toFixed(1)}</div>
                    <div className={styles.CentiloidBar}>
                      <div style={{width:`${Math.min(100, Math.max(0,v1[v2.fieldName]))}%`}}></div>
                    </div>
                  </>:<>
                    <div style={{width:"100%"}}>{'processing...'}<img className={styles.processingIcon} src={media.processing}/></div>
                  </>}
                </div>}
                else if (v2.title === 'SBR') {return <div style={{display:"flex", alignItems:'center', justifyContent:"center", }}>
                  {/* {v1[v2.fieldName]?.toFixed(2)} */}
                  {v1.Complete ? <>
                    {v1[v2.fieldName]?.toFixed(1)}
                  </>:<>
                    {'processing...'}<img className={styles.processingIcon} src={media.processing}/>
                  </>}
                </div>}
                else if (v2.title === 'Uploaded date') {return <div>
                  {v1[v2.fieldName].substring(0,16)}
                </div>}
                else if (v2.title === 'Patient name') {
                  return <div className={(slidingTarget?.id === v1.id && slidingTarget?.enable) ? styles.slideContainer:""}>
                    <span
                      onMouseEnter={(e)=>{
                        const currentDom = e.target.offsetWidth; 
                        const parentDom = e.target.parentElement.offsetWidth;
                        const slide = currentDom > parentDom;
                        setSlidingTarget({id:v1.id, enable:slide});
                        // debugger;
                      }}
                      // onMouseLeave={()=>setSlidingTarget({})}
                    >
                      {v1[v2.fieldName].toString()}
                    </span>
                  </div>
                }
                else {
                  return <div>{v1[v2.fieldName].toString()}</div>
                }
              })()}
            </td>)}
          </tr>
        )}
        {/* {FileList
        .sort((a,b)=>{
          if (Object.keys(sortClick).length!==0){
            const targetKey = tableHead.find(v=>v.title===Object.keys(sortClick)[0]).fieldName;
            if (Object.values(sortClick)[0] === 'ascend')
              return a[targetKey] < b[targetKey] ? -1:1
            else if (Object.values(sortClick)[0] === 'descend'){
              return b[targetKey] < a[targetKey] ? -1:1}
            else 
              return b.id-a.id
          } else {
            return b.id-a.id
          }
        })
        .map((v1,idx)=>
          <tr key={v1.fileID} style={{background: v1.Opened ? styles['opened-color']:"", color: control[productName].selectedFileID === v1.fileID ? styles['selected-color']:""}} 
              className={styles.trMain} 
              onMouseLeave={()=>setSlidingTarget({})}
              onClick={()=>{if(!LOCKSELECT || v1.Opened === false) clickHandler(v1)}} 
              onDoubleClick={()=>{doubleClickHandler(v1)}}>

            {tableHead.filter(v=>CTN_Mode ? v.title !== 'Centiloid':true).map((v2,idx)=><td key={idx} className={`${styles[v2.title.replaceAll(' ', '_')]}`} style={{color: v1.Complete ? "rgba(255,255,255,1)":"rgba(255,255,255,0.3)"}} onDoubleClick={(e)=>{if (v2.fieldName === 'Select') e.stopPropagation()}}>
              {(()=>{
                // debugger;
                const tracerInfo = coreItem[productName].tracers.find(el=>el.shortname===v1[v2.fieldName]);
                // debugger;
                if (v2.title === 'Select') {return <img src={v1['Select']===true ? media.Check : media.unCheck}/>}
                else if (v2.title === 'Tracer') {return <div className={styles.TracerMarker} style={{borderColor:tracerInfo?.color}}>
                  {v1[v2.fieldName].replaceAll("18","\u00B9\u2078")}
                </div>}
                else if (v2.title === 'Centiloid') {return <div style={{display:"flex", alignItems:'center', justifyContent:"space-between"}}>
                  {v1.Complete ? <>
                    <div style={{width:"40%", textAlign:"right", paddingRight:"4px"}}>{v1[v2.fieldName]?.toFixed(1)}</div>
                    <div className={styles.CentiloidBar}>
                      <div style={{width:`${Math.min(100, Math.max(0,v1[v2.fieldName]))}%`}}></div>
                    </div>
                  </>:<>
                    <div style={{width:"100%"}}>{'processing...'}<img className={styles.processingIcon} src={media.processing}/></div>
                  </>}
                </div>}
                else if (v2.title === 'SBR') {return <div style={{display:"flex", alignItems:'center', justifyContent:"center", }}>
      
                  {v1.Complete ? <>
                    {v1[v2.fieldName]?.toFixed(1)}
                  </>:<>
                    {'processing...'}<img className={styles.processingIcon} src={media.processing}/>
                  </>}
                </div>}
                else if (v2.title === 'Uploaded date') {return <div>
                  {v1[v2.fieldName].substring(0,16)}
                </div>}
                else if (v2.title === 'Patient name') {
                  return <div className={(slidingTarget?.id === v1.id && slidingTarget?.enable) ? styles.slideContainer:""}>
                    <span
                      onMouseEnter={(e)=>{
                        const currentDom = e.target.offsetWidth; 
                        const parentDom = e.target.parentElement.offsetWidth;
                        const slide = currentDom > parentDom;
                        setSlidingTarget({id:v1.id, enable:slide});
                        // debugger;
                      }}
                      // onMouseLeave={()=>setSlidingTarget({})}
                    >
                      {v1[v2.fieldName].toString()}
                    </span>
                  </div>
                }
                else {
                  return <div>{v1[v2.fieldName].toString()}</div>
                }
              })()}
            </td>)}
          </tr>
        )} */}
      </tbody>
    </table>
    <div className={styles.selectPageNum} style={{display:`${identifier==='upload'?'none':''}`}}>
        <div className={styles.arrowIcon} 
             onClick={()=>{
              const newCurrPage = ()=>{
                return(currPage===1 ? 1 : currPage-1)
              }
              setCurrPage(newCurrPage)
             }
            //  setCurrPage(currPage-1)
             }
        >
          <img src={media.arrow_left}/>
        </div>
        
        <div className={styles.selectNum_Arr} style={{overflow:"", }}>
          <div style={{visibility:pagenationNum.length > pageVolume && currPage > pageVolume/2 ? "visible":"hidden"}}><BsThreeDots/></div>
          {pagenationNum.filter(v=>(Math.min((currPage-pageVolume/2), pagenationNum.length-10) < v) && (v <= Math.max(10,(currPage + pageVolume/2)))).map(v=>{
            return <div style={{fontWeight:v===currPage ? 800:""}} onClick={()=>setCurrPage(v)}>{v}</div>
          })}
          <div style={{visibility:pagenationNum.length > pageVolume && pagenationNum.length-pageVolume/2 > currPage ? "visible":"hidden"}}><BsThreeDots/></div>
        </div>
        
        <div className={styles.arrowIcon} onClick={()=>{
          const newCurrPage = ()=>{
            return(currPage===pagenationNum.length? pagenationNum.length : currPage+1)
          }
          setCurrPage(newCurrPage)  
        }}
        >
          <img src={media.arrow_right}/>
        </div>
    </div>
  </>;
}
