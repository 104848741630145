import React, { useEffect, useImperativeHandle, useState, useCallback } from "react";
import styles from "./Home.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Signin, Signup, Forgot, SelectProduct } from "./components";
import { Header, Menu, Worklist, SpinnerModal } from "5_components";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";
// import { LogoWhite, brtnxLogo, setting } from "6_media";
import BrtnxLogo from "6_media/svg/BrtnxLogo";

export default function Home(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const [leftBtn, setLeftBtn] = useState({
    "/signin":{title: 'SIGN UP', push:"/signup"}, 
    "/signup":{title: 'SIGN IN', push:"/signin"}, 
    "/forgot":{title: 'SIGN IN', push:"/signin"}, 
    "/selectProduct":{title:'LOG OUT', push:"/signin"}
  })

  return (
    <>
      <div className={`${styles["container"]}`}>
        <div className={styles["leftfloat"]}>
          <div style={{width:"30%"}}><BrtnxLogo width="5vw" height="3vw" fill="white"/></div>
          {/* <div><img src={media.logoMain} alt=""/></div> */}
          <div onClick={()=>history.push(leftBtn[location.pathname].push)}>
            {leftBtn[location.pathname].title}
          </div>
        </div>
        
        {location.pathname === "/signin" && <div className={styles["rightfloat"]}><Signin /></div>}
        {location.pathname === "/signup" && <div className={styles["rightfloat"]}><Signup/></div>}
        {location.pathname === "/forgot" && <div className={styles["rightfloat"]}><Forgot/></div>}
        {location.pathname === "/selectProduct" && <div className={styles["rightfloat"]}><SelectProduct/></div>}
      </div>
      {modalState.spinner.status && <SpinnerModal/>}
    </>
  )
}
