import React, { useEffect, useState } from "react";
import styles from "./CardLayout.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import { median } from "d3";
import * as media from "6_media";
import ReactTooltip from 'react-tooltip';


export default function CardLayout({cards, content, width="50%", height="90%", focusTo, setFocusTo}) {
  const [infoState, setInfoState] = useState(false);
  // const location = useLocation();
  const isFocused = (componentName, focusTo) => {
    if (componentName === "Analysis_card2_1"){
      return focusTo === "Analysis_card2_2" ? "10%":"100%"
    } else if (componentName === "Analysis_card2_2"){
      return focusTo === "Analysis_card2_1" ? "10%":"100%"
    } else return ""
  }
  const isFocusAvailable = (componentName) => {
    const cond1 = componentName === "Analysis_card2_1";
    const cond2 = componentName === "Analysis_card2_2";
    if (cond1 || cond2) return true;
    else return false;
  }
  return (
    <div className={styles["container_wrapper"]} style={{width:width, height:height}} >
      {cards.map((card,idx)=>{
        return (
        <div key={idx} className={styles["container"]} 
          style={{
            // borderColor:card.componentName === focusTo ? "red":"",
            height:isFocusAvailable(card.componentName) ? isFocused(card.componentName, focusTo):""
            }}>
          <div className={styles["top"]}>
            {<div style={{display:"flex", justifyContent:"space-between",}}>
              {card.title === "Regional Centiloid" ?
                  <div className={styles.infoWrapper}>
                    <div 
                      data-tip data-for="tooltip"
                    >
                      <span>{card.title}</span>
                      <img src={media.info}/>
                    </div>
                    <ReactTooltip
                      id="tooltip"
                      effect="solid"
                      place="right"
                      type="dark"
                    >
                      {"Centiloid is calculated using whole cerebellum as a reference region."}
                    </ReactTooltip>
                  </div>
                : card.title
              } 
              {isFocusAvailable(card.componentName) && 
                <span style={{fontSize:"1.5vw", cursor:"pointer", userSelect:"none"}} 
                      onClick={()=>{focusTo === card.componentName ? setFocusTo(""):setFocusTo(card.componentName)}}>
                  {card.componentName === focusTo ? "\u2013":"+"}
                </span>}
            </div>}
            {card.Date && <div>{card.Date}</div>}
          </div>
          <div className={styles["bottom"]}>
            {card.content}
          </div>
        </div>)}
      )}
    </div>
  )
}

