import React, { useEffect, useState } from "react";
import styles from "./DefaultTracer.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";


export default function DefaultTracer(props) {
  const location = useLocation();
  const params = useParams();
  const productCoreItem = coreItem[params.product.toLowerCase()];
  const tracerColor = productCoreItem.tracers.reduce((obj, item)=>{
    obj['fullname']=item.fullname; 
    obj['shortname']=item.shortname;
    obj['color']=item.color;
    return obj;
  },{});
  const tracerItems = productCoreItem.tracers.map(item=>{
    return{
      'fullname':item.fullname,
      'color':item.color,
      'shortname':item.shortname
    }
  })

  return (
    <div className={styles["container"]}>
      <div className={styles["itemsContainer"]}>
        {tracerItems.map(item => 
          <div className={styles.items}>
            <div onClick={()=>props.defaultTracerProps.setSelectTracer(item)}>
              <img src={props.defaultTracerProps.selectTracer.shortname === item.shortname ? media.Check:media.unCheck}/>
            </div>
            <div style={{borderLeft: `6px solid ${item.color}`}}>{item.fullname.replace('18','\u00B9\u2078' )}</div>
          </div>
        )}
      </div>
    </div>
  )
}

