import React, { useEffect, useState } from "react";
import styles from "./ReportPages.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import {ReportPage1, ReportPage2,ReportPage22, ReportPage3} from "./components";
import * as services from "2_services/sliceApi";
import * as utils from "0_variables/utils";
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ReportPages(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase();
  const control = useSelector((state) => state.control);
  const productCoreItem = coreItem[productName];
  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  const [imageIds, setImageIds] = useState([]);
  const [sliceReady, setSliceReady] = useState(true);
  const [selectedFile, setSelectedFile] = useState(fileList.find(({fileID})=>fileID === props.fileID));

  useEffect(()=>{
    if (fileList.length === 0){
      history.push(`/${productName}/upload`);
    } else {
      const selectedFile = fileList.find(({fileID})=>fileID === props.fileID);
      setSelectedFile(selectedFile);
    }
  },[])

  const settingOfProduct = setting[productName]
  const tracerName = selectedFile['Tracer'];
  const refName = settingOfProduct.defaultRef[tracerName]
  const RF = (() => {
    if (refName === "Pons") return selectedFile["ratio_gry2pons"];
    else if (refName === "Whole cerebellum") return selectedFile["ratio_gry2crbl"];
    else if (refName === "Cerebellar gray matter") return 1;
    else if (refName === "Cerebral white matter") return selectedFile["ratio_gry2pons"];
    else if (refName === "Global cortex") return 1/selectedFile["Global"];
    else return 1;
  })()
 const RFforReport = (() => {
  if (refName === "Pons") return "pons";
  else if (refName === "Whole cerebellum") return "wc";
  else if (refName === "Cerebellar gray matter") return "cg";
  else if (refName === "Cerebral white matter") return selectedFile["wm"];
  else return "cg";
  })()
  return (
    <>
    {/* TODO: sliceReady는 ReportPage3에서 통신으로 이미지를 받아야했던 상황에 쓰였던건데 이제 필요없음 (나중에 삭제 필요) */}
      <div ref={el=>{if(props.innerRef) props.innerRef[0].current=el}} className={styles.A4pages}>
        <ReportPage1 {...props} selectedFile={selectedFile} fileID={props.fileID} pageNumber={"1/3"} RF={RF}/>
      </div>
      <div ref={el=>{if(props.innerRef) props.innerRef[1].current=el}} className={styles.A4pages}>
        <ReportPage2 {...props} selectedFile={selectedFile} fileID={props.fileID} pageNumber={"2/3"} RF={RF}/>
      </div>
      {/* <div ref={el=>{if(props.innerRef) props.innerRef[1].current=el}} className={styles.A4pages}
        style={{display:"none"}}
      >
        <ReportPage22 {...props} selectedFile={selectedFile} fileID={props.fileID} pageNumber={"2/3"} RF={RF}/>
      </div> */}
      <div ref={el=>{if(props.innerRef) props.innerRef[2].current=el}} className={styles.A4pages}>
        {/* TODO: imageIds를 여기서 만들어서 보내던 방식을 ReportPage3 내부에서 만들도록 변경함 (나중에 삭제 필요) */}
        <ReportPage3 {...props} selectedFile={selectedFile} imageIds={imageIds} fileID={props.fileID} pageNumber={"3/3"} refName={RFforReport}/>
      </div>
    </>
  )
}

// export function ReportPage1(props) {
//   const params = useParams();
//   const [productCoreItem, ] = useState(coreItem[params.product.toLowerCase()]);
//   const today = new Date();
//   const date = today.toJSON().slice(0, 10);
//   const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
//   return (
//     <>
//       <div ref={el=>{if(props.innerRef) props.innerRef=el}} className={styles.A4pages}>
//         <ReportLayout {...props}>
//           <div>{props.fileID}</div>
//         </ReportLayout>
//       </div>
//     </>
//   )
// }

// export function ReportPage2(props) {
//   const params = useParams();
//   const [productCoreItem, ] = useState(coreItem[params.product.toLowerCase()]);
//   const today = new Date();
//   const date = today.toJSON().slice(0, 10);
//   const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);

//   return (
//     <>
//       <div ref={el=>{debugger;if(props.innerRef) props.innerRef=el}} className={styles.A4pages}>
//         <ReportLayout {...props}>
//           <div>{props.fileID}</div>
//         </ReportLayout>
//       </div>
//     </>
//   )
// }

// export function ReportPage3(props) {
//   const params = useParams();
//   const [productCoreItem, ] = useState(coreItem[params.product.toLowerCase()]);
//   const today = new Date();
//   const date = today.toJSON().slice(0, 10);
//   const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);

//   return (
//     <>
//       <div ref={el=>{if(props.innerRef) props.innerRef=el}} className={styles.A4pages}>
//         <ReportLayout {...props}>
//           <div>{props.fileID}</div>
//         </ReportLayout>
//       </div>
//     </>
//   )
// }