import React, { useEffect, useState } from "react";
import styles from "./UploadingStep1.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
// import Container from "./components/Container";
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as media from "6_media";

export default function UploadingStep1({idx, selected, uploadingStepProps, closeModal,deleteFiles}) {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  // const [subRegionDefault, setSubRegionDefault] = useState(subRegions[productName].filter(({type})=>type===typeSelector[productName]));
  return (
    <>
      <div className={styles.boxContainer} ref={el => uploadingStepProps.handleRef(idx, el)} style={{display: selected ? '':'none'}}>
        {/* <span className={styles.backButton} onClick={()=>uploadingStepProps.executeScroll(idx-1)}>
          {'\u1438 Back'}
        </span> */}
        {/* <span className={styles.exitButton}
          onClick={()=>{ closeModal; deleteFiles; }
        }>
          {'X'}
        </span> */}
        <div className={styles.boxTitle}>
          {"Step1. Tracer Selection"}
        </div>

        <div className={styles.boxContent}>
          {productCoreItem.tracers.map(item=><span 
            className={`${uploadingStepProps.selectTracer.shortname === item.shortname ? styles.active:""}`}
            onClick={()=>uploadingStepProps.setSelectTracer(item)}
            style={{border:`3px ${uploadingStepProps.selectTracer.shortname === item.shortname ? item.color:"transparent"} solid`}}>
            <div>{item.fullname.replaceAll("18","\u00B9\u2078")}</div>
            <div>{item.commercialname}</div>
          </span>)}
        </div>


        <div className={styles.buttonGroup}>
          <div className={styles.nextButton} onClick={()=>uploadingStepProps.executeScroll(idx+1)}>
            {"Next \u1433"}
          </div>
        </div>
      </div>
    </>
  )
}