import * as modalActions from "1_reduxs/actions/modalAction";

const initialStates = {
  spinner: {status:false, percent:0, message:''},
  // uploader: false,
  // pacs: false,
  // expired: false,
  customROI: {status:false},
  pacsModal: {status:false},
  uploadModal: {status:false},
  expiredModal: {status:false},
  // Uploading: {pacs:false, upload:false},
};

const modalReducer = (state = initialStates, action) => {
  const { type, obj } = action;
  switch (type) {
    case modalActions.OPEN_SPINNER:
      return { ...state, spinner: {...state.spinner, ...obj} };
    // case modalActions.OPEN_UPLOADER:
    //   return { ...state, uploader: isOn };
    // case modalActions.OPEN_PACS:
    //   return { ...state, pacs: isOn };
    // case modalActions.OPEN_EXPIRED:
    //   return { ...state, expired: isOn };
    case modalActions.OPEN_PACS_MODAL:
      return { ...state, pacsModal: {status:obj.isOn} };
    case modalActions.OPEN_UPLOAD_MODAL:
      return { ...state, uploadModal: {status:obj.isOn} };
    case modalActions.OPEN_EXPIRED_MODAL:
      return { ...state, expiredModal: {status:obj.isOn} };
    case modalActions.OPEN_CUSTOMROI:
      return { ...state, customROI: {status:obj.isOn} };
    default:
      return state;
  }
};

export default modalReducer;
