import React, { useEffect, useState, useRef, createRef } from "react";
import styles from "./ReportViewers.module.scss";
import variables from '3_styles/_variables.scss';
import { useSelector, useDispatch } from "react-redux";
// import * as actionsControl from "1_reduxs/actions/controllerAction";
import {closeFileAndChangingTab, selectedFileID} from "1_reduxs/reducers/controllerReducer";
import { Route, useHistory, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import * as actionsFiles from "1_reduxs/actions/filesAction";
import {coreItem} from "0_variables/coreItem";
import * as utils from "0_variables/utils";
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import {ImageViewer} from "5_components";
import chroma from 'chroma-js';
import { RiArrowLeftSFill, RiArrowLeftFill } from 'react-icons/ri';
import * as media from "6_media";
import {Arrow} from './components';

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { median } from "d3";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ReportViewers(props) {
  const username = sessionStorage.getItem("username");
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const containerRef = useRef(null);
  const [axialViewportRef,setAxialViewportRef] = useState([...Array(8)].map((_, i) => createRef()));
  const [mipViewportRef,setMipViewportRef] = useState([...Array(8)].map((_, i) => createRef()));
  const axialCmap = 'jetV2';
  const mipCmap = 'invertGray';
  const [cmapAxial, setCmapAxial] = useState({name:axialCmap, from:1, to:255, code:[...Array(256)].map((v,i)=>chroma(cornerstone.colors.getColormap(axialCmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())})
  const [cmapMIP, setCmapMIP] = useState({name:mipCmap, from:0, to:255, code:[...Array(256)].map((v,i)=>chroma(cornerstone.colors.getColormap(mipCmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())})
  const wwwcsynchronizer = new cornerstoneTools.Synchronizer("cornerstoneimagerendered", cornerstoneTools.wwwcSynchronizer);
  const intensity = {min:0, max:32767};

  const Synchronizers = {
    wwwcsynchronizer,
  }
  // debugger; props.selectedFile.out_suvr_max
  const [axialProps,setAxialProps] = useState((()=>{
    if (productName==='amyloid' || productName==='fdg' || productName==='tau'){
      const suvrMax = props.selectedFile.out_suvr_max;
      const suvrMin = props.selectedFile.out_suvr_min;
      const suvrWidth = suvrMax-suvrMin;
      const intensityMax = 32767;
      const intensityMin = 0;
      const intensityUpper = Math.min(2.6/suvrWidth*intensityMax, intensityMax);
      const intensityLower = 0.2/suvrWidth*intensityMax;
      const intensityWidth = (intensityUpper-intensityLower).toFixed(0);
      const intensityCenter = ((intensityUpper+intensityLower)/2).toFixed(0);
      // debugger;
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapAxial.name,
            voi:{
              windowWidth:intensityWidth,
              windowCenter:intensityCenter,
            }
          },
          // name: 'PET'
        }
      }
    } else if (productName==='dat') {
      const suvrMax = props.selectedFile.out_suvr_max;
      const suvrMin = props.selectedFile.out_suvr_min;
      const suvrWidth = suvrMax-suvrMin;
      const intensityMax = 32767;
      const intensityMin = 0;
      const intensityUpper = Math.min(4.6/suvrWidth*intensityMax, intensityMax);
      const intensityLower = 0.2/suvrWidth*intensityMax;
      const intensityWidth = (intensityUpper-intensityLower).toFixed(0);
      const intensityCenter = ((intensityUpper+intensityLower)/2).toFixed(0);
      // debugger;
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapAxial.name,
            voi:{
              windowWidth:intensityWidth,
              windowCenter:intensityCenter,
            }
          },
          // name: 'PET'
        }
      }
    } else {
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapAxial.name,
            voi:{
              windowWidth:25767,
              windowCenter:13384,
            }
          },
          // name: 'PET'
        }
      }
    }
  })());

  const [mipProps,setMipProps] = useState((()=>{
    if (productName==='amyloid' || productName==='fdg' || productName==='tau'){
      const suvrMax = props.selectedFile.out_suvr_max;
      const suvrMin = props.selectedFile.out_suvr_min;
      const suvrWidth = suvrMax-suvrMin;
      const intensityMax = 32767;
      const intensityMin = 0;
      const intensityUpper = 2.6/suvrWidth*intensityMax;
      const intensityLower = 0.8/suvrWidth*intensityMax;
      const intensityWidth = (intensityUpper-intensityLower).toFixed(0);
      const intensityCenter = ((intensityUpper+intensityLower)/2).toFixed(0);
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapMIP.name,
            voi:{
              windowWidth:intensityWidth,
              windowCenter:intensityCenter,
            }
          },
          // name: 'PET'
        }
      }
    } else if (productName==='dat') {
      const suvrMax = props.selectedFile.out_suvr_max;
      const suvrMin = props.selectedFile.out_suvr_min;
      const suvrWidth = suvrMax-suvrMin;
      const intensityMax = 32767;
      const intensityMin = 0;
      const intensityUpper = Math.min(4.6/suvrWidth*intensityMax, intensityMax);
      const intensityLower = 0.2/suvrWidth*intensityMax;
      const intensityWidth = (intensityUpper-intensityLower).toFixed(0);
      const intensityCenter = ((intensityUpper+intensityLower)/2).toFixed(0);
      // debugger;
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapMIP.name,
            voi:{
              windowWidth:intensityWidth,
              windowCenter:intensityCenter,
            }
          },
          // name: 'PET'
        }
      }
    } else {
      return {
        options:{
          // opacity: 1,
          // visible: true,
          viewport: {
            colormap: cmapMIP.name,
            voi:{
              windowWidth:25767,
              windowCenter:13384,
            }
          },
          // name: 'PET'
        }
      }
    }
  })());

  const _axialProps = {
    vProps:axialProps, setVProps: setAxialProps
  }
  
  const _mipProps = {
    vProps:mipProps, setVProps: setMipProps
  }

  return (
    (()=>{
      // TODO: 땜빵 수정 필요
      if (productName === 'amyloid' || productName === 'fdg' || productName === 'tau'){
        return (
          <>
            <div className={`${styles['container']}`}>
                <div ref={containerRef} style={{width:"90%"}} className={styles.viewerContainer}>
                  {(containerRef.current !== null) && props.imageIds.map((el,i)=>{
                    const pxWidth = containerRef.current.clientWidth;
                    const Direction = el.split('/')[3]
                    const size = utils.getSizebyProduct(Direction);
                    const width = pxWidth * 0.24;
                    const height = width*utils.ratio2Width(size);
                    return (
                    <div style={{textAlign:"center",}}>
                      {/* <div style={{fontSize:"10px"}}>{el.toString()}</div> */}
                      <ImageViewer key={i} index={i} ref={axialViewportRef[i]} imageId={el} width={`${width}px`} height={`${height}px`} {..._axialProps} Synchronizers={Synchronizers}/>
                    </div>
                  )
                  })}
                </div>
                <div className={styles.cmapContainer}>
                  <div>{props.selectedFile.out_suvr_max?.toFixed(2)}</div>
                  {(()=>{
                    if (axialProps.options.viewport.voi?.windowCenter !== undefined){
                      // debugger;
                      const max = intensity.max;
                      const wc = utils.clamp(axialProps.options.viewport.voi.windowCenter/max*100,0,100);
                      const ww = utils.clamp(axialProps.options.viewport.voi.windowWidth/max*100,0,100);
                      const wu = utils.clamp(wc+(ww/2),0,100);
                      const wl = utils.clamp(wc-(ww/2),0,100);
                      const wcSUVR = (props.selectedFile.out_suvr_max * wc * 0.01)?.toFixed(2);
                      const wuSUVR = (props.selectedFile.out_suvr_max * wu * 0.01)?.toFixed(2);
                      const wlSUVR = (props.selectedFile.out_suvr_max * wl * 0.01)?.toFixed(2);
                      const win = {
                        center:wc.toFixed(0), 
                        width:ww.toFixed(0), 
                        upper:wu.toFixed(0), 
                        lower:wl.toFixed(0)
                      };
                      return (
                        <div className={styles.colorbar} 
                          style={{position:"relative", border:"0px green solid",
                          background:`linear-gradient(to top, ${cmapAxial.code[cmapAxial.from]} ${win.center}%, ${cmapAxial.code[cmapAxial.to]} ${win.center}%)`
                        }}>
                          <div style={{position:"relative", top:"0", left:"0", height:"100%", width:"100%", overflow:"hidden", borderRadius:"50px"}}>
                            <div style={{position:"absolute", height:`${ww}%`, width:"100%", transform:"translate(-50%,50%)", bottom:`${win.center}%`, left:"50%", 
                              background:`linear-gradient(to top, ${cmapAxial.code.slice(cmapAxial.from, cmapAxial.to).join(',')})`
                            }}>
                            </div>
                          </div>
                          <Arrow center={win.upper} value={wuSUVR}/>
                          <Arrow center={win.center} value={wcSUVR}/>
                          <Arrow center={win.lower} value={wlSUVR}/>
                        </div>
                      )
                    } else {
                      return (
                        <div className={styles.colorbar} style={{position:"relative", border:"0px red solid", background:`linear-gradient(to top, blue, red)`}}>
                          <div style={{position:"absolute", bottom:`${50}%`, left:"0", boxSizing:"border-box", border:"0px black solid", width:"100%"}}></div>
                        </div>
                      )
                    }
                  })()}
                  <div>{Math.max(0,props.selectedFile.out_suvr_min)?.toFixed(2)}</div>
                </div>
            </div>
          </>
        )
      } else if (productName === 'dat'){
        return (
          <>
            <div className={`${styles['container']}`}>
              <div ref={containerRef} style={{width:"90%"}} className={styles.viewerContainer}>
                {(containerRef.current !== null) && props.imageIds.filter(el=>{
                  const Direction = el.split('/')[3];
                  return Direction === 'axial';
                }).map((el,i)=>{
                  const pxWidth = containerRef.current.clientWidth;
                  const Direction = el.split('/')[3]
                  const size = utils.getSizebyProduct(Direction);
                  const width = pxWidth * 0.19;
                  const height = width*utils.ratio2Width(size);
                  return (
                  <div style={{textAlign:"center",}}>
                    {/* <div style={{fontSize:"10px"}}>{el.toString()}</div> */}
                    <ImageViewer key={i} index={i} ref={axialViewportRef[i]} imageId={el} width={`${width}px`} height={`${height}px`} {..._axialProps} Synchronizers={Synchronizers}/>
                  </div>
                )
                })}
              </div>
              <div className={styles.cmapContainer}>
                <div>{Math.max(0,props.selectedFile.out_suvr_max)?.toFixed(2)}</div>
                {(()=>{
                  if (axialProps.options.viewport.voi?.windowCenter !== undefined){
                    // debugger;
                    const max = intensity.max;
                    const wc = utils.clamp(axialProps.options.viewport.voi.windowCenter/max*100,0,100);
                    const ww = utils.clamp(axialProps.options.viewport.voi.windowWidth/max*100,0,100);
                    const wu = utils.clamp(wc+(ww/2),0,100);
                    const wl = utils.clamp(wc-(ww/2),0,100);
                    const wcSBR = (props.selectedFile.out_suvr_max * wc * 0.01)?.toFixed(2);
                    const wuSBR = (props.selectedFile.out_suvr_max * wu * 0.01)?.toFixed(2);
                    const wlSBR = (props.selectedFile.out_suvr_max * wl * 0.01)?.toFixed(2);
                    const win = {
                      center:wc.toFixed(0), 
                      width:ww.toFixed(0), 
                      upper:utils.clamp(wc+(ww/2),0,100).toFixed(0), 
                      lower:utils.clamp(wc-(ww/2),0,100).toFixed(0)
                    };
                    return (
                      <div className={styles.colorbar} 
                        style={{position:"relative", border:"0px green solid",
                        background:`linear-gradient(to top, ${cmapAxial.code[cmapAxial.from]} ${win.center}%, ${cmapAxial.code[cmapAxial.to]} ${win.center}%)`
                      }}>
                        <div style={{position:"relative", top:"0", left:"0", height:"100%", width:"100%", overflow:"hidden", borderRadius:"50px"}}>
                          <div style={{position:"absolute", height:`${ww}%`, width:"100%", transform:"translate(-50%,50%)", bottom:`${win.center}%`, left:"50%", 
                            background:`linear-gradient(to top, ${cmapAxial.code.slice(cmapAxial.from, cmapAxial.to).join(',')})`
                          }}>
                          </div>
                        </div>
                        <Arrow center={win.upper} value={wuSBR}/>
                        <Arrow center={win.center} value={wcSBR}/>
                        <Arrow center={win.lower} value={wlSBR}/>
                      </div>
                    )
                  } else {
                    return (
                      <div className={styles.colorbar} style={{position:"relative", border:"0px red solid", background:`linear-gradient(to top, blue, red)`}}>
                        <div style={{position:"absolute", bottom:`${50}%`, left:"0", boxSizing:"border-box", border:"0px black solid", width:"100%"}}></div>
                      </div>
                    )
                  }
                })()}
                <div>{Math.max(0,props.selectedFile.out_suvr_min)?.toFixed(2)}</div>
              </div>
            </div>
            <div className={`${styles['container']}`}>
              <div ref={containerRef} style={{width:"90%"}} className={styles.viewerContainer}>
                {(containerRef.current !== null) && props.imageIds.filter(el=>{
                  const Direction = el.split('/')[3];
                  return Direction === 'mip';
                }).map((el,i)=>{
                  const pxWidth = containerRef.current.clientWidth;
                  const Direction = el.split('/')[3]
                  const size = utils.getSizebyProduct(Direction);
                  const width = pxWidth * 0.24;
                  const height = width*utils.ratio2Width(size);
                  return (
                  <div style={{textAlign:"center",}}>
                    <ImageViewer key={i} index={i} ref={mipViewportRef[i]} imageId={el} width={`${width}px`} height={`${height}px`} {..._mipProps} Synchronizers={Synchronizers}/>
                  </div>
                )
                })}
              </div>
              <div className={styles.cmapContainer}>
                <div>{Math.max(0,props.selectedFile.out_suvr_max)?.toFixed(2)}</div>
                {(()=>{
                  if (mipProps.options.viewport.voi?.windowCenter !== undefined){
                    const max = intensity.max;
                    const wc = utils.clamp(mipProps.options.viewport.voi.windowCenter/max*100,0,100);
                    const ww = utils.clamp(mipProps.options.viewport.voi.windowWidth/max*100,0,100);
                    const wu = utils.clamp(wc+(ww/2),0,100);
                    const wl = utils.clamp(wc-(ww/2),0,100);
                    const wcSBR = (props.selectedFile.out_suvr_max * wc * 0.01)?.toFixed(2);
                    const wuSBR = (props.selectedFile.out_suvr_max * wu * 0.01)?.toFixed(2);
                    const wlSBR = (props.selectedFile.out_suvr_max * wl * 0.01)?.toFixed(2);
                    const win = {
                      center:wc.toFixed(0), 
                      width:ww.toFixed(0), 
                      upper:utils.clamp(wc+(ww/2),0,100).toFixed(0), 
                      lower:utils.clamp(wc-(ww/2),0,100).toFixed(0)
                    };
                    return(
                      <div className={styles.colorbar} 
                        style={{position:"relative", border:"0px green solid",
                        background:`linear-gradient(to top, ${cmapMIP.code[cmapMIP.from]} ${win.center}%, ${cmapMIP.code[cmapMIP.to]} ${win.center}%)`
                      }}>
                        <div style={{position:"relative", top:"0", left:"0", height:"100%", width:"100%", overflow:"hidden", borderRadius:"50px"}}>
                          <div style={{position:"absolute", height:`${ww}%`, width:"100%", transform:"translate(-50%,50%)", bottom:`${win.center}%`, left:"50%", 
                            background:`linear-gradient(to top, ${cmapMIP.code.slice(cmapMIP.from, cmapMIP.to).join(',')})`
                          }}>
                          </div>
                        </div>
                        <Arrow center={win.upper} value={wuSBR}/>
                        <Arrow center={win.center} value={wcSBR}/>
                        <Arrow center={win.lower} value={wlSBR}/>
                      </div>
                    )
                  } else {
                    return(
                      <div className={styles.colorbar} style={{position:"relative", border:"0px red solid", background:`linear-gradient(to top, blue, red)`}}>
                        <div style={{position:"absolute", bottom:`${50}%`, left:"0", boxSizing:"border-box", border:"0px black solid", width:"100%"}}></div>
                      </div>
                    )
                  }
                })()}
                <div>{Math.max(0,props.selectedFile.out_suvr_min)?.toFixed(2)}</div>
              </div>

      

            </div>
          </>
        )
      }
    })()
  )
}
