import React, {useEffect, useState} from "react";
import styles from "./SelectProduct.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import * as utils from "0_variables/utils";


export default function SelectProduct(){
	const history = useHistory();
	const dispatch = useDispatch();
	const [productSelected, setProductSelected] = useState('Amyloid');
	const loginState = useSelector((state) => state.login);
	const [signinValues, setSigninValues] = useState({'username':'', 'password':''});
	const [licenseInfo, setLicenseInfo] = useState({
		'amyloid':{"valid":false, "until":null}, 
		'dat':{"valid":false, "until":null}, 
		'fdg':{"valid":false, "until":null}, 
		'tau':{"valid":false, "until":null}
	})
	const productInfo = [
		{productFullName:"BTXBrain - Amyloid", productShortName:"amyloid", color:"#FF6D4C", validDate:licenseInfo["amyloid"]?.until, license:licenseInfo["amyloid"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - DAT", productShortName:"dat", color:"#F14CFF", validDate:licenseInfo["dat"]?.until, license:licenseInfo["dat"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - FDG", productShortName:"fdg", color:"#4CFF69", validDate:licenseInfo["fdg"]?.until, license:licenseInfo["fdg"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - Tau", productShortName:"tau", color:"#4C74FF", validDate:licenseInfo["tau"]?.until, license:licenseInfo["tau"]?.valid, version:"1.0.0"},
	]
	const [hoverState, setHoverState] = useState({
		amyloid : false,
		dat : false,
		fdg : false,
		tau : false,
	}) 

	const processFunc = async () => {
    if (loginState.logged){
      history.push(`/${productSelected}/upload`);
      console.log("login: Connected");
    } else {
      const resObj = await dispatch(actionsLogin.login_api({
        username:signinValues.username,
        password:signinValues.password,
      }))
      if (resObj.response) {
        history.push(`/selectProduct`);
        setSigninValues({'username':'', 'password':''});
      } else {
        if (resObj.code === undefined) alert("Network Error: Please check if Btxbrain-backend is running.");
        else alert("Login failed. Please enter a valid username and password.");
      }
      console.log(`${resObj.message} (${resObj.code}) `);
    }
  }
	useEffect(()=>{
		utils.getLicenseInfo(productInfo, setLicenseInfo)
	},[])

  return(
    <>
			<div className={styles.container}>
				<div className={styles.boxContainer}>
					{productInfo.map((product, idx) =><div className={styles.box}>
						<div 
							key={idx}
							className={styles.boxContents} style={{border:`4px solid ${product.color}`, opacity : `${product.license? "": "30%"}`}}
							onClick={()=>{
								product.license ? history.push(`/${product.productShortName}/dashboard`) : alert('A product license is required to use this service.')
								
							}}
							onMouseEnter={(e)=>{
								const updateHoverState = {}
								updateHoverState[product.productShortName] = true
								const update = {...hoverState, ...updateHoverState};
								// console.log(update)
								setHoverState(update)
								// debugger;
							}}
							onMouseLeave={(e)=>{
								const updateHoverState = {}
								updateHoverState[product.productShortName] = false
								const update = {...hoverState, ...updateHoverState};
								// console.log(update)
								setHoverState(update)
							}}
						>
							<div style={{opacity : `${product.license? "": "30%"}`}}>
								{product.license ? hoverState[product.productShortName] ? <div style={{fontSize:"1vw"}}>Valid until {product.validDate} </div> : product.productFullName : hoverState[product.productShortName] ?<div className={styles.licenseInfo}>A product license is required to use this service.</div> : product.productFullName}
							</div>
							<div style={{display : `${product.license? "": "none"}`}}>{product.version}</div>
						</div>
					</div>
					)}
				</div>
				{/* <div style={{fontSize:"0.6vw", opacity:"50%"}}>This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.</div> */}
			</div>
    </>
  )
}