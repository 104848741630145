import {coreItem} from "0_variables/coreItem";
import * as filesActions from "1_reduxs/actions/filesAction";

const initialStates = [
];

const filesReducer = (state = initialStates, action) => {
  const { type, items, item, openState, obj } = action;
  switch (type) {
    case filesActions.FETCH_FILES:
      if (state.length === 0) {
        const newFileList = [...items]
        return newFileList;
      } else {
        // TODO: upload에서 setTimeout으로 fetch_api를 계속 호출한 뒤에 받은 최신 목록들에는
        // reportItems에 값이 없음
        // 그 이유가 reportImageLoader를 새로 호출하지 않아서인듯함.
        // GlobalLayout useEffect에서 fileList 변화를 효율적으로 감지하는 방법이 필요함.
        // 그동안은 length로 확인했음
        // 목록에서 complete 이 false인 배열의 길이를 센싱하면 될듯.
        const newFileList = [...items.map((v,i)=>{
          const foundItem = state.find(v2=>v2.fileID === v.fileID);
          if (foundItem === undefined){
            return {...v}
          }else {
            return {...foundItem, ...v, Select:foundItem.Select, Opened:foundItem.Opened}
          }
        })]
        // debugger;
        return newFileList;
      }

    case filesActions.SELECT_SINGLE_FILE:
      return [...state.map((el) =>
        el.fileID === item.fileID ? { ...el, Select: !el.Select } : el
      )];

    case filesActions.SELECT_PAGE_ALL_FILES:
      const productName = obj.productName;
      const fileIDArray = obj.fileIDArray;
      // debugger;
      // TODO: item을 받아서 선택된 애들만 selection 상태 바꿔주기 (아직 item 사용안함)
      if (productName === 'all'){
        return [...state.map((el) =>({ ...el, Select: obj.status }))];
      } else {
        const tracerList = coreItem[productName].tracers.map(el=>el.shortname);
        const updated = state.map((el,idx)=>(tracerList.includes(el.Tracer) && el.Group >= obj.groupSelector) && fileIDArray.includes(el.id) ? {...el, Select:obj.status}:el)
        return [...updated]
      }


    case filesActions.SELECT_ALL_FILES:
      const productname = obj.productName;
      // TODO: item을 받아서 선택된 애들만 selection 상태 바꿔주기 (아직 item 사용안함)
      if (productname === 'all'){
        return [...state.map((el) =>({ ...el, Select: obj.status }))];
      } else {
        const tracerList = coreItem[productname].tracers.map(el=>el.shortname);
        const updated = state.map(el=>(tracerList.includes(el.Tracer) && el.Group >= obj.groupSelector) ? {...el, Select:obj.status}:el)
        return [...updated]
      }

    case filesActions.OPEN_SELECTED_FILE:
      // productName에 해당하는 파일들만 필터링
      const tracerList = coreItem[obj.productName].tracers.map(obj=>obj.shortname);
      const filterByProduct = state.filter((el) =>tracerList.includes(el.Tracer));
      // 필터링된 파일들의 Opened 상태를 바꾸기
      const updatedSelectedFilesOpen = state.map((el) =>
        (tracerList.includes(el.Tracer) && el.Select === true) ? { ...el, Opened: obj.openState } : el
      );
      return [...updatedSelectedFilesOpen];
      
    case filesActions.OPEN_SINGLE_FILE:
      const updatedFileOpen = state.map((el) =>
        el.fileID === obj.singleFile.fileID ? { ...el, Opened: true } : el
      );
      return [...updatedFileOpen];
    
    case filesActions.CLOSE_SINGLE_FILE:
      const updatedFileClose = state.map((el) =>
        el.fileID === obj.singleFile.fileID ? { ...el, Opened: false } : el
      );
      return [...updatedFileClose];

    default:
      return state;
  }
};
export default filesReducer;
