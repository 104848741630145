import Dexie from 'dexie';

export const BTXDB = new Dexie('BTXBrainDB');
// BTXDB.version(1).stores({
//   slices: 'id, Update, Type, ImageID, Direction, Width, Height, Depth, B64Data, CaseID', // Primary key and indexed props
// });
BTXDB.version(2).stores({
  slices: 'imageId', // Primary key and indexed props
});

// export const DBConfig = {
//   name: 'BTXBrainDB',
//   version: 1,
//   objectStoresMeta: [
//     {
//       store: 'slices',
//       storeConfig: { keyPath: 'id', autoIncrement: true },
//       storeSchema: [
//         { name: 'id', keypath: 'id', options: { unique: false } },
//         { name: 'Update', keypath: 'Update', options: { unique: false } },
//         { name: 'Type', keypath: 'Type', options: { unique: false } },
//         { name: 'ImageID', keypath: 'ImageID', options: { unique: false } },
//         { name: 'Direction', keypath: 'Direction', options: { unique: false } },
//         { name: 'Width', keypath: 'Width', options: { unique: false } },
//         { name: 'Height', keypath: 'Height', options: { unique: false } },
//         { name: 'Depth', keypath: 'Depth', options: { unique: false } },
//         { name: 'B64Data', keypath: 'B64Data', options: { unique: false } },
//         { name: 'CaseID', keypath: 'CaseID', options: { unique: false } },
//       ]
//     }
//   ]
// };
// alert('hi')