import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import allReducers from "1_reduxs/reducers";
import createStore from "1_reduxs/store";
import rootSaga from "1_reduxs/sagas";
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
const store = createStore(allReducers);
// sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
          <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
