import React, { useEffect, useState, useRef } from "react";

export default function TextDisplay(props) {
  return (
    <div style={{ 
      ...props.style,
      pointerEvent:"none", 
      userSelect:"none",
    }}>
      {`${props.title}: ${props.value}`}
    </div>
  )
}