import React, {useState, useEffect, useForm, useRef} from 'react';
import styles from './App.module.scss';
import * as utils from '0_variables/utils';
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import { Signin, Signup, Forgot, SelectProduct } from "4_routers/0_Home/components";
import { Route, useHistory, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import { Home, Dashboard, Upload, View, TempView, Analysis, Setting, Report } from "./4_routers";
// import { Home } from "./4_routers";
// import ReduxDebugger from "./ReduxDebugger"
import { HomeLayout, GlobalLayout } from "5_components";
import chroma from 'chroma-js';
import { useBeforeunload } from 'react-beforeunload';

import html2canvas from "html2canvas";
import jsPDF from "jspdf" ;
import fileSaver, {saveAs} from 'file-saver';
import JSZip from 'jszip' 

import * as cornerstone from "cornerstone-core";
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';
import { useLiveQuery } from "dexie-react-hooks";

function App() {
  const refPages = useRef([]);
  const [reportOn, setReportOn] = useState(false);
  const [openedWorklist, setOpenedWorklist] = useState(false);
  const [reportList, setReportList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const fileList = useSelector((state) => state.fileList);
  const [endDate, setEndDate] = useState("");
  const [remainCounts, setRemainCounts] = useState("");

  //개발시에만 주석처리
  // useBeforeunload((event) => {
  //   sessionStorage.clear();
  //   event.preventDefault()
  // });
  
  useEffect(()=>{

  // TODO: 20220603 로그인정보 삭제로직 제외 >>>
    // (async () => {
    //   const token = sessionStorage.getItem("token");
    //   // debugger;
    //   if (token){
    //     const resObj = await dispatch(actionsLogin.verify_token_api({
    //       token: token,
    //     }));
    //     if (resObj.response){
          
    //       dispatch(actionsFiles.fetch_api({IDBList:[]}));
    //       console.log(resObj.message);
    //     } else {
    //       console.log(resObj.message);
    //       alert('Server connection failed, try login again.');
    //       history.push('/signin');
    //     }
    //   } else {
    //     console.log("login: token required");
    //     history.push('/signin');
    //   }
    // })();
  // TODO: 20220603 로그인정보 삭제로직 제외 <<<
  },[])
  

  // useEffect(()=>{
  //   // dispatch(actionsControl.fetchReportItems({productName, fileList}))
  //   if (fileList.length !== 0){
  //     console.log(fileList);
  //     debugger;
  //   }
  // },[fileList.length])

  // useEffect(() => {
  //   // fileList에서 Open 이 false로 바뀌었을때, 여기서 control 처리를 해줘야함
  //   // 현재는 filter가 true만 보내기 때문에 false로 바뀐 데이터에 대해서는 처리가 안되는중
  //   // Opened 된 목록만 보내서 control의 openedFiles를 교체하면 기존 순서가 바뀌는 문제 발생
  //   // 
  //   const temp = fileList.filter((el) => el.Opened == true);
  //   dispatch(actionsControl.updateOpenFiles(temp));
  // }, [fileList]);

  const propsGlobalLayout = {
    reportOn, setReportOn,
    openedWorklist, setOpenedWorklist,
    remainCounts, setRemainCounts,
    endDate, setEndDate,
  }

  return (
    <>
      
      <Switch>
        {/* <Route exact path="/redux" render={(props)=><ReduxDebugger/>}/> */}

        <Route exact path="/signin"                     render={(props)=><Home/>}/>
        <Route exact path="/signup"                     render={(props)=><Home/>}/>
        <Route exact path="/forgot"                     render={(props)=><Home/>}/>
        <Route exact path="/selectProduct"              render={(props)=><Home/>}/>
        <Route exact path="/:product/dashboard"         render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><Dashboard {...props}/></GlobalLayout>}/>
        <Route exact path="/:product/upload"            render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><Upload    {...props} {...{setOpenedWorklist}} endDate={endDate} remainCounts={remainCounts}/></GlobalLayout>}/>
        <Route exact path="/:product/view/:fileID"      render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><View      {...props}/></GlobalLayout>}/>
        {/* <Route exact path="/:product/view/:fileID"      render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><TempView      {...props}/></GlobalLayout>}/> */}
        <Route exact path="/:product/analysis/:fileID"  render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><Analysis  {...props}/></GlobalLayout>}/>
        <Route exact path="/:product/setting"           render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><Setting   {...props}/></GlobalLayout>}/>
        <Route exact path="/:product/report/:fileID"    render={(props)=><GlobalLayout {...props} {...propsGlobalLayout}><Report    {...props}/></GlobalLayout>}/>
        <Route       path="/"                           render={(props)=><Redirect to={{pathname:'/signin'}}/>}/>
      </Switch>
      
    </>
  );
}

export default App;
