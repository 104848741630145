import React, { useEffect, useState, useRef, createRef, forwardRef } from "react";
import styles from "./Report.module.scss";
import * as utils from "0_variables/utils";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import { ReportPages } from "5_components";
import { GrDocumentPdf } from 'react-icons/gr';
import { FaFileDownload, FaFilePdf, FaFileUpload, FaUpload } from 'react-icons/fa';

// import html2canvas from "html2canvas";
// import jsPDF from "jspdf" ;
// import fileSaver, {saveAs} from 'file-saver';
// import JSZip from 'jszip' 
// import jszipUtils from 'jszip-utils';
import html2canvas from "html2canvas";
import jsPDF from "jspdf" ;
import fileSaver, {saveAs} from 'file-saver';
import JSZip from 'jszip';
import * as actionModal from "1_reduxs/actions/modalAction";
import controllerReducer from "1_reduxs/reducers/controllerReducer";

const Report = React.forwardRef((props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const control = useSelector((state) => state.control);
  const controlOfProduct = control[productName];
  // const fileList = useSelector((state) => state.fileList);
  const [elRefs, setElRefs] = useState([]);
  // const [reportRefs, setReportRefs] = useState([...Array(8)].map((_, i) => createRef()));
  // const [selectedFile, setSelectedFile] = useState(fileList.find(({fileID})=>fileID === params.fileID));
  // const setting = useSelector((state) => state.setting);
  // const [targetTracerRef, setTargetTracerRef] = useState({fullName:"", shortName:""});
  // const settingOfProduct = setting[productName]
  // const tracerName = selectedFile['Tracer'];
  // const refName = settingOfProduct.defaultRef[tracerName]
  // const [axialViewportRef,setAxialViewportRef] = useState([...Array(8)].map((_, i) => createRef()));
  // useEffect(()=>{
  //   // debugger;
  //   const selectedFile = fileList.find(({fileID})=>fileID == params.fileID);
  //   setSelectedFile(selectedFile);
  //   const shortName = utils.getShortName(refName)
  //   setTargetTracerRef({fullName:refName, shortName:shortName})
  //   // debugger;
  // },[params, refName])

  useEffect(()=>{
    // TODO: 여러개 열었을때 elRef도 여러개 담을 수 있도록 변경해야함!
    // setElRefs((elRefs)=>{
    //   return [...Array(controlOfProduct.openedFiles.length)].map((_,i1)=>elRefs[i1]=[...Array(3)].map((_,i2)=>createRef()));
    // })
    setElRefs([...Array(controlOfProduct.openedFiles.length)].map(el=>[...Array(3)].map((_,i2)=>createRef())))
  },[controlOfProduct.openedFiles])

  const downloadJPEG = (idx, elRefs, wait) => {
    const spinnerController = (onoff) => {
      if (onoff) dispatch(actionModal.open_spinner({status:true, percent:0}));
      else dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
    }
    // dispatch(actionModal.open_spinner({status:true, percent:0, message:'Downloading...'}));
    setTimeout(()=>{
      utils.generateJPEG([elRefs[idx]], spinnerController, (val)=>{})
      // .then(()=>
      //   setTimeout(()=>dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
      // ,wait))
    },0)
  }

  const downloadPDF = (idx, elRefs, wait) => {
    const spinnerController = (onoff) => {
      if (onoff) dispatch(actionModal.open_spinner({status:true, percent:0}));
      else dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
    }
    // const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+`${productName==="dat"? 'SBR_' :'SUVR_'}`+targetTracerRef.shortName+"_"+selectedFile.fileID+".pdf";
    // dispatch(actionModal.open_spinner({status:true, percent:0}));
    // spinnerController(true)
    setTimeout(()=>{
      // utils.generatePDF([elRefs[idx]], fname)
      utils.generatePDF([elRefs[idx]], spinnerController, (val)=>{})
      // .then(()=>{
      //   console.log('2')
      //   setTimeout(()=>{
      //     console.log('3')
      //     dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
      //   } ,wait)
      // })
    },0)
  }

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <div onClick={()=>alert('coming soon')} ><FaUpload size={"1.2vw"}/>&nbsp;{'PACS'}</div>
        <div onClick={()=>downloadJPEG(controlOfProduct.openedFiles.findIndex(el=>el.fileID===params.fileID), elRefs, 1000)}><FaFileDownload size={"1.2vw"}/>&nbsp;{'JPEG'}</div>
        <div onClick={()=>downloadPDF(controlOfProduct.openedFiles.findIndex(el=>el.fileID===params.fileID), elRefs, 1000)} ><FaFilePdf size={"1.2vw"}/>&nbsp;{'PDF'}</div>
      </div>
      {controlOfProduct.openedFiles.map((el,i)=>(
        <div key={i} className={styles.reportContainer} style={{visibility:params.fileID===el.fileID ? "visible":"hidden"}}>
          <ReportPages {...props} fileID={el.fileID} innerRef={elRefs[i]}/> 
        </div>
      ))}
    </div>
  )
});
export default Report;