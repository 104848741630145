import React, { useEffect, useState, useRef } from "react";
import styles from "./Setting.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {PageLayout, CardLayout, CustomROIModal} from "5_components";
import { MainConfig, DefaultTracer, ReportFormats } from "./components";
import {Buttons} from "5_components";
import * as actionModal from "1_reduxs/actions/modalAction";
import { findAllByAltText } from "@testing-library/react";
import {subRegions} from "0_variables/subRegions";
import * as actionSetting from "1_reduxs/reducers/settingReducer";
import * as utils from "0_variables/utils";


export default function Setting(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const modalState = useSelector((state) => state.modal);
  const username = sessionStorage.getItem('username');
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];

  const settingDefaultAtlas = setting[productName].defaultAtlas;
  const settingDefaultRef = setting[productName].defaultRef;
  const settingDefaultTracer = setting[productName].defaultTracer;
  const settingDefaultPInfoState = setting[productName].defaultPInfoState;
  const settingList = setting[productName].list;

  const [selectAtlas, setSelectAtlas] = useState(settingDefaultAtlas);
  const [selectRef, setSelectRef] = useState(settingDefaultRef);
  const [selectTracer, setSelectTracer] = useState(settingDefaultTracer);
  const [PInfoState, setPInfoState] =useState(settingDefaultPInfoState);
  const [subRegionList, setSubRegionList] = useState(settingList);
  // debugger;
  // 작성할때만 임시로 존재
  const [userDefinedROI, setUserDefinedROI] = useState("");
  const [selectedRegions,setSelectedRegions] = useState([]);

  const settingLocal = {
    defaultAtlas:selectAtlas,
    defaultRef:selectRef,
    defaultTracer:selectTracer,
    defaultPInfoState:PInfoState,
    list:subRegionList,
  };
  const settingGlobal = {
    ...setting[productName]
  }

  // 이부분에서 Logic이 잘못된점이 있음!
  useEffect(()=>{
    if (!setting.changed){ // 변경사항 없음 (또는 저장됨)
      // 현재 제품 설정에서 setting 변화가 없었을땐 제품 전환했을때, 전환된 제품의 setting을 잘 가져오는데...
      setSelectAtlas(settingDefaultAtlas)
      setSelectRef(settingDefaultRef)
      setSelectTracer(settingDefaultTracer)
      setPInfoState(settingDefaultPInfoState)
      setSubRegionList(settingList)
    } else { // 변경이 발생함
      // 제품 전환시 setting을 가져오지 않음
    }
  }, [productName, setting])

  useEffect(()=>{
    const changed = utils.isChangedObjects(settingGlobal, settingLocal)
    dispatch(actionSetting.changed_on_setting(changed, settingLocal));
  },[selectAtlas, selectRef, selectTracer, PInfoState, subRegionList])

  const mainConfigProps ={ 
    selectAtlas, setSelectAtlas,
    selectRef, setSelectRef,
    subRegionList, setSubRegionList,
    userDefinedROI, setUserDefinedROI,
  }

  // TODO: Setting.js에서 setting에 필요한 내용들을 모두 취합하여 서버에 저장하거나,
  // Redux에 임시로 상태저장을 해야함!
  // setting redux에 필요한 상태 변수들
  // (1) defaultAtlas
      // "AAL3"
  // (2) defaultRef
      // {
      //   "[18F]FMM": "Whole cerebellum",
      //   "[18F]FBP": "Whole cerebellum",
      //   "[18F]FBB": "Whole cerebellum",
      //   "[18F]FPN": "Whole cerebellum",
      // }
  // (3) defaultTracer
      // {fullname:'[18F]Flutemetamol', shortname:"[18F]FMM",  color:"#dfe403", commercialname:"Vizamyl"             },
  // (4) defaultPInfoState
      // [
      //   {name:"Patient Name", state: true},
      //   {name:"Patient ID", state:true},
      //   {name:"DOB", state:true},
      //   {name:"Sex", state:true},
      //   {name:"Type & Date of Examination", state:true},
      // ];
  // (5) list
      // [
      //   {static: true, primaryKey:  0,  belongToForeignKey:   0,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Global",           fullname: "Global",                        varname:{Left:"Global",                               Right:"Global"                              }},
      //   {static: true, primaryKey:  1,  belongToForeignKey:   1,  volumeForeignKey: -1, level: 0, report:true,  type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal",                       varname:{Left:"Frontal_L",                            Right:"Frontal_R"                           }},
      //   {static: true, primaryKey:  2,  belongToForeignKey:   1,  volumeForeignKey:  0, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Precentral",                    varname:{Left:"Precentral_L",                         Right:"Precentral_R"                        }},
      //   {static: true, primaryKey:  3,  belongToForeignKey:   1,  volumeForeignKey:  1, level: 1, report:false, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal",          fullname: "Frontal Sup",                   varname:{Left:"Frontal_Sup_L",                        Right:"Frontal_Sup_R"                       }},
      //    ...
      // ]

  const defaultTracerProps = {
    selectTracer, setSelectTracer
  }

  const reportFormatsProps = {
    PInfoState, setPInfoState
  }

  const closeHandler = () => {
    setUserDefinedROI("");
    setSelectedRegions([]);
    dispatch(actionModal.open_customROI(false));
  }

  const saveHandler = (title, list) => {
    // debugger;
    var newPrimaryKey = Math.max(...subRegionList.map(el => el.primaryKey))+1;
    var newIdentifier = Math.max(...subRegionList.map(el => el.identifier))+1;
    const titleObj_card1 = {
      static: false,
      primaryKey: newPrimaryKey,
      belongToForeignKey: newPrimaryKey,
      level: 0,
      type: utils.type1Selector[productName],
      multiAtlas:"Melbourne Subcortical",
      atlas: selectAtlas,
      belongTo: title,          
      fullname: title,       
      
      identifier: newIdentifier,
      report: true,           
      // TODO: 나중에 값 계산이 필요함
      varname:{
        Left:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.type===utils.type1Selector[productName]).varname.Left), 
        Right:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.type===utils.type1Selector[productName]).varname.Right), 
      },
      
      weight:{
        Left:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.level===1).weight.Left),
        Right:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.level===1).weight.Right),
      }
    }
    console.log(titleObj_card1);
    const titleObj_card2 = {
      static: false,
      primaryKey: newPrimaryKey+1,
      belongToForeignKey: newPrimaryKey+1,
      level: 0,
      type: utils.type2Selector[productName],
      atlas: selectAtlas,
      belongTo: title,          
      fullname: title,       
      
      identifier: newIdentifier,
      report: true,           
      // TODO: 나중에 값 계산이 필요함
      varname:{
        Left:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.type===utils.type2Selector[productName])?.varname.Left), 
        Right:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.type===utils.type2Selector[productName])?.varname.Right), 
      },
      weight:{
        Left:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.level===1).weight.Left),
        Right:list.map(fullname=>subRegionList.find(el=>el.fullname===fullname && el.level===1).weight.Right),
      }
    }
    
    const listObjArr1 = list.map((el,idx)=>({
      ...subRegions[productName].find(({fullname, atlas, type})=>fullname === el && atlas === selectAtlas && type===utils.type1Selector[productName]),
      static: false,
      primaryKey: newPrimaryKey+(2+idx),
      belongToForeignKey: newPrimaryKey,
      report: false,
      belongTo: title,             
    }))

    const listObjArr2 = list.map((el,idx)=>({
      ...subRegions[productName].find(({fullname, atlas, type})=>fullname === el && atlas === selectAtlas && type===utils.type2Selector[productName]),
      static: false,
      primaryKey: newPrimaryKey+(2+idx+listObjArr1.length),
      belongToForeignKey: newPrimaryKey+1,
      report: false,
      belongTo: title,             
    }))
    // debugger;
    if (productName === 'amyloid'){
      setSubRegionList([
        ...subRegionList,
        titleObj_card1,
        titleObj_card2,
        ...listObjArr1,
        ...listObjArr2,
      ])
    } else {
      setSubRegionList([
        ...subRegionList,
        titleObj_card1,
        ...listObjArr1,
        ...listObjArr2,
      ])
    }
    closeHandler();
  }

  const customROIModalProps = {
    selectAtlas,      setSelectAtlas,
    selectRef,        setSelectRef,
    subRegionList,    setSubRegionList,

    closeHandler, saveHandler,

    userDefinedROI,   setUserDefinedROI,
    selectedRegions,  setSelectedRegions,
  }

  const funcSelector = {
    'reset':()=>{
      if (window.confirm('Are you sure to reset all setting?')){
        // 리셋버튼을 누를때 해당 product의 세팅을 초기상태로 변환
        dispatch(actionSetting.reset_setting(productName));
        alert('Reset successful');
      }
    },
    'save':()=>{
      if (utils.isChangedObjects(settingGlobal, settingLocal)) {
        alert('Saved')
        dispatch(actionSetting.save_to_setting(productName, settingLocal))
      } else {
        alert('Nothing changed');
      }
    }
  }

  const settingPageTop = (top) => {
    return (
      <div className={styles.btnContainer}>
        <div className={styles.btnGroup1}>
          {top.btnGroup1.map((v,i)=>
            <Buttons key={i} {...{Enable:true, styleName:"rightBtn", imgSrc: v.icon, title: v.title, func:{onClickHandler: funcSelector[v.funcName]}}}/>
            )}
        </div>
      </div>
    )
  }

  const settingPageBottom = (bottom) => {
    const cardComponents = {
      MainConfig: <MainConfig mainConfigProps={mainConfigProps}/>,
      DefaultTracer: <DefaultTracer defaultTracerProps={defaultTracerProps}/>,
      ReportFormats: <ReportFormats reportFormatsProps={reportFormatsProps}/>,
    }
    const cardItem = productCoreItem[pageName].bottom.map((v,i1)=>({
      ...v,
      cards:v.cards.map((card,i2)=> {
        // TODO: 세팅 연동 디버깅중
        // coreItem에 정의된 contents (card 변수에 있음) 는 사용되나?
        // CardLayout으로 cardItem에 있는 cards props에 담겨서 전달되고 있으나 사용되지는 않음
        // MainConfig에서 확인할 수 없음 전달되기 않기 때문 (x)
        // 확인결과 MainConfig에서 coreItem의 contents가 사용중
        return {
          ...card, content:cardComponents[card.componentName],
        }
      })
    }));
    return (
      <>
        {cardItem.map((v,i)=>
          <CardLayout key={i} {...v} width={v.styles.width} height={v.styles.height}/>
        )}
      </>
    )
  }

  return (
    <>
      <PageLayout topContent={settingPageTop} bottomContent={settingPageBottom}/>
      {modalState.customROI.status && <CustomROIModal customROIModalProps={customROIModalProps}/>}
    </>
  )
}
