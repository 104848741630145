import React, { useEffect, useState } from "react";
import styles from "./Upload.module.scss";
import { useSelector, useDispatch } from "react-redux";
// import * as actionsControl from "1_reduxs/actions/controllerAction";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {PageLayout, Buttons, DropDownDiv, Burger, FileTable, PacsModal, UploadModal, ExpiredModal} from "5_components";
import * as actionModal from "1_reduxs/actions/modalAction";
import * as media from "6_media";
import * as utils from "0_variables/utils";
import * as services from '2_services/uploadApi';
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';
import axios from "axios";
import { IPinUSE } from "2_services/IPs";
// import { useLiveQuery } from "dexie-react-hooks";

// TODO: 열린 파일들은 선택되지 않도록 하는 LOCK 기능
const LOCKSELECT = false;

export default function Upload(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const control = useSelector((state) => state.control);
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const SNM_Mode = productCoreItem.SNM_Mode;
  // console.log(productName, coreItem[productName].productName, productCoreItem.productName);
  const tracerList = productCoreItem.tracers.map(obj=>obj.shortname);
  const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
  const pageName = location.pathname.split('/')[2];
  const [sortClick, setSortClick] = useState({"Uploaded date":"descend"});
  const [uploadResult, setUploadResult] = useState([]);
  const [getResult, setGetResult] = useState([]);
  const [time, setTime] = useState(0);
  const isChecking = productList.some(el=>!el.Complete);


  // const tracerList = productCoreItem.tracers.map(obj=>obj.shortname);
  const tableHead = productCoreItem[pageName].bottom.tableHead;
  const filteredFileList = fileList.filter(item=>tracerList.includes(item.Tracer));
  // console.log(productName, productCoreItem, tracerList, filteredFileList);
  // console.log(isChecking, time);
  useEffect(() => {
    // debugger;
      if (!isChecking) return undefined;
      const tick = setTimeout(() => {
          setTime(time + 1);
          dispatch(actionsFiles.fetch_api({IDBList:[]}));
      }, 20000);
      return () => clearTimeout(tick);
  }, [time, isChecking]);
  
  const viewHandler = () => {
    const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
    const filteredList = productList.filter((el) => el.Select === true || el.Opened === true);
    const filteredSelect = productList.filter((el) => el.Select === true);
    const targetFile = filteredSelect.at(-1);
    utils.openGroupFilesSelected({targetPage:'view', filteredList, targetFile, productName, dispatch, history});
    if (LOCKSELECT){ filteredSelect.forEach(item=>{ utils.selectSingleFile(dispatch, item) }) }
  }

  const analysisHandler = () => {
    const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
    const filteredList = productList.filter((el) => el.Select === true || el.Opened === true);
    const filteredSelect = productList.filter((el) => el.Select === true);
    const targetFile = filteredSelect.at(-1);
    utils.openGroupFilesSelected({targetPage:'analysis', filteredList, targetFile, productName, dispatch, history});
    if (LOCKSELECT){ filteredSelect.forEach(item=>{ utils.selectSingleFile(dispatch, item) }) };
  }

  const closeHandler = () => {
    const filteredFileList = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Select && item.Opened);
    filteredFileList.forEach(item=>{
      utils.closeSingleFile(dispatch, item, location.pathname.split('/')[2].toLowerCase(), productName, history);
    })
  }

  const worklistHandler = (status) => {
    props.setOpenedWorklist(true);
    setTimeout(()=>{
      const selectedFiles = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Select === true);
      dispatch(actionsFiles.update_worklist(status, selectedFiles));
    },500);
  }

  const deleteHandler = () => {
    const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
    dispatch(actionsFiles.delete_api(productList.filter((items) => items.Select == true)));
  }

  const clickedFileInput = () => {
    window.addEventListener('focus', handleFocusBack);
  }

  const handleFocusBack = (e) => {
    window.removeEventListener('focus', handleFocusBack);
  }

  const closeModal = (type) => {
    if (type === 'pacs'){
      dispatch(actionModal.open_PacsModal(false));
    } else if(type === 'upload'){
      dispatch(actionModal.open_UploadModal(false));
    } else {
      dispatch(actionModal.open_ExpiredModal(false));
    }
  }

  const openModal = (type) => {

    if (type === 'pacs'){
      if(!CTN_Mode){
        dispatch(actionModal.open_PacsModal(true));
      }
    } else if (type === 'upload'){
        dispatch(actionModal.open_UploadModal(true));
    } else {
        dispatch(actionModal.open_ExpiredModal(true))
    }
  }

  const pacsModalProps = {
    openModal:()=>{openModal('pacs'); }, 
    closeModal:()=>closeModal('pacs'), 
  }

  const expiredModalProps = {
    openModal:()=>{openModal('expired'); }, 
    closeModal:()=>closeModal('expired'), 
  }

  const uploadModalProps = {
    openModal:()=>openModal('upload'), 
    closeModal:()=>closeModal('upload'), 
    uploadResult, setUploadResult,
    getResult, setGetResult,
  }


  const uploadPageTop = (top) => {
    const funcSelector = {
      "view":()=>{viewHandler()},
      "analysis":()=>{analysisHandler()},
      "close":()=>{closeHandler()},
      "worklist_add":()=>{worklistHandler(true)},
      "worklist_delete":()=>{worklistHandler(false)},
      "file_delete":()=>{deleteHandler()},
      "pacs":()=>{openModal('pacs')},
      "sample":()=>{downloadSampleData()},
      // "upload":()=>{openModal('upload')},
      "upload":()=>{clickedFileInput()},
    }

    const downloadSampleData = () => {
      const link = IPinUSE + 'result/download/samples.zip';
      const fname = 'samples.zip'
      const getFile = async (link, fname) => {
        const res = await axios.get(link,{responseType: 'blob'})
        const file = new Blob([res.data]);
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      return getFile(link,fname)
    }

  
  
    const fileInputClicked = (e) => {
      openModal('upload');
      const FilesObj = e.target.files;
      let formData = new FormData();
      Array.from(FilesObj).forEach((myfile) => formData.append("myfiles", myfile));
      postFiles(formData);

      window.removeEventListener('focus', handleFocusBack);
    }

    const postFiles = async (formData) => {
      const token = sessionStorage.getItem("token");
      let res;
      try{
        dispatch(actionModal.open_spinner({status:true, percent:0, message:"Uploading..."}));
        res = await services.postFile({ token: token, obj: formData });
      } catch(e) {
        // TODO: 파일 직접업로드 실패시 예외처리 필요.
        alert("Failed to upload data. Please check your connection to the server.");
      } finally {
        setGetResult(res.data);
        dispatch(actionModal.open_spinner({status:false, percent:0}));
      }
    };
    return (
      <div className={styles.btnContainer}>
        <div className={styles.btnGroup1}>
          {top.btnGroup1.filter(v=>CTN_Mode ? v.title !== 'Analysis':true).map((v, idx)=><Buttons key={idx} {...{Enable:utils.isAnySelected(fileList, tracerList), styleName:"leftBtn", imgSrc: v.icon, title:v.title, func:{onClickHandler:funcSelector[v.funcName]}}}/>)}
        </div>
        {!CTN_Mode && <div className={styles.btnGroup2}>
          {top.btnGroup2.map((v, idx)=><Buttons key={idx} {...{Enable:utils.isAnySelected(fileList, tracerList), styleName:"leftBtn", imgSrc: v.icon, title:v.title, func:{onClickHandler:funcSelector[v.funcName]}}}/>)}
        </div>}
        <div className={styles.btnGroup3}>
          {top.btnGroup3.map((v, idx)=><Buttons key={idx} {...{Enable:utils.isAnySelected(fileList, tracerList), styleName:"deleteBtn", imgSrc: v.icon, title:v.title, func:{onClickHandler:funcSelector[v.funcName]}}}/>)}
        </div>
        <div className={styles.btnGroup4}>
          {top.btnGroup4.map((v, idx)=><Buttons key={idx} {...{SNM_Mode: SNM_Mode, Enable: v.title==='Connect to PACS' && CTN_Mode ? false:true, styleName:"rightBtn", imgSrc: v.icon, title:v.title, func:{onClickHandler:funcSelector[v.funcName], onChangeHandler:fileInputClicked}}} endDate={props.endDate} remainCounts={props.remainCounts} openModal={openModal}/>)}
        </div>
      </div>
    )
  }
  
  const uploadPageBottom = (bottom) => {
    return (
      <div className={styles.tableContainer}>
        <FileTable
          identifier={'upload'}
          LOCKSELECT={LOCKSELECT}
          productName={productName}
          tableHead={tableHead} FileList={filteredFileList}
          sortClick={sortClick} setSortClick={setSortClick}
          clickHandler={(singleFile)=>{utils.selectSingleFile(dispatch, singleFile)}} 
          doubleClickHandler={(singleFile)=>{
            const targetPage = 'view';
            utils.openSingleFileWithoutSelect({targetPage, history, dispatch, singleFile, fileList, tracerList, productName});
            if (LOCKSELECT) { debugger; singleFile.forEach(item=>{ utils.selectSingleFile(dispatch, item) }) }
          }}
        />
      </div>
    )
  }
  return (
    <>
      <PageLayout topContent={uploadPageTop} bottomContent={uploadPageBottom}/>
      {modalState.pacsModal.status && <PacsModal {...pacsModalProps}/>}
      {modalState.uploadModal.status && <UploadModal {...uploadModalProps}/>}
      {modalState.expiredModal.status && <ExpiredModal {...expiredModalProps} endDate={props.endDate} remainCounts={props.remainCounts}/>}
      {/* {modalState.expiredModal.status && <UploadModal {...uploadModalProps}/>} */}
    </>
  )
}
