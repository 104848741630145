import axios from "axios";
import { IPinUSE } from "./IPs";

export function Signin (data) {
  return axios.post(IPinUSE+'api/token/', data);
}

export function Registration (data) {
  return axios.post(IPinUSE+'rest-auth/registration/', data);
}

export function TokenVerification (data) {
  return axios.post(IPinUSE+'api/token/verify/', data);
}

export function getAuthJWT (data) {
  return axios.post(IPinUSE+'api/token/', data);
}

export function ResetPassword (Auth, data) {
  return axios.post(IPinUSE+'testing/forgot/', data, {headers:Auth});
}
