import html2canvas from "html2canvas";
import jsPDF from "jspdf" ;
import fileSaver, {saveAs} from 'file-saver';
import axios from "axios";
import JSZip from 'jszip';
import * as actionsFiles from "1_reduxs/actions/filesAction";
// import {openFile_FetchSlices_Jump, closeFileAndChangingTab} from "1_reduxs/reducers/controllerReducer";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import { IPinUSE } from "2_services/IPs";
import { useSelector, useDispatch } from "react-redux";
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';
import * as _ from "lodash";
import MNI_Axial from "0_variables/MNI152_T1_2mm_z";
import MNI_Coronal from "0_variables/MNI152_T1_2mm_y";
import MNI_Sagittal from "0_variables/MNI152_T1_2mm_x";
// import interpolateArray from '2d-bicubic-interpolate';

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export const ratio2Width = ({width, height}) => height/width;
export const ratio2Height = ({width, height}) => width/height;
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export const type1Selector = {
  'amyloid': 'suvr',
  'dat': 'sbr',
  'fdg': 'suvr',
  'tau': 'suvr',
}
export const type2Selector = {
  'amyloid': 'centiloid',
  // TODO: dat, fdg, tau는 type2 selector 필요없음 나중에 점검 필요 (임시로 채움)
  'dat': 'semiquantification',
  'fdg': 'centiloid',
  'tau': 'centiloid',
}

// TODO: 나중에 selectedFile의 Ref Factor를 가져오는 방법 고민 필요
export const RefFactor = ({refName, ponsRF, crblRF}) => {
  if (refName === "Pons") return ponsRF;
  else if (refName === "Whole cerebellum") return crblRF;
  else if (refName === "Cerebellar gray matter") return 1;
  else if (refName === "Cerebral white matter") return ponsRF;
  else return 1;
}
export const RefFactor1 = ({ productName, refName, ponsRF, crblRF, wmRF, cgRF, global }) => {
  // if (global){
  //   debugger;
  // }
  if (productName !== 'dat') {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Whole cerebellum") return crblRF;
    //TODO: amyloid용 refFactor 받아와야함
    else if (refName === "Cerebral white matter") return ponsRF;
    else if (refName === "Global cortex") return global; // for FDG
    else return 1; // Cerebellar gray matter
  } else {
    if (refName === "Pons") return ponsRF;
    else if (refName === "Cerebellar gray matter") return cgRF;
    else if (refName === "Cerebral white matter") return wmRF;
    else if (refName === "Whole cerebellum") return crblRF;
    else return 1; // occipital cortex
  }
}

export const isChangedObjects = (prevObj, currObj) => {
  // debugger;
  const result = !_.isEqual(prevObj, currObj)
  return result;
}
export const dataFormatForBrainTable = ({columnList, selectedFile, settingOfProduct, RF}) => {
  // debugger;
  const format = columnList.map(item=>{
    if (typeof(item.Lvar)==='string'){
      return {...item, Lval:(selectedFile[item.Lvar])*RF, Rval:(selectedFile[item.Rvar])*RF}
    } else {
      const ObjL = item.Lvar.reduce((obj,el1)=>{
        const targetRegion = settingOfProduct.list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Left === el1)
        obj.sum += selectedFile[el1] * targetRegion.weight.Left
        obj.weight += targetRegion.weight.Left
        return obj
      },{sum:0, weight:0})
      const ObjR = item.Rvar.reduce((obj,el1)=>{
        const targetRegion = settingOfProduct.list.find(el2=>el2.atlas === "Melbourne Subcortical" && el2.varname.Right === el1)
        obj.sum += selectedFile[el1] * targetRegion.weight.Right
        obj.weight += targetRegion.weight.Right
        return obj
      },{sum:0, weight:0})
      const MeanOfWeightedSumLeft = ObjL.sum/ObjL.weight
      const MeanOfWeightedSumRight = ObjR.sum/ObjR.weight
      return {...item, Lval:MeanOfWeightedSumLeft*RF, Rval:MeanOfWeightedSumRight*RF}
    }
  })
  return format;
}

export const metaDataLoader = async () => {

  "use strict";

  function metaDataProvider(type, imageId) {
    let identifier=imageId.split(/[:,/]+/)
    let device = identifier[0]//pet
    let inout = identifier[1]//output
    let slice = identifier[2] //0
    let direction=identifier[3]
    let Inverter=identifier[4]
    let colormap=identifier[5]
    let id=Number(identifier[6])
    let scale = 1;
    let cX = 1;
    let cY = 1;
    let cZ = 1;

    let width = 91;
    let height = 91;
    if (direction === "coronal"){width = 91+40; height = 91}
    else if (direction === "sagittal"){width = 109+20; height = 91}
    else if (direction === "axial"){width = 91+40; height = 109+20}
    else if (direction === "mip"){width = 109+20; height = 91}

    if(type === 'imagePlaneModule') {
      if (direction === 'coronal'){
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 91+40,
          rowCosines:     [ 0,  1,  0], 
          columnCosines:  [ 0,  0,  1],
          imagePositionPatient: [scale*id+cY, cX, cZ], //coronal plane에서 [xxx, sagittal line, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'sagittal'){
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 91,
          columns: 109+20,
          rowCosines:     [1,  0,  0], 
          columnCosines:  [ 0,  0, 1],
          imagePositionPatient: [cY, scale*id+cX, cZ], //sagittal plane에서 [coroanl line, xxx, axial line]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
      else if (direction === 'axial'){
        return {
          frameOfReferenceUID: '1.2.3.4.5',
          rows: 109+20,
          columns: 91+40,
          rowCosines:     [ 0, 1,  0], 
          columnCosines:  [ -1,  0,  0],
          imagePositionPatient: [cY+109+20, cX, -scale*id+cZ+91], //axial plane에서  [coronal line, sagittal line, xxx]
          columnPixelSpacing: 1,
          rowPixelSpacing: 1,
        }
      }
    }
  }
  cornerstone.metaData.addProvider(metaDataProvider);
};

export const reportImageLoader = async (productName, pageName, fileList) => {
  "use strict";
  // debugger;
  // const DB = fileList;
  // const DBarray = fileList.map(el=>el.reportItems)
  const DB = fileList.reduce((obj,el)=>{
    obj['reportItems'] = {
      ...obj['reportItems'],
      ...el.reportItems,
    }
    return obj;
  },{}).reportItems

  function getReportImage(imageId) {
    // debugger;
    const identifier=imageId.split(/[:,/]+/)
    const ImageLoaderName = identifier[0]
    const FileID = identifier[1]
    const Direction = identifier[2]
    const SliceID = Number(identifier[3])
    
    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    const getPixelData = ()=> {
      // const keyPairJSON = sessionStorage.getItem(FileID);
      // const data = JSON.parse(keyPairJSON)[imageId];
      // debugger;
      const data = DB[imageId];
      // console.log(data);
      try {
        if (data !== null && data !== undefined){
          return getPixelDataFromBase64(data)
        } else {
          throw "unknown imageId"
        }
      } catch (e) {
        // debugger;
      }
      // if (data !== null && data !== undefined) {
      //   debugger;
      //   return getPixelDataFromBase64(data)
      // } else {
      //   throw "unknowm imageId"
      // }
    }

    var image = {
        imageId: imageId,
        minPixelValue : 0,
        maxPixelValue : 32767,
        slope: 1.0,
        intercept: 0,
        windowCenter : 16384,
        windowWidth : 32767,
        getPixelData: getPixelData,
        rows: height,
        columns: width,
        height: height,
        width: width,
        color: false,
        columnPixelSpacing: 2,
        rowPixelSpacing: 2,
        sizeInBytes: width * height * 2,
    };
    return {
        promise: new Promise((resolve) => {
          resolve(image);
        }),
        cancelFn: undefined
    };
  }
  if (pageName === 'report') {
    cornerstone.registerImageLoader(productName+pageName, getReportImage);
  }
}

export const imageLoader = async (productName, pageName, controlOfProduct) => {
  "use strict";
  const openedFilesofProduct = controlOfProduct.openedFiles;
  const mniDB = {
    'axial':MNI_Axial,
    'coronal':MNI_Coronal,
    'sagittal':MNI_Sagittal,
  }
  // debugger;
  // const reportItemsofProduct = controlOfProduct.reportItems;
  function getMNIImage(imageId) {
    // amyloidview://{type}/{fileID}/{Direction}/{flipped}/{colormap}/{sliceID}
    const identifier=imageId.split(/[:,/]+/)
    const ImageLoaderName = identifier[0]
    const Type = identifier[1]
    const FileID = identifier[2]
    const Direction = identifier[3]
    const Flipped = identifier[4]
    const colormap = identifier[5]
    const SliceID = Number(identifier[6])
    
    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;

    function getPixelData () {
      // debugger;
      const data = mniDB[Direction][SliceID]
      // debugger;

      if (data !== null)
        return getPixelDataFromBase64(data)
      else 
        throw "unknowm imageId"
    }

    var image = {
        imageId: imageId,
        minPixelValue : 0,
        maxPixelValue : 32767,
        suvrSlope: 1,
        slope: 1.0,
        intercept: 0,
        windowCenter : 16384,
        windowWidth : 32767,
        getPixelData: getPixelData,
        rows: height,
        columns: width,
        height: height,
        width: width,
        color: false,
        columnPixelSpacing: 2,
        rowPixelSpacing: 2,
        sizeInBytes: width * height * 2,
    };

    return {
        promise: new Promise((resolve) => {
          resolve(image);
        }),
        cancelFn: undefined
    };
  }
  function getSliceImage(imageId) {
    // amyloidview://{type}/{fileID}/{Direction}/{flipped}/{colormap}/{sliceID}
    const identifier=imageId.split(/[:,/]+/)
    const ImageLoaderName = identifier[0]
    const Type = identifier[1]
    const FileID = identifier[2]
    const Direction = identifier[3]
    const Flipped = identifier[4]
    const colormap = identifier[5]
    const SliceID = Number(identifier[6])
    // const temp = 0.5;
    
    const size = getSizebyProduct(Direction);
    const width = size.width;
    const height = size.height;
    
    const file = openedFilesofProduct.find(el=>el.fileID === FileID)
    const suvrSlope = Type === 'output' ? (file.out_suvr_max - file.out_suvr_min)/32767 : (file.in_suvr_max - file.in_suvr_min)/32767;

    function getPixelData () {
      // const file = openedFilesofProduct.find(el=>el.fileID === FileID)
      // if (Direction === 'sagittal') {
      //   debugger;
      // }
      const data = file.Slices.find(el=>el.Type===Type && el.Direction===Direction && el.ImageID===SliceID).B64Data;

      if (data !== null){
        if (Direction === 'mip') return getPixelDataFromBase64(data)
        else if (Direction === 'sagittal') return getPixelDataFromBase64PETsagittal(data)
        else return getPixelDataFromBase64PET(data)
      } else 
        throw "unknowm imageId"
    }

    var image = {
        imageId: imageId,
        minPixelValue : 0,
        maxPixelValue : 32767,
        suvrSlope: suvrSlope,
        slope: 1,
        intercept: 0,
        windowCenter : 16384,
        windowWidth : 32767,
        getPixelData: getPixelData,
        rows: height,
        columns: width,
        height: height,
        width: width,
        color: false,
        columnPixelSpacing: 2,
        rowPixelSpacing: 2,
        sizeInBytes: width * height * 2,
    };

    return {
        promise: new Promise((resolve) => {
          resolve(image);
        }),
        cancelFn: undefined
    };
  }
  if (pageName === 'view') {
    cornerstone.registerImageLoader('mni'+pageName, getMNIImage);
    cornerstone.registerImageLoader(productName+pageName, getSliceImage);
  };
}

export const getSizebyProduct = (Direction) => {
  if(Direction === 'coronal') return {width:91+40, height:91}
  else if(Direction === 'sagittal') return {width:109+20, height:91}
  else if(Direction === 'axial') return {width:91+40, height:109+20}
  else if (Direction === "mip") return {width:109+20, height:91}
  else return {width:91, height:91}
}

export const reportImageIdsbyProduct = (productName, fileID) => {
  if (productName === 'amyloid'){
    return [
      'amyloidreport://'+fileID+'/axial/'+60,
      'amyloidreport://'+fileID+'/axial/'+55,
      'amyloidreport://'+fileID+'/axial/'+50,
      'amyloidreport://'+fileID+'/axial/'+45,
      'amyloidreport://'+fileID+'/axial/'+40,
      'amyloidreport://'+fileID+'/axial/'+35,
      'amyloidreport://'+fileID+'/axial/'+30,
      'amyloidreport://'+fileID+'/axial/'+25,
    ]
  } else if (productName === 'dat'){
    return [
      'datreport://'+fileID+'/axial/'+40,
      'datreport://'+fileID+'/axial/'+37,
      'datreport://'+fileID+'/axial/'+34,
      'datreport://'+fileID+'/axial/'+31,
      'datreport://'+fileID+'/axial/'+28,
      'datreport://'+fileID+'/mip/'+5,
      'datreport://'+fileID+'/mip/'+10,
      'datreport://'+fileID+'/mip/'+15,
      'datreport://'+fileID+'/mip/'+20,
      'datreport://'+fileID+'/mip/'+25,
      'datreport://'+fileID+'/mip/'+30,
      'datreport://'+fileID+'/mip/'+35,
      'datreport://'+fileID+'/mip/'+40,
    ]
  } else if (productName === 'fdg'){
    // TODO: FDG와 TAU 제품에 대한 데이터 룰 필요함
    return [
      'fdgreport://'+fileID+'/axial/'+60,
      'fdgreport://'+fileID+'/axial/'+55,
      'fdgreport://'+fileID+'/axial/'+50,
      'fdgreport://'+fileID+'/axial/'+45,
      'fdgreport://'+fileID+'/axial/'+40,
      'fdgreport://'+fileID+'/axial/'+35,
      'fdgreport://'+fileID+'/axial/'+30,
      'fdgreport://'+fileID+'/axial/'+25,
    ]
  } else if (productName === 'tau'){
    return [
      'taureport://'+fileID+'/axial/'+60,
      'taureport://'+fileID+'/axial/'+55,
      'taureport://'+fileID+'/axial/'+50,
      'taureport://'+fileID+'/axial/'+45,
      'taureport://'+fileID+'/axial/'+40,
      'taureport://'+fileID+'/axial/'+35,
      'taureport://'+fileID+'/axial/'+30,
      'taureport://'+fileID+'/axial/'+25,
    ]
  }
}
export const str2pixelDataPET = (str) => {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  // var bufView2 = new Int16Array(buf);
  var index = 0;
  for (var i=0, strLen=str.length; i<strLen; i+=2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i+1);
    bufView[index] = lower + (upper <<8);
    index++;
  }

  const originArr = Array.from(bufView)
  let Arr2D = []
  while(originArr.length) Arr2D.push(originArr.splice(0,66));
  // while(newData.length) newArr2D.push(newData.splice(0,66*2-1));


  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  bufView = Int16Array.from(newArr.flat().map(v=>clamp(v,0,32767)))
  return bufView;
}
export function LinearInterp2D(orig2D) {
  // dims = arr.shape
  const origDim = [ orig2D.length, orig2D[0].length ];
  // new_arr = np.zeros([2*dims[0]-1, 2*dims[1]-1])
  const targDim = [origDim[0]*2-1, origDim[1]*2-1];
  let targ2D = new Array(targDim[0]).fill(0).map(() => new Array(targDim[1]).fill(0));

  for (let i = 0; i < origDim[0]-1; i++) {
    for (let j = 0; j < origDim[1]-1; j++) {
      // targ2D[2*i, 2*j] 
      targ2D[2 * i    ][ 2 * j    ] = orig2D[i][j]
      targ2D[2 * i + 1][ 2 * j    ] = 0.50 * (orig2D[i ][ j] + orig2D[i + 1][ j])
      targ2D[2 * i    ][ 2 * j + 1] = 0.50 * (orig2D[i ][ j] + orig2D[i][ j + 1])
      targ2D[2 * i + 1][ 2 * j + 1] = 0.25 * (orig2D[i ][ j] + orig2D[i + 1][ j] + orig2D[i ][ j] + orig2D[i][ j + 1])
    }
  }
  return targ2D
}
export function CubicInterp2D(orig2D) {
  // dims = arr.shape
  const origDim = [ orig2D.length, orig2D[0].length ];
  let pad2D = new Array(origDim[0]+3).fill(0).map(() => new Array(origDim[1]+3).fill(0));

  for (var i = 0; i < origDim[0]; i++){
    for (var j = 0; j < origDim[1]; j++){
      pad2D[i+1][j+1] = orig2D[i][j]
    }
  }
  // 2D matrix slicing
  // new_arr = np.zeros([2*dims[0]-1, 2*dims[1]-1])
  const targDim = [origDim[0]*2-1, origDim[1]*2-1];
  let targ2D = new Array(targDim[0]).fill(0).map(() => new Array(targDim[1]).fill(0));

  for (let i = 0; i < origDim[0]-1; i++) {
    for (let j = 0; j < origDim[1]-1; j++) {
        // tmp4x4 = pad2D[i:i+4][ j:j+4]
        // if (i == 25 && j == 25){
        //   debugger
        // }
        let tmp4x4 = pad2D.slice(i, i + 4).map(i => i.slice(j, j + 4))
        targ2D[2 * i    ][ 2 * j    ] = pad2D[i+1][ j+1]
        targ2D[2 * i + 1][ 2 * j    ] = Math.max(_cubic(tmp4x4, 0.5, 0),0)
        targ2D[2 * i    ][ 2 * j + 1] = Math.max(_cubic(tmp4x4, 0, 0.5),0)
        targ2D[2 * i + 1][ 2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0.5),0)

        // # Boundary condition
        if (i == origDim[0] - 2 || j == origDim[1] - 2){
          targ2D[2 * i + 2][ 2 * j    ] = pad2D[i + 2][ j + 1]
          targ2D[2 * i    ][ 2 * j + 2] = pad2D[i + 1][ j + 2]
          targ2D[2 * i + 2][ 2 * j + 1] = Math.max(_cubic(tmp4x4, 0.5, 0),0)
          targ2D[2 * i + 1][ 2 * j + 2] = Math.max(_cubic(tmp4x4, 0, 0.5),0)
          targ2D[2 * i + 2][ 2 * j + 2] = pad2D[i + 2][ j + 2]
        } else {
          continue;
        }

    }
  }
  return targ2D
}
export function _cubic(p, x, y){
  let a00 = p[1][1]
  let a01 = -.5 * p[1][0] + .5 * p[1][2]
  let a02 = p[1][0] - 2.5 * p[1][1] + 2 * p[1][2] - .5 * p[1][3]
  let a03 = -.5 * p[1][0] + 1.5 * p[1][1] - 1.5 * p[1][2] + .5 * p[1][3]
  let a10 = -.5 * p[0][1] + .5 * p[2][1]
  let a11 = .25 * p[0][0] - .25 * p[0][2] - .25 * p[2][0] + .25 * p[2][2]
  let a12 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + .5 * p[2][0] - 1.25 * p[2][1] + p[2][2] - .25 * p[2][3]
  let a13 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .25 * p[2][0] + .75 * p[2][1] - .75 * p[2][2] + .25 * p[2][3]
  let a20 = p[0][1] - 2.5 * p[1][1] + 2 * p[2][1] - .5 * p[3][1]
  let a21 = -.5 * p[0][0] + .5 * p[0][2] + 1.25 * p[1][0] - 1.25 * p[1][2] - p[2][0] + p[2][2] + .25 * p[3][0] - .25 * p[3][2]
  let a22 = p[0][0] - 2.5 * p[0][1] + 2 * p[0][2] - .5 * p[0][3] - 2.5 * p[1][0] + 6.25 * p[1][1] - 5 * p[1][2] + 1.25 * p[1][3] + 2 * p[2][0] - 5 * p[2][1] + 4 * p[2][2] - p[2][3] - .5 * p[3][0] + 1.25 * p[3][1] - p[3][2] + .25 * p[3][3]
  let a23 = -.5 * p[0][0] + 1.5 * p[0][1] - 1.5 * p[0][2] + .5 * p[0][3] + 1.25 * p[1][0] - 3.75 * p[1][1] + 3.75 * p[1][2] - 1.25 * p[1][3] - p[2][0] + 3 * p[2][1] - 3 * p[2][2] + p[2][3] + .25 * p[3][0] - .75 * p[3][1] + .75 * p[3][2] - .25 * p[3][3]
  let a30 = -.5 * p[0][1] + 1.5 * p[1][1] - 1.5 * p[2][1] + .5 * p[3][1]
  let a31 = .25 * p[0][0] - .25 * p[0][2] - .75 * p[1][0] + .75 * p[1][2] + .75 * p[2][0] - .75 * p[2][2] - .25 * p[3][0] + .25 * p[3][2]
  let a32 = -.5 * p[0][0] + 1.25 * p[0][1] - p[0][2] + .25 * p[0][3] + 1.5 * p[1][0] - 3.75 * p[1][1] + 3 * p[1][2] - .75 * p[1][3] - 1.5 * p[2][0] + 3.75 * p[2][1] - 3 * p[2][2] + .75 * p[2][3] + .5 * p[3][0] - 1.25 * p[3][1] + p[3][2] - .25 * p[3][3]
  let a33 = .25 * p[0][0] - .75 * p[0][1] + .75 * p[0][2] - .25 * p[0][3] - .75 * p[1][0] + 2.25 * p[1][1] - 2.25 * p[1][2] + .75 * p[1][3] + .75 * p[2][0] - 2.25 * p[2][1] + 2.25 * p[2][2] - .75 * p[2][3] - .25 * p[3][0] + .75 * p[3][1] - .75 * p[3][2] + .25 * p[3][3]
  let x2 = x * x
  let x3 = x2 * x
  return a00 + (a01 + (a02 + a03 * y) * y) * y + (a10 + (a11 + (a12 + a13 * y) * y) * y) * x + (a20 + (a21 + (a22 + a23 * y) * y) * y) * x2 + (a30 + (a31 + (a32 + a33 * y) * y) * y) * x3
}

// export function interpolate2D(data) {
//   const dimensions = [ data.length, data[0].length ];

//   let newDim = [dimensions[0]*2-1, dimensions[1]*2-1];
//   let newData = new Array(newDim[0]).fill(0).map(() => new Array(newDim[1]).fill(0));
//   for (var y = 0; y < dimensions[0]; y++) {   
//     for (var x = 0; x < dimensions[1]; x++) {
//       newData[y][2*x] = data[y][x]
//       newData[y][2*x+1] = (data[y][x] + data[y][x+1])/2
//     } 
//   }
  
//   return newData
// }

// export const str2pixelDataPET = (str) => {
//   var buf = new ArrayBuffer(str.length); // 2 bytes for each char
//   var bufView = new Int16Array(buf);
//   // var bufView2 = new Int16Array(buf);
//   var index = 0;
//   for (var i=0, strLen=str.length; i<strLen; i+=2) {
//     var lower = str.charCodeAt(i);
//     var upper = str.charCodeAt(i+1);
//     bufView[index] = lower + (upper <<8);
//     index++;
//   }

//   const originArr = Array.from(bufView)
//   let Arr2D = []
//   let newArr2D = []
//   while(originArr.length) Arr2D.push(originArr.splice(0,66));
//   const t0 = performance.now()
//   const data = _.flatten(Arr2D.map((a,y)=>a.map((v,x)=>({x:x, y:y, z:v}))));
//   const t1 = performance.now()
//   const newData = interpolateArray(data, 1).map(v=>Math.max(0,parseInt(v.z)))
//   const t2 = performance.now()
//   // while(newData.length) newArr2D.push(newData.splice(0,66*2-1));


//   // const newArr = scaleConcat(Arr2D, 2);
//   // var newbufView = Int16Array.from(newData)
//   // debugger;
//   var bufView2 = Int16Array.from(newData)
//   const t3 = performance.now()
//   console.log('performance check ')
//   console.log('toDict: ' + (t1-t0) + ', ' + 'interpolate: ' + (t2-t1) + ', ' + 'typedArray: ' + (t3-t2) + ', ' + 'Total: ' + (t3-t0))
//   return bufView2;
// }

export const str2pixelDataPETsagittal = (str) => {
  // debugger;
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i=0, strLen=str.length; i<strLen; i+=2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i+1);
    bufView[index] = lower + (upper <<8);
    index++;
  }
  const originArr = Array.from(bufView)
  
  // pixelData.forEach(v=>{
  //   if (v < 0) console.log('minus') 
  //   else if (v >= 32768) console.log('overflow') 
  // })


  let Arr2D = []
  while(originArr.length) Arr2D.push(originArr.splice(0,65));
  // const newArr = scaleConcat(Arr2D, 2);
  // const newArr = LinearInterp2D(Arr2D, 2);
  const newArr = CubicInterp2D(Arr2D);
  const flatData = newArr.flat().map(v=>clamp(v, 0, 32767))
  bufView = Int16Array.from(flatData)
  return bufView;
}

export function scaleConcat(array, factor) {
	let scaled = [];

	for(const row of array) {
		let x = [];

		for(const item of row)
			x = x.concat(Array(factor).fill(item));

		scaled = scaled.concat(Array(factor).fill(x));
	}
  // debugger;
  scaled.shift();
  let transData = scaled[0].map((_, colIndex) => scaled.map(row => row[colIndex]))
  transData.shift();
  scaled = transData[0].map((_, colIndex) => transData.map(row => row[colIndex]))
	return scaled;

  // scaled.push(scaled.at(-1))
  // let transData = scaled[0].map((_, colIndex) => scaled.map(row => row[colIndex]))
  // transData.push(transData.at(-1))
  // scaled = transData[0].map((_, colIndex) => transData.map(row => row[colIndex]))
	// return scaled;
}


// export function interpolateArray(data, fitCount) {
  

//   var linearInterpolate = function (before, after, atPoint) {
//       return before + (after - before) * atPoint;
//   };

//   var newData = new Array();

//   var springFactor = new Number((data.length - 1) / (fitCount - 1));
//   newData[0] = data[0]; // for new allocation
//   for ( var i = 1; i < fitCount - 1; i++) {
//       var tmp = i * springFactor;
//       var before = new Number(Math.floor(tmp)).toFixed();
//       var after = new Number(Math.ceil(tmp)).toFixed();
//       var atPoint = tmp - before;
//       newData[i] = linearInterpolate(data[before], data[after], atPoint);
//   }
//   newData[fitCount - 1] = data[data.length - 1]; // for new allocation
//   return newData;
// };

// export function scaleConcat(array, factor) {
// 	let scaled = [];

// 	for(const row of array) {
// 		let x = [];

// 		for(const item of row)
// 			x = x.concat(Array(factor).fill(item));

// 		scaled = scaled.concat(Array(factor).fill(x));
// 	}
// 	return scaled;
// }

export const str2pixelData = (str) => {
  var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  var bufView = new Int16Array(buf);
  var index = 0;
  for (var i=0, strLen=str.length; i<strLen; i+=2) {
    var lower = str.charCodeAt(i);
    var upper = str.charCodeAt(i+1);
    bufView[index] = lower + (upper <<8);
    index++;
  }
  return bufView;
}

export const getPixelDataFromBase64 = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelData(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PET = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPET(pixelDataAsString);
  return pixelData;
}

export const getPixelDataFromBase64PETsagittal = (base64PixelData) => {
  var pixelDataAsString = window.atob(base64PixelData);
  var pixelData = str2pixelDataPETsagittal(pixelDataAsString);
  return pixelData;
}

export const closeSingleFile = (dispatch, singleFile, currentPage, productName, history) => {
  dispatch(actionsControl.closeFileAndChangingTab(productName, singleFile, history, currentPage));
  dispatch(actionsFiles.closeFile(productName, singleFile));
}

export const getTargetPathOnWorklist = (currentPage) => {
  if (currentPage === 'dashboard' || currentPage === 'upload' || currentPage === 'setting'){
    return 'view';
  } else {
    return currentPage;
  }
}

// export const amyloidCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas,godThanksSUVR, godThanksCentiloid) => {
//   const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
//   if (selectedAll.length === 0) {
//     alert('No file selected on the worklist, please select file');
//   } else {
//     const bottomCards = productCoreItem["analysis"].bottom;
//     const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
//     const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
//     const structuredData = selectedAll.reduce((arr, srcData, idx)=>{

//       const tracerName = srcData['Tracer'];
//       const refName = settingOfProduct.defaultRef[tracerName];
//       const shortRefName = getShortName(refName);
//       const ref = {fullName:refName, shortName:shortRefName};
//       const structData = structAmyloidCSV({ref, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
//       return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
//     },[])
//     const fname = 'fnameForWorklist.csv'
//     generateCSV({data:structuredData, transpose:true, fname})
//   }
// }

export const getShortName = (refName) => {
  if (refName === "Whole cerebellum"){
    const shortName = "wcrbll"
    return shortName;
  } else if (refName === "Cerebellar gray matter"){
    const shortName = "crbllGM"
    return shortName;
  } else if (refName === "Pons"){
    const shortName = "pons"
    return shortName;
  } else if (refName === "Cerebral white matter"){
    const shortName = "cerebralWM"
    return shortName;
  } else {
    const shortName = "wcrbll"
    return shortName;
  }
}

export const datCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas, godThanksSBR, godThanksSemiquatification) => {
  const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
  if (selectedAll.length === 0 ) {
    alert('No file selected on the worklist, please select file');
  } else {
    const bottomCards = productCoreItem["analysis"].bottom;
    const srcSBR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcSemiquant = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
    const structuredData = selectedAll.reduce((arr, srcData, idx)=>{
      const tracerName = srcData['Tracer'];
      const refName = settingOfProduct.defaultRef[tracerName];
      const shortRefName = getShortName(refName);
      const ref = {fullName:refName, shortName:shortRefName};
      const structData = structDATCSV({ref, atlas:defaultAtlas, srcData:srcData, srcSBR:srcSBR, srcSemiquant:srcSemiquant, godThanksSBR, godThanksSemiquatification}); 
      return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
    },[])
    const fname = 'fnameForWorklist.csv'
    generateCSV({data:structuredData, transpose:true, fname})
  }
}

export const fdgCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('fdgCSVFormat')
}

export const tauCSVFormatFromWorklist = (filteredFileList, productCoreItem, defaultAtlas) => {
  alert('tauCSVFormat')
}

export const openGroupFilesSelected = ({targetPage, filteredList, targetFile, productName, dispatch, history}) => {
  dispatch(actionsControl.openFile_FetchSlices_Jump(productName, filteredList, targetFile));
  dispatch(actionsFiles.openFiles(productName, true));
  history.push(`/${productName}/${targetPage}/${targetFile.fileID}`);
}

export const openSingleFileWithoutSelect = ({targetPage, history, dispatch, singleFile, fileList, tracerList, productName}) => {
  const temp = fileList.filter((el) => tracerList.includes(el.Tracer) && (el.fileID === singleFile.fileID || el.Opened === true));
  dispatch(actionsControl.openFile_FetchSlices_Jump(productName, temp, singleFile));
  dispatch(actionsFiles.openFile(productName, singleFile));
  history.push(`/${productName}/${targetPage}/${singleFile.fileID}`);
}

export const isAnySelected = (fileList, tracerList) => {
  const selectedFiles = fileList.filter(el=>tracerList.includes(el.Tracer) && el.Select === true);
  if (selectedFiles.length === 0) return false;
  else return true;
}

export const selectSingleFile = (dispatch, singleFile) => {
  dispatch(actionsFiles.selectFile(singleFile));
}

export const removesessionStorage = () =>{
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("username");
}

export const getCanvasBlob = (canvas) => {
  return new Promise(function(resolve, reject) {
    canvas.toBlob(function(blob) {
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const generateJPEG = async (elRefs, func, func2)=>{
  func(true)
  const zip = new JSZip();
  const promiseAll = Promise.all(elRefs.map((elRef,i)=>{

    const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
    const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18')
    const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
    const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()
    
    const fname = PatientID +"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+"_"+i+".jpg"
    console.log(fname)

    return Promise.all(elRef.map( async (ref,idx)=>{
      const canvasItem = await html2canvas(ref.current, {allowTaint:true, useCORS:true});
      const blob = getCanvasBlob(canvasItem);
      zip.file(fname, blob);
      // zip.file('page ('+i+'_'+idx+').jpg', blob);
    }));
  }))
  promiseAll.then(()=>{
    zip.generateAsync({type:'blob'}).then((content) => {
      saveAs(content, 'brtnx(jpg).zip');
      func(false)
      func2(false)
    });
  })
}

// export const generatePDF = async (elRefs, fname) => {
export const generatePDF = async (elRefs, func, func2) => {
  func(true)
  const pdfArr = elRefs.map(_=>new jsPDF('p', 'mm', 'A4'))
  const width = pdfArr[0].internal.pageSize.getWidth();
  const height = pdfArr[0].internal.pageSize.getHeight();
  
  let zip = new JSZip();
  
  const promiseAll = Promise.all(elRefs.map( async (elRef,i)=>{
    const PatientID = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[1].childNodes[1].childNodes[0].textContent
    const tracerName = (elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[5].childNodes[1].childNodes[0].textContent).replace('\u00B9\u2078', '18')
    const AcquisitionDateTime = elRef[0].current.childNodes[0].childNodes[2].childNodes[0].childNodes[1].childNodes[0].childNodes[4].childNodes[1].childNodes[0].textContent
    const refName = (elRef[0].current.childNodes[0].childNodes[3].childNodes[1].textContent).split(':').at(-1).trim()
    
    const fname = PatientID +"_"+tracerName+"_"+AcquisitionDateTime+"_"+refName+"_"+i+".pdf"
    console.log(fname)
    // debugger;
    const canvasArray = await Promise.all(elRef.map((ref,i)=>html2canvas(ref.current, {allowTaint:true, useCORS:true})));

    return Promise.all(canvasArray.map( async (canvas,idx)=>{
      const imgData = canvas.toDataURL('image/png',);
      pdfArr[i].addImage(imgData, 'JPEG', 0, 0, width, height);
      if (idx < canvasArray.length-1) pdfArr[i].addPage();
      else zip.file(fname, pdfArr[i].output('blob'));
      // else zip.file('report ('+i+').pdf', pdfArr[i].output('blob'));
      return idx;
    }));
  }))
  promiseAll.then(()=>{
    console.log("complete: ", promiseAll);
    zip.generateAsync({type:'blob'}).then((content) => {
      saveAs(content, 'brtnx(pdf).zip');
      func(false)
      func2(false)
    });
  });
}

export const structAmyloidCSV = ({ref, atlas, srcData, srcSUVR, srcCentiloid, godThanksSUVR, godThanksCentiloid}={}) => {
  // debugger;
  const godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)
      v0.subItem.map(v1=> {
        arr0.region.push(v0.Region)
        arr0.subRegion.push(v1.Region)
        arr0["Total SUVR"].push(v1.Total)
        arr0["Left SUVR"].push(v1.Left)
        arr0["Right SUVR"].push(v1.Right)
      })
      return arr0
    },{region:[], subRegion:[], "Total SUVR":[], "Left SUVR":[], "Right SUVR":[]})
  
    
    
    const godThanksCentiloidValues = godThanksCentiloid.items.reduce((arr0, v0)=>{
    arr0["Total Centiloid"].push(v0.Total)
    arr0["Left Centiloid"].push(v0.Left)
    arr0["Right Centiloid"].push(v0.Right)
      v0.subItem.map(v1=> {
        arr0["Total Centiloid"].push(v1.Total)
        arr0["Left Centiloid"].push(v1.Left)
        arr0["Right Centiloid"].push(v1.Right)
      })
      return arr0
  }, {"Total Centiloid":[], "Left Centiloid":[], "Right Centiloid":[]})

  console.log(godThanksFormating)
    // debugger;
  const refFactor = (()=>{
    if (ref.shortName == "pons" || ref.shortName == "cerebralWM") return srcData["ratio_gry2pons"];
    else if (ref.shortName == "wcrbll") return srcData["ratio_gry2crbl"];
    else return 1;
  })();

  const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
    const subItem = item.subItem.reduce((columnArr2, subitem)=>{
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
      return columnArr2
    },[])
    columnArr=[...columnArr, ...subItem]
    return columnArr
  },[])

  const columnsCentiloid = srcCentiloid.reduce((columnArr, item)=>{
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
    const subItem = item.subItem.reduce((columnArr2, subitem)=>{
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left Centiloid", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right Centiloid", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
      return columnArr2
    },[])
    columnArr=[...columnArr, ...subItem]
    return columnArr
  },[])

  const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
    columnArr[0].push(item.lobe)
    columnArr[1].push(item.fullname)
    return columnArr
  },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...godThanksFormating.region],
    ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...godThanksFormating.subRegion],
  ]
  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const rowIndexSUVR = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  const rowIndexCentiloid = ['Total Centiloid', 'Left Centiloid', 'Right Centiloid']
  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndexSUVR.map(item1=>{
    const rowSUVR = godThanksFormating[item1]
    return [ srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  const rowsCentiloid = rowIndexCentiloid.map(item1=>{
    const rowCentiloid = godThanksCentiloidValues[item1]
    return [ srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName, item1, ...rowCentiloid]
  })
  //  debugger;
  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR, 
    ...rowsCentiloid
  ]

  return {
    headRows:columnAllIndex,
    bodyRows:finalRowsBody,
  }
}

export const structDATCSV = ({ref, atlas, srcData, srcSBR, srcSemiquant, godThanksSBR, godThanksSemiquatification}={}) => {

  const godThanksFormating = godThanksSBR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SBR"].push(v0.Total)
    arr0["Left SBR"].push(v0.Left)
    arr0["Right SBR"].push(v0.Right)
      v0.subItem.map(v1=> {
        arr0.region.push(v0.Region)
        arr0.subRegion.push(v1.Region)
        arr0["Total SBR"].push(v1.Total)
        arr0["Left SBR"].push(v1.Left)
        arr0["Right SBR"].push(v1.Right)
      })
      return arr0
    },{region:[], subRegion:[], "Total SBR":[], "Left SBR":[], "Right SBR":[]})

  const godThanksSemiquatificationValues = godThanksSemiquatification.items.reduce((arr0, v0)=>{
    arr0.region.push(v0.Measure)
    arr0.subRegion.push(v0.Region)
    arr0["Total Semiquatification"].push(v0.Total)
    arr0["Left Semiquatification"].push(v0.Left)
    arr0["Right Semiquatification"].push(v0.Right)
      v0.subItem.map(v1=> {
        arr0.region.push(v0.Region)
        arr0.subRegion.push(v1.Region)
        arr0["Total Semiquatification"].push(v1.Total)
        arr0["Left Semiquatification"].push(v1.Left)
        arr0["Right Semiquatification"].push(v1.Right)
      })
      return arr0
  }, {region:[], subRegion:[],"Total Semiquatification":[], "Left Semiquatification":[], "Right Semiquatification":[]})  


  // debugger;

  
  const srcSBR_Semiquant = [

    ...srcSBR,
    ...srcSemiquant
  ]
 
  const columnsSBR_Semiquant = srcSBR_Semiquant.reduce((columnArr, item)=>{
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Left", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Right", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
    const subItem = item.subItem.reduce((columnArr2, subitem)=>{
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
      return columnArr2
    },[])
    columnArr=[...columnArr, ...subItem]
    return columnArr
  },[])

  const columnIndex = columnsSBR_Semiquant.filter(({Type})=>Type==='Left').reduce((columnArr, item)=>{
    columnArr[0].push(item.lobe)
    columnArr[1].push(item.fullname)
    return columnArr
  },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID", "Patient Name", "Atlas","Reference", "Region",   ...godThanksFormating.region, ...godThanksSemiquatificationValues.region],
    ["fileID", "Patient ID", "Patient Name", "Atlas","Reference", "Subregion",...godThanksFormating.subRegion],
  ]



  const columns = [...columnsSBR_Semiquant]

  const rowIndex = ['Total', 'Left', 'Right']
  const rows = rowIndex.map(item1=>{
    // const row = columns.filter(({Type})=>Type===item1).map(item2=>{
    //   return srcData[item2.var]
    // })
    const rowSBR = godThanksFormating[`${item1} SBR`]
    const rowSemiquatification = godThanksSemiquatificationValues[`${item1} Semiquatification`]
    // debugger;
    return [srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName, item1, ...rowSBR, ...rowSemiquatification ]
  })

  return {  
    headRows:columnAllIndex,
    bodyRows:rows,
  }
}

export const structFDGTauCSV = ({ref, atlas, srcData, srcSUVR,  godThanksSUVR}={}) => {
  const godThanksFormating = godThanksSUVR.items.reduce((arr0, v0) => {
    arr0.region.push(v0.Region)
    arr0.subRegion.push(v0.Region)
    arr0["Total SUVR"].push(v0.Total)
    arr0["Left SUVR"].push(v0.Left)
    arr0["Right SUVR"].push(v0.Right)
      v0.subItem.map(v1=> {
        arr0.region.push(v0.Region)
        arr0.subRegion.push(v1.Region)
        arr0["Total SUVR"].push(v1.Total)
        arr0["Left SUVR"].push(v1.Left)
        arr0["Right SUVR"].push(v1.Right)
      })
      return arr0
    },{region:[], subRegion:[], "Total SUVR":[], "Left SUVR":[], "Right SUVR":[]})

  console.log(godThanksFormating)
    // debugger;
  // const refFactor = (()=>{
  //   if (ref.shortName == "pons" || ref.shortName == "cerebralWM") return srcData["ratio_gry2pons"];
  //   else if (ref.shortName == "wcrbll") return srcData["ratio_gry2crbl"];
  //   else return 1;
  // })();

  const columnsSUVR = srcSUVR.reduce((columnArr, item)=>{
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: item.varname.Left, lobe:item.fullname, fullname: item.fullname})
    columnArr.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: item.varname.Right, lobe:item.fullname, fullname: item.fullname})
    const subItem = item.subItem.reduce((columnArr2, subitem)=>{
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Left SUVR", var: subitem.varname.Left, lobe: item.fullname, fullname: subitem.fullname})
      columnArr2.push({'Patient ID':srcData.PatientID, Type: "Right SUVR", var: subitem.varname.Right, lobe: item.fullname, fullname: subitem.fullname})
      return columnArr2
    },[])
    columnArr=[...columnArr, ...subItem]
    return columnArr
  },[])

  const columnIndex = columnsSUVR.filter(({Type})=>Type==='Left SUVR').reduce((columnArr, item)=>{
    columnArr[0].push(item.lobe)
    columnArr[1].push(item.fullname)
    return columnArr
  },[[],[]])

  const columnAllIndex = [
    ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...godThanksFormating.region],
    ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...godThanksFormating.subRegion],
  ]
  // const columnAllIndex = [
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Region",   ...columnIndex[0]],
  //   ["fileID", "Patient ID", "Patient Name", "Atlas", "Reference", "Subregion",...columnIndex[1]],
  // ]
  // const columns = [...columnsSUVR, ...columnsCentiloid]

  const rowIndexSUVR = ['Total SUVR', 'Left SUVR', 'Right SUVR']
  // const rowIndex = ['Total SUVR', 'Left SUVR', 'Right SUVR', 'Total Centiloid', 'Left Centiloid','Right Centiloid']
  const rowsSUVR = rowIndexSUVR.map(item1=>{
    const rowSUVR = godThanksFormating[item1]
    return [ srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName, item1, ...rowSUVR]
  })
  //  debugger;
  const finalRowsBody = [
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsSUVR], 
    // ...[srcData.fileID, srcData.PatientID, srcData.PatientName, atlas, ref.fullName,...rowsCentiloid]
    ...rowsSUVR, 
  ]

  return {
    headRows:columnAllIndex,
    bodyRows:finalRowsBody,
  }
}

export const generateCSV = async ({data, transpose=false, fname}={}) => {

  const transData = transpose ? data[0].map((_, colIndex) => data.map(row => row[colIndex])):data;
  const csvContent = "data:text/csv;charset=utf-8," + transData.map(e => {
    return e.join(",")})
    .join("\n");
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fname);
  // link.setAttribute("download", "brtnx_quantified.csv");
  document.body.appendChild(link); 
  
  link.click();
}

export const analysisDATtable1 = ( {settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  
  const result = settingOfProduct.list.filter(({level, type, atlas, isFolded, multiAtlas})=> multiAtlas === defaultAtlas && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    // debugger;
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){// 서버에서 직접 받는 값들
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total'){
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2;
          const totalVar = item.varname.Left.slice(0,-2)
          // const newValue = selectedFile[totalVar]
          obj[head]=(oldValue)*RF
        }else if (head==='Left')
          obj[head]=(selectedFile[item.varname.Left])*RF
        else if (head==='Right')
          obj[head]=(selectedFile[item.varname.Right])*RF
        return obj
      }else {// 프론트에서 조합하는 값들
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)

        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
            const LeftAvg = item.varname.Left.reduce((acc,el,idx)=>{
                                const weightLeft = item.weight.Left[idx]
                                console.log("weightLeft1:",weightLeft)
                                acc += selectedFile[el] * weightLeft
                                return acc
                              },0)
            const RightAvg = item.varname.Right.reduce((acc,el,idx)=>{
                                const weightRight = item.weight.Right[idx]
                                console.log("weightRight1:",weightRight)
                                acc += selectedFile[el] * weightRight
                                return acc
                              },0)
            // const weightedMean1 = (RightAvg/RightWeightAcc + LeftAvg/LeftWeightAcc)/2
            const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
            // console.log(weightedMean1, weightedMean2)
            return weightedMean2*RF
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
           },0)/LeftWeightAcc*RF
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          },0)/RightWeightAcc*RF
        return obj
      }
    },{})
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    // console.log(subItem)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return tableItems.tableHead.reduce((obj,head,idx)=>{
          if (head==='Region'){
            obj[head]=item2.fullname
          } else if (head==='Total') {
            // console.log(item2.fullname)
            // if (item2.fullname==="Caudate body" && Object.keys(selectedFile).length !== 0){
            //   debugger;
            // }
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2*RF
            // debugger;
            // console.log(item2, item2.atlas, item2.fullname, item2?.weight?.Left, item2?.weight?.Right)
            // const newTotal = (selectedFile[item2.varname.Left]*1 + selectedFile[item2.varname.Right]*1)/(2)*RF
            // if (item2.fullname === "Precuneus") {
            //   debugger;
            // }
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)*RF
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          } else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]*RF
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]*RF
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }
    tableItem['id'] = idx
    return tableItem
  })
  // console.log(result);
  // debugger;
  // RF 처리
  return {tableHead:tableItems.tableHead, items:result};
}
export const analysisAmyloidtable1 = ( {settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  const result = settingOfProduct.list.filter(({level, type, atlas, isFolded})=> atlas === defaultAtlas && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){// 서버에서 직접 받는 값들
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total'){
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2;
          const totalVar = item.varname.Left.slice(0,-2)
          // const newValue = selectedFile[totalVar]
          obj[head]=(oldValue)*RF
        }else if (head==='Left')
          obj[head]=(selectedFile[item.varname.Left])*RF
        else if (head==='Right')
          obj[head]=(selectedFile[item.varname.Right])*RF
        return obj
      }else {// 프론트에서 조합하는 값들
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)

        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
            const LeftAvg = item.varname.Left.reduce((acc,el,idx)=>{
                                const weightLeft = item.weight.Left[idx]
                                console.log("weightLeft1:",weightLeft)
                                acc += selectedFile[el] * weightLeft
                                return acc
                              },0)
            const RightAvg = item.varname.Right.reduce((acc,el,idx)=>{
                                const weightRight = item.weight.Right[idx]
                                console.log("weightRight1:",weightRight)
                                acc += selectedFile[el] * weightRight
                                return acc
                              },0)
            // const weightedMean1 = (RightAvg/RightWeightAcc + LeftAvg/LeftWeightAcc)/2
            const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
            // console.log(weightedMean1, weightedMean2)
            return weightedMean2*RF
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
           },0)/LeftWeightAcc*RF
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          },0)/RightWeightAcc*RF
        return obj
      }
    },{})
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    // console.log(subItem)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return tableItems.tableHead.reduce((obj,head,idx)=>{
          if (head==='Region'){
            obj[head]=item2.fullname
          } else if (head==='Total') {
            // console.log(item2.fullname)
            // if (item2.fullname==="Caudate body" && Object.keys(selectedFile).length !== 0){
            //   debugger;
            // }
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2*RF
            // debugger;
            // console.log(item2, item2.atlas, item2.fullname, item2?.weight?.Left, item2?.weight?.Right)
            // const newTotal = (selectedFile[item2.varname.Left]*1 + selectedFile[item2.varname.Right]*1)/(2)*RF
            // if (item2.fullname === "Precuneus") {
            //   debugger;
            // }
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)*RF
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          } else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]*RF
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]*RF
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }
    tableItem['id'] = idx
    return tableItem
  })
  // console.log(result);
  // debugger;
  // RF 처리
  return {tableHead:tableItems.tableHead, items:result};
}
export const analysisADATtable1 = ( {settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  const result = settingOfProduct.list.filter(({level, type, atlas, isFolded, multiAtlas})=> multiAtlas === defaultAtlas && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){// 서버에서 직접 받는 값들
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total'){
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2;
          const totalVar = item.varname.Left.slice(0,-2)
          // const newValue = selectedFile[totalVar]
          obj[head]=(oldValue)*RF
        }else if (head==='Left')
          obj[head]=(selectedFile[item.varname.Left])*RF
        else if (head==='Right')
          obj[head]=(selectedFile[item.varname.Right])*RF
        return obj
      }else {// 프론트에서 조합하는 값들
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)

        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
            const LeftAvg = item.varname.Left.reduce((acc,el,idx)=>{
                                const weightLeft = item.weight.Left[idx]
                                console.log("weightLeft1:",weightLeft)
                                acc += selectedFile[el] * weightLeft
                                return acc
                              },0)
            const RightAvg = item.varname.Right.reduce((acc,el,idx)=>{
                                const weightRight = item.weight.Right[idx]
                                console.log("weightRight1:",weightRight)
                                acc += selectedFile[el] * weightRight
                                return acc
                              },0)
            // const weightedMean1 = (RightAvg/RightWeightAcc + LeftAvg/LeftWeightAcc)/2
            const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
            // console.log(weightedMean1, weightedMean2)
            return weightedMean2*RF
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
           },0)/LeftWeightAcc*RF
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          },0)/RightWeightAcc*RF
        return obj
      }
    },{})
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    // console.log(subItem)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return tableItems.tableHead.reduce((obj,head,idx)=>{
          if (head==='Region'){
            obj[head]=item2.fullname
          } else if (head==='Total') {
            // console.log(item2.fullname)
            // if (item2.fullname==="Caudate body" && Object.keys(selectedFile).length !== 0){
            //   debugger;
            // }
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2*RF
            // debugger;
            // console.log(item2, item2.atlas, item2.fullname, item2?.weight?.Left, item2?.weight?.Right)
            // const newTotal = (selectedFile[item2.varname.Left]*1 + selectedFile[item2.varname.Right]*1)/(2)*RF
            // if (item2.fullname === "Precuneus") {
            //   debugger;
            // }
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)*RF
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          } else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]*RF
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]*RF
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }
    tableItem['id'] = idx
    return tableItem
  })
  // console.log(result);
  // debugger;
  // RF 처리
  return {tableHead:tableItems.tableHead, items:result};
}

export const analysisAmyloidDATcard2_2 =({settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas,}={}) =>{
  // if (settingOfProduct.list.filter(el=>el.isFolded).length !== 0){
  //   debugger;
  //   // TODO: setting.list 에 isFolded가 centiloid에 대해서는 반영되지 않음
  // }
  const result = settingOfProduct.list.filter(({level, type, atlas, isFolded})=> atlas === defaultAtlas && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Measure')
          obj[head]=item.fullname
        else if (head==='Total')
          obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        else if (head==='Left')
          obj[head]=selectedFile[item.varname.Left]
        else if (head==='Right')
          obj[head]=selectedFile[item.varname.Right]
        else if (head==='Centiloid')
          obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)
        // debugger;
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total' || head==='Centiloid')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
              const RightAvg = item.varname.Right.reduce((acc,el, idx)=>{
                                  const weightRight = item.weight.Right[idx]
                                  acc += selectedFile[el]* weightRight
                                  return acc
                                },0)
              const LeftAvg = item.varname.Left.reduce((acc,el, idx)=>{
                                  const weightLeft = item.weight.Left[idx]
                                  acc += selectedFile[el]* weightLeft
                                  return acc
                                },0)
              const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
              return (weightedMean2)
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el]* weightLeft
            return acc
           },0)/LeftWeightAcc
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el]* weightRight
            return acc
          },0)/RightWeightAcc
        return obj

      }
    },{})
    
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return tableItems.tableHead.reduce((obj,head,idx)=>{
          if (head==='Region')
            obj[head]=item2.fullname
          else if (head==='Measure')
            obj[head]=item.fullname
          else if (head==='Total'){
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          }else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]
          else if (head==='Centiloid'){
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)
            } catch(e){
              console.log(e)
            }
            obj[head]=newTotal
          }
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return {tableHead:tableItems.tableHead, items:result};
}
export const analysisDATcard2_2 =({settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas,}={}) =>{
  // if (settingOfProduct.list.filter(el=>el.isFolded).length !== 0){
    
  //   // TODO: setting.list 에 isFolded가 centiloid에 대해서는 반영되지 않음
  // }
  const result = settingOfProduct.list.filter(({level, type, atlas, isFolded, multiAtlas})=> multiAtlas === defaultAtlas && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    // debugger;
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Measure')
          obj[head]=item.fullname
        else if (head==='Total')
          obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        else if (head==='Left')
          obj[head]=selectedFile[item.varname.Left]
        else if (head==='Right')
          obj[head]=selectedFile[item.varname.Right]
        else if (head==='Centiloid')
          obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)
        // debugger;
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total' || head==='Centiloid')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
              const RightAvg = item.varname.Right.reduce((acc,el, idx)=>{
                                  const weightRight = item.weight.Right[idx]
                                  acc += selectedFile[el]* weightRight
                                  return acc
                                },0)
              const LeftAvg = item.varname.Left.reduce((acc,el, idx)=>{
                                  const weightLeft = item.weight.Left[idx]
                                  acc += selectedFile[el]* weightLeft
                                  return acc
                                },0)
              const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
              return (weightedMean2)
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el]* weightLeft
            return acc
           },0)/LeftWeightAcc
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el]* weightRight
            return acc
          },0)/RightWeightAcc
        return obj

      }
    },{})
    
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return tableItems.tableHead.reduce((obj,head,idx)=>{
          if (head==='Region')
            obj[head]=item2.fullname
          else if (head==='Measure')
            obj[head]=item.fullname
          else if (head==='Total'){
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          }else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]
          else if (head==='Centiloid'){
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)
            } catch(e){
              console.log(e)
            }
            obj[head]=newTotal
          }
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return {tableHead:tableItems.tableHead, items:result};
}


export const reportAmyloidtable1 = ( {settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  const result = settingOfProduct.list.filter(({level, type, atlas, report, isFolded})=> atlas === defaultAtlas && report===true && type===typeSelect && !isFolded).map((item,idx)=>{
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total'){
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2;
          const totalVar = item.varname.Left.slice(0,-2)
          // const newValue = selectedFile[totalVar]
          obj[head]=(oldValue)*RF
        }else if (head==='Left')
          obj[head]=(selectedFile[item.varname.Left])*RF
        else if (head==='Right')
          obj[head]=(selectedFile[item.varname.Right])*RF
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)

        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
              const RightAvg = item.varname.Right.reduce((acc,el, idx)=>{
                                  const weightRight = item.weight.Right[idx]
                                  acc += selectedFile[el]* weightRight
                                  return acc
                                },0)
              const LeftAvg = item.varname.Left.reduce((acc,el, idx)=>{
                                  const weightLeft = item.weight.Left[idx]
                                  acc += selectedFile[el]* weightLeft
                                  return acc
                                },0)
              const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
              return weightedMean2*RF
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el]* weightLeft
            return acc
           },0)/LeftWeightAcc*RF
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el]* weightRight
            return acc
          },0)/RightWeightAcc*RF
        return obj
      }
    },{})
    tableItem['subItem'] = []
    tableItem['id'] = idx
    return tableItem
  })
  return {tableHead:tableItems.tableHead, items:result};
}

export const reportDATtable1 = ( {settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  const result = settingOfProduct.list.filter(({level, type, atlas, report})=> atlas === defaultAtlas && report===true && type===typeSelect).map((item,idx)=>{
    const tableItem = tableItems.tableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total'){
          const oldValue = (selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2;
          const totalVar = item.varname.Left.slice(0,-2)
          // const newValue = selectedFile[totalVar]
          obj[head]=(oldValue)*RF
        }
        else if (head==='Left')
          obj[head]=(selectedFile[item.varname.Left])*RF
        else if (head==='Right')
          obj[head]=(selectedFile[item.varname.Right])*RF
        return obj
      } else {
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)
        // debugger;
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Total')
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
              const RightAvg = item.varname.Right.reduce((acc,el,idx)=>{
                                  const weightRight = item.weight.Right[idx]
                                  console.log("weightRight1:",weightRight)
                                  acc += selectedFile[el] * weightRight
                                  return acc
                                },0)
              const LeftAvg = item.varname.Left.reduce((acc,el,idx)=>{
                                  const weightLeft = item.weight.Left[idx]
                                  console.log("weightLeft1:",weightLeft)
                                  acc += selectedFile[el] * weightLeft
                                  return acc
                                },0)
              const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)
              return weightedMean2*RF
          })()
        else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el,idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el] * weightLeft
            return acc
           },0)/LeftWeightAcc*RF
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el,idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el] * weightRight
            return acc
          },0)/RightWeightAcc*RF
        return obj

      }
    },{})
    tableItem['subItem'] = []
    tableItem['id'] = idx
    return tableItem
  })
  return {tableHead:tableItems.tableHead, items:result};
}

export const reportAmyloidDATtable2 =({settingOfProduct, typeSelect, tableItems, selectedFile, defaultAtlas, RF}={}) =>{
  // if (defaultAtlas.includes("Mal")){
  //   debugger;
  //   console.log(subRegions)
  // }
  const newTableHead = tableItems.tableHead.filter(el=>el!=='Centiloid');
  const result = settingOfProduct.list.filter(({level, type, atlas, report, isFolded})=> atlas === defaultAtlas && report && level===0 && type===typeSelect && !isFolded).map((item,idx)=>{
    const tableItem = newTableHead.reduce((obj,head,idx)=>{
      if (typeof(item.varname.Left) === 'string'){ // 서버에서 받은 값을 그대로 출력시키는 부분
        if (head==='Region')
          obj[head]=item.fullname
        else if (head==='Measure')
          obj[head]=item.fullname
        else if (head==='Total')
          obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        else if (head==='Left')
          obj[head]=selectedFile[item.varname.Left]
        else if (head==='Right')
          obj[head]=selectedFile[item.varname.Right]
        // else if (head==='Centiloid')
        //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        return obj
      } else { // custom ROI 에 대해서만 동작하는 부분
        // if (head==='Region')
        //   obj[head]=item.fullname
        // else if (head==='Measure')
        //   obj[head]=item.fullname
        // else if (head==='Total')
        //   obj[head]=25
        // else if (head==='Left')
        //   obj[head]=26
        // else if (head==='Right')
        //   obj[head]=27
        // // else if (head==='Centiloid')
        // //   obj[head]=(selectedFile[item.varname.Left] + selectedFile[item.varname.Right])/2
        // return obj
        // debugger;
        const RightWeightAcc = item.weight.Right.reduce((sum, val)=>{
          return sum+val
        },0)
        const LeftWeightAcc = item.weight.Left.reduce((sum, val)=>{
          return sum+val
        },0)
        if (head==='Region'){
          obj[head]=item.fullname
        } else if (head==='Measure'){
          obj[head]=item.fullname
        }
        else if (head==='Total'){
        //TODO : 펼쳐진 코드 정리
          obj[head]=(()=>{
              const RightAvg = item.varname.Right.reduce((acc,el,idx)=>{
                                  const weightRight = item.weight.Right[idx]
                                  acc += selectedFile[el] * weightRight
                                  return acc
                                },0)
              const LeftAvg = item.varname.Left.reduce((acc,el,idx)=>{
                                  const weightLeft = item.weight.Left[idx]
                                  acc += selectedFile[el] * weightLeft
                                  return acc
                                },0)
              const weightedMean2 = (RightAvg+LeftAvg)/(RightWeightAcc+LeftWeightAcc)                  
              return weightedMean2
          })()
        }else if (head==='Left')
          obj[head]=item.varname.Left.reduce((acc,el, idx)=>{
            const weightLeft = item.weight.Left[idx]
            acc += selectedFile[el]* weightLeft
            return acc
          },0)/LeftWeightAcc
        else if (head==='Right')
          obj[head]=item.varname.Right.reduce((acc,el, idx)=>{
            const weightRight = item.weight.Right[idx]
            acc += selectedFile[el]* weightRight
            return acc
          },0)/RightWeightAcc
        return obj

      }
    },{})
    
    const subItem = settingOfProduct.list.filter(({id, belongToForeignKey, level})=> level === 1 && id !== item.primaryKey && belongToForeignKey === item.primaryKey)
    if (subItem.length !== 0) {
      tableItem['subItem'] = subItem.map(item2=>{
        return newTableHead.reduce((obj,head,idx)=>{
          if (head==='Region')
            obj[head]=item2.fullname
          else if (head==='Measure')
            obj[head]=item.fullname
          else if (head==='Total'){
            const oldTotal = (selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
            let newTotal
            try{
              newTotal = (selectedFile[item2.varname.Left]*item2.weight.Left + selectedFile[item2.varname.Right]*item2.weight.Right)/(item2.weight.Left+item2.weight.Right)
            } catch(e){
              console.log(e)
            }
            // if (Math.abs(oldTotal - newTotal) > 1.0) console.error('please check weighted sum process')
            obj[head]=newTotal
          }else if (head==='Left')
            obj[head]=selectedFile[item2.varname.Left]
          else if (head==='Right')
            obj[head]=selectedFile[item2.varname.Right]
          // else if (head==='Centiloid')
          //   obj[head]=(selectedFile[item2.varname.Left] + selectedFile[item2.varname.Right])/2
          return obj
        },{})
      })
    } else {
      tableItem['subItem'] = []
    }

    tableItem['id'] = idx
    return tableItem
  })
  return {tableHead:newTableHead, items:result};
}

export const viewEnable = (controlOfProduct, selectedFileID) => {
  if (selectedFileID === null) {
    return false;
  } else {
    const sliceReady = controlOfProduct.openedFiles.find(el=>el.fileID === selectedFileID).Slices.length;
    return sliceReady === 0 ? false:true;
  }
}


// export const applyReferenceFactor = (temp, selectedFile, settingOfProduct) =>{
//   // debugger;
//   const tracerName = selectedFile['Tracer'];
//   const refName = settingOfProduct.defaultRef[tracerName]
//   const RF = RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
//   // const RF = RefFactor({productName, refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"], wmRF:selectedFile["ratio_gry2wm"], cgRF:selectedFile["ratio_gry2cg"], global:1/selectedFile['Global']})
  
//   // const RF = (() => {
//   //   if (refName === "Pons") return selectedFile["ratio_gry2pons"];
//   //   else if (refName === "Whole cerebellum") return selectedFile["ratio_gry2crbl"];
//   //   else if (refName === "Cerebellar gray matter") return 1;
//   //   else if (refName === "Cerebral white matter") return selectedFile["ratio_gry2pons"];
//   //   else return 1;
//   // })()

//   const apply_ref_temp = temp.items.map(v=>{
//     return {
//       ...v,
//       Total:v.Total * RF,
//       Left:v.Left * RF,
//       Right:v.Right * RF,
//       subItem: v.subItem.map(vv=>{
//         return {
//           ...vv,
//           Total:vv.Total * RF,
//           Left:vv.Left * RF,
//           Right:vv.Right * RF,
//           }
//         })
//     }
//   })  
  
//   return {...temp, items:apply_ref_temp}
// }


export const getLicenseInfo = (productInfo, setLicenseInfo) =>{
  const getAllProductLicenseInfo = async (obj) => {
    try {
      return await Promise.all(
        productInfo.map(v=>{
          return axios.get(IPinUSE + `result/download/licenseinfo-${v.productShortName}.txt`).then(res=>res).catch(e=>null)
      }))
    } catch (err) {
      console.log(err);
    }
  }
  getAllProductLicenseInfo().then(res=>{
   
    const today = new Date();
    const todayFormatted = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-'+('0' + today.getDate()).slice(-2);
    const todayISO = new Date(todayFormatted)?.toISOString();
    const amyloidEndDate = res[0]===null ? null:new Date(res[0].data.split(/\s+/)[0])?.toISOString()?.slice(0,10);
    const amyloidCount = res[0]===null ? null:Number(res[0].data.split(/\s+/)[1]);
    const datEndDate = res[1]===null ? null:new Date(res[1].data.split(/\s+/)[0])?.toISOString()?.slice(0,10);
    const datCount = res[1]===null ? null:Number(res[1].data.split(/\s+/)[1]);
    const fdgEndDate = res[2]===null ? null:new Date(res[2].data.split(/\s+/)[0])?.toISOString()?.slice(0,10);
    const fdgCount = res[2]===null ? null:Number(res[2].data.split(/\s+/)[1]);
    const tauEndDate = res[3]===null ? null:new Date(res[3].data.split(/\s+/)[0])?.toISOString()?.slice(0,10);
    const tauCount = res[3]===null ? null:Number(res[3].data.split(/\s+/)[1]);
    setLicenseInfo({
      'amyloid':{"valid":todayISO < amyloidEndDate, "until":amyloidEndDate}, 
      'dat':{"valid":todayISO < datEndDate, "until":datEndDate}, 
      'fdg':{"valid":todayISO < fdgEndDate, "until":fdgEndDate}, 
      'tau':{"valid":todayISO < tauEndDate, "until":tauEndDate}
    })
    // return true;
    // return({
    //   'amyloid':{"valid":todayISO < amyloidEndDate, "until":amyloidEndDate}, 
    //   'dat':{"valid":todayISO < datEndDate, "until":datEndDate}, 
    //   'fdg':{"valid":todayISO < fdgEndDate, "until":fdgEndDate}, 
    //   'tau':{"valid":todayISO < tauEndDate, "until":tauEndDate}
    // })
  })
  
  // return Promise.resolve(status)
}