import React, {useState, useEffect} from 'react';
import styles from './DropDownDiv.module.scss';
import * as media from "../../6_media";

function DropDownDiv(props) {
  const style=props.style;
  const [arrowState, setArrowState] = useState(false)
  // const [defaultName, setDefaultName] = useState(props.item.default)
  const itemList = props.item?.options
  useEffect(()=>{
    // debugger;
  }, [])
  return (
    <div className={styles[props.styleName]}>
    <div tabIndex={0} className={styles["container"]} 
          style={style}
          onBlur={()=>setArrowState(false)}>
      <div 
        //className props로 받아 바꾸기
        style={{background:style?.background}}
        className={styles["title_container"]}
        onClick={()=>setArrowState(!arrowState)}
      >
        <div>
         {props.item?.selectItem==="invertGray"? "Gray scale":props.item?.selectItem==="pet"?"GE":props.item?.selectItem==="jet"?"Jet":props.item?.selectItem==="hot"?"Hot":props.item?.selectItem}
         {/* {props.item?.selectItem} */}
        </div>
        <div className={styles["arrow_img"]}>
          <img src={arrowState ? media.arrow_up:media.arrow_down} alt=""/>
        </div>
      </div>
      <div 
        className={styles["drop_container"]}
        style={{display:`${!arrowState ? 'none' : ''}`, background:style?.background}}
      >
        {itemList?.map((v,i)=>
          <div key={i}
            onClick={()=>{
              setArrowState(false);
              props.item.setItem(v);
            }}
          >
            {v==="invertGray"?"Gray scale":v==="pet"?"GE":v==="jet"?"Jet":v==="hot"?"Hot":v}
          </div>
        )}

      </div>
    </div>
    </div>
  )
}

export default DropDownDiv
