import React, { useEffect, useState } from "react";
import styles from "./ReportPage3.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import {coreItem} from "0_variables/coreItem";
import {ReportLayout} from "../";
import amyloid_page3 from "./amyloid_page3.PNG";
import dat_page3 from "./dat_page3.PNG";
import tau_page3 from "./tau_page3.PNG";
import fdg_page3 from "./fdg_page3.PNG";
import {ImageViewer} from "./components";
import * as services from "2_services/sliceApi";
import * as utils from "0_variables/utils";
import * as media from "6_media";
import {ReportViewers, Arrow} from "5_components";
import { IPinUSE } from "2_services/IPs";
import chroma from 'chroma-js';
import { RiArrowLeftSFill, RiArrowLeftFill } from 'react-icons/ri';

import MNI_Axial from "0_variables/MNI152_T1_2mm_z";
import MNI_Coronal from "0_variables/MNI152_T1_2mm_y";
import MNI_Sagittal from "0_variables/MNI152_T1_2mm_x";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ReportPage3(props) {
  const fileList = useSelector((state) => state.fileList);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const pageName = location.pathname.split('/')[2];
  const username = sessionStorage.getItem("username");
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const control = useSelector((state) => state.control);
  const controlOfProduct = control[productName];
  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const axialCmap = 'hotV2';
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);
  const [sliceReady, setSliceReady] = useState(false);
  const [imageIds, setImageIds] = useState(utils.reportImageIdsbyProduct(productName, props.fileID));
  const [cmapAxial, setCmapAxial] = useState({name:axialCmap, from:1, to:255, code:[...Array(256)].map((v,i)=>chroma(cornerstone.colors.getColormap(axialCmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())})
  const [ref, setRef] = useState(props.refName)

  useEffect(()=>{
    // 혹시나 놓칠 수 있는 상황 대비해서 마지막으로 로딩함
    utils.reportImageLoader(productName, "report", fileList)
  },[])

  useEffect(()=>{
    setImageIds(utils.reportImageIdsbyProduct(productName, props.fileID))
    setRef(props.refName)
  },[params])

  useEffect(()=>{
    // TODO: 이런 로직에는 문제가 있음
    // (1) 더블클릭으로 열었을때 바로 리덕스와 imageLoader 추가를 해야하고
    // 리포트 열었을땐, redux에서 이미지를 가져온 경우에만 전체 페이지를 출력시켜야함
    // (2) 리포트를 묶음으로 다운받는 요청에 대해서는
    // 선택된 항목에대해서만
    // 데이터를 불러오고, 리덕스에 담아놓은 뒤, imageLoader를 세팅하면
    // 리포트를 출력시킬 수 있음
    // (3) 탭을 전환할땐, imageViewer의 componentDidUptdate로 이미지 교체시키던 display를 바꿔주던 해야함
    // componentDidMount 일때에만 display를 하고 있으므로 갱신이 이뤄지지 않음

    // if (Object.keys(controlOfProduct.reportItems).length !== 0 && imageIds.length !== 0){
    //   setSliceReady(true)
    // }

  }, [control[productName].reportItems])

  // debugger;
  return (
    <>
      <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
        <div className={styles.ImageContainer}>
          <div className={styles.ReportTitle}>
            <img src={media.reportIcon3} alt=""/>&nbsp;{(()=>{
              switch(productName){
                case "amyloid":
                  return 'Amyloid-beta PET Image'
                case "dat":
                  return 'DAT PET Image'
                case "tau":
                  return 'Tau PET Image'
                case "fdg":
                  return 'FDG PET Image'
                default:
                  return productName + ' PET Image'
              }
            })()}
          </div>
          <div className={styles.imageWrapper}>
            <div className={styles.imageTitle}>
              {(()=>{
                switch(productName){
                  case "amyloid":
                    return "Spatially normalized SUVR Image"
                  case "dat":
                    return "Spatially normalized SBR image"
                  case "tau":
                    return "Spatially normalized SUVR Image"
                  case "fdg":
                    return "Spatially normalized SUVR Image"
                  default:
                    return ""
                }
              })()}
            </div>
            <div style={{width:"90%"}} className={styles.SUVRImage}>
              {/* {props.selectedFile && <ReportViewers imageIds={imageIds} selectedFile={props.selectedFile}/>} */}
              <div className={styles.SUVRImage_box}>
                <div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_60.png'
                        }
                        alt="axial 60"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_55.png'
                        }
                        alt="axial 55"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_50.png'
                        }
                        alt="axial 50"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_45.png'
                        }
                        alt="axial 45"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_40.png'
                        }
                        alt="axial 40"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_35.png'
                        }
                        alt="axial 35"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_30.png'
                        }
                        alt="axial 30"
                    />
                  </div>
                  <div>
                    <img 
                      src={
                        IPinUSE+"result/download/" +
                        username +
                        "/database/" +
                        props.selectedFile.fileID +
                        '/'+ref+'_output_axial_25.png'
                        }
                        alt="axial 25"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.colorbarJet}>
                  <div>
                    <img src={media.colormapJet}/>
                    <div>
                        <div><RiArrowLeftSFill size={'25px'}/>2.60</div>
                        {/* <div><RiArrowLeftSFill size={'25px'}/>1.6</div> */}
                        {/* <div><img src={media.surfaceArrow} alt="" style={{width:"12px", height:"12px"}}/>1.6</div> */}
                        <div><RiArrowLeftSFill size={'25px'}/>0.80</div>
                    </div>
                  </div>
                  <div>
                    <div>SUVR</div>
                  </div>
                </div>
            </div>
              {/* {(()=>{
                  if (productName === 'amyloid') { return <>
                      <div style={{padding:"0 10%", border:"5px blue solid", display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                        {imageIds.map(el=>{
                          const Direction = el.split('/')[3]
                          const size = utils.getSizebyProduct(Direction);
                          const width = 120;
                          const height = width*utils.ratio2Width(size);
                          return <ImageViewer imageId={el} width={`${width}px`} height={`${height}px`} colormap={'jetV2'}/>
                        })}
                      </div>
                    </>
                  } else if (productName === 'dat') { return <>
                      <div style={{border:"1px blue solid", display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                        {imageIds.filter(el=>el.split('/')[3]==='axial').map(el=>{
                          const Direction = el.split('/')[3]
                          const size = utils.getSizebyProduct(Direction);
                          const width = 120;
                          const height = width*utils.ratio2Width(size);
                          return <ImageViewer imageId={el} width={`${width}px`} height={`${height}px`} colormap={'jetV2'}/>
                        })}
                      </div>
                      <div style={{padding:"0 10%", border:"1px blue solid", display:"flex", flexWrap:"wrap", justifyContent:"center"}}>
                        {imageIds.filter(el=>el.split('/')[3]==='mip').map(el=>{
                          const Direction = el.split('/')[3]
                          const size = utils.getSizebyProduct(Direction);
                          const width = 140;
                          const height = width*utils.ratio2Width(size);
                          return <ImageViewer imageId={el} width={`${width}px`} height={`${height}px`} colormap={'invertGray'}/>
                        })}
                      </div>
                    </>
                  } else {
                    // TODO: FDG와 TAU 제품용 화면 구성 제작 필요함
                  }
              })()} */}
          </div>
          { productName !== "dat" && <div className={styles.imageWrapper}>
            <div className={styles.imageTitle}>
              {(()=>{
                switch(productName){
                  case "amyloid":
                    return "SUVR rendered on brain surface"
                  case "dat":
                    return ""
                  case "tau":
                    return "SUVR rendered on brain surface"
                  case "fdg":
                    return "SUVR rendered on brain surface"
                  default:
                    return ""
                }
              })()}
            </div>
            <div className={styles.surfaceImgWrapper}>
              <div>
                <div>
                  <div>
                    <img 
                      className={styles.surfaceImg}
                      src={
                       IPinUSE+"result/download/" +
                       username +
                       "/database/" +
                       props.selectedFile.fileID +
                       '/_rlat_1.6.png'
                       }
                       alt="Right Lateral"
                    />
                  </div>
                  <div>
                    <img 
                      className={styles.surfaceImg}
                      src={
                       IPinUSE+"result/download/" +
                       username +
                       "/database/" +
                       props.selectedFile.fileID +
                       '/_rmed_1.6.png'
                       }
                       alt="Right Medial"
                    />
                  </div>
                  
                </div>
                

                <div>
                  <div>
                    <img 
                      className={styles.surfaceImg}
                      src={
                       IPinUSE+"result/download/" +
                       username +
                       "/database/" +
                       props.selectedFile.fileID +
                       '/_llat_1.6.png'
                       }
                       alt="Left Lateral"
                    />
                  </div>
                  <div>
                    <img 
                      className={styles.surfaceImg}
                      src={
                       IPinUSE+"result/download/" +
                       username +
                       "/database/" +
                       props.selectedFile.fileID +
                       '/_lmed_1.6.png'
                       }
                       alt="Left Medial"
                    />
                  </div>
                </div>
                <div className={styles.colorbarHot}>
                  <div>
                    <img src={media.colormapHot}/>
                    <div>
                        <div><RiArrowLeftSFill size={'25px'}/>2.00</div>
                        <div><RiArrowLeftSFill size={'25px'}/>1.6</div>
                        {/* <div><img src={media.surfaceArrow} alt="" style={{width:"12px", height:"12px"}}/>1.6</div> */}
                        <div><RiArrowLeftSFill size={'25px'}/>0.00</div>
                    </div>
                  </div>
                  <div>
                    <div>SUVR</div>
                  </div>
                </div>
                
              </div> 
            </div>
          </div>
          }
          
        </div>
      </ReportLayout>
    </>
  )
}
