import React, { useEffect, useState } from "react";
import styles from "./UploadingStep3.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { PacsTable } from "5_components";
import { IPinUSE } from "2_services/IPs";
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as services from '2_services/uploadApi';
import * as media from "6_media";
import { BiTrash } from 'react-icons/bi';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';

export default function UploadingStep3({idx, selected, uploadingStepProps}) {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  const fileList = useSelector((state) => state.fileList);
  const tableHead = productCoreItem[pageName].bottom.uploadingTableHead3;
  const [jpgUrlHead, setJpgUrlHead] = useState(IPinUSE+'result/download/'+sessionStorage.getItem("username")+'/uploader/');
  const [worklistChecker, setWorklistChecker] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortClick, setSortClick] = useState({});

  const _clickHandler = (selectedObj) => {
    if (selectedObj === null) {
      setSelectedItem(null);
    } else {
      const newSelectedItem = uploadingStepProps.getResult.find(el=>el.id === selectedObj.id);
      setSelectedItem(newSelectedItem);
    }
  }

  const getResultReform = () => {
    return (
      uploadingStepProps.getResult.map(el=>{
        el['Marker']="\u3008";
        // el['Delete']="\u{1F5D1}"; 
        el['Delete']=<BiTrash size={"0.8vw"}/>; 
        return el;
      })
    )
  }

  return (
    <>
      <div className={styles.boxContainer} ref={el => uploadingStepProps.handleRef(idx, el)} style={{display: selected ? '':'none'}}>
        <span className={styles.backButton} onClick={()=>uploadingStepProps.executeScroll(idx-1)}>
          {'\u1438 Back'}
        </span>

        <div className={styles.boxTitle}>
          {/* <div><label for='PatientID'>        Patient ID         <input id='PatientID'        value={inputs.PatientID}        name="PatientID"        placeholder="0000-0000"           onChange={handleChange}/></label></div>
          <div><label for='StudyDate'>        Study Date         <input id='StudyDate'        value={inputs.StudyDate}        name="StudyDate"        placeholder="YYYYMMDD - YYYYMMDD" onChange={handleChange}/></label></div>
          <div><label for='StudyDescription'> Study Description  <input id='StudyDescription' value={inputs.StudyDescription} name="StudyDescription" placeholder="Tracer Memo"         onChange={handleChange}/></label></div>
          <div className={styles.searchBtn} onClick={()=>findHandler()}>  Search</div> */}
        </div>

        <div className={styles.boxContent}>
          <PacsTable
            productName={productName}
            tableHead={tableHead} FileList={getResultReform()} 
            sortClick={sortClick} setSortClick={setSortClick}
            deleteHandler={(item, seriesID, _)=>{uploadingStepProps._deleteGetHandler(item, seriesID, _); _clickHandler(null);}}
            clickHandler={(obj)=>{_clickHandler(obj)}}
            doubleClickHandler={(v1)=>{}}
            foldable={false}
            selectedItem={selectedItem}
          />
          <div className={`${styles.previewImg}`}>
            <div>{<img src={jpgUrlHead+selectedItem?.FileName?.split(".")[0]+"_hy.jpg"} alt=""/>}</div>
            <div>{<img src={jpgUrlHead+selectedItem?.FileName?.split(".")[0]+"_hx.jpg"} alt=""/>}</div>
            <div>{<img src={jpgUrlHead+selectedItem?.FileName?.split(".")[0]+"_hz.jpg"} alt=""/>}</div>
            <div></div>
          </div>
        </div>


        <div className={styles.buttonGroup}>
          <div className={styles.nextButton} onClick={()=>uploadingStepProps.runHandler(worklistChecker)}>
            {"Run"}
          </div>
          <div className={styles.optionText}>
            {(()=>{
              if (worklistChecker) return <RiCheckboxFill color={styles['icon-color']} size={styles['icon-size']} onClick={()=>setWorklistChecker(!worklistChecker)}/>
              else return <RiCheckboxBlankLine size={styles['icon-size']} onClick={()=>setWorklistChecker(!worklistChecker)}/>
            })()}
            <div>&nbsp;{'Add to worklist right away'}</div>
          </div>
        </div>
      </div>
    </>
  )
}