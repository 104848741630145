import axios from "axios";
import { IPinUSE } from "./IPs";

export function getSlices(data) {
  return axios.get(IPinUSE+"testing/viewer/"+data.fileID+"/",{
    headers:{
      'Authorization':'jwt '+data.token
      }
  });
}

export function getReportImage(data) {
  return axios.get(IPinUSE+"testing/report/"+data.fileID+"/mip/"+data.imageID+"/",{
    headers:{
      'Authorization':'jwt '+data.token
      }
  });
}

export function getSlice(data) {
  // debugger;
  return axios.get(IPinUSE+"testing/slice/"+data.fileID+"/"+data.Direction+"/"+data.sliceID+"/",{
    headers:{
      'Authorization':'jwt '+data.token
      }
  });
}