import React from 'react';

export default function BrainLobe(props){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.24 88.21">
   <defs>
      <style>{`.cls-2{fill:#f06253;}.cls-6{fill:gray;}.cls-7{fill:none;stroke:#383c41;stroke-linecap:round;stroke-linejoin:round;}`}</style>
   </defs>
   <g id="레이어_2" data-name="레이어 2">
      <g id="뇌_밑색00">
         <path class="cls-1" style={{fill:`${props.fillFrontal}`}} d="M110.26,38.12A10.56,10.56,0,0,0,108.11,30s0-2-2.59-5.83c-4.69-6.73-9.13-8.6-11-10.31s-2.6-3.41-6.09-5.12-8-3.18-9.86-4a27,27,0,0,0-4.72-1.71,10.48,10.48,0,0,0-4,.15,30.57,30.57,0,0,0-3.82-.89,22.59,22.59,0,0,0-3.64,0c-.52,0-2.52-.59-4.15-.93a3.58,3.58,0,0,0-2.67.41A6.49,6.49,0,0,0,51.08.53a12,12,0,0,0-5,1.61s1,2.49,6.66,3.82a7.8,7.8,0,0,0,0,3.68,5.86,5.86,0,0,0,1.49,2.52s.18,3.56,2,5.23,4.19,2.06,4.69,4.73-.61,3.95,0,6.23,1.38,4.73.13,7.57S62.15,43,62.15,43a6.43,6.43,0,0,0,3.19,2.76c1.93.62,3.79-.15,5.42,2.08s2.15,3.56,3.63,5,2.6,1.7,3.42,3.34,1.61,3.35,2.85,4.15c2,.91,6.25-.67,9.37-1.45a48.47,48.47,0,0,1,6.79-1c6.62.11,8.45-3.73,8.45-3.73,4.84-1.89,5.62-7.72,5.62-7.72A8.14,8.14,0,0,0,110.26,38.12Z"/>
         <path class="cls-2" d="M16.66,44.7l.32-.16A1.59,1.59,0,0,0,16.66,44.7Z"/>
         <path class="cls-2" d="M16.66,44.7l.32-.16A1.59,1.59,0,0,0,16.66,44.7Z"/>
         <path class="cls-2" d="M28.69,34.42c-.55-.92-1.73-.55-3.87-1.8a3.42,3.42,0,0,1-1.95-3.09c-7.9,4.12-9-2.89-9-2.89-3.12-1.67-2.56-5.4-2.56-5.4a16.7,16.7,0,0,0-4.62,3c-1.45,1.62-1.39,4.62-1.39,4.62S1.62,32.37.77,35.54A11.1,11.1,0,0,0,1.34,42a14.12,14.12,0,0,0-.56,3.06c-.11,1.67.39,4.79,2.78,6.46s8,.61,10.19.72a41.69,41.69,0,0,1,7.74-.9,10,10,0,0,1,.82-4.44,12.35,12.35,0,0,1,2.2-2.74,16.71,16.71,0,0,0,2.66-2,4,4,0,0,1-1-1.89,4.23,4.23,0,0,1,.31-2.35,4.15,4.15,0,0,1,.33-2.29A2.37,2.37,0,0,1,28.69,34.42Zm-12,10.29a2.39,2.39,0,0,1,.32-.16Z"/>
         <path class="cls-3" style={{fill:`${props.fillTemporal}`}} d="M80.66,60.35c-1.24-.8-2-2.52-2.86-4.15s-1.93-1.93-3.41-3.34-2-2.75-3.63-5-3.49-1.46-5.42-2.08a6.43,6.43,0,0,1-3.19-2.76s-2.36-1-5.4-2.39-3.39-2.42-4.86-3.4-1.53-.39-3.32-1S46.35,34.31,43.68,33s-2.23-2.9-3.67-4-5.23,1-7.9,1.56-3.42,3.89-3.42,3.89a2.37,2.37,0,0,0-1.89,1.23,4.15,4.15,0,0,0-.33,2.29,4.23,4.23,0,0,0-.31,2.35,4,4,0,0,0,1,1.89,16.71,16.71,0,0,1-2.66,2,12.65,12.65,0,0,0-2.2,2.74,10.07,10.07,0,0,0-.82,4.44c.94,3.39,3.73,3.68,5.69,4.13a13.52,13.52,0,0,0,4.05,4.72,25.6,25.6,0,0,0,7.39,3.34c2.89.95,7.4,4.4,11.15,5.57,1.86.57,3.2.61,6.76,1.28S60.65,71.81,64,72s5.72-1,7.07-1.28,6,.23,8.86-2.72S80.66,60.35,80.66,60.35Z"/>
         <path class="cls-2" d="M13.75,52.22c7.21-.54,10.77-8.08,10.77-8.08a17.29,17.29,0,0,0,2.66-2,3.9,3.9,0,0,1-1-1.89,4.17,4.17,0,0,1,.3-2.35,4.1,4.1,0,0,1,.34-2.29,2.35,2.35,0,0,1,1.89-1.23"/>
         <path class="cls-4" style={{fill:`${props.fillParietal}`}} d="M61.05,35.92c1.24-2.84.47-5.28-.14-7.56s.5-3.57,0-6.24-2.84-3.06-4.68-4.73-2-5.23-2-5.23a5.86,5.86,0,0,1-1.49-2.52,7.75,7.75,0,0,1,0-3.67c-5.63-1.33-6.65-3.82-6.65-3.82a15.77,15.77,0,0,0-6.57.22,34,34,0,0,0-6.92,2.71s-2.37-.7-6.23.82a8.55,8.55,0,0,0-4.88,5.26s-1.29-.25-4.56,2.41c-.79,2-1.93,6.1.53,9.88,0,0-.87,5.08,2.29,6.07a4.48,4.48,0,0,0,2.73.2,3,3,0,0,0,.4-.2,3.42,3.42,0,0,0,1.95,3.09c2.14,1.25,3.32.88,3.88,1.81,0,0,.74-3.34,3.41-3.89S38.54,27.85,40,29s1,2.67,3.67,4,3.12,2.65,4.9,3.28,1.84,0,3.31,1,1.84,2,4.87,3.39S62.14,43,62.14,43,59.8,38.76,61.05,35.92Z"/>
         <path class="cls-5" style={{fill:`${props.fillOccipital}`}} d="M28.68,34.43c-.56-.93-1.74-.56-3.88-1.81a3.42,3.42,0,0,1-1.95-3.09,14.22,14.22,0,0,1-1.59.72c.38-.16.78-.33,1.19-.53a4.48,4.48,0,0,1-2.73-.2c-3.16-1-2.29-6.07-2.29-6.07-2.46-3.78-1.32-7.92-.53-9.88-.4.33-.82.68-1.28,1.1-4.23,3.84-4.34,6.56-4.34,6.56v0h0a16.83,16.83,0,0,0-4.62,3c-1.45,1.61-1.39,4.62-1.39,4.62S1.6,32.37.75,35.54A11.13,11.13,0,0,0,1.32,42a14.12,14.12,0,0,0-.56,3.06c-.11,1.67.39,4.78,2.79,6.45s8,.61,10.18.73a40.77,40.77,0,0,1,7.74-.9,10,10,0,0,1,.82-4.45,12,12,0,0,1,2.21-2.73,17.31,17.31,0,0,0,2.65-2,3.93,3.93,0,0,1-1-1.89,4.23,4.23,0,0,1,.31-2.35,4.11,4.11,0,0,1,.33-2.28A2.36,2.36,0,0,1,28.68,34.43Zm-12,10.28a3,3,0,0,1,.31-.15Z"/>
         <path class="cls-6" d="M44.58,66.61a8.46,8.46,0,0,1-1.34,1.9c-.78.78-4.51,1.67-6.85,2.67a23.92,23.92,0,0,0-4.9,2.4c-1.18.89-5.72.89-9.28-.34a13.61,13.61,0,0,1-7.29-6.06,29.74,29.74,0,0,0-3.66-5.7c-1.14-1.35-3.08-3.15-2.88-5a10.65,10.65,0,0,1,1.92-4.12c1.38-.09,2.64-.2,3.45-.16h0a28.54,28.54,0,0,1,7.74-.9c1.27,3.49,3.73,3.68,5.69,4.13a13.52,13.52,0,0,0,4.05,4.72,25.87,25.87,0,0,0,7.39,3.34A41.89,41.89,0,0,1,44.58,66.61Z"/>
         <path class="cls-6" d="M40.49,69.73c-2.3,8.07-.22,13.08-.22,13.08s.68-4.43,2.6-6.53c1.71-1.87,5.52-1.5,8.6-2.82s2.64-3.53,2.64-3.53a31,31,0,0,1-4.34-.86,28.51,28.51,0,0,1-5.19-2.46,8.46,8.46,0,0,1-1.34,1.9A9.1,9.1,0,0,1,40.49,69.73Z"/>
         <path class="cls-6" d="M36.71,87.71c-.23-6.83-1.41-6.53-2.16-10.42s0-5.33,0-5.33l1.84-.78c1.2-.51,2.76-1,4.1-1.45-2.3,8.07-.22,13.08-.22,13.08.74,2.62,1.46,4.9,1.46,4.9"/>
      </g>
      <g id="뇌_선00">
         <path class="cls-7" d="M44.58,66.61a8.46,8.46,0,0,1-1.34,1.9c-.78.78-4.51,1.67-6.85,2.67a23.92,23.92,0,0,0-4.9,2.4c-1.18.89-5.72.89-9.28-.34a13.61,13.61,0,0,1-7.29-6.06,29.74,29.74,0,0,0-3.66-5.7c-1.14-1.35-3.08-3.15-2.88-5a10.65,10.65,0,0,1,1.92-4.12c1.38-.09,2.64-.2,3.45-.16h0a28.54,28.54,0,0,1,7.74-.9c1.27,3.49,3.73,3.68,5.69,4.13a13.52,13.52,0,0,0,4.05,4.72,25.87,25.87,0,0,0,7.39,3.34A41.89,41.89,0,0,1,44.58,66.61Z"/>
         <path class="cls-7" d="M62.15,43s-2.33-4.28-1.09-7.12.48-5.29-.13-7.57.5-3.56,0-6.23-2.84-3.06-4.69-4.73-2-5.23-2-5.23a5.86,5.86,0,0,1-1.49-2.52,7.75,7.75,0,0,1,0-3.67c-5.64-1.34-6.66-3.82-6.66-3.82"/>
         <path class="cls-7" d="M80.66,60.33c-1.24-.79-2-2.52-2.85-4.15s-1.93-1.93-3.42-3.34-2-2.74-3.63-5-3.49-1.46-5.42-2.08A6.43,6.43,0,0,1,62.15,43s-2.36-1-5.39-2.39-3.4-2.42-4.87-3.4-1.53-.39-3.31-1-2.23-1.94-4.9-3.27S41.46,30.07,40,29a1.66,1.66,0,0,0-1.36-.31"/>
         <path class="cls-7" d="M42.71,26.92A2.89,2.89,0,0,0,40,29"/>
         <path class="cls-7" d="M46.74,27.94s2.84-.88,3.73,1a26.36,26.36,0,0,0,2.28,3.79"/>
         <path class="cls-7" d="M32.11,18.22A2.94,2.94,0,0,0,35.2,21"/>
         <path class="cls-7" d="M36.39,35.63a9.29,9.29,0,0,1-4.28-5.12c-1.16-3.57-.66-6.26,1.21-8.23a4.34,4.34,0,0,1,3.24-1.49c1,0,2.11.73,3.86.26A17.68,17.68,0,0,0,44,19.55"/>
         <path class="cls-7" d="M24.36,25a5.21,5.21,0,0,0-1.49,4.53"/>
         <path class="cls-7" d="M38.45,16.05s2.24,3.46,5.54,3.5,3.85-.87,6,.58,3.53,3,2.73,6-1.83,3.67-1.83,3.67"/>
         <path class="cls-7" d="M61.82,17.05s-1.89,1.39-.89,5.06"/>
         <path class="cls-7" d="M58.3,37.26s-1.91,3-6.41,0"/>
         <path class="cls-7" d="M17.45,23.44A4.5,4.5,0,0,0,17.31,26"/>
         <path class="cls-7" d="M22.87,29.51a3.42,3.42,0,0,0,1.95,3.09c2.14,1.25,3.32.88,3.88,1.81,0,0,.74-3.34,3.41-3.9"/>
         <path class="cls-7" d="M46.07,6.25a11.25,11.25,0,0,0-3.36,1.28c-1.53,1-1.81,2-3.53,2.34a14.48,14.48,0,0,0-4.46,1.45c-1.61.89-2.5,1.67-4.11,2.39s-2.56.33-4.51,1.95-2.67,3.17-4.62,4.67-3.28,1.24-4,3.11a4.05,4.05,0,0,0-3.59,3.18,9,9,0,0,0,1.34,7.79c-2.34,1.05-3.12,4-3.12,4"/>
         <path class="cls-7" d="M21.7,13.1a9.62,9.62,0,0,1-.22-1.95"/>
         <path class="cls-7" d="M30.33,7.37a4.65,4.65,0,0,1,2.26-2.3"/>
         <path class="cls-7" d="M50,11.87a4.27,4.27,0,0,0-2.7-1.33c-2.11-.45-1.59.33-3.67,0a5.88,5.88,0,0,1-3-1.27"/>
         <path class="cls-7" d="M18.39,36S16,35.41,15.2,34.41"/>
         <path class="cls-7" d="M8.38,34.41S7,34.78,6.9,37"/>
         <path class="cls-7" d="M5.82,31.32a5.82,5.82,0,0,1-.53-2.48"/>
         <path class="cls-7" d="M13.86,49.73a9.47,9.47,0,0,1-8-3.94"/>
         <path class="cls-7" d="M1.34,42s.91,3.82,3,5.45a4.88,4.88,0,0,0,3.87.76"/>
         <path class="cls-7" d="M27.18,42.16s-2.6,2.43-5,3A10.08,10.08,0,0,1,17,44.53,5.67,5.67,0,0,1,9.74,43,5.23,5.23,0,0,1,9,41.83c-1.67-2.25-2.92-8-2.92-8"/>
         <path class="cls-7" d="M37,45a22.85,22.85,0,0,1-12.49-.85"/>
         <path class="cls-7" d="M49.75,47.94a15.12,15.12,0,0,1-1.84-.63c-1.33-.52-1.82-1.63-2.63-2.17s-1.12-.31-3.38-1-2-1.12-4-2.45-3.57-1.86-6.41-3.56-2.79-3.71-2.79-3.71"/>
         <path class="cls-7" d="M44.16,41.18s3.08.35,4.16,2.35.26,2.92,1.43,4.41c1.65,2.12,4.51,1.88,5.13,2.49a10.2,10.2,0,0,0,5.23,2.71,7.25,7.25,0,0,1,4.31,2.19,9,9,0,0,0,2.74,2.93c1.23.66,3.27,2.07,3.27,3.45s-.82,3.78,0,4.63"/>
         <path class="cls-7" d="M75.4,64.38s-3.63-.25-5-2.15"/>
         <path class="cls-7" d="M62.71,48.43s-1,2.56.67,3.45"/>
         <path class="cls-7" d="M61.15,53.35s.21-1.13,2.23-1.47a5.65,5.65,0,0,1,3.22.73"/>
         <path class="cls-7" d="M29.62,52.2a15.34,15.34,0,0,0,4.38.62c2.84-.11,3.65-.71,5.22-.38a5.21,5.21,0,0,1,2.51,1.32"/>
         <path class="cls-7" d="M13.75,52.21a33.16,33.16,0,0,1,7.74-.89"/>
         <path class="cls-7" d="M61.71,60.33a4.51,4.51,0,0,0-3.41-.5c-2.27.39-2.71,2.17-7.61,1.5S42,57.16,39,55.33a30.75,30.75,0,0,0-5-2.51"/>
         <path class="cls-7" d="M63.88,65.62a6.42,6.42,0,0,1-3.3-.7c-1.47-.84-2.47-2.39-3.48-2.89a9.47,9.47,0,0,0-4-.55"/>
         <path class="cls-7" d="M41.9,44.13s3.9,1.13,3.2,6.47a6.81,6.81,0,0,1,3.31,1.28,7.57,7.57,0,0,1,2,3.22"/>
         <path class="cls-7" d="M52.73,6a13.57,13.57,0,0,1,1.38-2.71"/>
         <path class="cls-7" d="M67.5,8s-2,2.5-1.45,5.12,3.45,9,4.89,9.84a6,6,0,0,0,6-.48c1.51-1.3,3.17-2.52,4.9-2,0,0,.85-1.58,3.45-1.5s3.26,1.49,4.45,1.71S91.31,22.33,92,23s.58,2,1.5,2.67,2,.45,2.89,2a6.2,6.2,0,0,1,.95,3,3.1,3.1,0,0,1,2.36,3.26"/>
         <path class="cls-7" d="M72.24,33.82s-2.37-3.67-2.37-6.08a13.45,13.45,0,0,1,1.07-4.8"/>
         <path class="cls-7" d="M66,41.83a15.16,15.16,0,0,0,2.64-3.42c.81-1.67,2.28-3.82,3.63-4.59a4.68,4.68,0,0,1,5,0c1.62,1.14,2.89,2.31,5.18,1.81a16.31,16.31,0,0,0,3.67-1.22"/>
         <path class="cls-7" d="M80.66,35.69a5,5,0,0,1,2.86,1.55,4.94,4.94,0,0,0,2.89,1.91"/>
         <path class="cls-7" d="M62.54,6.25s1.12,4.29,3.43,5.29"/>
         <path class="cls-7" d="M69.91,3.21A31.66,31.66,0,0,1,72.9,6c1,1.11.68,2.09,3.39,2.94s3,.1,5.56,1.21,2.63,2.82,5.34,3.78,2.67,2.34,4.45,3.53A14.69,14.69,0,0,1,95,20.58a3.9,3.9,0,0,0,2.6,1.4,7.79,7.79,0,0,0,1.74,2.85c1.19,1.15,2.6,1.82,3.11,3.6s-1.26,1.6.52,3.49a6.82,6.82,0,0,1,2.12,4.38"/>
         <path class="cls-7" d="M79.59,20.65S81,24.06,84.33,24.5c1.75.23,2.24.08,3.68,1.34s2.08,5.71,2.08,5.71,5,1.82,5.71,3.86a20.92,20.92,0,0,0,1.3,3,5.44,5.44,0,0,1,3.54,2.9,12.85,12.85,0,0,1,1,3.32"/>
         <path class="cls-7" d="M74.17,10.57s3,1.86,2.67,4.14a7.93,7.93,0,0,1,3.82-.17c2.52.45,3.3,1.51,3.3,1.51"/>
         <path class="cls-7" d="M68,46.2s3.83.95,5.23-1.17.94-5.27,3.83-7.11,5.18-1.51,6.46-.68"/>
         <path class="cls-7" d="M72,49.62s2.25,3.31,7.07,1.81a25.08,25.08,0,0,0,7.38-3.49s2.57,1.15,3.79.49"/>
         <path class="cls-7" d="M76.45,47.48s2.79.54,4.18-3.35a10.81,10.81,0,0,1,2.93-3.83c1.29-1.18,2.41-1.63,4.48-.63a2.31,2.31,0,0,1,1.56,2.69,11.71,11.71,0,0,1,4.6.67,3.9,3.9,0,0,1,2.41,2.65c.3.86,1.52,1.52,1.49,2.52s-1.82,1.95-1.82,1.95"/>
         <path class="cls-7" d="M76.45,54.51s3.36,2.3,5.77,1.67,5.3-2.36,6.86-2.42,3.64,0,3.64,0"/>
         <path class="cls-7" d="M13,56.61s4.84,1.6,7.07,4,3.57,5,8.66,5.73,7.38.34,9,.67,2.55,1.22,4,.83a5.17,5.17,0,0,0,2.39-1.5"/>
         <path class="cls-7" d="M40.49,69.73c-2.3,8.07-.22,13.08-.22,13.08s.68-4.43,2.6-6.53c1.71-1.87,5.52-1.5,8.6-2.82s2.64-3.53,2.64-3.53a31,31,0,0,1-4.34-.86,28.51,28.51,0,0,1-5.19-2.46,8.46,8.46,0,0,1-1.34,1.9A9.1,9.1,0,0,1,40.49,69.73Z"/>
         <path class="cls-7" d="M36.71,87.71c-.23-6.83-1.41-6.53-2.16-10.42s0-5.33,0-5.33l1.84-.78c1.2-.51,2.76-1,4.1-1.45-2.3,8.07-.22,13.08-.22,13.08.74,2.62,1.46,4.9,1.46,4.9"/>
         <path class="cls-7" d="M25.61,44.48s-2.17-.58-3.48,2.82c-1.76,4.58.23,5.73.85,6.46s2.55,1.45,4.2,1.68c0,0,2.31,5.84,11.44,8.06"/>
         <path class="cls-7" d="M44.58,66.61a41.89,41.89,0,0,0-6-3.11,25.87,25.87,0,0,1-7.39-3.34,13.52,13.52,0,0,1-4.05-4.72c-2-.45-4.42-.64-5.69-4.13a28.54,28.54,0,0,0-7.74.9h0c-.81,0-2.07.07-3.45.16-2.41.14-5.21.18-6.73-.88C1.17,49.82.67,46.7.78,45A14.87,14.87,0,0,1,1.34,42a11.1,11.1,0,0,1-.57-6.45c.85-3.17,4.52-6.68,4.52-6.68s0-3,1.39-4.62a16.7,16.7,0,0,1,4.62-3s.11-2.73,4.34-6.57,5.84-3.5,5.84-3.5a8.55,8.55,0,0,1,4.88-5.27c3.86-1.52,6.23-.81,6.23-.81a34,34,0,0,1,6.92-2.71,15.71,15.71,0,0,1,6.56-.22,12.08,12.08,0,0,1,5-1.62A6.49,6.49,0,0,1,55.63,1.8a3.58,3.58,0,0,1,2.67-.41c1.63.34,3.63.89,4.15.93a22.59,22.59,0,0,1,3.64,0,30.57,30.57,0,0,1,3.82.89,10.48,10.48,0,0,1,4-.15,27,27,0,0,1,4.72,1.71c1.81.78,6.38,2.26,9.86,4s4.23,3.41,6.09,5.12,6.29,3.57,11,10.31C108.15,28,108.11,30,108.11,30a10.56,10.56,0,0,1,2.15,8.12,8.16,8.16,0,0,1,.63,8.28s-.78,5.82-5.62,7.71c0,0-1.83,3.84-8.45,3.73A47.6,47.6,0,0,0,90,58.89c-3.12.78-7.33,2.35-9.37,1.44,0,0,2.14,4.68-.7,7.63s-7.53,2.47-8.87,2.71S67.39,72.1,64,72s-3.92-.93-7.49-1.6a67,67,0,0,1-6.76-1.28A28.51,28.51,0,0,1,44.58,66.61Z"/>
      </g>
   </g>
</svg>
  )
}