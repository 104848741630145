import React, { useEffect, useState } from "react";
import styles from './Information.module.scss';
import { useSelector, useDispatch } from "react-redux";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import { Route, useHistory, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import * as media from "6_media";
import { Burger } from "5_components";
import {coreItem} from "0_variables/coreItem";

export default function Information({patientInfo, templateInfo, burgerProps}) {
  const params = useParams();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const tracerList = productCoreItem.tracers;
  const tracerColor = tracerList.reduce((obj,item)=>{
    obj[item.shortname]=item.color;
    return obj;
  },{});
  const striatum_pair = {
    "Melbourne Subcortical" : {
      extrastriatumAtlas:"AAL3, ATAG", 
      firstRegion:["Dorsal striatum","Dorsal raphe nucleus","Substantia nigra"]
    },
    "AAL3" :{
      extrastriatumAtlas:"ATAG", 
      firstRegion:["Dorsal raphe nucleus", "Substantia nigra"]
    },
    "Harvard-Oxford" : {
      extrastriatumAtlas:"AAL3, ATAG", 
      firstRegion:["Caudate","Substantia nigra"]
    },
  }
  // debugger;
  return <div className={styles.container}>
    <div className={styles.pInfo}>
      {patientInfo.map((v,i)=>
        <div key={i} className={`${styles[v.title?.replaceAll(' ','_')]}`}>
          <div>{v.title}</div>
          <div className={`${styles[v.title?.replaceAll(' ','_')]}`} >
            {(()=>{
              if (v.title === 'Tracer') return <span style={{borderColor:tracerColor[v.content]}} > {v.content?.replace('18','\u00B9\u2078')}</span>;
              else return <span>{v.content}</span>;
            })()}
          </div>
        </div>
      )}
    </div>
    {<div className={styles.indicator}>
      {!CTN_Mode && templateInfo.map((v,i)=>
        <div key={i}>
          <div>{v.title}</div>
          &emsp;
          <div>{v.title==='Atlas' ? productName ==='dat'? v.content +", "+striatum_pair[v.content].extrastriatumAtlas:v.content :v.content}</div>
          {/* <div>{striatum_pair['Melbourne Subcortical'].extrastriatumAtlas}</div> */}
          {/* <div>{productName==='dat' ?v.content + striatum_pair[v.content].extrastriatumAtlas :v.content}</div> */}
        </div>
      )}
    </div>}
    {(!CTN_Mode && burgerProps !== null) && <div className={styles.burger}>
      <Burger {...burgerProps}/>
    </div>}
  </div>;
}
