import React from "react";

export default function BrtnxLogo(props) {
  return (
      <svg  viewBox="0 0 151 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <svg width="400" height="110" viewBox="0 0 151 30" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <path d="M99.3289 18.9897V15.0982C96.264 15.0982 93.7793 12.5896 93.7793 9.49472C93.7793 6.40006 96.264 3.88222 99.3289 3.88222C102.394 3.88222 104.888 6.39083 104.888 9.49472H108.741C108.741 4.24832 104.525 0 99.338 0C94.1512 0 89.9346 4.24832 89.9346 9.49472C89.9346 14.7411 94.142 18.9897 99.338 18.9897H99.3289Z" fill="url(#paint0_linear_739_184)"/>
      <path d="M99.3441 15.115V19.0062C104.54 19.0062 108.748 14.7579 108.748 9.51147H104.894C104.894 12.6061 102.409 15.115 99.3352 15.115H99.3441Z" fill="url(#paint1_linear_739_184)"/>
      <path d="M0 0.727328C1.07902 0.507584 3.23727 0.315308 5.31392 0.315308C7.82561 0.315308 9.36731 0.56252 10.682 1.34986C11.9517 2.02734 12.8403 3.28186 12.8403 4.97565C12.8403 6.66944 11.8973 8.07954 9.87504 8.86688V8.92182C11.9517 9.47118 13.4932 11.0736 13.4932 13.445C13.4932 15.1296 12.7406 16.4391 11.607 17.3363C10.2831 18.3983 8.07044 19.0026 4.45231 19.0026C2.43029 19.0026 0.915795 18.8653 0 18.7279V0.727328V0.727328ZM4.07146 7.73168H5.42273C7.60797 7.73168 8.74162 6.83425 8.74162 5.47007C8.74162 4.1059 7.68959 3.3458 5.83079 3.3458C4.91478 3.3458 4.3979 3.40074 4.0806 3.48314V7.73168H4.07146ZM4.07146 15.8805C4.47952 15.9629 4.96026 15.9629 5.66757 15.9629C7.52636 15.9629 9.17688 15.2305 9.17688 13.2985C9.17688 11.3665 7.52636 10.7072 5.47714 10.7072H4.07146V15.8805V15.8805Z" fill="white"/>
      <path d="M16.2961 0.727328C17.62 0.480116 19.5606 0.315308 21.7733 0.315308C24.4754 0.315308 26.3616 0.727328 27.6583 1.78935C28.7647 2.6591 29.3541 3.94109 29.3541 5.63488C29.3541 7.95143 27.7038 9.56281 26.144 10.1304V10.2128C27.4134 10.7257 28.1118 11.9343 28.5743 13.6189C29.1456 15.7157 29.6805 18.1146 30.0342 18.8195H25.8267C25.5546 18.2977 25.0922 16.8051 24.5842 14.5438C24.0765 12.2272 23.2876 11.6321 21.5918 11.6047H20.3767V18.8288H16.3053V0.727328H16.2961V0.727328ZM20.3676 8.60143H21.9909C24.0401 8.60143 25.2554 7.56687 25.2554 5.95527C25.2554 4.34388 24.1217 3.4192 22.2358 3.4192C21.2381 3.4192 20.676 3.47413 20.3767 3.55654V8.60143H20.3676Z" fill="white"/>
      <path d="M36.4326 0.44812H32.334V18.806H36.4326V0.44812Z" fill="white"/>
      <path d="M55.1894 17.959C53.9197 18.3984 51.5169 18.9935 49.1229 18.9935C45.804 18.9935 43.4009 18.1512 41.7324 16.5399C40.0912 14.9282 39.1663 12.5295 39.1663 9.83765C39.1935 3.73051 43.5914 0.242065 49.5582 0.242065C51.9066 0.242065 53.7112 0.709022 54.5998 1.11181L53.7385 4.46292C52.741 3.99596 51.5258 3.66634 49.531 3.66634C46.1033 3.66634 43.4917 5.6258 43.4917 9.60867C43.4917 13.5915 45.8674 15.6334 49.2407 15.6334C50.1567 15.6334 50.9184 15.5235 51.2357 15.3862V11.5222H48.4245V8.28098H55.1983V17.959H55.1894Z" fill="white"/>
      <path d="M62.5103 0.44812V7.53488H69.284V0.44812H73.3827V18.8058H69.284V11.1514H62.5103V18.8058H58.4114V0.44812H62.5103V0.44812Z" fill="white"/>
      <path d="M80.5071 3.93656H75.5923V0.44812H89.566V3.93656H84.5968V18.8058H80.4979V3.93656H80.5071Z" fill="white"/>
      <path d="M110.323 18.8058V0.44812H115.129L118.91 7.17779C119.989 9.10957 121.041 11.3986 121.875 13.477H121.93C121.685 11.0508 121.603 8.56943 121.603 5.79516V0.44812H125.385V18.8058H121.068L117.187 11.719C116.108 9.73232 114.893 7.38831 114.032 5.23657H113.923C114.059 7.69046 114.113 10.2725 114.113 13.2757V18.8058H110.323Z" fill="white"/>
      <path d="M133.088 0.44812H128.99V18.806H133.088V0.44812Z" fill="white"/>
      <path d="M146.23 18.8058L144.579 15.4822C143.908 14.2004 143.473 13.2482 142.965 12.186H142.911C142.53 13.2482 142.076 14.2004 141.478 15.4822L139.991 18.8058H135.294L140.553 9.51258L135.493 0.44812H140.218L141.814 3.79922C142.358 4.91618 142.757 5.8134 143.192 6.84818H143.246C143.681 5.67606 144.026 4.86125 144.489 3.79922L146.03 0.44812H150.727L145.604 9.41171L150.999 18.815H146.248L146.23 18.8058Z" fill="white"/>
      <path d="M1.86717 22.8071H0.516113V29.4819H1.86717V22.8071Z" fill="white"/>
      <path d="M27.2146 29.4675V25.3289C27.2146 25.0267 27.2326 24.569 27.269 23.9372H27.2418C27.1602 24.3218 27.0875 24.5872 27.0242 24.752L25.1743 29.4583H24.1405L22.2635 24.7887C22.218 24.6696 22.1455 24.3859 22.0458 23.928H22.0095C22.0458 24.4224 22.0639 24.9628 22.0639 25.5396V29.4583H20.8306V22.7836H22.7712L24.4126 26.9405C24.5394 27.2883 24.6391 27.5905 24.6755 27.8559H24.7118C24.7843 27.5905 24.884 27.2883 25.0019 26.9312L26.6522 22.7836H28.5384V29.4583H27.1963L27.2146 29.4675Z" fill="white"/>
      <path d="M52.9012 29.4819H51.4139L50.8517 27.8795H48.231L47.678 29.4819H46.209L48.7934 22.8071H50.3529L52.9012 29.4819ZM50.5161 26.8632L49.6639 24.446C49.6275 24.3453 49.5912 24.1621 49.5551 23.8874H49.5187C49.4915 24.0889 49.4552 24.2719 49.4008 24.4277L48.5394 26.8539H50.5161V26.8632Z" fill="white"/>
      <path d="M77.3921 29.0283C76.7028 29.4036 75.8776 29.5959 74.8982 29.5959C73.8192 29.5959 72.9578 29.2937 72.3138 28.6987C71.6701 28.0944 71.3528 27.2886 71.3528 26.2448C71.3528 25.201 71.6973 24.3495 72.3955 23.6902C73.0938 23.031 74.0188 22.6924 75.1613 22.6924C75.9048 22.6924 76.5577 22.793 77.1017 23.0035V24.2581C76.5577 23.9467 75.9048 23.7911 75.1159 23.7911C74.4268 23.7911 73.8645 24.0108 73.4203 24.4411C72.985 24.8806 72.7674 25.4574 72.7674 26.1716C72.7674 26.8858 72.9667 27.4718 73.3567 27.8746C73.7467 28.2866 74.2908 28.4881 74.9709 28.4881C75.3879 28.4881 75.7326 28.424 76.0136 28.2959V26.8216H74.5809V25.7688H77.3738V29.0283H77.3921Z" fill="white"/>
      <path d="M99.9121 22.8071H98.561V29.4819H99.9121V22.8071Z" fill="white"/>
      <path d="M127.128 29.4675H125.714L122.621 24.9443C122.494 24.7613 122.386 24.5872 122.304 24.4317H122.277C122.304 24.6422 122.322 24.9902 122.322 25.4662V29.4675H121.025V22.7928H122.54L125.523 27.1967C125.677 27.4164 125.786 27.5905 125.85 27.7096H125.868C125.84 27.5905 125.832 27.2791 125.832 26.7757V22.7836H127.119V29.4583L127.128 29.4675Z" fill="white"/>
      <path d="M150.57 29.0283C149.881 29.4036 149.055 29.5959 148.085 29.5959C147.006 29.5959 146.136 29.2937 145.501 28.6987C144.857 28.0944 144.549 27.2886 144.549 26.2448C144.549 25.201 144.902 24.3495 145.591 23.6902C146.29 23.031 147.205 22.6924 148.357 22.6924C149.101 22.6924 149.754 22.793 150.298 23.0035V24.2581C149.763 23.9467 149.101 23.7911 148.312 23.7911C147.623 23.7911 147.051 24.0108 146.616 24.4411C146.181 24.8806 145.963 25.4574 145.963 26.1716C145.963 26.8858 146.163 27.4718 146.553 27.8746C146.943 28.2866 147.487 28.4881 148.167 28.4881C148.584 28.4881 148.938 28.424 149.21 28.2959V26.8216H147.777V25.7688H150.57V29.0283Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_739_184" x1="89.9346" y1="9.49478" x2="108.742" y2="9.49478" gradientUnits="userSpaceOnUse">
      <stop stop-color="white"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      <linearGradient id="paint1_linear_739_184" x1="101.103" y1="17.2941" x2="105.225" y2="13.0946" gradientUnits="userSpaceOnUse">
      <stop stop-color="white" stop-opacity="0"/>
      <stop offset="0.56" stop-color="white" stop-opacity="0.65"/>
      <stop offset="1" stop-color="white"/>
      </linearGradient>
      </defs>
      </svg>
  );
}
