import React, {createRef, useEffect, useRef, useState} from "react";
import styles from "./GlobalLayout.module.scss";
// import { initDB } from 'react-indexed-db';
// import { useIndexedDB } from 'react-indexed-db';
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';
import { useLiveQuery } from "dexie-react-hooks";
import { Redirect, useHistory, useLocation, useParams,Switch } from "react-router-dom";
import { Header, Menu, Worklist, SpinnerModal, CustomROIModal, ReportPages } from "5_components";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import { useSelector, useDispatch } from "react-redux";
import {coreItem} from "0_variables/coreItem";
import * as utils from "0_variables/utils";
// import { ImageLoader } from "5_components";
// import * as actionsControl from "1_reduxs/actions/controllerAction";
import {fetchSlices} from "1_reduxs/reducers/controllerReducer";
import * as actionsModal from "1_reduxs/actions/modalAction";
import { IoMdArrowRoundBack } from 'react-icons/io';
import * as services from '2_services/uploadApi';
import axios from "axios";
import { IPinUSE } from "2_services/IPs";
import ExpiredModal from "./2_Modal/ExpiredModal";

// initDB(DBConfig);

const GlobalLayout = React.forwardRef((props) => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const [elRefs, setElRefs] = useState([]);
  const control = useSelector((state) => state.control);
  const refPages = useRef([]);
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const tracerList = productCoreItem.tracers.map(obj=>obj.shortname);
  const fileList = useSelector((state) => state.fileList);
  const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
  const notCompleteList = productList.filter(el=>!el.Complete).map(el=>el.Complete);
  const selectedFileListOnProduct = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Select && item.Group !== 0).map(({fileID})=>fileID);
  const [isSliceReady, setIsSliceReady] = useState(false);
  const [prevPage, setPrevPage] = useState("analysis");

  // const getLicenseInfo = async (obj) => {
  //   try {
  //     return await axios.get(IPinUSE + "result/download/licenseinfo.txt");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  // getLicenseInfo().then(res=>setLicenseInfo(res.data))

  useEffect(()=>{
    const fetchCounts = async () => {
      try {
        const token = sessionStorage.getItem("token");
        const res = await services.getCounts({ token: token });
        return res.data;
      } catch (err) {
        console.log(err);
      }
    }

    const fetchEndDate = async () => {
      try {
        const res = await axios.get(IPinUSE + "result/download/licenseinfo-"+productName+".txt");
        return res.data.split('\n')[0];
      } catch (err) {
        console.log(err);
      }
    }

    fetchCounts().then(cnts=>{
      // console.log(cnts)
      props.setRemainCounts(cnts)
    })
    fetchEndDate().then(date=>{
      // console.log(date)
      props.setEndDate(date)
    })

    // fetchCounts()
  })

  const fetchCounts = async () => {
    console.log('globallayout.js useEffect called');
    const token = sessionStorage.getItem("token");
    const res = await services.getCounts({ token: token });
    // return await axios.get(IPinUSE + "result/download/licenseinfo.txt");
    console.log(res.data);
    props.setRemainCounts(res.data);
  }

  useEffect(()=>{
    utils.metaDataLoader()
    if (fileList.length === 0) {
      // TODO: Fetch_api 왜 했는지 나중에 체크 필요함
      // debugger;
      dispatch(actionsFiles.fetch_api({IDBList:[]}));
    }
    // BTXDB.open()
    // utils.imageLoader('amyloid','report', control[productName]);
    // utils.imageLoader('dat','report', control[productName]);

    // TODO: product와 page 목적에 맞는 imageLoader 등록하기 (추가 필요)
    // BTXDB.on('ready',()=>{
    //   alert('Setup BTXDB');
    // })
    // .catch (function (err) {
    //   console.error('Failed to open db: ' + (err.stack || err));
    // });
    utils.imageLoader('mni','view', control[productName]);
  }, [])

  useEffect(()=>{
    // console.log(notCompleteList);
    utils.reportImageLoader(productName, "report", fileList)
    if (notCompleteList.length !== 0){
      setTimeout(()=>{
        utils.reportImageLoader(productName, "report", fileList)
      },3000)
    }
  },[notCompleteList.length, fileList.length])

  useEffect(()=>{
    // utils.imageLoader('mni','view', control[productName]);
    utils.imageLoader(productName,'view', control[productName]);
  }, [control[productName].openedFiles])

  useEffect(()=>{
    // TODO: 리포트를 4장으로 변경 가능하도록 수정 필요.
    setElRefs([...Array(selectedFileListOnProduct.length)].map((_,i1)=>[...Array(3)].map((_,i2)=>createRef())))
  },[selectedFileListOnProduct.length])

  useEffect(()=>{
    const currentPage = location.pathname.split('/')[2];
    if (currentPage === 'view' || currentPage === 'analysis') {
      setPrevPage(location.pathname.split('/')[2])
    }
  },[location])

  return (
    <>
    {/* <ImageLoader {...props}> */}
      <div className={`${styles['container']}`}>
        {props.children}
      </div>
      <div className={styles.backBtn}>
        <span onClick={()=>history.push(`/${productName}/${prevPage}/${params.fileID}`)}>
          <IoMdArrowRoundBack size={"1.5vw"}/>{" back to "+prevPage}
        </span>
      </div>
      <Header/>
      <Menu remainCounts={props.remainCounts} endDate={props.endDate}/>
      {!CTN_Mode && <Worklist {...props} elRefs={elRefs}/>}
      {/* TODO: worklist에서 선택목록 묶음 다운받기 기능 검토중 2022.04.11 (월) */}
      {/* TODO: 선택했을때 리포트를 띄우지 말고, 다운로드 버튼 눌렀을때에만 리포트를 띄우고 다운로드 종료후에 리포트 닫기 */}
      <div className={styles.reportContainer} style={{visibility: props.reportOn ? "visible":"hidden", overflow:"scroll"}}>
        <div className={styles.buttons} onClick={()=>props.setReportOn(false)}><span>X</span></div>
          {elRefs.map((elRef,i)=>{
            const fileID = selectedFileListOnProduct[i];
            return(
              // <ReportPages fileID={fileID} innerRef={elRef} key={i} reportItembyProduct={utils.reportItembyProduct(productName, fileID)}/>
              props.reportOn && <ReportPages key={i} fileID={fileID} innerRef={elRef}/>
            )}
          )}
      </div>
      
      {modalState.spinner.status && <SpinnerModal/>}
      {/* {modalState.customROI.status && <CustomROIModal/>} */}
    {/* </ImageLoader> */}
    </>
  );
})
export default GlobalLayout;