import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const create = (reducers) => {
  return createStore(reducers, composeEnhancer(applyMiddleware(thunk, sagaMiddleware)));
};

export default create;
