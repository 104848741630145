import React, { useEffect, useState } from 'react';
import {coreItem} from "0_variables/coreItem";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import styles from './Buttons.module.scss';
import * as media from "6_media";

export default function Buttons(props) {
  //TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)>>>
  const today = new Date();
  const expiredDate = new Date(props.endDate)
  const dateValid = today<expiredDate
  const countValid = props.remainCounts > 0
   //TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)<<<

  return (
    <>
      {(()=>{
        if (props.title==='Connect to PACS') {
          return (
          <div className={styles[props.styleName]} style={{opacity:props.Enable ? 1:0.3, display:props.SNM_Mode ? 'none':''}} onClick={()=>{props.func.onClickHandler()}}>
            <label>
              {props.title}
            </label>
          </div>
        )} 
        else if (props.title==='Upload') {
          return (
          <div className={styles[props.styleName]} style={{opacity:props.Enable ? 1:0.3}}>
            <label>
              {props.title}
              {/* TODO: 폴더 단위로 업로드하기 추가 필요*/}

              {/* TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)>>> */}
              {dateValid&&countValid ? <input style={{display:"none"}} multiple type="file" 
                onChange={e=>{props.func.onChangeHandler(e)}} 
                onClick={e=>{e.target.value=null; props.func.onClickHandler()}}
              /> : <input style={{display:"none"}}
                onClick={()=>{
                props.openModal('expired')}} />
              }
              {/* TODO : 20220609 Expired modal 조건 생성 (button component에서 조건분기)<<< */}

              {/* <input style={{display:"none"}} multiple type="file" 
                onChange={e=>{props.func.onChangeHandler(e)}} 
                onClick={e=>{e.target.value=null; props.func.onClickHandler()}}
              /> */}
            </label>
          </div>
        )} 
        else if (props.title==='Download samples') {
          return (
          <div className={styles[props.styleName]} style={{visibility:props.SNM_Mode ? 'visible':'hidden'}} onClick={()=>{props.func.onClickHandler()}}>
            <label>
              {props.title}
            </label>
          </div>
        )} 
        else {
          return (
          <div className={styles[props.styleName]} style={{opacity:props.Enable ? 1:0.3}} onClick={()=>{props.Enable ? props.func.onClickHandler():alert('please select file')}}> 
            <div>{props.imgSrc}</div>
            <div>{props.title}</div>
          </div>
        )}
      })()}
    </>
  )
}
