const version = [
    { 'Manufacturer': 'Brightonix Imaging Inc.' },
    { 'Hospital': 'CMC-St. Vincent' },
    { 'Product name': 'BTXBrain' },
    { 'Version': 'v1.0.0' },
    { 'Release': '2023.01.26' },
    { '': 'Privacy Policy | Terms of Service' },
    { 'Copyright': '© 2022 Brightonix Imaging Inc. All rights reserved.' },
  ]
  
  export const build_info = [
    { 'Manufacturer': 'Brightonix Imaging Inc.' },
    // {'Hospital' : 'CMC-St. Vincent'},
    { 'Hospital': 'General' },
    { 'Product name': 'BTXBrain' },
    { 'Version-front': 'v2.6.0-front' },
    { 'Version-back': 'v2.6.0-back' },
    { 'Release': '2023.02.06' },
    { 'Copyright': '© 2022 Brightonix Imaging Inc. All rights reserved.' },
  ];
  
  export const hospitalInfo1 = { name: "BRTNX", IP: "http://211.119.65.121:8000/", superuser: { username: "admin", passwd: "admin" } };
  export const releaseIP = { name: "BRTNX", IP: "http://localhost:8000/", superuser: { username: "admin", passwd: "admin" } };
  export const hospitalInfo4 = { name: "BRTNX", IP: "http://211.119.65.122:8002/", superuser: { username: "admin", passwd: "admin" } };
  export const hospitalInfo5 = { name: "CLOUD", IP: "https://147.46.195.109:8003/", superuser: { username: "admin", passwd: "imaging2016@" } };
  export const hospitalInfo6 = { name: "SNUH", IP: "http://172.16.122.74:8022/", superuser: { username: "admin", passwd: "admin" } };
  export const hospitalInfo7 = { name: "SMCMC", IP: "http://10.10.37.151:8000/", superuser: { username: "admin", passwd: "admin" } };
  
  export const remoteServer0 = {
    name: "BRTNX", IP: "http://211.119.65.121:8000/",
    superuser: { username: "admin", passwd: "admin" },
    InfoList: version
  };
  export const remoteServer1 = {
    name: "BRTNX", IP: "/back/",
    superuser: { username: "admin", passwd: "imaging2016@" },
    InfoList: version
  };
  export const remoteServer2 = {
    name: "BRTNX", IP: "http://localhost:8000/",
    superuser: { username: "admin", passwd: "admin" },
    InfoList: version
  };
  export const remoteServer3 = {
    name: "BRTNX", IP: "http://10.10.37.151:8000/",
    superuser: { username: "admin", passwd: "admin" },
    InfoList: version
  };
  export const localServer = {
    name: "BRTNX", IP: "http://localhost:8000/",
    superuser: { username: "admin", passwd: "admin" },
    InfoList: version
  };
  
  export const IPinUSE = remoteServer1.IP;
  export const SUPERUSER = remoteServer1.superuser;
  export const InfoList = remoteServer1.InfoList;