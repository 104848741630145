import React, { useEffect, useState, useRef, createRef, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
// import React, { Component, createRef, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImageViewer.module.scss';
import { connect } from "react-redux";
// import * as actionsSlices from 'reduxs/actions/slicesAction';
// import * as actionsControl from 'reduxs/actions/controllerAction';
// import { COLORMAP } from 'variables/viewInfo';
import {coreItem} from "0_variables/coreItem";
import * as services from "2_services/sliceApi";
import * as utils from "0_variables/utils";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

const ImageViewer = forwardRef((props, ref) => {
  const username = sessionStorage.getItem("username");
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const elementRef = useRef(null);
  // const [voi, setVoi] = useState({ww:0, wc:0});
  // const viewport = cornerstone.getViewport(ref.current);
  
  useEffect(()=>{
    const element = ref.current;
    // debugger;
    cornerstone.enable(element);
    cornerstoneTools.mouseInput.enable(element);

    cornerstone.loadImage(props.imageId).then(function(image) {
      cornerstone.displayImage(element, image);
      // cornerstoneTools.wwwc.activate(element, 1);
      // cornerstoneTools.wwwcRegion.activate(element, 2);
      const vp = cornerstone.getViewport(element);
      cornerstone.setViewport(element, {...vp, ...props.vProps.options.viewport});
      cornerstone.updateImage(element);

      // const viewport = cornerstone.getViewport(element);
      // const ww = vp?.voi.windowWidth.toFixed(0);
      // const wc = vp?.voi.windowCenter.toFixed(0);
      // setVoi({ww,wc})
      // props.tempFunc(props.index, ww, wc)
      props.Synchronizers.wwwcsynchronizer.add(element);
    });
    // element.addEventListener("cornerstoneimagerendered",onImageRendered);
    // TODO: intensity: {wc: 16384, ww:32767}
    // map to suvr value: suvr min max comes from fileList out_suvr_max, out_suvr_min (?)
    return () => {
      // element.removeEventListener("cornerstoneimagerendered",onImageRendered);
    };
  },[])

  useEffect(()=>{
    const element = ref.current;
    cornerstone.loadImage(props.imageId).then(function(image) {
      cornerstone.displayImage(element, image);
      // cornerstoneTools.wwwc.activate(element, 1);
      const vp = cornerstone.getViewport(element);
      cornerstone.setViewport(element, {...vp, ...props.vProps.options.viewport});
      cornerstone.updateImage(element);

      // const viewport = cornerstone.getViewport(element);
      // const ww = vp?.voi.windowWidth.toFixed(0);
      // const wc = vp?.voi.windowCenter.toFixed(0);
      // setVoi({ww,wc})
      // props.tempFunc(props.index, ww, wc)
    });
  },[props.imageId])


  // const onImageRendered = (e) => {
  //   // const element = e.detail.element;
  //   const enabledElement = e.detail.enabledElement;
  //   // console.log(element, enabledElement);
  //   // console.log(enabledElement.viewport.voi.windowCenter, enabledElement.viewport.voi.windowWidth)
  //   const ww = enabledElement.viewport.voi.windowWidth.toFixed(0);
  //   const wc = enabledElement.viewport.voi.windowCenter.toFixed(0);
  //   setVoi({ww,wc})
  //   if (props.index===0){
  //     props.setVProps({
  //       ...props.vProps,
  //       options:{
  //         ...props.vProps.options,
  //         viewport:{
  //           ...enabledElement.viewport,
  //         }
  //       }
  //     })
  //   }
  //   // debugger;
  // }

  return (
    <div ref={ref} style={{position:"relative", width:props.width, height:props.height}}>
      {/* <div style={{position:"absolute", bottom:"0", left:"0", fontSize:"0.8vw"}}>
        {`ww/wc: ${voi.ww}/${voi.wc}`}
      </div> */}
      {/* <div style={{position:"absolute", top:"0", left:"0", fontSize:"0.6vw"}}>
        {`ww: ${voi.ww}`}
      </div>
      <div style={{position:"absolute", bottom:"0", left:"0", fontSize:"0.6vw"}}>
        {`wc: ${voi.wc}`}
      </div> */}
    </div>
  )
})
export default ImageViewer;




// class ImageViewer extends Component {
//   state = {
//     // imageId: 'example://1',
//   };
//   element = createRef();
//   componentDidMount(){
//     const {element} = this;
//     debugger;
//     const options = {
//       // opacity: 1,
//       // visible: true,
//       viewport: {
//         colormap: this.props.colormap,
//       },
//       // name: 'PET'
//     }
//     cornerstone.enable(element);
//     cornerstoneTools.mouseInput.enable(element);
//     cornerstone.loadImage(this.props.imageId).then(function(image) {
//       cornerstone.displayImage(element, image);
//       cornerstoneTools.wwwc.activate(element, 4);
//       const vp = cornerstone.getViewport(element);
//       cornerstone.setViewport(element, {...vp, ...options.viewport});
//       cornerstone.updateImage(element);
//     });
//   }
//   componentDidUpdate(){
//     const {element} = this;
//     const options = {
//       // opacity: 1,
//       // visible: true,
//       viewport: {
//         colormap: this.props.colormap,
//       },
//       // name: 'PET'
//     }
//     cornerstone.loadImage(this.props.imageId).then(function(image) {
//       cornerstone.displayImage(element, image);
//       cornerstoneTools.wwwc.activate(element, 4);
//       const vp = cornerstone.getViewport(element);
//       cornerstone.setViewport(element, {...vp, ...options.viewport});
//       cornerstone.updateImage(element);
//     });
//   }

//   render() {
//     return (
//       <>
//         {/* <div ref={input => this.element=input} style={{width:this.props.width, height:this.props.height}} ></div> */}
//         <div ref={input => this.element=input} style={{width:this.props.width, height:this.props.height}} ></div>
//       </>
//     );
//   }
// }
// ImageViewer.defaultProps = {
//   width: "15vmin",
//   height: "15vmin",
//   colormap: "gray",
// }
// ImageViewer.propTypes = {
//   width: PropTypes.string,
//   height: PropTypes.string,
// }
// const mapStateToProps = (state) => ({
//   fileList: state.fileList,
//   control: state.control,
// });

// const mapDispatchToProps = (dispatch) => ({
//   // fetchSlices: (obj)=>dispatch(actionsSlices.fetchSlices(obj)),
//   // update_currentIdx: (obj)=>dispatch(actionsControl.update_currentIdx(obj)),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);