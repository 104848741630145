// Action types
export const OPEN_SPINNER = "modal/OPEN_SPINNER";
// export const OPEN_UPLOADER = "modal/OPEN_UPLOADER";
// export const OPEN_PACS = "modal/OPEN_PACS";
// export const OPEN_EXPIRED = "modal/OPEN_EXPIRED";
export const OPEN_PACS_MODAL = "modal/OPEN_PACS_MODAL";
export const OPEN_UPLOAD_MODAL = "modal/OPEN_UPLOAD_MODAL";
export const OPEN_CUSTOMROI = "modal/OPEN_CUSTOMROI";
export const OPEN_EXPIRED_MODAL = "modal/OPEN_EXPIRED_MODAL"

// Action creators
export const open_spinner = (obj) => {
  return {
    type: OPEN_SPINNER,
    obj,
  };
};

// export const open_uploadermodal = (isOn) => {
//   return {
//     type: OPEN_UPLOADER,
//     isOn,
//   };
// };

// export const open_pacsmodal = (isOn) => {
//   return {
//     type: OPEN_PACS,
//     isOn,
//   };
// };

// export const open_expiredmodal = (isOn) => {
//   return {
//     type: OPEN_EXPIRED,
//     isOn,
//   };
// };

export const open_PacsModal = (isOn) => {
  return {
    type: OPEN_PACS_MODAL,
    obj:{isOn},
  };
};
export const open_UploadModal = (isOn) => {
  return {
    type: OPEN_UPLOAD_MODAL,
    obj:{isOn},
  };
};
export const open_ExpiredModal = (isOn) => {
  return {
    type: OPEN_EXPIRED_MODAL,
    obj:{isOn},
  };
};

export const open_customROI = (isOn) => {
  return {
    type: OPEN_CUSTOMROI,
    obj:{isOn:isOn},
  };
};