import React, { useEffect, useState } from 'react';
import styles from './PacsTable.module.scss';
import { useSelector, useDispatch } from "react-redux";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionsControl from "1_reduxs/actions/controllerAction";
import {coreItem} from "0_variables/coreItem";
import * as media from "6_media";

export default function PacsTable({productName, tableHead, FileList, sortClick, setSortClick, clickHandler, deleteHandler, doubleClickHandler, foldable, selectedItem}) {
  const dispatch = useDispatch();
  const [trClick, setTrClick] = useState([]);
  // const [trClick, setTrClick] = useState([]);
  // debugger;
  const _openHandler = (item) => {
    if (trClick.includes(item)) {
      const _trClick = trClick.filter(el=>el!==item);
      setTrClick([..._trClick])
    } else {
      setTrClick([...trClick, item])
    }
  }
  return <>
    <table className={styles.table}>
      <thead>
        <tr>
          {tableHead.map(head=>
          <th className={`${styles[head.title.replaceAll(' ', '_')]}`}
            onClick={()=>{!Object.keys(sortClick).includes(head.title) ? setSortClick({[head.title]:'ascend'}):sortClick[head.title]==='ascend' ? setSortClick({[head.title]:'descend'}):setSortClick("")}}
          >
            <div>
              <span>{(()=>{
                if (head.title==='Marker'){
                  return ""
                } else {
                  return head.title
                }
              })()}</span>
              <div>
                {(()=>{
                  if (head.title==='Marker'){
                    return <div></div>
                  } else {
                    return <img src={!Object.keys(sortClick).includes(head.title) ? media.arrowSort : sortClick[head.title]==='ascend' ? media.arrow_down : media.arrow_up} alt=""/>
                  }
                })()}
              </div>
            </div>
          </th>)}
        </tr>
      </thead>
      <tbody>
        {FileList
        .sort((a,b)=>{
          if (Object.keys(sortClick).length!==0){
            // debugger
            const targetKey = tableHead.find(v=>v.title===Object.keys(sortClick)[0]).fieldName;
            if (Object.values(sortClick)[0] === 'ascend')
              return a[targetKey] < b[targetKey] ? -1:1
            else if (Object.values(sortClick)[0] === 'descend'){
              return b[targetKey] < a[targetKey] ? -1:1}
            else 
              return b.id-a.id
          } else {
            return b.id-a.id
          }
        })
        .map(v1=>{
          return (
          <>
            <tr 
              className={styles.trMain}
              style={{color: `${selectedItem === v1 ? styles['active-color']:""}`}}
              onClick={()=>{_openHandler(v1); clickHandler(v1)}} onDoubleClick={()=>{doubleClickHandler(v1)}}>
              {tableHead.map(v2=><td className={`${styles[v2.title.replaceAll(' ', '_')]} ${trClick.includes(v1) ? styles.active:styles.inactive}`}>
                {(()=>{
                  const tracerInfo = coreItem[productName].tracers.find(el=>el.shortname===v1[v2.fieldName]);
                  if (v2.title === 'Tracer') {
                    if (false && tracerInfo?.color===undefined) debugger;
                    return (
                    <div className={styles.TracerMarker} style={{borderColor:tracerInfo?.color}}>
                      {v1[v2.fieldName]}
                    </div>)
                  } else if (v2.title === 'Marker') {
                    return <div>{v1[v2.fieldName]}</div>
                  } else if (v2.title === 'Delete') {
                    return <span onClick={(e)=>{e.stopPropagation(); deleteHandler(v1, v1.id, -1);}}>{v1[v2.fieldName]}</span>
                  } else {
                    return v1[v2.fieldName]?.toString()
                  }
                })()}
              </td>)}
            </tr>

            {v1.seriesItem.map((el1, idx1)=>
              <tr className={`${styles.trSub} ${!foldable || trClick.includes(v1) ? styles.active:styles.inactive}`}
                onClick={()=>clickHandler(el1)}
              >
                {tableHead.map((el2,idx2)=>{
                  return <td className={`${styles[el2.title.replaceAll(' ', '_')]}`}>
                    {(()=>{
                      if (el2.title==="Delete") return <span style={{fontSize:"1.0vw"}} onClick={(e)=>{e.stopPropagation(); deleteHandler(el1, v1.id, el1.id);}}>{el1[el2.fieldName]}</span>
                      else return el1[el2.fieldName]
                    })()}
                  </td>
                })}
              </tr>
            )}
          </>)
        })}
      </tbody>
    </table>
  </>;
}
