import React, { useEffect, useState } from "react";
import styles from "./View.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {Container} from "./components";
import {coreItem} from "0_variables/coreItem";
import { BiLoaderAlt } from 'react-icons/bi';

export default function View(props) {
  const control = useSelector((state) => state.control);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const tracerList = coreItem[productName].tracers;
  return (
    <>
      {control[productName].openedFiles.map(el=>{
        // debugger;
        const selectedFileID = control[productName].selectedFileID;
        if (el.Slices.length === 792) {
          return (
            <>
              <Container key={el.fileID} {...props} enabled={el.fileID===selectedFileID} {...el} tracerList={tracerList} productName={productName}/>
            </>
          )
        } else {
          return (
            <div key={el.fileID} className={styles.loading}>
              {el.fileID===params.fileID && 
                <>
                  <span className={styles.loadIcon}>
                    <BiLoaderAlt size={"100%"} color={styles.spinnerColor}/>
                  </span>
                  <span className={styles.loadMsg}>
                    {'Loading...'}
                  </span>
                </>}
            </div>
          )
        }
      })}
    </>
  )
}
