import React, {useEffect, useState} from 'react';
import styles from './Analysis_card3.module.scss';
import { IPinUSE } from "../../../../2_services/IPs";

export default function Analysis_card3(props) {

  const [currentStepIndex, setCurrentStepIndex] = useState(1.6); 
  const username = sessionStorage.getItem("username");
  const refStepIndex = {
    'wcrbll':{ref:'wcrbll', min:0.8, max:2.4, step:0.2, default:1.3, th_percent: 50, divide:7},
    'crbllGM':{ref:'crbllGM', min:1.0, max:2.2, step:0.2, default:1.6, th_percent: 50, divide:7},
    'pons':{ref:'pons', min:0.6, max:1.6, step:0.2, default:0.8, th_percent: 50, divide:6},
    'cerebralWM':{ref:'cerebralWM', min:0.6, max:1.6, step:0.2, default:0.8, th_percent: 50, divide:6},
  }
 
  // const [targetTracerRef, setTargetTracerRef] = useState({shortName:props.refName});
  const [targetTracerRef, setTargetTracerRef] = useState("wcrbll");
  useEffect(()=>{
    if (props.refName === "Whole cerebellum"){
      setTargetTracerRef("wcrbll")
    }else if (props.refName === "Pons"){
      setTargetTracerRef("pons")
    }else if (props.refName === "Cerebellar gray matter"){
      setTargetTracerRef("crbllGM")
    }else if (props.refName === "Cerebral white matter"){
      setTargetTracerRef("cerebralWM")
    }
  })

  return (

    <>
      <div className={styles.Container}>
        <div className={styles.imgContainer}>
          <div className={styles.imgContainer_subContainer}>
            <div className={styles.imgBox}>
              <img
                  src={
                    IPinUSE+"result/download/" +
                    username +
                    "/database/" +
                    props.selectedFile.fileID +
                    `/_rlat_`+parseFloat(currentStepIndex).toFixed(1)+'.png'
                  }
                  alt=""
              />
                <span>{'Right Lateral'}</span>
            </div>
            <div className={styles.imgBox}>
                <img
                  src={
                    IPinUSE+"result/download/" +
                    username +
                    "/database/" +
                    props.selectedFile.fileID +
                    `/_llat_`+parseFloat(currentStepIndex).toFixed(1)+'.png'
                  }
                  alt=""
                />
                <span>{'Left Lateral'}</span>
            </div>  
          </div>
          <div className={styles.imgContainer_subContainer}>
            <div className={styles.imgBox}>
                <img
                  src={IPinUSE+"result/download/" +
                    username +
                    "/database/" +
                    props.selectedFile.fileID +
                    `/_rmed_`+parseFloat(currentStepIndex).toFixed(1)+'.png'
                  }
                  alt=""
                />
                <span>{'Right Medial'}</span>
            </div>
            <div className={styles.imgBox}>
                <img
                  src={
                    IPinUSE+"result/download/" +
                    username +
                    "/database/" +
                    props.selectedFile.fileID +
                    `/_lmed_`+parseFloat(currentStepIndex).toFixed(1)+'.png'
                  }
                  alt=""
                />
                <span>{'Left Medial'}</span>
            </div>
          </div>
          
        </div> 
        <div className={styles.indicatorContainer}>
          <div>SUVR</div>
          <div>
                <input type="range" 
                  min={`${refStepIndex[targetTracerRef]?.min}`} 
                  max={`${refStepIndex[targetTracerRef]?.max}`} 
                  step={`${refStepIndex[targetTracerRef]?.step}`} 
                  value={currentStepIndex}
                  onInput={(e)=>setCurrentStepIndex(e.currentTarget.value)}
                />
          </div>
          <div>
              <span> &nbsp;{(()=>{
                  switch(targetTracerRef){
                    case 'wcrbll':
                      return parseFloat(currentStepIndex).toFixed(1)
                      // return parseFloat(currentStepIndex*props.selectedFile.ratio_gry2crbl).toFixed(1)
                    case 'crbllGM':
                      return parseFloat(currentStepIndex).toFixed(1)
                    case 'pons':
                      return parseFloat(currentStepIndex).toFixed(1)
                      // return parseFloat(currentStepIndex*props.selectedFile.ratio_gry2pons).toFixed(1)
                    case 'cerebralWM':
                      return parseFloat(currentStepIndex).toFixed(1)
                      // return parseFloat(currentStepIndex*props.selectedFile.ratio_gry2pons).toFixed(1)
                    default:
                      return parseFloat(currentStepIndex).toFixed(1)
                  }
                })()}</span>
          </div>
        </div> 
      </div>
      
    </>
  )
}
