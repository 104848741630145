import React, { useEffect, useState } from "react";
import styles from "./MenuButton.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actionSetting from "1_reduxs/reducers/settingReducer";

export default function MenuButton({item}) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const pageName = location.pathname.split('/')[2];
  const setting = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  // console.log(location.pathname.split('/')[2])
  
  return(
    <div className={`${styles['sub-container']} ${item.enabled ? styles.enabled:""} ${location.pathname == item.push ? styles['active']:''}`} 
        onClick={()=>{
          if (item.enabled){
              if (pageName === 'setting'){
                if (setting.changed && window.confirm('Do you really want to save changes?')) {
                  const settingOfProduct = setting.prevConfig;
                  dispatch(actionSetting.save_to_setting(productName, settingOfProduct));
                }
                item.callBack(); 
                history.push(item.push);
              } else {
                item.callBack(); 
                history.push(item.push);
              }
            }
          }
        }>
      <div className={`${styles['menu-icon']}`}>{location.pathname == item.push ? <img src={item.activeIcon}/>:<img src={item.unactiveIcon}/>}</div>
      <div className={`${styles['menu-name']}`}>{item.name}</div>
    </div>
  )
}