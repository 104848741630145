import * as filesApi from "2_services/filesApi";
export const FETCH_FILES = "files/FETCH_FILES";
export const SELECT_SINGLE_FILE = "files/SELECT_SINGLE_FILE";
export const SELECT_PAGE_ALL_FILES = "files/SELECT_PAGE_ALL_FILES";
export const SELECT_ALL_FILES = "files/SELECT_ALL_FILES";
export const OPEN_SELECTED_FILE = "files/OPEN_SELECTED_FILE";
export const OPEN_SINGLE_FILE = "files/OPEN_SINGLE_FILE";
export const CLOSE_SINGLE_FILE = "files/CLOSE_SINGLE_FILE";

export function fetch_api({IDBList}) {
  return async (dispatch) => {
    try {
      // dispatch(actionModal.open_spinner({status:true, percent:0}));
      const token = sessionStorage.getItem("token");
      // if (token !== null){
        const res = await filesApi.fetchFiles({ token: token });
        const tempData = res.data.map(item=>({...item}));
        const fileList = tempData.map(el=>({...el, Cached: IDBList.includes(Number(el.fileID)), ratio_gry2crbl:0.82954927, ratio_gry2pons:0.67833017}))
        // console.log(fileList.map(el=>el.Cached))
        dispatch(fetchFiles(fileList));
      // }
    } catch (err) {
      // alert("filesAction/fetch_api error");
      // dispatch(actionModal.open_spinner({status:false, percent:0}));
    } finally {
      // dispatch(actionModal.open_spinner({status:false, percent:100}));
    }
  };
}

export function update_worklist(status, selectedFiles) {
  return async (dispatch, getState) => {
    const token = sessionStorage.getItem("token");
    try {
      const res = await filesApi.update_worklist({
        token: token,
        obj: selectedFiles,
        addToWorklist: status,
      });
      const targetFileIDs = selectedFiles.map(el=>el.fileID);
      const fileList = getState().fileList.map(el=> targetFileIDs.includes(el.fileID) ? {...el, Group:status ? 1:0}:el);
      dispatch(fetchFiles(fileList));
    } catch (err) {
      alert("filesAction/update_worklist error");
    }
  }
}

export function delete_api(record) {
  return async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const res = await filesApi.deleteSelection({ token: token, obj: record });
      dispatch(fetchFiles(res.data));
    } catch (err) {
      alert("fetch files failed, try again");
    }
  };
}


export const fetchFiles = (items) => {
  return {
    type: FETCH_FILES,
    items: items,
  };
};

export const selectFile = (item) => {
  return {
    type: SELECT_SINGLE_FILE,
    item: item,
  };
};

export const selectPageAllFiles = (groupSelector, productName, status, fileIDArray) => {
  return {
    type: SELECT_PAGE_ALL_FILES,
    // groupSelector:groupSelector,
    obj:{status, productName, groupSelector, fileIDArray},
  };
};
export const selectAllFiles = (groupSelector, productName, status) => {
  return {
    type: SELECT_ALL_FILES,
    // groupSelector:groupSelector,
    obj:{status, productName, groupSelector},
  };
};

export const openFile = (productName, singleFile) => {
  return {
    type: OPEN_SINGLE_FILE,
    obj: {productName, singleFile},
  };
};

export const openFiles = (productName, openState) => {
  return {
    type: OPEN_SELECTED_FILE,
    obj: {productName, openState},
    // openState: openState,
  };
};

export const closeFile = (productName, singleFile) => {
  return {
    type: CLOSE_SINGLE_FILE,
    obj: {productName, singleFile},
  };
};