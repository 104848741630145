import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import styles from "./Container.module.scss";
// import {ImageViewer} from "5_components/7_ReportPages/components/ReportPage3/components";
import {ViewerGroup} from "5_components";
import {PageLayout, CardLayout, Information} from "5_components";
import * as utils from "0_variables/utils";
import {coreItem} from "0_variables/coreItem";
import InputRange from 'react-input-range';
import "5_components/14_InputRange/InputRange.scss";
import * as media from "6_media";
import {debounce} from 'lodash';
import { IPinUSE } from "2_services/IPs";
import axios from "axios";
import { gsap } from "gsap";

// import { FaPlay } from 'react-icons/fa';
import { DropDownDiv } from "5_components";
import { MdPlayArrow, MdPause, MdOutlineRefresh } from 'react-icons/md';
import { Burger } from "5_components";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { style } from "d3";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function Container(props) {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const control = useSelector((state) => state.control);
  const [selectedFile, setSelectedFile] = useState(fileList.find(({fileID})=>fileID === params.fileID));
  const productName = params.product.toLowerCase();
  const pageName = location.pathname.split('/')[2];
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const topItems = productCoreItem[pageName].top;
  const username = sessionStorage.getItem('username');
  const bottomCards = productCoreItem['analysis'].bottom;
  // const bottomCards = productCoreItem[pageName].bottom;
  const setting = useSelector((state) => state.setting);

  const el = useRef();
  const q = gsap.utils.selector(el);

  const [cmap, selectCmap] = useState("jet");
  const [clipConfig, setClipConfig] = useState({status: false, speed:20});
  const [opacity, setOpacity] = useState(0.4);
  const [inverted, setInverted] = useState(false);
  const [crosshair, setCrosshair] = useState(true);
  const [sNorm, setSNorm] = useState(CTN_Mode ? false:true);
  // const [doubleSidedInputVal, setDoubleSidedInputVal] = useState({min:0, max:32767});
  const [colorbarPressed, setColorbarPressed] = useState({top:false, center:false, bottom:false});

  // const [coronalWC_out, setCoronalWC_out] = useState(Math.floor(32767/2));
  // const [sagittalWC_out, setSagittalWC_out] = useState(Math.floor(32767/2));
  // const [axialWC_out, setAxialWC_out] = useState(Math.floor(32767/2));
  // const [wwwcForward, setWWWCForward] = useState(false);
  const [WC_out, setWC_out] = useState(Math.floor(32767/2));
  const [WW_out, setWW_out] = useState(Math.floor(32767));
  const [WMax_out, setWMax_out] = useState(32767);
  const [WMin_out, setWMin_out] = useState(0);
  // console.log(WW_out, WC_out)
  const [WC_in, setWC_in] = useState(Math.floor(32767/2));
  const [WW_in, setWW_in] = useState(Math.floor(32767));
  const [WMax_in, setWMax_in] = useState(32767);
  const [WMin_in, setWMin_in] = useState(0);
  // console.log(WW_in, WC_in)
  // const doubleSidedInputVal = useRef({min:0, max:32767});
  // const setDoubleSidedInputVal = (obj) => {
  //   // debugger;
  //   doubleSidedInputVal.current = obj;
  //   return doubleSidedInputVal;
  // }

  const [targetTracerRef, setTargetTracerRef] = useState({fullName:"", shortName:""});
  const defaultAtlas = setting[productName].defaultAtlas;
  const outputPixelInfo = {
    regularizedMax:32767,
    min:selectedFile.out_suvr_min, 
    max:selectedFile.out_suvr_max, 
    width: CTN_Mode ? 32767:selectedFile.out_suvr_max-selectedFile.out_suvr_min,
    center: (selectedFile.out_suvr_max+selectedFile.out_suvr_min)/2,
    // value: 0,
  };
  const inputPixelInfo = {
    regularizedMax:32767,
    min:selectedFile.in_suvr_min, 
    max:selectedFile.in_suvr_max, 
    width: CTN_Mode ? 32767:selectedFile.in_suvr_max-selectedFile.in_suvr_min,
    center: (selectedFile.in_suvr_max+selectedFile.in_suvr_min)/2,
    // value: 0,
  };
  const settingOfProduct = setting[productName]
  const tracerName = selectedFile['Tracer'];
  // const refName = settingOfProduct.defaultRef[tracerName]
  // const getShortName = (selectedFile,control) => {
  //   debugger;
  //   let refName;
  //   let shortName;
  //   if (refName === "Whole cerebellum"){
  //     shortName = "wcrbll"
  //   } else if (refName === "Cerebellar gray matter"){
  //     shortName = "crbllGM"
  //   } else if (refName === "Pons"){
  //     shortName = "pons"
  //   } else if (refName === "Cerebral white matter"){
  //     shortName = "cerebralWM"
  //   } else {
  //     shortName = "wcrbll"
  //   }
  //   return shortName
  // }
  const refName = settingOfProduct.defaultRef[tracerName]
  const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})

  // useEffect(()=>{
  //   setWWWCForward(false);
  // }, [WC_out, WW_out, WC_in, WW_in])
  
  // console.log(params);
  useEffect(()=>{
    const selectedFile = fileList.find(({fileID})=>fileID === params.fileID);
    setSelectedFile(selectedFile);
    const shortName = utils.getShortName(refName)
    setTargetTracerRef({fullName:refName, shortName:shortName})
  },[params, refName])

  // useEffect(()=>{
  //   console.log(doubleSidedInputVal)
  // }, [doubleSidedInputVal])

  useEffect(()=>{
    // gsap.to(q(".reactIcon"), {
    //   rotation: "+=360"
    
    // });
  })

  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, {scale: 1.2 });
  };

  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1 });
  };

  const onClick = ({currentTarget}) =>{
    gsap.to(currentTarget, {rotation:"+=360"});
  }

  const burgerFuncs = {
    openReport: () => history.push(`/${productName}/report/${params.fileID}`),
    alert:()=>alert('hi'),
    downloadCSV_Individual: () => {
      // debugger;
      // alert('hi csv')
      if (productName === 'amyloid') return amyloidCSVFormat()
      else if (productName === 'dat') return datCSVFormat()
      else if (productName === 'fdg') return fdgCSVFormat()
      else if (productName === 'tau') return tauCSVFormat()
      else return amyloidCSVFormat()
    },

    downloadNifitiSUVR_Individaul : () =>{
      // debugger;
      const link = IPinUSE+"result/download/" + username + "/database/" + selectedFile.fileID + '/'+'surv_output_' + selectedFile.fileID + '.nii';
      // const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_intensity"+"_"+selectedFile.fileID+".nii";
      const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".nii";
      const getFile = async (link, fname) => {
        // debugger;
        // const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+'output_' + props.fileID + '.nii';
        // const link = IPinUSE+"result/download/" + 'licenseinfo.txt';
        const res = await axios.get(link, {responseType: 'blob'});
        // debugger;
        const file = new Blob([res.data]);
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      // debugger;
      return getFile(link,fname)
    },

    downloadNifitiIntensity_Individaul : () =>{
      const link = IPinUSE+"result/download/" + username + "/database/" + selectedFile.fileID + '/'+'output_' + selectedFile.fileID + '.nii';
      const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_intensity"+"_"+selectedFile.fileID+".nii";
      // debugger;
      const getFile = async (link, fname) => {
        // debugger;
        // const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+'output_' + props.fileID + '.nii';
        // const link = IPinUSE+"result/download/" + 'licenseinfo.txt';
        const res = await axios.get(link, {responseType: 'blob'});
        // debugger;
        const file = new Blob([res.data]);
        let element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = fname;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
      // debugger;
      return getFile(link,fname)
    }

  }

  const amyloidCSVFormat = () => {
    //TODO : bottomCards가 읽히지 않음 확인필요
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const godThanksCentiloid = utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
    const structData = utils.structAmyloidCSV({ref:targetTracerRef, atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    // debugger;
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const datCSVFormat = () => {
    const srcSBR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcSemiquant = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSBR = utils.analysisADATtable1({   settingOfProduct:setting['dat'    ], typeSelect: coreItem['dat'    ].type1Selector, tableItems:coreItem['dat'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const godThanksSemiquatification = utils.analysisDATcard2_2({settingOfProduct:setting['dat'], typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
    const structData = utils.structDATCSV({ref:targetTracerRef, atlas:defaultAtlas, srcData:srcData, srcSBR:srcSBR, srcSemiquant:srcSemiquant, godThanksSBR, godThanksSemiquatification}); 
    // debugger;
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SBR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const fdgCSVFormat = () => {
    // alert('fdgCSVFormat')
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['fdg'], typeSelect: coreItem['fdg'].type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const structData = utils.structFDGTauCSV({ref:targetTracerRef,atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR,  godThanksSUVR}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }

  const tauCSVFormat = () => {
    // alert('tauCSVFormat')
    const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
    const srcData = fileList.find(({fileID})=>fileID === params.fileID)
    const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['tau'], typeSelect: coreItem['tau'].type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
    const structData = utils.structFDGTauCSV({ref:targetTracerRef,atlas:defaultAtlas, srcData:srcData, srcSUVR:srcSUVR, godThanksSUVR}); 
    const structuredData = [
      ...structData.headRows,
      ...structData.bodyRows,
    ]
    const fname = selectedFile.PatientID.replaceAll(/[^0-9/-]/gi,"")+"_"+selectedFile.Tracer.replaceAll(/[^\w]/gi,"").replace("18F","F18")+"_"+selectedFile.AcquisitionDateTime.replaceAll(/([0-9])[/-]([0-9])/gi,"$1$2")+"_SUVR_"+targetTracerRef.shortName+"_"+selectedFile.fileID+".csv";
    utils.generateCSV({data:structuredData, transpose:true, fname})
  }



  const analysisPageTop =(top)=>{
    const patientInfo = topItems.item1.map(item=>({title:item.title, content:selectedFile[item.var]}))
    const templateInfo = topItems.item2.map(item=>{
      if (item.title === 'Atlas') return {title:item.title, content:setting[productName][item.var]}
      else return {title:item.title, content:setting[productName][item.var][selectedFile.Tracer]}
    })
    const burgerProps = {
      itemList: topItems.item3.map(item3=>({title:item3.title, func: burgerFuncs[item3.var]}))
    }
    const cmapDropdown = {
      selectItem: cmap, 
      options:["invertGray","hot","jet","pet"], 
      setItem:(val)=>{
        return selectCmap(val)
      }
    }

    return (
      <>
        <div className={styles.viewController} ref={el}>
          <div className={styles.mip}>
            <span>MIP </span>
            <span className={styles.reactIcon}  onClick={()=>setClipConfig({...clipConfig, status:!clipConfig.status})}>
              {clipConfig.status ? <MdPause className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave} size={"1.7vw"}/>:<MdPlayArrow className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave} size={"1.7vw"}/>}
            </span>
            <span className={styles.inputRange}>
              <div className={"singleSidedInput"}>
                <InputRange
                  draggableTrack
                  step={1}
                  maxValue={50}
                  minValue={0}
                  value={{min:0, max:clipConfig.speed}}
                  onChange={value => {setClipConfig({...clipConfig, speed:value.max})}}
                />
              </div>
            </span>
          </div>
          {!CTN_Mode && <div className={styles.overlay}>
            <span>Overlay</span>
            <span className={styles.reactIcon} onClick={()=>setOpacity(0.6)}>
              <MdOutlineRefresh className="reactIcon" color={"white"} size={"1.7vw"} onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={onClick}/>
            </span>
            <span className={styles.inputRange}>
              <div className={"singleSidedInput"}>
                <InputRange
                  // draggableTrack
                  step={0.05}
                  maxValue={1}
                  minValue={0}
                  value={{min:0, max:opacity}}
                  onChange={value => {setOpacity(value.max)}}
                />
              </div>
            </span>
          </div>}
          <div className={styles.intensity}>
            <span>{CTN_Mode ? "Intensity" :"SUVR"}</span>
            <span className={styles.reactIcon} onClick={()=>{
              if (sNorm){
                // setWW_out(Math.floor(32767));
                // setWC_out(Math.floor(32767/2));
                setWMax_out(32767);
                setWMin_out(0);
              } else {
                // setWW_in(Math.floor(32767));
                // setWC_in(Math.floor(32767/2));
                setWMax_in(32767);
                setWMin_in(0);
              }
            }}>
              <MdOutlineRefresh color={"white"} size={"1.7vw"} className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave} onClick={onClick}/>
            </span>
            <span className={styles.inputRange}>
              <div className={"doubleSidedInput"}>
                <InputRange
                  draggableTrack
                  step={10}
                  maxValue={32767}
                  minValue={0}
                  value={(()=>{
                    if (sNorm) {
                      // const min = Math.floor(WC_out-WW_out/2);
                      // const max = Math.floor(WC_out+WW_out/2);
                      const max = WMax_out;
                      const min = WMin_out;
                      return { min, max }
                    } else {
                      // const min = Math.floor(WC_in-WW_in/2);
                      // const max = Math.floor(WC_in+WW_in/2);
                      const max = WMax_in;
                      const min = WMin_in;
                      return { min, max }
                    }
                  })()}
                  onChange={value => {
                    // setWWWCForward(true);
                    const min = Math.max(0, value.min);
                    const max = Math.min(32767, value.max);
                    const WC = Math.floor((min+max)/2);
                    const WW = Math.floor(max-min);
                    if (sNorm) {
                      // setWC_out(WC);
                      // setWW_out(WW);
                      setWMax_out(max);
                      setWMin_out(min);
                    } else {
                      // setWC_in(WC);
                      // setWW_in(WW);
                      setWMax_in(max);
                      setWMin_in(min);
                    }
                  }}
                />
              </div>
            </span>
          </div>
          <div className={styles.btnGroup}>
            <span onClick={()=>setCrosshair(!crosshair)} className={styles.reactIcon} style={{width:"1.7vw", height:"1.7vw", }}><img src={crosshair ? media.viewCrosshairOff:media.viewCrosshairOn} style={{width:"80%", height:"80%"}} alt="" className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave}/></span>
            {!CTN_Mode && <span onClick={()=>setSNorm(!sNorm)} className={styles.reactIcon} style={{width:"1.7vw", height:"1.7vw", }}><img src={sNorm ? media.viewSnOff:media.viewSnOn}        style={{width:"80%", height:"80%"}} alt="" className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave}/></span>}
            <span onClick={()=>setInverted(!inverted)} className={styles.reactIcon} style={{width:"1.7vw", height:"1.7vw", }}><img src={inverted ? media.viewInvertOff:media.viewInvertOn}     style={{width:"80%", height:"80%"}} alt="" className="reactIcon" onMouseEnter={onEnter} onMouseLeave={onLeave}/></span>
          </div>
          <div className={styles.colormap}>
            <span><DropDownDiv style={{width:"10vw", height:"100%", background:"#34383C"}} item={cmapDropdown}/></span>
          </div>
          {!CTN_Mode && <div className={styles.burger}>
            <Burger {...burgerProps}/>
          </div>}
        </div>
        <Information patientInfo={patientInfo} templateInfo={templateInfo} burgerProps={null}/>
      </>
    )
  }

  // const [viewportSize, setViewportSize] = useState({
  //   'coronal':{width:null, height:null},
  //   'sagittal':{width:null, height:null},
  //   'axial':{width:null, height:null},
  //   'mip':{width:null, height:null},
  // })


  const [viewerWidth, setViewerWidth] = useState(null);

  const handleResize = debounce(()=>{
    const size = { 
      width: window.innerWidth || document.body.clientWidth, 
      height: window.innerHeight || document.body.clientHeight 
    };
    if (size.width > size.height) setViewerWidth(25);
    else setViewerWidth(35);
  }, 100)

  useEffect(()=>{
    handleResize();
    window.addEventListener('resize', handleResize);
    return ()=>{
      window.removeEventListener('resize', handleResize);
    }
  },[])

  const OutputViewerGroupProps = {
    pixelInfo:outputPixelInfo,
    inout:"output",
    cmap,
    opacity,
    clipConfig,
    crosshair,
    inverted,
    sNorm,

    // coronalWC:WC_out, setCoronalWC:setWC_out,
    // sagittalWC:WC_out, setSagittalWC:setWC_out,
    // axialWC:WC_out, setAxialWC:setWC_out,
    // mipWC:WC_out, setMipWC:setWC_out,

    // coronalWW:WW_out, setCoronalWW:setWW_out,
    // sagittalWW:WW_out, setSagittalWW:setWW_out,
    // axialWW:WW_out, setAxialWW:setWW_out,
    // mipWW:WW_out, setMipWW:setWW_out,
    
    coronalWMax:WMax_out, setCoronalWMax:setWMax_out,
    sagittalWMax:WMax_out, setSagittalWMax:setWMax_out,
    axialWMax:WMax_out, setAxialWMax:setWMax_out,
    mipWMax:WMax_out, setMipWMax:setWMax_out,
    
    coronalWMin:WMin_out, setCoronalWMin:setWMin_out,
    sagittalWMin:WMin_out, setSagittalWMin:setWMin_out,
    axialWMin:WMin_out, setAxialWMin:setWMin_out,
    mipWMin:WMin_out, setMipWMin:setWMin_out,

    colorbarPressed, setColorbarPressed,
  }

  const InputViewerGroupProps = {
    pixelInfo:inputPixelInfo,
    inout:"input",
    cmap,
    opacity,
    clipConfig,
    crosshair,
    inverted,
    sNorm:!sNorm,

    // coronalWC:WC_in, setCoronalWC:setWC_in,
    // sagittalWC:WC_in, setSagittalWC:setWC_in,
    // axialWC:WC_in, setAxialWC:setWC_in,
    // mipWC:WC_in, setMipWC:setWC_in,
    
    // coronalWW:WW_in, setCoronalWW:setWW_in,
    // sagittalWW:WW_in, setSagittalWW:setWW_in,
    // axialWW:WW_in, setAxialWW:setWW_in,
    // mipWW:WW_in, setMipWW:setWW_in,
    
    coronalWMax:WMax_in, setCoronalWMax:setWMax_in,
    sagittalWMax:WMax_in, setSagittalWMax:setWMax_in,
    axialWMax:WMax_in, setAxialWMax:setWMax_in,
    mipWMax:WMax_in, setMipWMax:setWMax_in,
    
    coronalWMin:WMin_in, setCoronalWMin:setWMin_in,
    sagittalWMin:WMin_in, setSagittalWMin:setWMin_in,
    axialWMin:WMin_in, setAxialWMin:setWMin_in,
    mipWMin:WMin_in, setMipWMin:setWMin_in,

    colorbarPressed, setColorbarPressed,
  }

  return (
    <div className={`${styles["container"]}`} style={{opacity:props.enabled ? 1:0.3, zIndex:props.enabled ? 0:-1}}>
      <div style={{border:"px red solid"}}>
        {analysisPageTop()}
      </div>

      {(()=>{
        if (viewerWidth) {
          return (
          <div style={{overflow:"auto"}}>
            <ViewerGroup {...props} viewerWidth={viewerWidth} {...OutputViewerGroupProps}/>
            <ViewerGroup {...props} viewerWidth={viewerWidth} {...InputViewerGroupProps}/>
          </div>
          )
        }
      })()}
    </div>
  )
}
