import React, { useEffect, useState } from "react";
import styles from "./Signup.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import { HomePassButton, InputText } from "5_components";

export default function Signup(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [signupValues, setSignupValues] = useState({'fullname':'', 'username':'', 'password1':'', 'password2':'','email':'', 'institution':''});
  const [isAgreeChecked, setIsAgreeChecked] = useState(false);
  const validPassword = signupValues.password1 === signupValues.password2 ? true : false;
  console.log(validPassword)
  const processFunc = async () => {
    if (validPassword){
      if (isAgreeChecked){
        const resObj = await dispatch(actionsLogin.register_api({
          fullname:signupValues.fullname,
          last_name:signupValues.fullname,
          username: signupValues.username,
          password1: signupValues.password1,
          password2:signupValues.password2,
          email: signupValues.email,
          institution: signupValues.institution
        }))
        if (resObj.response) {
            alert("We will check your information and provide you with demo cases shortly")
            history.push(`/signin`);
            setSignupValues({'fullname':'', 'username':'', 'password1':'', 'password2':'','email':'', 'phone number':''});
        } else {
          if (resObj.code === undefined) alert("Network Error: Please check if Btxbrain-backend is running.");
          else alert(resObj.message);
        }
        console.log(`${resObj.message} (${resObj.code}) `);
      }else{
        alert('Please agree to the policy.')
      } 
    }
  }

  const propsSignUp ={
    processFunc,
    btnClassName:"signup-btn",
    title:"SIGN UP"
  }
  return (
    <div className={styles["container"]}>
      {/* <div>{location.pathname}</div> */}
      <div>
        <h1 className={`${styles["title"]}`}>{'SIGN UP'}</h1>
        <InputText title="FULL NAME" name="fullname" placeholder="Enter your full name"  value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <InputText title="USERNAME (ID)" name="username" placeholder="Enter username"  value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <InputText title="PASSWORD" type="password"  name="password1" placeholder="Enter password"  value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <InputText title="CONFIRM PASSWORD"  type="password" name="password2" placeholder="Confirm password"  value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <div 
          className={`${styles["CheckPassword"]}`}
          style={{display:`${validPassword ? "none": ""}`}}
        >
            The password confirmation doses not match.
        </div>
        <InputText title="EMAIL" name="email" placeholder="Enter email"  value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <InputText title="INSTITUTION" name="institution" placeholder="Enter your institution" value={signupValues} setValue={setSignupValues} inputClassName="signup-input"/>
        <div className={`${styles["policy"]}`}>
            <input
              type="checkbox"
              checked={isAgreeChecked}
              onChange={() => setIsAgreeChecked(!isAgreeChecked)}
            />
            <div>{'Agree to the policy'}</div>
        </div>
        <HomePassButton item={propsSignUp}/>
        {/* <div className={`${styles["pass-btn"]}`}><button onClick={()=>history.push('/signin')}>SIGN UP</button></div> */}
      </div>  
      
    </div>
  )
}
