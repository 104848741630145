import React, { useEffect, useState, useRef, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import styles from "./ViewerGroup.module.scss";
import {StackViewer} from "4_routers/3_View/components";
// import {PageLayout, CardLayout, Information} from "5_components";
import * as utils from "0_variables/utils";
import {coreItem} from "0_variables/coreItem";
import "5_components/14_InputRange/InputRange.scss";
import * as media from "6_media";
import chroma from 'chroma-js';
import {debounce} from 'lodash';
import { MdDragHandle,  } from 'react-icons/md';
import { IoMdArrowDropleft,  } from 'react-icons/io';
import { BsNodePlus, BsNodeMinus} from 'react-icons/bs';

// import { FaPlay } from 'react-icons/fa';
// import { DropDownDiv } from "5_components";
// import { MdPlayArrow, MdOutlineRefresh } from 'react-icons/md';
// import { Burger } from "5_components";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { style } from "d3";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ViewerGroup(props) {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const fileList = useSelector((state) => state.fileList);
  const [selectedFile, setSelectedFile] = useState(fileList.find(({fileID})=>fileID === params.fileID));
  const [yOffset, setYOffset] = useState({y:0, WC:16384});
  const [savedValue, setSavedValue] = useState([]);
  const headRef = useRef(null);
  const bodyRef = useRef(null);
  const footRef = useRef(null);
  const cmapCode = {
    name:props.cmap, 
    from:1, to:255, 
    code:
    props.inverted ? 
    [...Array(256).keys()].reverse().map(i=>chroma(cornerstone.colors.getColormap(props.cmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())
    :
    [...Array(256).keys()].map(i=>chroma(cornerstone.colors.getColormap(props.cmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())
  };
  // console.log(cmapCode);



  const [sagittalSliceX, setSagittalSliceX] = useState(50);
  const [coronalSliceY, setCoronalSliceY] = useState(60);
  const [axialSliceZ, setAxialSliceZ] = useState(45);
  const [mipAngle, setMipAngle] = useState(0);
  const productName = params.product.toLowerCase();
  const pageName = location.pathname.split('/')[2];
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const viewerWidth = props.viewerWidth;
  const cmap = props.cmap;
  const [PETRef,setPETRef] = useState([...Array(4)].map((_, i) => createRef()));
  const wwwcsynchronizer = new cornerstoneTools.Synchronizer("cornerstoneimagerendered", cornerstoneTools.wwwcSynchronizer);
  const referenceLinesync = new cornerstoneTools.Synchronizer("cornerstonenewimage", cornerstoneTools.updateImageSynchronizer);

  // useEffect(()=>{
  //   try{
  //     const temp = {name:props.cmap, from:1, to:255, code:[...Array(256)].map((v,i)=>chroma(cornerstone.colors.getColormap(props.cmap).getColor(i).map((el,idx)=>idx!==3 ? el:el/255)).name())};
  //     setCmapCode(temp)
  //   } catch(e){
  //     console.log(e);
  //   }
  // },[props.cmap])

  const height = (width, Direction) => {
    const size = utils.getSizebyProduct(Direction);
    const ratio = utils.ratio2Width(size)
    return width*ratio;
  }
  const width = (height, Direction) => {
    const size = utils.getSizebyProduct(Direction);
    const ratio = utils.ratio2Height(size)
    return height*ratio;
  }

  const coronalStacks = [
    {
      imageIds: [...Array(109+20-1).keys()].map((v,i)=>("mni"+pageName+"://"+props.inout+"/"+props.fileID+"/coronal/right/invertGray/"+i)),
      currentImageIdIndex: 128/2+11,
      options: {
        opacity: 1,
        visible: true,
        viewport: {
          // invert:props.inverted,
          colormap: props.cmap==='invertGray' ? 'invertGray':'gray',
        },
        name: 'MNI'
      }
    },
    {
      imageIds: [...Array(109+20-1).keys()].map((v,i)=>(productName+pageName+"://"+props.inout+"/"+props.fileID+"/coronal/right/invertGray/"+i)),
      currentImageIdIndex: 128/2+11,
      options: {
        opacity: props.opacity,
        visible: true,
        viewport: {
          // invert:props.inverted,
          colormap: props.cmap,
          // scale: 6.0,
          // translation: {
          //     x: 24,
          //     y: 10
          // },
          // voi: {
          //     windowWidth: 89,
          //     windowCenter: 150
          // },
          // invert: false,
          // pixelReplication: false
        },
        name: 'PET'
      }
    }]
  const sagittalStacks = [
    {
      imageIds: [...Array(91+40).keys()].map((v,i)=>("mni"+pageName+"://"+props.inout+"/"+props.fileID+"/sagittal/right/invertGray/"+i)),
      currentImageIdIndex: 130/2,
      options: {
        opacity: 1,
        visible: true,
        viewport: {
          colormap: props.cmap==='invertGray' ? 'invertGray':'gray',
        },
        name: 'MNI'
      }
    },
    {
      imageIds: [...Array(91+40).keys()].map((v,i)=>(productName+pageName+"://"+props.inout+"/"+props.fileID+"/sagittal/right/invertGray/"+i)),
      currentImageIdIndex: 130/2,
      options: {
        opacity: props.opacity,
        // visible: true,
        viewport: {
          // colormap: COLORMAP[this.props.toolSet.colormap.id],
          colormap: props.cmap,
        },
        name: 'PET'
      }
    }]
  const axialStacks = [
    {
      imageIds: [...Array(91).keys()].map((v,i)=>("mni"+pageName+"://"+props.inout+"/"+props.fileID+"/axial/right/invertGray/"+i)),
      currentImageIdIndex: 45-8,
      options: {
        opacity: 1,
        visible: true,
        viewport: {
          colormap: props.cmap==='invertGray' ? 'invertGray':'gray',
        },
        name: 'MNI'
      }
    },
    {
      imageIds: [...Array(91).keys()].map((v,i)=>(productName+pageName+"://"+props.inout+"/"+props.fileID+"/axial/right/invertGray/"+i)),
      currentImageIdIndex: 45-8,
      options: {
        opacity: props.opacity,
        // visible: true,
        viewport: {
          // colormap: COLORMAP[this.props.toolSet.colormap.id],
          colormap: props.cmap,
        },
        name: 'PET'
      }
    }]
  const mipStacks = [
    // {
    //   imageIds: [...Array(45).keys()].map((v,i)=>("mni"+pageName+"://"+props.inout+"/"+props.fileID+"/mip/right/invertGray/"+i)),
    //   currentImageIdIndex: 30,
    //   options: {
    //     opacity: 1,
    //     visible: true,
    //     viewport: {
    //       colormap: 'invertGray',
    //     },
    //     name: 'MNI'
    //   }
    // },
    {
      imageIds: [...Array(45).keys()].map((v,i)=>(productName+pageName+"://"+props.inout+"/"+props.fileID+"/mip/right/invertGray/"+i)),
      currentImageIdIndex: 0,
      options: {
        opacity: 1,
        // visible: true,
        viewport: {
          // colormap: COLORMAP[this.props.toolSet.colormap.id],
          colormap: props.cmap,
        },
        name: 'PET'
      }
    }]

  const sliceProps = {
    inout:props.inout,
    pixelInfo:props.pixelInfo,
    // setPixelInfo:props.setPixelInfo,
    crosshair:props.crosshair,
    wwwcsynchronizer,
    referenceLinesync,
    // productName:props.match.params.product,
    inverted:props.inverted,
    clipConfig:props.clipConfig,
    // setWW:props.setWW,
    // setWC:props.setWC,
  }
  
  const mipProps = {
    inout:props.inout,
    pixelInfo:props.pixelInfo,
    // setPixelInfo:props.setPixelInfo,
    crosshair:props.crosshair,
    wwwcsynchronizer,
    referenceLinesync,
    // productName:props.match.params.product,
    inverted:props.inverted,
    clipConfig:props.clipConfig,
    // setWW:props.setWW,
    // setWC:props.setWC,
  }
  // console.log({
  //   sagittalSliceX,
  //   coronalSliceY,
  //   axialSliceZ,
  // })
  const depthInfo = {
    coronal: 109+20-1,
    sagittal: 91+40,
    axial: 91,
    mip: 45,
  }
  // console.log(wc)
  const windowCenter = (props.mipWMax + props.mipWMin)/2;
  const windowWidth = props.mipWMax - props.mipWMin;
  const centerPercent = windowCenter/32767*100;
  const widthPercent = windowWidth/32767*100;
  // console.log(props);
  return (
    <div style={{zIndex: props.sNorm ? "0":"-1", position:"absolute", width:"100%", display:"flex", justifyContent:"center"}}>
      <div className={styles.imageWrapper}>

        <div className={styles.divUpper} style={{height:`${height(viewerWidth, 'coronal')}vw`}}>
          <div className={styles.divCoronal} style={{width:`${viewerWidth}vw`}}>
            <StackViewer params={props.match.params} WMin={props.coronalWMin} WMax={props.coronalWMax} setWMin={props.setCoronalWMin} setWMax={props.setCoronalWMax} ref={PETRef[0]} productName={props.match.params.product} depthInfo={depthInfo} x={sagittalSliceX} y={90-axialSliceZ} z={coronalSliceY} getSliceID={setCoronalSliceY} type={"slice"} direction={"coronal"} width={`100%`} height={`100%`} Stacks={coronalStacks} cornerstoneProps={sliceProps}/>
          </div>
          <div className={styles.divSagittal} style={{width:`${viewerWidth}vw`}}>
            <StackViewer params={props.match.params} WMin={props.sagittalWMin} WMax={props.sagittalWMax} setWMin={props.setSagittalWMin} setWMax={props.setSagittalWMax} ref={PETRef[1]} productName={props.match.params.product} depthInfo={depthInfo} x={coronalSliceY} y={90-axialSliceZ} z={sagittalSliceX} getSliceID={setSagittalSliceX} type={"slice"} direction={"sagittal"} width={`102%`} height={`102%`} Stacks={sagittalStacks} cornerstoneProps={sliceProps}/>
          </div>
        </div>

        <div className={styles.divLower} style={{height:`${height(viewerWidth, 'axial')}vw`}}>
          <div className={styles.divAxial} style={{width:`${viewerWidth}vw`}}>
            <StackViewer params={props.match.params} WMin={props.axialWMin} WMax={props.axialWMax} setWMin={props.setAxialWMin} setWMax={props.setAxialWMax} ref={PETRef[2]} productName={props.match.params.product} depthInfo={depthInfo} x={sagittalSliceX} y={128-coronalSliceY} z={axialSliceZ} getSliceID={setAxialSliceZ} type={"slice"} direction={"axial"} width={`100%`} height={`100%`} Stacks={axialStacks} cornerstoneProps={sliceProps}/>
          </div>
          <div className={styles.divMip} style={{width:`${viewerWidth}vw`}}>
            <StackViewer params={props.match.params} WMin={props.mipWMin} WMax={props.mipWMax} setWMin={props.setMipWMin} setWMax={props.setMipWMax} ref={PETRef[3]} productName={props.match.params.product} depthInfo={depthInfo} x={coronalSliceY} y={90-axialSliceZ} z={sagittalSliceX} a={mipAngle} getSliceID={setMipAngle} type={"mip"} direction={"mip"} width={`140%`} height={`100%`} Stacks={mipStacks} cornerstoneProps={mipProps}/>
          </div>
        </div>

      </div>

      <div className={styles.colorbarWrapper}
        onMouseUp={(e)=>{ e.stopPropagation(); props.setColorbarPressed({top:false, center:false, bottom:false}); }}
        onMouseLeave={(e)=>{ e.stopPropagation(); props.setColorbarPressed({top:false, center:false, bottom:false}); }}
      >
        <div className={styles.colorbarContainer}
          style={{
            position:"relative",
            background:`linear-gradient(to top, ${cmapCode.code[cmapCode.from]} ${centerPercent}%, ${cmapCode.code[cmapCode.to]} ${centerPercent}%)`
          }}
        >
          {(()=>{
            if (props.sNorm && PETRef[0].current !== null){
              const head = ((windowCenter+windowWidth/2)/32767);
              const body = ((windowCenter)/32767);
              const foot = ((windowCenter-windowWidth/2)/32767);
              const x = sagittalSliceX;
              const y = 90-axialSliceZ;
              const pixel = cornerstone.getPixels(PETRef[0].current, x, y, 1,1);
              const percent = pixel/32767;
              const value = (pixel / props.pixelInfo.regularizedMax * props.pixelInfo.width).toFixed(2);
              // console.log(savedValue)
              return(<>
                {!CTN_Mode && <div className={styles.currentvalue} style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"absolute", transform:"translate(0%, -50%)", top:`${(1-percent)*100}%`,                     left:"90%", width:"90%"}}><BsNodePlus size={"1.2vw"} 
                  onClick={()=>{
                    const isExist = savedValue.find(({pos})=>Math.floor(pos) === Math.floor((1-percent)*100));
                    if (!isExist) {
                      setSavedValue([...savedValue, {id:savedValue.length, value, pos:(1-percent)*100}]);
                    }
                  }}
                  />
                  <span>{CTN_Mode ? Math.floor(value):value}</span>
                </div>}
                {savedValue.map(el=><div className={styles.savedvaluelist} style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"absolute", transform:"translate(0%, -50%)", top:`${el.pos}%`,          left:"90%", width:"90%"}} onClick={()=>setSavedValue(savedValue.filter((el2)=>el.id !== el2.id).map((el3,idx)=>({...el3, id:idx})))}><BsNodeMinus size={"1.2vw"}/><span>{el.value}</span></div>)}
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"absolute", transform:"translate(0%, -50%)", top:`${utils.clamp((1-head)*100,0,100)}%`,     left:"90%", width:"90%", userSelect:"none", pointerEvent:"none"}}><IoMdArrowDropleft size={"1.2vw"}/><span>{CTN_Mode ? Math.floor(head*props.pixelInfo.width):(head*props.pixelInfo.width).toFixed(2)}</span></div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"absolute", transform:"translate(0%, -50%)", top:`${utils.clamp((1-body)*100,0,100)}%`,     left:"90%", width:"90%", userSelect:"none", pointerEvent:"none"}}><IoMdArrowDropleft size={"1.2vw"}/><span>{CTN_Mode ? Math.floor(body*props.pixelInfo.width):(body*props.pixelInfo.width).toFixed(2)}</span></div>
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"absolute", transform:"translate(0%, -50%)", top:`${utils.clamp((1-foot)*100,0,100)}%`,     left:"90%", width:"90%", userSelect:"none", pointerEvent:"none"}}><IoMdArrowDropleft size={"1.2vw"}/><span>{CTN_Mode ? Math.floor(foot*props.pixelInfo.width):(foot*props.pixelInfo.width).toFixed(2)}</span></div>
              </>)}
          })()}
          <div style={{height:"100%", width:"100%", overflow:"hidden", border:"0px cyan solid", boxSizing:"border-box", position:"relative"}}
            // onMouseUp={(e)=>{ e.stopPropagation(); props.setColorbarPressed({top:false, center:false, bottom:false}); }}
            // onMouseLeave={(e)=>{ e.stopPropagation(); props.setColorbarPressed({top:false, center:false, bottom:false}); }}
            onMouseMove={(e)=>{
              e.stopPropagation();
              if (props.colorbarPressed.center) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;

                // const yPercentfromMouseDown = 1-(yOffset.y - containerBounds.top)/containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                // const currentCenterPercent = yOffset.WC/32767; 
                // const yOffsetFromCenter = yPercentfromMouseDown - currentCenterPercent;
                // const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                // const updatedWC = ((yPercent-yOffsetFromCenter)*32767);
                // props.setmipWMax(updatedWC);

                // const yPercent = 1-(e.clientY - bounds.top)/bounds.height;
                // const updatedWC = Math.floor((yPercent-yOffset)*32767);
                // props.setmipWMax(updatedWC);
                
                const yPercentfromMouseDown = 1-(yOffset.y - containerBounds.top)/containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                const currentCenterPercent = yOffset.WC/32767; 
                const yOffsetFromCenter = yPercentfromMouseDown - currentCenterPercent;
                const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                const updatedWC = ((yPercent-yOffsetFromCenter)*32767);
                const currentWidth = utils.clamp(windowWidth, 0 ,32767);
                const updateMax = utils.clamp(updatedWC + currentWidth/2, 2000, 32767);
                const updateMin = utils.clamp(updatedWC - currentWidth/2, 0, 32767-2000);
                props.setMipWMax(updateMax); //max
                props.setMipWMin(updateMin); //min

              } else if (props.colorbarPressed.top) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;
                const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                const updateMax = utils.clamp(yPercent*32767, 0, 32767);
                props.setMipWMax(updateMax); //max



                // const container = headRef.current.parentElement.parentElement;
                // const containerBounds = container.getBoundingClientRect();
                // const containerHeight = containerBounds.height;

                // // const unit = 32767/containerHeight;
                // // const yDiff = yOffset.y-e.clientY;
                // // const updateY = yDiff * unit;
                // // const updateWW = utils.clamp(windowWidth + 2*updateY, 3000, 32767);
                // // props.setMipWMin(updateWW);
                

                // // const yPercentfromMouseDown = 1-(yOffset.y - containerBounds.top)/containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                // // const currentCenterPercent = yOffset.WC/32767; 
                // // const yOffsetFromCenter = yPercentfromMouseDown - currentCenterPercent;
                // // const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                // // const updatedWC = ((yPercent-yOffsetFromCenter)*32767);
                // // props.setMipWMax(updatedWC);
                
                // const yPercentfromMouseDown = 1-(yOffset.y - containerBounds.top)/containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                // const currentCenterPercent = yOffset.WC/32767; 
                // const yOffsetFromCenter = yPercentfromMouseDown - currentCenterPercent;
                // const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                // const updatedWC = ((yPercent-yOffsetFromCenter)*32767);
                // const currentWidth = utils.clamp(windowWidth, 0 ,32767);
                // const updateMin = utils.clamp(updatedWC - currentWidth/2, 0, 32767);
                // const updateMax = utils.clamp(updatedWC + currentWidth/2, updateMin+2000, 32767);
                // props.setMipWMax(updateMax); //max
                
                // setYOffset({y:e.clientY, WC:(updateMax+updateMin)/2});
              } else if (props.colorbarPressed.bottom) {
                const container = bodyRef.current.parentElement;
                const containerBounds = container.getBoundingClientRect();
                const containerHeight = containerBounds.height;
                const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                const updateMin = utils.clamp(yPercent*32767, 0, 32767);
                props.setMipWMin(updateMin); //max
                // const container = headRef.current.parentElement.parentElement;
                // const containerBounds = container.getBoundingClientRect();
                // const containerHeight = containerBounds.height;

                // // const unit = 32767/containerHeight;
                // // const yDiff = yOffset.y-e.clientY;
                // // const updateY = yDiff * unit;
                // // const updateWW = utils.clamp(windowWidth - 2*updateY, 3000, 32767);
                // // props.setMipWMin(updateWW);

                // const yPercentfromMouseDown = 1-(yOffset.y - containerBounds.top)/containerHeight; // 여기서 중심으로부터의 offset을 제거해줘야함
                // const currentCenterPercent = yOffset.WC/32767; 
                // const yOffsetFromCenter = yPercentfromMouseDown - currentCenterPercent;
                // const yPercent = 1-(e.clientY - containerBounds.top)/containerHeight;
                // const updatedWC = ((yPercent-yOffsetFromCenter)*32767);
                // const currentWidth = utils.clamp(windowWidth, 0 ,32767);
                // const updateMax = utils.clamp(updatedWC + currentWidth/2, 0, 32767);
                // const updateMin = utils.clamp(updatedWC - currentWidth/2, 0, updateMax-2000);
                // props.setMipWMin(updateMin); //max

                // setYOffset({y:e.clientY, WC:windowCenter});
              }
            }}
          >
            {(()=>{
              // const temp = cornerstone.getEnabledElement(PETRef[0].current);
              // console.log(temp.viewport.voi);
              // console.log('updated: ', props.wwwc)
              // const windowCenter = props.coronalWC;
              // const centerPercent = windowCenter/32767*100;
              return (
                <div ref={bodyRef}
                  style={{
                      display:"flex", flexDirection:"column", justifyContent:"space-between", alignitems:"center",
                      position:"absolute", top:`${100-centerPercent}%`, left:"50%", height:`${widthPercent}%`, width:"100%", border:"0px blue solid", boxSizing:"border-box", transform:"translate(-50%, -50%)",
                      background:`linear-gradient(to top, ${cmapCode.code.slice(cmapCode.from, cmapCode.to).join(',')})`
                  }}
                  onMouseDown={(e)=>{ 
                    e.stopPropagation(); 
                    props.setColorbarPressed({top:false, center:true, bottom:false}); 
                    setYOffset({y:e.clientY, WC:windowCenter});

                    // const parent = bodyRef.current.parentElement;
                    // const bounds = parent.getBoundingClientRect();
                    // const yPercent = 1-(e.clientY - bounds.top)/bounds.height; // 여기서 중심으로부터의 offset을 제거해줘야함
                    // const currentCenterPercent = props.mipWMax/32767; 
                    // const yOffsetFromCenter = yPercent - currentCenterPercent;
                    // setYOffset(yOffsetFromCenter);
                    // console.log("down: ", e.clientY, yOffsetFromCenter)
                  }}
                >
                  <div ref={headRef}
                    onMouseDown={(e)=>{
                      e.stopPropagation();
                      props.setColorbarPressed({top:true, center:false, bottom:false}); 
                      // const parent = headRef.current.parentElement.parentElement;
                      // const bounds = parent.getBoundingClientRect();
                      // const yPercent = 1-(e.clientY - bounds.top)/bounds.height; // 여기서 중심으로부터의 offset을 제거해줘야함
                      // const currentTopPercent = (props.mipWMax+props.mipWMin/2)/32767; 
                      // const yOffsetFromCenter = yPercent - currentTopPercent;
                      setYOffset({y:e.clientY, WC:windowCenter});
                    }} 
                    style={{height:"10px", border:"0px red solid", color:`${(props.colorbarPressed.center || props.colorbarPressed.top) ? styles.activeColor:"red"}`, textAlign:"center", transform:"translate(0%,0%)", display:"flex", alignItems:"center", justifyContent:"center"}}
                  >
                    {<MdDragHandle size={"1vw"}/>}
                  </div>
                  <div ref={footRef}
                    onMouseDown={(e)=>{
                      e.stopPropagation();
                      props.setColorbarPressed({top:false, center:false, bottom:true}); 
                      // const parent = footRef.current.parentElement.parentElement;
                      // const bounds = parent.getBoundingClientRect();
                      // const yPercent = 1-(e.clientY - bounds.top)/bounds.height; // 여기서 중심으로부터의 offset을 제거해줘야함
                      // const currentBottomPercent = (props.mipWMax-props.mipWMin/2)/32767; 
                      // const yOffsetFromCenter = yPercent - currentBottomPercent;
                      setYOffset({y:e.clientY, WC:windowCenter});
                    }} 
                    style={{height:"10px", border:"0px red solid", color:`${(props.colorbarPressed.center || props.colorbarPressed.bottom) ? styles.activeColor:"red"}`, textAlign:"center", transform:"translate(0%,0%)", display:"flex", alignItems:"center", justifyContent:"center"}}
                  >
                    <MdDragHandle size={"1vw"}/>
                  </div>
                </div>
              )
            })()}
          </div>
        </div>
      </div>
    </div>
  )
}