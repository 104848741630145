import axios from "axios";
import { IPinUSE } from "./IPs";

export function getCounts(data) {
  // debugger;
  return axios.get(IPinUSE+"testing/uploader/", {
    headers: {
      Authorization: "jwt " + data.token,
    },
  });
}

export function runFile(data) {
  // debugger;
  return axios.put(IPinUSE+"testing/uploader/",
    {
      obj:data.obj.map(el=>({...el, Delete:""})), 
      Tracer:data.Tracer, 
      addToWorklist:data.addToWorklist
    },
    {
    headers:{
      'Authorization':'jwt '+data.token,
    }
  });
}

export function postPacs (data) {
  return axios.post(IPinUSE+"testing/pacs/",
  {
    Method:data.Method, 
    PatientID:data.PatientID, 
    PatientName:data.PatientName, 
    StudyInstanceUID:data.StudyInstanceUID, 
    SeriesInfo:data.SeriesInfo, 
    Tracer:data.Tracer, 
    StudyDate:data.StudyDate, 
    StudyDescription:data.StudyDescription
  },
  {
    headers:{
      'Authorization':'jwt '+data.token,
    }
  })
}

export function deleteFilefromPacs(data) {
  return axios.delete(IPinUSE+"testing/pacs/",{
    headers:{
      'Authorization':'jwt '+data.token,
    }
  });
}

export function postFile(data) {
  return axios.post(IPinUSE+"testing/uploader/",
  data.obj,
  {
    headers:{
      'Authorization':'jwt '+data.token,
      'content-type': 'multipart/form-data',
    }
  });
}