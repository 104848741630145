import React,{useState, useEffect, useRef, useCallback} from 'react';
// import "./Container.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import * as cornerstone from "cornerstone-core";
// import Pdf from "react-to-pdf";
// import { exportComponentAsJPEG, exportComponentAsPDF } from "react-component-export-image";
// import {ImageViewer} from './components';
// import { COLORMAP } from '0_variables/viewInfo';
import * as media from '6_media';
import { IPinUSE } from "2_services/IPs";
import InputRange from 'react-input-range';
// import "react-input-range/lib/css/index.css";
// import "./InputRange.scss";

const options = {
  orientation: 'landscape',
  unit: 'px',
  format: [1920, 1080],
}
const colormapsList = cornerstone.colors.getColormapsList();
function Container(props) {
  // const username = sessionStorage.getItem("username");
  // const loginState = useSelector((state) => state.login);
  // const control = useSelector((state) => state.control);
  // const fileList = useSelector((state) => state.fileList);
  // const sliceList = useSelector((state) => state.sliceList);
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const location = useLocation();
  // const ref = useRef();
  // const refOutput0 = useRef();
  // const refOutput1 = useRef();
  // const refOutput2 = useRef();
  // const refOutput3 = useRef();
  // const refInput0 = useRef();
  // const refInput1 = useRef();
  // const refInput2 = useRef();
  // const refInput3 = useRef();

  // const [selectedFile, setSelectedFile] = useState({});
  
  // const [slideON, setSlideON] = useState(false);
  // const [isSlicesExist, setIsSlicesExist] = useState(false);
  // const [isVisible, setIsVisible] = useState(false);
  // const [burgerOpen, setBurgerOpen] = useState(false);
  // const [colormapOpen, setColormapOpen] = useState(false);
  // const [inputRangeValue, setInputRangeValue] = useState({min:0,max:100});
  // const [toolSet, setToolSet] = useState({
  //   mip:{
  //     play: false,
  //     speed: 20,
  //   },
  //   mni:{
  //     visible: false,
  //     opacity: 0,
  //   },
  //   suvr:{
  //     output:{
  //       update: false,
  //       centerPercent: 50,
  //       widthPercent: 90,
  //       bottom: 0,
  //       center:0.5,
  //       top: 1,
  //       min: 0,
  //       max: 1,
  //     },
  //     input:{
  //       update: false,
  //       centerPercent: 50,
  //       widthPercent: 90,
  //       bottom: 0,
  //       center:0.5,
  //       top: 1,
  //       min: 0,
  //       max: 1,
  //     },
  //   },
  //   colormap:{
  //     id:1,
  //     bottom:cornerstone.colors.getColormap(COLORMAP[1]).getColor(0),
  //     top:cornerstone.colors.getColormap(COLORMAP[1]).getColor(255),
  //   },
  //   crosshair: true,
  //   sn: true,
  //   invert: false,
  //   msv: false,
  // })

  // const TOOLSET = {
  //   ...toolSet,
  //   setSuvr: (state)=>{
  //     setToolSet({
  //       ...toolSet, 
  //       suvr:{
  //         ...toolSet.suvr,
  //         ...state,
  //       }
  //     })
  //   },
  // };

  // useEffect(()=>{
  //   // debugger;
  //   const colormap = cornerstone.colors.getColormap(COLORMAP[toolSet.colormap.id]);
  //   const numOfColors = colormap.getNumberOfColors();
  //   const color0 = colormap.getColor(toolSet.colormap.id === 1 ? 40:0);
  //   const color255 = colormap.getColor(numOfColors-1);
  //   // console.log(colormap.getNumberOfColors(), color0, color255);
  //   setToolSet({
  //     ...toolSet,
  //     colormap:{
  //       ...toolSet.colormap,
  //       bottom: color0,
  //       top:color255,
  //     }
  //   })
  // },[toolSet.colormap.id])

  // useEffect(()=>{
  //   // visible only selected tab
  //   const selectrdFileID = control.openedFiles[control.ctrlX].fileID;
  //   setIsVisible(props.fileID === selectrdFileID);
  // })

  // useEffect(()=>{
  //   const isSlicesExist = control.openedFiles.find(({fileID})=>fileID==props.fileID).Slices.length !== 0;
  //   setIsSlicesExist(isSlicesExist);
  // },[control])

  //  useEffect(()=>{
  //   //  debugger;
  //   const selectedFile = fileList.find(({fileID})=>fileID === props.fileID);
  //   setSelectedFile(selectedFile);
  //  },[])

  // const [colorbarPressed, setColorbarPressed] = useState({top:false, center:false, bottom:false});
  // const onMouseMoveTopOutput = (event) => {
  //   event.stopPropagation();
  //   if (colorbarPressed.top) {
  //     const widthPixel = refOutput0.current.clientHeight * toolSet.suvr.output.widthPercent / 100;
  //     const parentHeigt = refOutput0.current.clientHeight;
  //     const centerPercent = toolSet.suvr.output.centerPercent;
  //     const widthPercent = 100*(widthPixel - 2*event.movementY)/parentHeigt;

  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         output:{
  //           ...toolSet.suvr.output,
  //           widthPercent: Number(widthPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.output.max),
  //           center: Number(centerPercent/100*toolSet.suvr.output.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.output.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }

  // const onMouseMoveCenterOutput = (event) => {
  //   event.preventDefault();
  //   if (colorbarPressed.center) {
  //     const centerPixel = refOutput0.current.clientHeight * toolSet.suvr.output.centerPercent / 100;
  //     const parentHeigt = refOutput0.current.clientHeight;
  //     const centerPercent = 100*(centerPixel - 1*event.movementY)/parentHeigt;
  //     const widthPercent = toolSet.suvr.output.widthPercent;
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         output:{
  //           ...toolSet.suvr.output,
  //           centerPercent: Number(centerPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.output.max),
  //           center: Number(centerPercent/100*toolSet.suvr.output.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.output.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }

  // const onMouseMoveBottomOutput = (event) => {
  //   event.stopPropagation();
  //   if (colorbarPressed.bottom) {
  //     const widthPixel = refOutput0.current.clientHeight * toolSet.suvr.output.widthPercent / 100;
  //     const parentHeigt = refOutput0.current.clientHeight;
  //     const centerPercent = toolSet.suvr.output.centerPercent;
  //     const widthPercent = 100*(widthPixel + 2*event.movementY)/parentHeigt;
  //     // console.log(widthPixel, "/", parentHeigt);
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         output:{
  //           ...toolSet.suvr.output,
  //           widthPercent: Number(widthPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.output.max),
  //           center: Number(centerPercent/100*toolSet.suvr.output.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.output.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }

  // const onMouseMoveTopInput = (event) => {
  //   event.stopPropagation();
  //   if (colorbarPressed.top) {
  //     const widthPixel = refInput0.current.clientHeight * toolSet.suvr.input.widthPercent / 100;
  //     const parentHeigt = refInput0.current.clientHeight;
  //     const centerPercent = toolSet.suvr.input.centerPercent;
  //     const widthPercent = 100*(widthPixel - 2*event.movementY)/parentHeigt;

  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         input:{
  //           ...toolSet.suvr.input,
  //           widthPercent: Number(widthPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.input.max),
  //           center: Number(centerPercent/100*toolSet.suvr.input.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.input.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }
  // const onMouseMoveCenterInput = (event) => {
  //   event.preventDefault();
  //   if (colorbarPressed.center) {
  //     const centerPixel = refInput0.current.clientHeight * toolSet.suvr.input.centerPercent / 100;
  //     const parentHeigt = refInput0.current.clientHeight;
  //     const centerPercent = 100*(centerPixel - 1*event.movementY)/parentHeigt;
  //     const widthPercent = toolSet.suvr.input.widthPercent;
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         input:{
  //           ...toolSet.suvr.input,
  //           centerPercent: Number(centerPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.input.max),
  //           center: Number(centerPercent/100*toolSet.suvr.input.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.input.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }
  // const onMouseMoveBottomInput = (event) => {
  //   event.stopPropagation();
  //   if (colorbarPressed.bottom) {
  //     const widthPixel = refInput0.current.clientHeight * toolSet.suvr.input.widthPercent / 100;
  //     const parentHeigt = refInput0.current.clientHeight;
  //     const centerPercent = toolSet.suvr.input.centerPercent;
  //     const widthPercent = 100*(widthPixel + 2*event.movementY)/parentHeigt;
  //     // console.log(widthPixel, "/", parentHeigt);
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         input:{
  //           ...toolSet.suvr.input,
  //           widthPercent: Number(widthPercent),
  //           bottom: Number((centerPercent-widthPercent/2)/100*toolSet.suvr.input.max),
  //           center: Number(centerPercent/100*toolSet.suvr.input.max),
  //           top: Number((centerPercent+widthPercent/2)/100*toolSet.suvr.input.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }

  // const suvrReset = () => {
  //   const centerPercent = 50;
  //   const widthPercent = 100;
  //   if (toolSet.sn){
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         output:{
  //           ...toolSet.suvr.output,
  //           centerPercent: centerPercent,
  //           widthPercent: widthPercent,
  //           bottom: Number((centerPercent-widthPercent/2)/widthPercent*toolSet.suvr.output.max),
  //           center: Number(centerPercent/widthPercent*toolSet.suvr.output.max),
  //           top: Number((centerPercent+widthPercent/2)/widthPercent*toolSet.suvr.output.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   } else {
  //     setToolSet({
  //       ...toolSet,
  //       suvr:{
  //         ...toolSet.suvr,
  //         input:{
  //           ...toolSet.suvr.input,
  //           centerPercent: centerPercent,
  //           widthPercent: widthPercent,
  //           bottom: Number((centerPercent-widthPercent/2)/widthPercent*toolSet.suvr.input.max),
  //           center: Number(centerPercent/widthPercent*toolSet.suvr.input.max),
  //           top: Number((centerPercent+widthPercent/2)/widthPercent*toolSet.suvr.input.max),
  //           update:true,
  //         }
  //       }
  //     })
  //   }
  // }

  return (
    <div>
      {/* <div ref={ref} className="Container3" style={{visibility: isVisible ? "visible":"hidden"}}> */}
      {/* <div className="Container3-Header">
        <div>
          <div>
            <div>MIP</div>
            <div onClick={()=>setToolSet({...toolSet, mip:{...toolSet.mip, play:!toolSet.mip.play}})} className="Container3-Header-Icon"><img src={toolSet.mip.play ? media.viewMipStop:media.viewMipPlay}/></div>
            <div className="mipInput" style={{width:"40%", border:"none"}}>
              <InputRange
                draggableTrack
                step={1}
                maxValue={50}
                minValue={0}
                value={{min:0, max:toolSet.mip.speed}}
                onChange={value => {
                  setToolSet({...toolSet, mip:{...toolSet.mip, speed:Number(value.max.toFixed(0))}});
                }}
              />
            </div>
          </div>
          <div className='Overlay'>
            <div>Overlay</div>
            <div onClick={()=>setToolSet({...toolSet, mni:{...toolSet.mni, visible:!toolSet.mni.visible}})} className="Container3-Header-Icon"><img src={toolSet.mni.visible ? media.viewOverlayOff:media.viewOverlayOn}/></div>
            <div onClick={()=>setToolSet({...toolSet, mni:{...toolSet.mni, visible:true, opacity:0.5}})} className="Container3-Header-Icon"><img src={media.viewSuvrReset}/></div>
            <div className="mniInput" style={{width:"30%", border:"none"}}>
              <InputRange
                draggableTrack
                step={0.1}
                maxValue={1}
                minValue={0}
                value={{min:0, max:toolSet.mni.opacity}}
                onChange={value => {
                  setToolSet({...toolSet, mni:{...toolSet.mni, visible:true, opacity:Number(value.max.toFixed(1))}});
                }}
              />
            </div>
          </div>
          <div>
            <div>SBR</div>
            <div className="Container3-Header-Icon" onClick={suvrReset}><img src={media.viewSuvrReset}/></div>
            <div className="suvrInput" style={{width:"40%", border:"none"}}>
              <InputRange
                draggableTrack
                step={1}
                maxValue={100}
                minValue={0}
                value={{
                  min:toolSet.sn === true ? 
                    toolSet.suvr.output.centerPercent-toolSet.suvr.output.widthPercent/2:toolSet.suvr.input.centerPercent-toolSet.suvr.input.widthPercent/2, 
                  max:toolSet.sn === true ? 
                    toolSet.suvr.output.centerPercent+toolSet.suvr.output.widthPercent/2:toolSet.suvr.input.centerPercent+toolSet.suvr.input.widthPercent/2}}
                onChange={value => {
                  if (toolSet.sn){
                    setToolSet({
                      ...toolSet, 
                      suvr:{
                        ...toolSet.suvr, 
                        output:{
                          ...toolSet.suvr.output,
                          widthPercent: Number(value.max-value.min),
                          centerPercent: Number(value.max+value.min)/2,
                          update:true,
                        }
                      }
                    });
                  } else {
                    setToolSet({
                      ...toolSet, 
                      suvr:{
                        ...toolSet.suvr, 
                        input:{
                          ...toolSet.suvr.input,
                          widthPercent: Number(value.max-value.min),
                          centerPercent: Number(value.max+value.min)/2,
                          update:true,
                        }
                      }
                    });
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div onClick={()=>setToolSet({...toolSet, crosshair:!toolSet.crosshair})} className="Container3-Header-Icon"><img src={toolSet.crosshair ? media.viewCrosshairOff:media.viewCrosshairOn}/></div>
          </div>
          <div>
            <div onClick={()=>setToolSet({...toolSet, sn:!toolSet.sn})} className="Container3-Header-Icon"><img src={toolSet.sn ? media.viewSnOff:media.viewSnOn}/></div>
          </div>
          <div>
            <div onClick={()=>setToolSet({...toolSet, invert:!toolSet.invert})} className="Container3-Header-Icon"><img style={{transform: toolSet.invert ? "scaleX(-1)":"scaleX(1)"}} src={media.viewInvertOn}/></div>
          </div>
          <div style={{display:"none"}}>
            <div onClick={()=>setToolSet({...toolSet, msv:!toolSet.msv})} className="Container3-Header-Icon">{' @ '}</div>
          </div>
          
          <div className="gray-color-map">
            <div onClick={()=>{setColormapOpen(!colormapOpen); setBurgerOpen(false);}}>
              {toolSet.colormap.id === 0 ? "Gray scale":toolSet.colormap.id===1 ? "GE color":toolSet.colormap.id===2 ?"Jet":"Hot"} 
              <img src={media.iconDownArrow}/>
              <div style={{visibility: colormapOpen ? "visible":"hidden"}} onMouseLeave={()=>setColormapOpen(false)}>
                <div>
                  <div onClick={()=>setToolSet({...toolSet, colormap:{...toolSet.colormap, id:0}})}>Gray scale</div>
                </div>
                <div>
                  <div onClick={()=>setToolSet({...toolSet, colormap:{...toolSet.colormap, id:1}})}>GE color </div>
                </div>
                <div>
                  <div onClick={()=>setToolSet({...toolSet, colormap:{...toolSet.colormap, id:2}})}>Jet </div>
                </div>
                <div>
                  <div onClick={()=>setToolSet({...toolSet, colormap:{...toolSet.colormap, id:3}})}>Hot </div>
                </div>
              </div>
            </div>
          </div>


          <div className="burger-div">
            <div className="Container3-Header-Icon" onClick={()=>{setBurgerOpen(!burgerOpen); setColormapOpen(false);}}>
              <img  src={media.viewBurger}/>
              <div style={{visibility: burgerOpen ? "visible":"hidden", width:"8vw"}} onMouseLeave={()=>setBurgerOpen(false)}>
                <div>
                  <div 
                    onClick={() => history.push({
                      pathname:'/report',
                      state:{
                        selectedFile: selectedFile,
                      }
                  })}>
                    Generate a report
                  </div>
                </div>
                <div>
                  <div onClick={() => {
                    const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+'output_' + props.fileID + '.nii';
                    window.open(link, "_blank");
                  }}>
                    Export to NIFTI
                  </div>
                </div>
                <div>
                  <div 
                    onClick={() => {
                    const link = IPinUSE+"result/download/" + username + "/database/" + props.fileID + '/'+props.PatientID+'_' + props.fileID + '.csv';
                    window.open(link, "_blank");
                    }}
                  >
                    Export to CSV
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='view_table'>
          <table>
            <tr>
              <th>Tracer</th>
              <th>Patient Name</th>
              <th>Patient ID</th>
              <th>DOB</th>
              <th>Sex</th>
            </tr>
            <tr>
              <td>{props.Tracer.replace("18","\u00B9\u2078")}</td>
              <td>{props.PatientName}</td>
              <td>{props.PatientID}</td>
              <td>{props.BirthDate}</td>
              <td>{props.Sex}</td>
            </tr>
          </table>

          <table>
            <tr>
              <th>Atlas&nbsp;</th>
              <td>&nbsp; Melbourne Subcortical Atlas</td>
            </tr>
            <tr>
              <th>Reference region&nbsp;</th>
              <td>&nbsp; Occipital lobe</td>
           </tr>
          </table>
        </div>

      </div>
      <div className="Container3-Body">
        <div className="left">
        </div>
        <div className="center">
          {isSlicesExist && <ImageViewer fileID={props.fileID} isVisible={isVisible} toolSet={TOOLSET}/>}
        </div>
        <div className="right">
          {toolSet.sn && <div ref={refOutput0}
            style={{background:`linear-gradient(to bottom, rgba(${toolSet.invert ? toolSet.colormap.bottom[0]:toolSet.colormap.top[0]}, 
                                                                ${toolSet.invert ? toolSet.colormap.bottom[1]:toolSet.colormap.top[1]}, 
                                                                ${toolSet.invert ? toolSet.colormap.bottom[2]:toolSet.colormap.top[2]}, 
                                                                ${toolSet.invert ? toolSet.colormap.bottom[3]:toolSet.colormap.top[3]}) 
                                                                ${100-toolSet.suvr.output.centerPercent}%, 
                                                           rgba(${toolSet.invert ? toolSet.colormap.top[0]:toolSet.colormap.bottom[0]}, 
                                                                ${toolSet.invert ? toolSet.colormap.top[1]:toolSet.colormap.bottom[1]}, 
                                                                ${toolSet.invert ? toolSet.colormap.top[2]:toolSet.colormap.bottom[2]}, 
                                                                ${toolSet.invert ? toolSet.colormap.top[3]:toolSet.colormap.bottom[3]}) 
                                                                ${100-toolSet.suvr.output.centerPercent}%)`}}>
            <div
              onMouseMove={ onMouseMoveCenterOutput }
              onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:true, bottom:false})} }
              onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
              onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
              style={{
                bottom:`${toolSet.suvr.output.centerPercent}%`, 
                height:`${toolSet.suvr.output.widthPercent}%`,
              }}>
              <div className={`${toolSet.colormap.id === 0 ? 
                "colormapInvertedGray":toolSet.colormap.id===1 ? 
                "colormapPET":toolSet.colormap.id===2 ? 
                "colormapJet":"colormapHot"} ${toolSet.invert ? "inverted":""}`}>
              </div>
              <div ref={refOutput1}
                  onMouseMove={ onMouseMoveTopOutput }
                  onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:true, center:false, bottom:false})} }
                  onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
                  onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})}}>
                <div>{' '}{(toolSet.suvr.output.top).toFixed(2)}</div>
              </div>
              <div ref={refOutput2}>
                <div>{' '}{(toolSet.suvr.output.center).toFixed(2)}</div>
              </div>
              <div ref={refOutput3}
                  onMouseMove={ onMouseMoveBottomOutput }
                  onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:true})} }
                  onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
                  onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})}}>
                <div>{' '}{(toolSet.suvr.output.bottom).toFixed(2)}</div>
              </div>
            </div>
          </div>}
         
          {!toolSet.sn && <div ref={refInput0}
            style={{background:`linear-gradient(to bottom, rgba(${toolSet.invert ? toolSet.colormap.bottom[0]:toolSet.colormap.top[0]}, 
              ${toolSet.invert ? toolSet.colormap.bottom[1]:toolSet.colormap.top[1]}, 
              ${toolSet.invert ? toolSet.colormap.bottom[2]:toolSet.colormap.top[2]}, 
              ${toolSet.invert ? toolSet.colormap.bottom[3]:toolSet.colormap.top[3]}) 
              ${100-toolSet.suvr.output.centerPercent}%, 
         rgba(${toolSet.invert ? toolSet.colormap.top[0]:toolSet.colormap.bottom[0]}, 
              ${toolSet.invert ? toolSet.colormap.top[1]:toolSet.colormap.bottom[1]}, 
              ${toolSet.invert ? toolSet.colormap.top[2]:toolSet.colormap.bottom[2]}, 
              ${toolSet.invert ? toolSet.colormap.top[3]:toolSet.colormap.bottom[3]}) 
              ${100-toolSet.suvr.output.centerPercent}%)`}}>
            
            <div
              onMouseMove={ onMouseMoveCenterInput }
              onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:true, bottom:false})} }
              onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
              onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
              style={{
                bottom:`${toolSet.suvr.input.centerPercent}%`, 
                height:`${toolSet.suvr.input.widthPercent}%`,
              }}>
              <div className={`${toolSet.colormap.id === 0 ? 
                "colormapInvertedGray":toolSet.colormap.id===1 ? 
                "colormapPET":toolSet.colormap.id===2 ? 
                "colormapJet":"colormapHot"} ${toolSet.invert ? "inverted":""}`}>
              </div>
              <div ref={refInput1}
                  onMouseMove={ onMouseMoveTopInput }
                  onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:true, center:false, bottom:false})} }
                  onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
                  onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})}}>
                <div>{' '}{(toolSet.suvr.input.top).toFixed(2)}</div>
              </div>
              <div ref={refInput2}>
                <div>{' '}{(toolSet.suvr.input.center).toFixed(2)}</div>
              </div>
              <div ref={refInput3}
                  onMouseMove={ onMouseMoveBottomInput }
                  onMouseDown={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:true})} }
                  onMouseUp={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})} }
                  onMouseLeave={ (e) => {e.stopPropagation(); setColorbarPressed({top:false, center:false, bottom:false})}}>
                <div>{' '}{(toolSet.suvr.input.bottom).toFixed(2)}</div>
              </div>
            </div>

          </div>}
          <span>SBR</span>
        </div>
      </div> */}
    </div>
  )
}
export default Container;
