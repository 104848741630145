// import {removesessionStorage} from "0_variables/utils";
import * as loginActions from "1_reduxs/actions/loginAction";
import * as utils from "0_variables/utils";

const initialStates = {
  username: "Anonymous",
  token: null,
  // logged: false,
};

const LoginReducer = (state = initialStates, action) => {
  const { type, obj } = action;
  switch (type) {
    case loginActions.SETLOGINREDUX:
      if (!obj.logged){
        utils.removesessionStorage();
      }
      return {
        ...state,
        username: obj.username,
        token: obj.token,
        logged: obj.logged,
      };
    default:
      return state;
  }
};

export default LoginReducer;
