import React, { useEffect, useState } from "react";
import styles from "./Signin.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { InputText, HomePassButton, DropDownDiv } from "5_components";
import {coreItem} from "0_variables/coreItem";
import * as utils from "0_variables/utils";

export default function Signin(props) {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const username = sessionStorage.getItem('username');
  // const productName = params.product.toLowerCase();
  const productCoreItem = coreItem['amyloid'];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const loginState = useSelector((state) => state.login);
  const fileList = useSelector((state) => state.fileList);
  const [productList, setProductList] = useState(Object.keys(coreItem).map(k=>({key: k, productName:coreItem[k].productName})));
  const [productSelected, setProductSelected] = useState('amyloid');
  const [signinValues, setSigninValues] = useState({'username':'', 'password':''});
  // TODO: 20220603 로그인정보 삭제로직 제외 >>>
  // useEffect(()=>{
  //   dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))
  //   alert('hi1')
  //   utils.removesessionStorage();
  // },[])
  // useEffect(()=>{
  //   // Signin 페이지에서 fileList를 []으로 초기화해야함
  //   // 계정을 전환할때, dashboard의 useEffect에서 fetch_api를 호출하지 않도록 되어있음
  //   dispatch(actionsFiles.fetch_api({IDBList:[]}));
  //   // if (username === null){
  //   //   dispatch(actionsFiles.fetch_api({IDBList:[]}));
  //   //   alert('hi2')
  //   //   sessionStorage.clear();
  //   // }
  // },[username])
  // TODO: 20220603 로그인정보 삭제로직 제외 <<<

  // useEffect(()=>{
  //   const sessionStorageState = sessionStorage.getItem('token')
  //   dispatch(actionsFiles.fetchFiles({token: sessionStorageState}));
  // },[username])

  const processFunc = async () => {
    // debugger;
    if (loginState.logged){
      if (CTN_Mode) history.push(`/amyloid/dashboard`);
      else history.push(`/selectProduct`);
      // else history.push(`/${productSelected}/dashboard`);
      console.log("login: Connected");
    } else {
      const resObj = await dispatch(actionsLogin.login_api({
        username:signinValues.username,
        password:signinValues.password,
      }))
      if (resObj.response) {
        if (CTN_Mode) history.push(`/amyloid/dashboard`);
        else history.push(`/selectProduct`);
        // else history.push(`/${productSelected}/dashboard`);
        setSigninValues({'username':'', 'password':''});
      } else {
        if (resObj.code === undefined) alert("Network Error: Please check if Btxbrain-backend is running.");
        else if (resObj.code === "Pending") alert(resObj.message);
        else alert("Login failed. Please enter a valid username and password.");
      }
      console.log(`${resObj.message} (${resObj.code}) `);
    }
  }
  // const processFunc = async () => {
  //   if (loginState.logged){
  //     history.push(`/${productSelected}/dashboard`);
  //     console.log("login: Connected");
  //   } else {
  //     const resObj = await dispatch(actionsLogin.login_api({
  //       username:signinValues.username,
  //       password:signinValues.password,
  //     }))
  //     if (resObj.response) {
  //       history.push(`/${productSelected}/dashboard`);
  //       setSigninValues({'username':'', 'password':''});
  //     } else {
  //       if (resObj.code === undefined) alert("Network Error: Please check if Btxbrain-backend is running.");
  //       else alert("Login failed. Please enter a valid username and password.");
  //     }
  //     console.log(`${resObj.message} (${resObj.code}) `);
  //   }
  // }


  
  const propsSignIn = {
    processFunc,
    title: "LOG IN",
    // title: loginState.logged ? `LOG IN: ${username.toUpperCase()}`:"LOG IN",
    btnClassName:"pass-btn",
  }

  const propsLogout = {
    processFunc: ()=>{
      dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }));
      // history.push('/');
    },
    title: "LOG OUT",
    btnClassName:"logout-btn"
  }
  // debugger;
  return (
    <div className={styles["container"]}>
      <div>
        <h1 className={`${styles["title"]}`}>{'SIGN IN'}</h1>
  
        {!CTN_Mode && <div className={`${styles["product"]}`}>
          {/* <select name="product" onChange={(e)=>setProductSelected(e.target.value)} value={productSelected}>
            {productList.map((item,idx)=><option key={idx} value={item.key}>{item.productName}</option>)}
          </select> */}
          {/* <DropDownDiv style={{background:"#202223"}} item={productDropdown}/> */}
        </div>}

        {/* <input type="text" name="ch1" onChange={(e)=>console.log(e.target.name, e.target.value)}/> */}

        {<>
          <InputText title="USERNAME" type="text" name="username" placeholder="Enter Username" value={signinValues} setValue={setSigninValues} inputClassName="signin-input" />
          <InputText title="PASSWORD" type="password" name="password" placeholder="Enter Password" value={signinValues} setValue={setSigninValues} inputClassName="signin-input"/>
        </>}
        {/* {!loginState.logged && <>
          <InputText title="USERNAME" type="text" name="username" placeholder="Enter Username" value={signinValues} setValue={setSigninValues} inputClassName="signin-input" />
          <InputText title="PASSWORD" type="password" name="password" placeholder="Enter Password" value={signinValues} setValue={setSigninValues} inputClassName="signin-input"/>
        </>} */}

        {/* <div className={`${styles["pass-btn"]}`}><button onClick={processSignin}>{loginState.logged ? `LOG IN: ${username.toUpperCase()}`:"LOG IN"}</button></div> */}
        <HomePassButton item={propsSignIn}/>

        {<>
          <div className={`${styles["forgot-password"]}`}onClick={()=>history.push('/forgot')}>{'Forgot password?'}</div>
        </>}
        {/* {!loginState.logged && <>
          <div className={`${styles["forgot-password"]}`}onClick={()=>history.push('/forgot')}>{'Forgot password?'}</div>
        </>} */}

        {/* {loginState.logged && 
          <HomePassButton item={propsLogout}/>
          // <div className={`${styles["logout-btn"]}`}>
          //   <button onClick={()=>dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))}>
          //     {`LOG OUT`}
          //   </button>
          // </div>
        } */}
        <div className={`${styles["license"]} `}>{'License has authorized'}</div>
      </div>
      <div style={{fontSize:"0.6vw", opacity:"50%"}}>This product has not been approved by the MFDS and FDA and is not intended to diagnose, treat, cure, or prevent any disease.</div>
    </div>
  )
}
