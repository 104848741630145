import React, { useEffect, useState, useRef } from "react";
import styles from "./Worklist.module.scss";
import * as utils from "0_variables/utils";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import * as actionsControl from "1_reduxs/actions/controllerAction";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionModal from "1_reduxs/actions/modalAction";
import {coreItem} from "0_variables/coreItem";
import {PageLayout, Buttons, DropDownDiv, Burger, FileTable} from "5_components";
import * as d3 from "d3";
import * as media from "6_media";


export default function Worklist(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const productName = params.product.toLowerCase();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const settingOfProduct = setting[productName];
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  const topItems = productCoreItem['worklist'].top;
  // const [targetTracerRef, setTargetTracerRef] = useState({fullName:"", shortName:""});
  // const [opened, setOpenedWorklist] = useState(false);
  const [sortClick, setSortClick] = useState({});
  const tracerList = productCoreItem.tracers.map(obj=>obj.shortname);
  const defaultAtlas = setting[productName].defaultAtlas
  const tableHead = [
    {title: 'Select', fieldName:'Select'},
    {title: 'Tracer', fieldName:'Tracer'},
    // {title: 'Centiloid', fieldName:'Global_C'},
    {title: 'Patient name', fieldName:'PatientName'},
    {title: 'Patient ID', fieldName:'PatientID'},
    {title: 'DOB', fieldName:'Age'},
    {title: 'Sex', fieldName:'Sex'},
    // {title: 'Scan data', fieldName:'AcquisitionDateTime'},
    {title: 'Uploaded date', fieldName:'Update'},
  ];
  // const [selectedFile, setSelectedFile] = useState(fileList.find(({fileID})=>fileID === params.fileID));
  // debugger;
  // const tracerName = selectedFile['Tracer'];
  // const refName = settingOfProduct.defaultRef[tracerName]
  // const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
  
  
  const filteredFileList = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Group !== 0);
  // console.log(filteredFileList);
  
  const amyloidCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas) => {
    const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
    console.log(selectedAll);
    if (selectedAll.length === 0) {
      alert('No file selected on the worklist, please select file');
    } else {
      const bottomCards = productCoreItem["analysis"].bottom;
      const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
      const srcCentiloid = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
      const structuredData = selectedAll.reduce((arr, srcData, idx)=>{
        const selectedFile = srcData;
        const tracerName = selectedFile['Tracer'];
        const refName = settingOfProduct.defaultRef[tracerName]
        const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
        const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
        const godThanksCentiloid = utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
        
        const shortRefName = utils.getShortName(refName);
        const ref = {fullName:refName, shortName:shortRefName};
        const structData = utils.structAmyloidCSV({ref, atlas:defaultAtlas, srcData:selectedFile, srcSUVR:srcSUVR, srcCentiloid:srcCentiloid, godThanksSUVR, godThanksCentiloid}); 
        return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
      },[])
      const fname = 'brtnx_quantified.csv'
      utils.generateCSV({data:structuredData, transpose:true, fname})
    }
  }

  
  const datCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas, godThanksSBR, godThanksSemiquatification) => {
    const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
    if (selectedAll.length === 0 ) {
      alert('No file selected on the worklist, please select file');
    } else {
      const bottomCards = productCoreItem["analysis"].bottom;
      const srcSBR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
      const srcSemiquant = bottomCards[1].cards[1].content.filter(({atlas})=>atlas===defaultAtlas);
      const structuredData = selectedAll.reduce((arr, srcData, idx)=>{
        const selectedFile = srcData;
        const tracerName = srcData['Tracer'];
        const refName = settingOfProduct.defaultRef[tracerName];
        const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
        
        const godThanksSBR = utils.analysisAmyloidtable1({   settingOfProduct:setting['dat'    ], typeSelect: coreItem['dat'    ].type1Selector, tableItems:coreItem['dat'    ]['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
        const godThanksSemiquatification = utils.analysisAmyloidDATcard2_2({settingOfProduct:setting['dat'], typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: selectedFile, defaultAtlas:defaultAtlas })
        
        const shortRefName = utils.getShortName(refName);
        const ref = {fullName:refName, shortName:shortRefName};
        const structData = utils.structDATCSV({ref, atlas:defaultAtlas, srcData:srcData, srcSBR:srcSBR, srcSemiquant:srcSemiquant, godThanksSBR, godThanksSemiquatification}); 
        return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
      },[])
      const fname = 'fnameForWorklist.csv'
      utils.generateCSV({data:structuredData, transpose:true, fname})
    }
  }
  const FDGTauCSVFormatFromWorklist = (settingOfProduct, filteredFileList, productCoreItem, defaultAtlas) => {
    const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
    console.log(selectedAll);
    if (selectedAll.length === 0) {
      alert('No file selected on the worklist, please select file');
    } else {
      const bottomCards = productCoreItem["analysis"].bottom;
      const srcSUVR = bottomCards[1].cards[0].content.filter(({atlas})=>atlas===defaultAtlas);
      const structuredData = selectedAll.reduce((arr, srcData, idx)=>{
        const selectedFile = srcData;
        const tracerName = selectedFile['Tracer'];
        const refName = settingOfProduct.defaultRef[tracerName]
        const RF = utils.RefFactor({refName, ponsRF:selectedFile["ratio_gry2pons"], crblRF:selectedFile["ratio_gry2crbl"]})
        const godThanksSUVR = utils.analysisAmyloidtable1({settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: selectedFile, defaultAtlas:defaultAtlas, RF:RF })
        
        const shortRefName = utils.getShortName(refName);
        const ref = {fullName:refName, shortName:shortRefName};
        const structData = utils.structFDGTauCSV({ref, atlas:defaultAtlas, srcData:selectedFile, srcSUVR:srcSUVR,  godThanksSUVR, }); 
        return idx === 0 ? arr=[...structData.headRows, ...structData.bodyRows]:arr=[...arr, ...structData.bodyRows];
      },[])
      const fname = 'brtnx_quantified.csv'
      utils.generateCSV({data:structuredData, transpose:true, fname})
    }
  }
  // console.log(tracerList, filteredFileList);

  const wait = (timeToDelay) => new Promise((resolve) => setTimeout(resolve, timeToDelay)) //이와 같이 선언 후

  const burgerFuncs = {
    downloadPDF: () => {
      const spinnerController = (onoff) => {
        if (onoff) dispatch(actionModal.open_spinner({status:true, percent:0}));
        else dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
      }
      const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
      debugger;
      if (selectedAll.length <= 5){
        if (selectedAll.length === 0 ) {
          alert('No file selected on the worklist, please select file');
        } else {
          props.setReportOn(true);
          spinnerController(true);
          // dispatch(actionModal.open_spinner({status:true, percent:0, message:"Downloading..."}));
          setTimeout(()=>{
            utils.generatePDF(props.elRefs, spinnerController, props.setReportOn)
            // .then(()=>
            //   setTimeout(()=>{
            //     // dispatch(actionModal.open_spinner({status:false, percent:0, message:""}));
            //     props.setReportOn(false);
            //   },2000))
          },1000)
        }
      } else {
        alert('Please select 5 or less.')
      }
    },
    downloadJPEG: async () => {
      const spinnerController = (onoff) => {
        if (onoff) dispatch(actionModal.open_spinner({status:true, percent:0}));
        else dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
      }
      const selectedAll = filteredFileList.filter(({Group, Select})=>Select===true && Group !== 0);
      if (selectedAll.length <= 5){
        if (selectedAll.length === 0 ) {
          alert('No file selected on the worklist, please select file');
        } else {
          props.setReportOn(true);
          spinnerController(true);
          dispatch(actionModal.open_spinner({status:true, percent:0, message:"Downloading..."}));
          setTimeout(()=>{
            utils.generateJPEG(props.elRefs, spinnerController, props.setReportOn)
            // .then(()=>
            //   setTimeout(()=>{
            //     dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
            //     props.setReportOn(false);
            //   },2000))
          },1000)
        }
      } else {
        alert('Please select 5 or less.')
      }
      
    },
    downloadCSV_Group: ()=>{
      if (productName === 'amyloid')  return amyloidCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
      else if (productName === 'dat') return datCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
      // else if (productName === 'dat') return utils.datCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
      else if (productName === 'fdg') return FDGTauCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
      else if (productName === 'tau') return FDGTauCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
      else                            return amyloidCSVFormatFromWorklist(settingOfProduct, filteredFileList, productCoreItem, defaultAtlas)
    }
  }

  const deleteFromWorklist = (status) => {
    const selectedFiles = fileList.filter(item=>tracerList.includes(item.Tracer) && (item.Select === true && item.Group === 1));
    dispatch(actionsFiles.update_worklist(status, selectedFiles));
  }

  const openHandler = () => {
    const targetPage = utils.getTargetPathOnWorklist(location.pathname.split('/')[2].toLowerCase());
    const productList = fileList.filter((el) => tracerList.includes(el.Tracer));
    const selectedGroup = productList.filter(el=> el.Group !== 0 && el.Select === true);
    const filteredList = [...productList.filter((el) => el.Opened === true), ...selectedGroup]
    const targetFile = selectedGroup.at(-1);
    utils.openGroupFilesSelected({targetPage, filteredList, targetFile, productName, dispatch, history});
    props.setOpenedWorklist(false)
    selectedGroup.forEach(item=>{
      utils.selectSingleFile(dispatch, item)
    })
  }

  const closeHandler = () => {
    const filteredFileList = fileList.filter(item=>tracerList.includes(item.Tracer) && item.Select && item.Opened && item.Group !== 0);
    filteredFileList.forEach(item=>{
      utils.closeSingleFile(dispatch, item, location.pathname.split('/')[2].toLowerCase(), productName, history);
    })
  }

  const funcSelector = {
    "Worklist":()=>{deleteFromWorklist(false)},
    "Open":()=>{openHandler()},
    "Close":()=>{closeHandler()},
  }

  const burgerProps = {
    itemList: topItems.item1.map(item1=>({title:item1.title, func: burgerFuncs[item1.var]}))
  }

  return (
    <>
      {props.openedWorklist && <div className={styles.bg_worklist}></div>}
      <div className={`${styles.container} ${props.openedWorklist ? styles.opened:styles.closed}`}>
        <div className={styles.worklistHandle} onClick={()=>props.setOpenedWorklist(!props.openedWorklist)}>

        </div>
        <div className={styles.worklistBox}>
          <div className={styles.head}>
            <div onClick={()=>props.setReportOn(!props.reportOn)}>{'WORKLIST'}</div>
            <Burger {...burgerProps}/>
          </div>
          <div className={styles.body}>
            <FileTable 
              identifier={'worklist'}
              LOCKSELECT={false}
              productName={productName}
              tableHead={tableHead} FileList={filteredFileList}
              sortClick={sortClick} setSortClick={setSortClick}
              clickHandler={(singleFile)=>{utils.selectSingleFile(dispatch, singleFile)}} 
              doubleClickHandler={(singleFile)=>{
                const targetPage = utils.getTargetPathOnWorklist(location.pathname.split('/')[2].toLowerCase());
                utils.openSingleFileWithoutSelect({targetPage, history, dispatch, singleFile, fileList, tracerList, productName})
              }}
            />
          </div>
          <div className={styles.foot}>
            <div>
              <Buttons {...{Enable: utils.isAnySelected(filteredFileList, tracerList), styleName: 'worklistBtn', imgSrc:<img src={media.minus}/>, title:'Worklist', func:{onClickHandler:funcSelector['Worklist']}}}/>
            </div>
            <div>
              <Buttons {...{Enable: utils.isAnySelected(filteredFileList, tracerList), styleName: 'worklistBtn', imgSrc:'', title:'Open', func:{onClickHandler:funcSelector['Open']}}}/>
              <Buttons {...{Enable: utils.isAnySelected(filteredFileList, tracerList), styleName: 'worklistBtn', imgSrc:'', title:'Close', func:{onClickHandler:funcSelector['Close']}}}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}