import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from './PatientInfoTable.module.scss';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

export default function PatientInfoTable(props) {
  const params = useParams();
  const productName = params.product.toLowerCase()
  const setting = useSelector((state) => state.setting);

  
  return <>
    <table className={styles.table}>
      {
        setting[productName].defaultPInfoState.map(info=>{
          
          return(

            // info.state && <tr>
          <tr >
          
          
            <th>{info.name === "Type & Date of Examination" ? 'Study Date' : info.name}</th>
            <td>
              {/* //TODO:20220602 info state display none >>> */}
              <span style={{display:`${info.state ?"" : "none"}`}}>
              {/* //TODO:20220602 info state display none <<< */}
              {props.contents[info.name === 'Patient Name'? 'Name' : info.name ==='Type & Date of Examination' ? 'Study Date' : info.name]}
              </span>
            </td>
          </tr>
          )
        })
      }
      <tr > 
        <th>{'Study Description'}</th>
        <td>
          <span>
            {props.contents["Study Description"].replace('18','\u00B9\u2078')}
          </span>
        </td>
      </tr>
      {/* <tr>
        <th>{"Patient ID"}</th>
        <td><span>{props.contents["Patient ID"]}</span></td>
      </tr>
      <tr>
        <th>{"Name"}</th>
        <td><span>{props.contents["Name"]}</span></td>
      </tr>
      <tr>
        <th>{"DOB"}</th>
        <td><span>{props.contents["DOB"]}</span></td>
      </tr>
      <tr>
        <th>{"Sex"}</th>
        <td><span>{props.contents["Sex"]}</span></td>
      </tr>
      <tr>
        <th>{"Study Date"}</th>
        <td><span>{props.contents["Study Date"]}</span></td>
      </tr>
      <tr>
        <th>{"Study Description"}</th>
        <td><span>{props.contents["Study Description"]}</span></td>
      </tr> */}
    </table>
  </>;
}
