import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from './Analysis_card2.module.scss';
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {QuantTable} from "5_components";
import * as media from "6_media";

export default function Analysis_card2(props) {
  // debugger;
  const location = useLocation();
  const params = useParams();
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({"id":"ascend"});
  const [selectedFile, setSelectedFile] = useState({});
  const fileList = useSelector((state) => state.fileList);

  useEffect(()=>{
    const selectedFile = fileList.find(({fileID})=>fileID == params.fileID);
    setSelectedFile(selectedFile);
  },[params])

  // console.log(props.items)
  return <div className={styles.container}>
    {(()=>{
      // console.log('analysis: ', props.items)
      return <QuantTable tableHead={props.tableHead} items={props.items} trClick={trClick} setTrClick={setTrClick} sortClick={sortClick} setSortClick={setSortClick} data={props.data} defaultAtlas={props.defaultAtlas}/>
    })()}
  </div>;
}
