import React, { useEffect, useState, useRef } from "react";
import styles from './Burger.module.scss';
import * as media from "../../6_media";



export default function Burger({itemList}) {
  const [clickState, setClickState] = useState(false);
  const divRef = useRef();
  // console.log(divRef)
  // debugger;
  return (
      <div tabIndex={0} className={styles.container}
          onClick={()=>setClickState(!clickState)}
          onBlur={()=>setClickState(false)}>
        <div className={styles.burger_container}>
        <img src={media.viewBurger}/>
        </div>
        <div className={styles.list_container} style={{display:`${clickState ? "" :"none"}`}}>
          {itemList.map((item, idx)=><div key={idx} onClick={()=>{item.func(); setClickState(false)}}>
            <div>{item.title}</div>
          </div>)}
          {/* <div onClick={()=>{func.downloadPDF(func.props.elRefs, 2000)}}>Export all to PDF</div>
          <div onClick={()=>{func.downloadJPEG(func.props.elRefs, 2000)}}>Export all to JPEG</div>
          <div onClick={()=>{func.props.setReportOn(true)}}>Report page</div> */}

          {/* <div>Export all to CSV</div>
          <div>Export all to NIFTI(SUVR)</div>
          <div>Export all to NIFTI(Intensity)</div> */}
        </div>
      </div>
   
  )
}
