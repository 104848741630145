import React, { useEffect, useState } from "react";
import styles from "./PageLayout.module.scss";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";

export default function PageLayout(props) {
  const location = useLocation();
  const params = useParams();
  const productCoreItem = coreItem[params.product.toLowerCase()];
  const pageName = location.pathname.split('/')[2];

  
  return (
    <div className={`${styles["container"]}`}>
      <div className={`${styles["top"]}`}>
        {props.topContent(productCoreItem[pageName].top)}
      </div>
      
      <div className={`${styles["bottom"]}`}>
        {props.bottomContent(productCoreItem[pageName].bottom)}
      </div>
    </div>
  )
}
