import React, { useEffect, useState } from "react";
import styles from "./InputText.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";

export default function InputText({title, name, placeholder, value, setValue, type, inputClassName}) {
  
  console.log(inputClassName)
  return (
    <div className={`${styles[`${inputClassName}`]}`}>
      <div className={`${styles["input-title"]}`}>{title}</div>
      <div className={`${styles["input-content"]}`} >
        <label ><input
          type={type}
          name={name}
          value={value?.[name]}
          autocomplete="off"
          onChange={(e)=>{
            setValue({...value, [e.target.name]:e.target.value})
          }}
          placeholder={placeholder}
        /></label>
      </div>
    </div>
  )
}