import React, { useEffect, useState } from "react";
import styles from "./CustomROIModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
// import Container from "./components/Container";
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as media from "6_media";
import * as utils from "0_variables/utils";

export default function CustomROIModal(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const productName = params.product.toLowerCase();
  // const [selectedRegions,setSelectedRegions] = useState([]);
  // const typeSelector = {
  //   'amyloid': 'suvr',
  //   'dat': 'sbr',
  //   'fdg': 'suvr',
  //   'tau': 'suvr',
  // }
  const exceptList = {
    'amyloid':['Global', 'Brainstem'],
    'dat':['Dorsal striatum', 'Ventral striatum'],
    'fdg':['Global', 'Brainstem'],
    'tau':['Global', 'Brainstem'],
  }
  const [subRegionDefault, setSubRegionDefault] = useState(subRegions[productName].filter(({type})=>type===utils.type1Selector[productName]));
  // console.log(props.customROIModalProps.subRegionList[0])
  return (
    <div className={`${styles['container']}`}
      onClick={()=>{ 
        if (window.confirm("Are you sure to close user-defined ROI?")){
          props.customROIModalProps.closeHandler();
        }
      }}
    >
      {/* <span 
        className={styles.exitButton}
        onClick={()=>{
          props.customROIModalProps.closeHandler();
        }
      }>
        {'X'}
      </span> */}
      <div className={styles.boxContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.boxTitle}>
          User-Defined ROI
        </div>
        <div className={styles.boxContent}>
          <div className={styles.brain_regions_analyze_subContent}>
            <div>
              <div className={styles.brain_regions_analyze_subContent_title}>
                <div>
                  ROI name : {/* {props.customROIModalProps.userDefinedROI} */}
                  <input name="userDefinedRoi" type="text" placeholder="My ROI name" 
                      // onKeyPress={onEnterPress} 
                      value={props.customROIModalProps.userDefinedROI} 
                      onChange={(e)=>{
                        if (isNaN(e.target.value)){
                          props.customROIModalProps.setUserDefinedROI(e.target.value);
                        } else {
                          alert('A ROI name must start with a letter.');
                        }
                      }}
                  />
                </div>
              </div>
            </div>
            {subRegionDefault.filter(({type, atlas, level, fullname})=> !exceptList[productName].includes(fullname) && level === 0 && atlas===props.customROIModalProps.selectAtlas && type===utils.type1Selector[productName]).map(item=>(
              <div>
                <div className={styles.brain_regions_analyze_subContent_title}>
                  <div>{item.fullname}</div>
                </div>
                <div>
                  {subRegionDefault.filter(({belongToForeignKey, level})=> level === 1 && belongToForeignKey === item.primaryKey).map(subItem=>(
                    <div className={styles[`${subItem.fullname.length>16 ? "brain_regions_analyze_subContent_subregion_overflow": "brain_regions_analyze_subContent_subregion"}`]}
                      onClick={()=>{
                        const check = props.customROIModalProps.selectedRegions.includes(subItem.fullname)
                        if (check) {
                          const filtered = props.customROIModalProps.selectedRegions.filter(el => el !== subItem.fullname)
                          props.customROIModalProps.setSelectedRegions([...filtered])
                        } else {
                          props.customROIModalProps.setSelectedRegions([...props.customROIModalProps.selectedRegions, subItem.fullname])
                        }
                      }}
                    >
                      <div>{subItem.fullname}</div>
                      <div>{props.customROIModalProps.selectedRegions.includes(subItem.fullname) ? <img src={media.justCheck} alt=""/>:""}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.saveButton} 
              onClick={()=>{
                // debugger;
                if (props.customROIModalProps.userDefinedROI === "") {
                  alert('Please Enter ROI name.')
                } else {
                  if(props.customROIModalProps.selectedRegions[0] !== undefined){
                    const title = props.customROIModalProps.userDefinedROI;
                    const list = props.customROIModalProps.selectedRegions;
                    props.customROIModalProps.saveHandler(title, list);
                    props.customROIModalProps.closeHandler();
                  }else{
                    alert('Please select subregion(s).')
                  }
                }
              }
        }>
          Save
        </div>
      </div>
    </div>
  )
}