import React, { useEffect, useState } from "react";
import styles from "./Menu.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
// import Container from "./components/Container";
import {coreItem} from "0_variables/coreItem";
import {MenuButton} from "./components";
import * as media from "6_media";
import * as utils from "0_variables/utils";
import { CgTimer } from 'react-icons/cg';
import { BiUserCircle } from 'react-icons/bi';
import { SiCountingworkspro } from 'react-icons/si';
import axios from "axios";
import { IPinUSE } from "2_services/IPs";
import BrtnxLogo from "6_media/svg/BrtnxLogo";

export default function Menu(props) {
  const username = sessionStorage.getItem("username");
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const CTN_Mode = productCoreItem.CTN_Mode;
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const [licenseInfo, setLicenseInfo] = useState()
  const dispatch = useDispatch();
 
  // useEffect(()=>{
  //   const getLicenseInfo = async (obj) => {
  //     try {
  //       return await axios.get(IPinUSE + "result/download/licenseinfo.txt");
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   getLicenseInfo().then(res=>setLicenseInfo(res.data))
  // },[])

  const menuItem = (() =>{
    if (!CTN_Mode) return {
      page:[
        {key:0, enabled:true, name:"Dashboard", unactiveIcon:media.dashboard, push:`/${params.product}/dashboard`, activeIcon:media.blueDashboard, callBack:()=>(undefined)},
        {key:1, enabled:true, name:"Upload", unactiveIcon:media.upload, push:`/${params.product}/upload`, activeIcon:media.blueUpload, callBack:()=>(undefined)},
        {key:2, enabled:utils.viewEnable(control[productName], control[productName].selectedFileID), name:"View", unactiveIcon:media.view, push:`/${params.product}/view/${control[productName].selectedFileID}`, activeIcon:media.blueView, callBack:()=>(undefined)},
        {key:3, enabled:control[productName].selectedFileID !== null, name:"Analysis", unactiveIcon:media.analysis, push:`/${params.product}/analysis/${control[productName].selectedFileID}`, activeIcon:media.blueAnalysis, callBack:()=>(undefined)},
        // {key:4, enabled:control[productName].selectedFileID !== null, name:"Report", unactiveIcon:media.report, push:`/${params.product}/report/${control[productName].selectedFileID}`, activeIcon:media.blueReport, callBack:()=>(undefined)},
        {key:4, enabled:true, name:"Setting", unactiveIcon:media.setting, push:`/${params.product}/setting`, activeIcon:media.blueSetting, callBack:()=>(undefined)},
      ],
      logout:{key:5, enabled:true, name:"Log out", unactiveIcon:media.logout, push:"/signin", callBack:()=>dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))},
    } 
    else return {
      page:[
        {key:0, enabled:true, name:"Dashboard", unactiveIcon:media.dashboard, push:`/${params.product}/dashboard`, activeIcon:media.blueDashboard, callBack:()=>(undefined)},
        {key:1, enabled:true, name:"Upload", unactiveIcon:media.upload, push:`/${params.product}/upload`, activeIcon:media.blueUpload, callBack:()=>(undefined)},
        {key:2, enabled:utils.viewEnable(control[productName], control[productName].selectedFileID), name:"View", unactiveIcon:media.view, push:`/${params.product}/view/${control[productName].selectedFileID}`, activeIcon:media.blueView, callBack:()=>(undefined)},
        // {key:3, enabled:control[productName].selectedFileID !== null, name:"Analysis", unactiveIcon:media.analysis, push:`/${params.product}/analysis/${control[productName].selectedFileID}`, activeIcon:media.blueAnalysis, callBack:()=>(undefined)},
        // {key:4, enabled:control[productName].selectedFileID !== null, name:"Report", unactiveIcon:media.report, push:`/${params.product}/report/${control[productName].selectedFileID}`, activeIcon:media.blueReport, callBack:()=>(undefined)},
        // {key:4, enabled:true, name:"Setting", unactiveIcon:media.setting, push:`/${params.product}/setting`, activeIcon:media.blueSetting, callBack:()=>(undefined)},
      ],
      logout:{key:5, enabled:true, name:"Log out", unactiveIcon:media.logout, push:"/signin", callBack:()=>dispatch(actionsLogin.setLoginRedux({ username: "", token: "", logged: false }))},
    }
  })()
  return (
    <div className={`${styles['container']} ${pageName==='report' ? styles.hidden:""}`}>
      <div className={`${styles['up-items']}`}>
        <div onClick={()=>{
          if (CTN_Mode) history.push('/signin');
          else history.push('/selectProduct');
        }} className={`${styles['logo-container']}`}>
          <div className={`${styles['logo-style']}`}><BrtnxLogo width="50%" fill="white"/></div>
        </div>
        
        {menuItem.page.map((item, idx)=>
          <MenuButton key={item.key} item={item}/>
        )}
      </div>

      <div className={`${styles['down-items']}`}>
        <div className={`${styles['license-info']}`}>
          <div>
            <BiUserCircle size={"1vw"} strokeWidth={"0px"}/>
            <span>{username + " ("+props.remainCounts+")"}</span>
          </div>
          {/* <div>
            <SiCountingworkspro size={"1vw"} strokeWidth={"0px"}/>
            <span>{username}</span>
          </div> */}
          <div>
            <CgTimer size={"1vw"} strokeWidth={"0px"}/>
            {/* <span>{licenseInfo?.slice(0,10)}</span>endDate */}
            <span>{props.endDate}</span>
          </div>
        </div>
        
        <MenuButton key={menuItem.logout.key} item={menuItem.logout}/>
      </div>
    </div>
  )
}
