import React, { useEffect, useState } from "react";
import styles from "./PieGraph.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Legend } from 'recharts';

export default function PieGraph(props) {
  const params = useParams();
  const [activeIndex, setActiveIndex] = useState(null);
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const tracerList = coreItem[productName].tracers;
  const COLORS = tracerList.map(({color})=>color);
  const data = productCoreItem.tracers.map(({shortname})=>{
    return {
      name: shortname,
      value: fileList.filter(({Tracer})=>Tracer===shortname).length
    }
  })


  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  const onPieLeave = (_, index) => {
    setActiveIndex(null);
  };
  
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    
    return (
      <g>
        {/* <text fontSize={"3em"} x={cx} y={cy} dy={8} textAnchor="middle" fill="white">
          {this.state.data.reduce((acc,v)=>{acc+=v.value; return acc},0)}
        </text> */}
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text fontSize="15px" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white">{`${name} ${value}`}</text>
        <text fontSize="15px" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="white">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <div className={styles['container']}>
      <div className={styles['graphContainer']}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width="100%" height="100%">
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius="40%"
              outerRadius="55%"
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
              onMouseLeave={onPieLeave}
            >
              {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
            </Pie>
          {/* <Legend iconSize={15} iconType={'circle'} height={1} /> */}
          </PieChart>
        </ResponsiveContainer>
        <div className={styles['textContainer']}>
          <span>{data.reduce((acc,v)=>{acc+=v.value; return acc},0)}</span>
          <span>{'TOTAL'}</span>
        </div>
      </div>
      
      <div className={styles['legendContainer']}>
        {tracerList.map((v,i)=><div key={i} style={{borderLeft:`6px ${v.color} solid`}}>{v.shortname.replace('18', '\u00B9\u2078')}</div>)}
      </div>
    </div>
  );
}

// import React, { Component } from 'react';
// import styles from "./PieGraph.module.scss";
// import { connect } from "react-redux";
// import {coreItem} from "0_variables/coreItem";
// import { PieChart, Pie, Sector, ResponsiveContainer, Cell, Legend } from 'recharts';
// 
// class PieGraph extends Component {
//   state = {
//     activeIndex: null,
//     tracerList: coreItem[this.props.match.params.product.toLowerCase()].tracers,
//     coreItem: coreItem,
//     data: coreItem[this.props.match.params.product.toLowerCase()].tracers.map(({shortname})=>{
//       return {
//         name: shortname,
//         value: this.props.fileList.filter(({Tracer})=>Tracer===shortname).length
//       }
//     })
//   };
//   // componentDidMount(){
//   //   this.setState({
//   //     data: this.state.coreItem[this.props.match.params.product.toLowerCase()].tracers.map(({shortname})=>{
//   //       return {
//   //         name: shortname,
//   //         value: this.props.fileList.filter(({Tracer})=>Tracer===shortname).length
//   //       }
//   //     })
//   //   })
//   // }
//   componentDidUpdate(prevProps, prevState){
//     if (prevProps.fileList !== this.props.fileList){
//       // debugger;
//       this.setState({
//         data: this.state.coreItem[this.props.match.params.product.toLowerCase()].tracers.map(({shortname})=>{
//           return {
//             name: shortname,
//             value: this.props.fileList.filter(({Tracer})=>Tracer===shortname).length
//           }
//         })
//       })
//     }
//   }
//   onPieEnter = (_, index) => {
//     this.setState({
//       activeIndex: index,
//     });
//   };
//   onPieLeave = (_, index) => {
//     this.setState({
//       activeIndex: null,
//     });
//   };
  
//   renderActiveShape = (props) => {
//     const RADIAN = Math.PI / 180;
//     const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, name } = props;
//     const sin = Math.sin(-RADIAN * midAngle);
//     const cos = Math.cos(-RADIAN * midAngle);
//     const sx = cx + (outerRadius + 10) * cos;
//     const sy = cy + (outerRadius + 10) * sin;
//     const mx = cx + (outerRadius + 30) * cos;
//     const my = cy + (outerRadius + 30) * sin;
//     const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//     const ey = my;
//     const textAnchor = cos >= 0 ? 'start' : 'end';
    
//     //let sum_value =  ((data.value).map)
    
//     return (
//       <g>
//         {/* <text fontSize={"3em"} x={cx} y={cy} dy={8} textAnchor="middle" fill="white">
//           {this.state.data.reduce((acc,v)=>{acc+=v.value; return acc},0)}
//         </text> */}
//         <Sector
//           cx={cx}
//           cy={cy}
//           innerRadius={innerRadius}
//           outerRadius={outerRadius}
//           startAngle={startAngle}
//           endAngle={endAngle}
//           fill={fill}
//           />
//         <Sector
//           cx={cx}
//           cy={cy}
//           startAngle={startAngle}
//           endAngle={endAngle}
//           innerRadius={outerRadius + 6}
//           outerRadius={outerRadius + 10}
//           fill={fill}
//         />
//         <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
//         <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//         <text fontSize="15px" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white">{`${name} ${value}`}</text>
//         <text fontSize="15px" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="white">
//           {`(Rate ${(percent * 100).toFixed(2)}%)`}
//         </text>
//       </g>
//     );
//   };

//   render() {
//     const data = this.state.tracerList.map(({shortname})=>{
//       return {
//         name: shortname, 
//         value: this.props.fileList.filter(({Tracer})=>Tracer===shortname).length}
//       }
//     );
//     // console.log(data.reduce((acc,v)=>{acc+=v.value; return acc},0));
//     // console.log(coreItem[this.props.match.params.product.toLowerCase()][this.props.location.pathname.split('/')[2]]);
//     // console.log(this.state.data);
//     const COLORS = this.state.tracerList.map(({color})=>color);

//     return (
//       <div className={styles['container']}>
//         <div className={styles['graphContainer']}>
//           <ResponsiveContainer width="100%" height="100%">
//             <PieChart width="100%" height="100%">
//               <Pie
//                 activeIndex={this.state.activeIndex}
//                 activeShape={this.renderActiveShape}
//                 data={data}
//                 cx="50%"
//                 cy="50%"
//                 innerRadius="40%"
//                 outerRadius="55%"
//                 fill="#8884d8"
//                 dataKey="value"
//                 onMouseEnter={this.onPieEnter}
//                 onMouseLeave={this.onPieLeave}
//               >
//                 {data.map((entry, index) => (
//                 <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
//               ))}
//               </Pie>
//             {/* <Legend iconSize={15} iconType={'circle'} height={1} /> */}
//             </PieChart>
//           </ResponsiveContainer>
//           <div className={styles['textContainer']}>
//             <span>{this.state.data.reduce((acc,v)=>{acc+=v.value; return acc},0)}</span>
//             <span>{'TOTAL'}</span>
//           </div>
//         </div>
        
//         <div className={styles['legendContainer']}>
//           {this.state.tracerList.map((v,i)=><div key={i} style={{borderLeft:`6px ${v.color} solid`}}>{v.shortname.replace('18', '\u00B9\u2078')}</div>)}
//         </div>
//       </div>
//     );
//   }
// } const mapStateToProps = (state) => ({
//   fileList: state.fileList,
// });
// export default connect(mapStateToProps)(PieGraph);