
// import * as actionSetting from "1_reduxs/actions/settingAction";
import {createAction, handleActions} from 'redux-actions';
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as utils from "0_variables/utils";

export const ADD_CUSTOMROI = "setting/ADD_CUSTOMROI";
export const DELETE_CUSTOMROI = "setting/DELETE_CUSTOMROI";
export const SAVE_TO_SETTING = "setting/SAVE_TO_SETTING";
export const RESET_SETTING = "setting/RESET_SETTING";
export const CHANGED_ON_SETTING = "setting/CHANGED_ON_SETTING";

export const add_customROI = createAction(ADD_CUSTOMROI, (productName, customROI) => {
  return {
    obj: {productName, customROI},
  };
});

export const delete_customROI = createAction(DELETE_CUSTOMROI, (productName, customROI) => {
  return {
    obj: {productName, customROI},
  };
});

export const save_to_setting = createAction(SAVE_TO_SETTING, (productName, settingOfProduct) => {
  return {
    obj: {productName, settingOfProduct},
  };
});

export const reset_setting = createAction(RESET_SETTING, (productName) => {
  return {
    obj: {productName},
  };
});

export const changed_on_setting = createAction(CHANGED_ON_SETTING, (changed, settingLocal) => {
  return {
    obj: {changed, settingLocal},
  };
});

export const initialStates = {
  changed: false,
  prevConfig: {},
  ...Object.keys(coreItem).reduce((obj, productName)=>{
    obj[productName]={
      defaultAtlas:coreItem[productName].defaultAtlas,
      defaultRef:coreItem[productName].defaultRef,
      defaultTracer:coreItem[productName].defaultTracer,
      defaultPInfoState:coreItem[productName].defaultPInfoState,
      list: subRegions[productName].map(el=>({...el, isFolded:false})),
    };
    return obj;
  },{}),
};

const settingReducer = handleActions(
  {
    [RESET_SETTING]:(state, {payload}) => {
      const {obj} = payload;
      return {
        ...state,
        changed: false,
        [obj.productName]:{
          ...obj.settingOfProduct,
          defaultAtlas:coreItem[obj.productName].defaultAtlas,
          defaultRef:coreItem[obj.productName].defaultRef,
          defaultTracer:coreItem[obj.productName].defaultTracer,
          defaultPInfoState:coreItem[obj.productName].defaultPInfoState,
          list: subRegions[obj.productName].map(el=>({...el, isFolded:false})),
        }
      }
    },
    [SAVE_TO_SETTING]:(state, {payload}) => {
      const {obj} = payload;
      return {
        ...state,
        changed: false,
        [obj.productName]:{
        ...obj.settingOfProduct,
        }
      }
    },
    [ADD_CUSTOMROI]:(state, {payload}) => {
      const {obj} = payload;
      const newState = {
        ...state,
        [obj.productName]:{
          ...state[obj.productName],
          list:[
            ...state[obj.productName].list,
            ...obj.customROI,
          ]
        }
      }
      return {
        ...newState,
      }
    },
    [DELETE_CUSTOMROI]:(state, {payload}) => {
      const {obj} = payload;
      const filteredList = state[obj.productName].list.filter(item=> item.belongToForeignKey !== obj.customROI.belongToForeignKey)
      return {
        ...state,
        [obj.productName]:{
          ...state[obj.productName],
          list:[
            ...filteredList,
          ]
        }
      }
    },
    [CHANGED_ON_SETTING]:(state, {payload}) => {
      const {obj} = payload;
      return {
        ...state,
        changed:obj.changed,
        prevConfig:{
          ...obj.settingLocal,
        }
      }
    },
  },
  initialStates,
)

// const settingReducer = (state = initialStates, action) => {
//   const { type, obj } = action;

//   switch (type) {
//     case actionSetting.ADD_CUSTOMROI: 
//       // debugger;
//       // 추가할때 이름 겹치지 않는지 검사 필요
//       // 추가할때 primaryKey가 고유한 값인지 검사 필요 (최대값에서 값 추가)
//       // belongToForeignKey를 올바른 primaryKey로 연결
//       return {
//         ...state,
//         [obj.productName]:{
//           ...state[obj.productName],
//           list:[
//             ...state[obj.productName].list,
//             ...obj.customROI,
//           ]
//         }
//       };
//     case actionSetting.DELETE_CUSTOMROI: 
//       // debugger;
//       // 삭제할때, primaryKey를 고유한 값으로 유지
//       // belongToForeignKey가 잘못 연결되지 않는지 주의
//       const filteredList = state[obj.productName].list.filter(item=> item.belongToForeignKey !== obj.customROI.belongToForeignKey)
//       return {
//         ...state,
//         [obj.productName]:{
//           ...state[obj.productName],
//           list:[
//             ...filteredList,
//           ]
//         }
//       };

//     default: 
//       return state;
//   }
// };

export default settingReducer;
