import React from "react";
import styles from "./ExpiredModal.module.scss";
import * as media from "6_media";


export default function ExpiredModal(props){

  return(
    <div className={styles.container}
    onClick={()=>{ 
        props.closeModal();
    }}
    >
      <div className={styles.licenseBox}>
        <div>
          <img src={media.sandsClock}/>
        </div>
        <div>
          Please complete your email verification. 
         
        </div>
        <div>
          
          Or, contact us to extend the demo.
        </div>
        <div>
          Remained count : {props.remainCounts}
        </div>
        <div>
          Expired date : {props.endDate}
        </div>
        <div>
          Please contact brtnx@brtnx.com
        </div>
      </div>
    </div>
  )
}