import React, { useEffect, useState } from "react";
import styles from "./SpinnerModal.module.scss";
import * as media from "6_media";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
// import Container from "./components/Container";
import {coreItem} from "0_variables/coreItem";
import { FiLoader } from 'react-icons/fi';

export default function SpinnerModal(props) {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const closeHandler = () => {
    dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
  }
  return (
    <div className={`${styles.container}`}>
      {/* <span className={styles.exitButton} onClick={()=>{closeHandler();}}>
        {'X'}
      </span> */}
      <div className={styles.spinnerImg}>
        <FiLoader size={"25vmin"} color={styles['spinner-color']}/>
      </div>
      <span className={styles.msgText}>{modalState.spinner?.message}</span>
    </div>
  )
}
