import React, { useEffect, useState } from "react";
import styles from "./UploadingStep2.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { PacsTable } from "5_components";
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as services from '2_services/uploadApi';
import * as media from "6_media";
import { BiTrash } from 'react-icons/bi';

export default function UploadingStep2({idx, selected, uploadingStepProps}) {
  const date0 = new Date();
  const currentDate = date0.getFullYear()+('0' + (date0.getMonth()+1)).slice(-2)+('0' + date0.getDate()).slice(-2);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const fileList = useSelector((state) => state.fileList);
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  // const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({});
  const [inputs, setInputs] = useState({
    PatientID: '', 	//사용할 문자열들을 저장하는 객체 형태로 관리!
    StudyDate: currentDate,
    // StudyDate: "20160627",
    StudyDescription: '',
  });
  const handleChange = (e) => { 
    const { name, value }  = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  // const tracerList = productCoreItem.tracers.map(obj=>obj.shortname);
  const tableHead = productCoreItem[pageName].bottom.uploadingTableHead2;
  // const filteredFileList = fileList.filter(item=>tracerList.includes(item.Tracer));
  // debugger;
  const getResultReform = () => {
    return (
      uploadingStepProps.findResult.map(el=>{
        el['Marker']="\u3008";
        el['Delete']=<BiTrash size={"0.8vw"}/>; 
        return el;}
      )
    )
  }
  return (
    <>
      <div className={styles.boxContainer} ref={el => uploadingStepProps.handleRef(idx, el)} style={{display: selected ? '':'none'}}>
        <span className={styles.backButton} onClick={()=>uploadingStepProps.executeScroll(idx-1)}>
          {'\u1438 Back'}
        </span>

        <div className={styles.boxTitle}>
          <div><label for='PatientID'>        Patient ID         <input id='PatientID'        value={inputs.PatientID}        name="PatientID"        placeholder="0000-0000"           onChange={handleChange}/></label></div>
          <div><label for='StudyDate'>        Study Date         <input id='StudyDate'        value={inputs.StudyDate}        name="StudyDate"        placeholder="YYYYMMDD - YYYYMMDD" onChange={handleChange}/></label></div>
          <div><label for='StudyDescription'> Study Description  <input id='StudyDescription' value={inputs.StudyDescription} name="StudyDescription" placeholder="Tracer Memo"         onChange={handleChange}/></label></div>
          <div className={styles.searchBtn} onClick={()=>uploadingStepProps.findHandler(inputs)}>  Search</div>
        </div>

        <div className={styles.boxContent}>
          <PacsTable
            // type={uploadingStepProps.type}
            productName={productName}
            tableHead={tableHead} FileList={getResultReform()} 
            sortClick={sortClick} setSortClick={setSortClick}
            deleteHandler={(item, studyID, seriesID)=>{uploadingStepProps._deleteFindHandler(item, studyID, seriesID); }}
            clickHandler={(v1)=>{}}
            doubleClickHandler={(v1)=>{}}
            foldable={true}
            selectedItem={null}
          />
        </div>


        <div className={styles.buttonGroup}>
          <div className={styles.nextButton} onClick={()=>{uploadingStepProps.getHandler(); uploadingStepProps.executeScroll(idx+1)}}>
            {"Download \u1433"}
          </div>
        </div>
      </div>
    </>
  )
}