import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import variables from '3_styles/_variables.scss';
import { useSelector, useDispatch } from "react-redux";
// import * as actionsControl from "1_reduxs/actions/controllerAction";
import {closeFileAndChangingTab, selectedFileID} from "1_reduxs/reducers/controllerReducer";
import { Route, useHistory, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import * as actionsFiles from "1_reduxs/actions/filesAction";
import {coreItem} from "0_variables/coreItem";
import * as utils from "0_variables/utils";
import { DropDownDiv } from "5_components";
import { BiLoaderAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { BiTransferAlt } from 'react-icons/bi';

export default function Header(props) {
  const username = sessionStorage.getItem("username");
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const setting = useSelector((state) => state.setting);
  const [productList, setProductList] = useState(Object.keys(coreItem).map(k=>({key: k, productName:coreItem[k].productName})));
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2].toLowerCase();
  const CTN_Mode = productCoreItem.CTN_Mode;
  const tracerObjs = productCoreItem.tracers;
  const tracerList = tracerObjs.map(v => v.shortname);
  const [hoverState, setHoverState] = useState(null)
  const [productSelected, setProductSelected] = useState(coreItem[productName].productName);
  const [licenseInfo, setLicenseInfo] = useState({
		'amyloid':{"valid":false, "until":null}, 
		'dat':{"valid":false, "until":null}, 
		'fdg':{"valid":false, "until":null}, 
		'tau':{"valid":false, "until":null}
	})
  const productInfo = [
		{productFullName:"BTXBrain - Amyloid", productShortName:"amyloid", color:"#FF6D4C", validDate:licenseInfo["amyloid"]?.until, license:licenseInfo["amyloid"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - DAT", productShortName:"dat", color:"#F14CFF", validDate:licenseInfo["dat"]?.until, license:licenseInfo["dat"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - FDG", productShortName:"fdg", color:"#4CFF69", validDate:licenseInfo["fdg"]?.until, license:licenseInfo["fdg"]?.valid, version:"1.0.0"},
		{productFullName:"BTXBrain - Tau", productShortName:"tau", color:"#4C74FF", validDate:licenseInfo["tau"]?.until, license:licenseInfo["tau"]?.valid, version:"1.0.0"},
	]

  const tracerColor = tracerObjs.reduce((obj,item)=>{
    obj[item.shortname]=item.color;
    return obj;
  },{});

  const productDropdown = {
    options: Object.values(productList.filter(v=> licenseInfo[v.key].valid).map(v=> v.productName)),
    selectItem:productSelected,
    setItem:(val)=>{
      if (setting.changed) {
        alert("please save the changes before leaving the settings");
      } else {
        const productName = val.toLowerCase(0);
        if (licenseInfo[productName].valid){
          productTransition(productName);
        }else{
          alert('A product license is required to use this service.')
        }
        return setProductSelected(val)
      }
      
    }
  }

  const productTransition = (productName) => {
    if (pageName === 'dashboard' || pageName === 'upload' || pageName === 'setting') {
      const targetUrl = `/${productName}/${pageName}`;
      // window.location.href = "http://localhost:3000"+targetUrl;
      history.push(targetUrl);
    } else {
      const targetUrl = `/${productName}/upload`;
      // window.location.href = "http://localhost:3000"+targetUrl;
      history.push(targetUrl);
    }
  }

  useEffect(()=>{
		utils.getLicenseInfo(productInfo, setLicenseInfo)
	},[])

  useEffect(()=>{
    // 주소창으로 접근했는데 selectedFileID가 null인 경우, upload 페이지로 리디렉션
    if (control[productName].selectedFileID === null){
      const currentPage = location.pathname.split('/')[2].toLowerCase();
      switch(currentPage){
        case 'view':
          history.push(`/${productName}/upload`)
          break;
        case 'analysis':
          history.push(`/${productName}/upload`)
          break;
        case 'report':
          history.push(`/${productName}/upload`)
          break;
        default:
          break;
      }
    }
  },[control[productName].selectedFileID])

  const tabSwitch = (currentPage, targetFileID) => {
    switch(currentPage){
      case 'view':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      case 'analysis':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      case 'report':
        history.push(`/${productName}/${currentPage}/${targetFileID}`);
        break;
      default:
        break;
    }
  }

  const headerEnable = (currentPage) => {
    if (currentPage === "dashboard" || currentPage === "upload" || currentPage === "setting"){
      return false;
    } else {
      return true;
    }
  }

  const headerTabText = (item) => {
    return <span className={styles.fileInfo} style={{opacity: utils.viewEnable(control[productName], item.fileID) ? 1:0.3, borderColor:tracerColor[item.Tracer]}}>{item.fileID} {item.PatientName}</span>
  }

  const headerTabIcon = (item, viewEnabled) => {
    if (viewEnabled){
      return (
        <span 
          className={styles.closeIcon} 
          // style={{visibility:(headerEnable(location.pathname.split('/')[2].toLowerCase()) && hoverState === item.fileID) ? "visible":"hidden"}}
          onClick={(e)=>{
            e.stopPropagation(); 
            utils.closeSingleFile(dispatch, item, location.pathname.split('/')[2].toLowerCase(), productName, history);
            // if (headerEnable(location.pathname.split('/')[2].toLowerCase())){
            //   if (viewEnabled){
            //     utils.closeSingleFile(dispatch, item, location.pathname.split('/')[2].toLowerCase(), productName, history);
            //     setHoverState(null);
            //   } else {
            //     console.log('view slices are not prepared.')
            //   }
            // }
          }}
        >
          <AiOutlineClose/>
        </span>
      )
    } else {
      return <span className={styles.loadIcon}><BiLoaderAlt/></span>
    }
  }

  const headerTabContainer = (item) => {
    return (
      <div key={item.fileID}
        style={{opacity: headerEnable(location.pathname.split('/')[2].toLowerCase()) ? 1:0.4}}
        className={control[productName].selectedFileID === item.fileID ? styles.activeTab:""}
        onClick={()=>{
          if (headerEnable(location.pathname.split('/')[2].toLowerCase())){
            dispatch(selectedFileID(productName, item));
            const currentPage = location.pathname.split('/')[2].toLowerCase();
            const targetFileID = item.fileID;
            tabSwitch(currentPage, targetFileID);
          }
        }}
        onMouseEnter={()=>{if (hoverState===null) setHoverState(item.fileID)}}
        onMouseOver={()=>{if (hoverState===null) setHoverState(item.fileID)}}
        onMouseLeave={()=>{setHoverState(null)}}
      >
        {headerTabText(item)}
        {headerTabIcon(item, utils.viewEnable(control[productName], item.fileID))}
      </div>
    )
  }

  return (
    <div className={`${styles['container']}`}>
      <div className={`${styles['tabgroup']}`}>
        {control[productName].openedFiles.filter(({Tracer})=>tracerList.includes(Tracer)).map(item=> headerTabContainer(item))}
      </div>
      <div className={`${styles['infogroup']}`}>
        {/* {username} */}
        {!CTN_Mode && <>
          <BiTransferAlt size={"1.2vw"}/>
          <div className={`${styles["product"]}`}>
            {/* <select name="product" onChange={(e)=>{
              debugger;
              const productTransition = () => {
                if (pageName === 'dashboard' || pageName === 'upload' || pageName === 'setting') {
                  const targetUrl = `/${e.target.value}/${pageName}`;
                  // window.location.href = "http://localhost:3000"+targetUrl;
                  history.push(targetUrl);
                } else {
                  const targetUrl = `/${e.target.value}/upload`;
                  // window.location.href = "http://localhost:3000"+targetUrl;
                  history.push(targetUrl);
                }
              }

              if (setting.changed) {
                alert("please save the changes before leaving the settings");
              } else {
                productTransition();
              }
            }} value={productName}
            >
              {productList.map((item,idx)=><option key={idx} value={item.key}>{item.productName}</option>)}
            </select> */}
            <DropDownDiv style={{background:"#202223"}} item={productDropdown} styleName={'headerStyle'}/>
          </div>
        </>}
      </div>
    </div>
  )
}
