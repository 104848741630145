import React, { useEffect, useState } from "react";
import styles from "./Dashboard.module.scss";
// import common_styles from "./Common.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import * as actionsFiles from "1_reduxs/actions/filesAction";
import * as actionsControl from "1_reduxs/reducers/controllerReducer";
import {PageLayout, CardLayout} from "5_components";
import {coreItem} from "0_variables/coreItem";
import { BarGraph, PieGraph } from "./components";
import Dexie from 'dexie';
import { BTXDB } from '5_components/DBConfig';
import { useLiveQuery } from "dexie-react-hooks";
import * as utils from "0_variables/utils";

export default function Dashboard(props) {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const pageName = location.pathname.split('/')[2];
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const control = useSelector((state) => state.control);
  const controlOfProduct = control[productName];
  const fileList = useSelector((state) => state.fileList);
  const username = sessionStorage.getItem('username');
  const token = sessionStorage.getItem("token");
  // alert(params.product)

  const dashboardPageTop = (top) => {
    return (
      <div className={styles["top"]}>
        {`Hello, ${username}`}
        <br/>
        {`Have a great day with Btxbrain-${productCoreItem.productName} !`}
      </div>
    )
  }
  
  const dashboardPageBottom = () => {
    const cardComponents = {
      BarGraph: <BarGraph/>,
      PieGraph: <PieGraph {...props}/>,
      // PieGraph: <BarGraph/>,
    }
    const cardItem = productCoreItem[pageName].bottom.map((v,i1)=>({
      ...v,
      cards:v.cards.map((card,i2)=> {
        return {
          ...card, content:cardComponents[card.componentName],
        }
      })
    }));
    
    return (
      <>
        {cardItem.map((v,i)=>
          <CardLayout key={i} {...v}/>
        )}
      </>
    )
  }

  return (
    <>
      <PageLayout topContent={dashboardPageTop} bottomContent={dashboardPageBottom}/>
    </>
  )
}
