import { combineReducers } from "redux";

import loginReducer from "./loginReducer";
import modalReducer from "./modalReducer";
import controllerReducer from "./controllerReducer";
import filesReducer from "./filesReducer";
import settingReducer from "./settingReducer";
// import slicesReducer from "./slicesReducer";
// import screenReducer from "./screenReducer";

const rootReducer = combineReducers({
  login: loginReducer,
  modal: modalReducer,
  fileList: filesReducer,
  control: controllerReducer,
  setting: settingReducer,
  // sliceList: slicesReducer,
  // screen: screenReducer,
});

export default rootReducer;
