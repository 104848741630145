import React, { useEffect, useState } from "react";
import styles from "./HomePassButton.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionsLogin from "1_reduxs/actions/loginAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";

export default function HomePassButton({item}) {
  const location = useLocation();
  // console.log(propsStyle)
  return (
    <div className={`${styles[`${item.btnClassName}`]}`}>
      <button onClick={item.processFunc} style={{background:item.propsStyle?.background ? item.propsStyle?.background:""}}>
        {item.title}
      </button>
    </div>
  )
}