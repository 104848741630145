import React, { useEffect, useState, useRef } from "react";
import styles from "./ReportPage2.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import {ReportLayout} from "../";
import amyloid_page2 from "./amyloid_page2.PNG";
import dat_page2 from "./dat_page2.PNG";
import tau_page2 from "./tau_page2.PNG";
import fdg_page2 from "./fdg_page2.PNG";
import {ImageViewer} from "5_components/7_ReportPages/components/ReportPage3/components";
import * as utils from "0_variables/utils";
import {QuantTable} from "5_components";
import * as media from "6_media";
import * as d3 from "d3";
// import ResizeObserver from '@juggle/resize-observer'

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import { scale } from "chroma-js";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

export default function ReportPage2(props) {

  // const element = useRef();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const productName = params.product.toLowerCase();
  const pageName = "report";
  const Direction = "axial";
  const productCoreItem = coreItem[productName]; 
  // const [selectedFile, setSelectedFile] = useState({});
  // const topItems = productCoreItem[pageName].top;
  // TODO: Analysis에서 사용하는 테이블 헤더, 컨텐트 정보를 그대로 가져와서 사용중 >> 나중에는 공통 변수로 묶어서 사용하면 좋을듯함.
  const bottomCards = productCoreItem["analysis"].bottom; 
  const page2Table1Items = productCoreItem["analysis"].bottom[1].cards[0]; 
  const page2Table2Items = productCoreItem["analysis"].bottom[1].cards[1]; 
  const [trClick, setTrClick] = useState([]);
  const [sortClick, setSortClick] = useState({});
  const [defaultAtlas, setDefaultAtlas] = useState(setting[productName].defaultAtlas)

  const today = new Date();
  const date = today.toJSON().slice(0, 10);
  const nDate = date.slice(8, 10) + '/' + date.slice(5, 7) + '/' + date.slice(0, 4);

  const [data, setData] = useState([24, 30, 45, 70, 26]);
  // const content_height = new ResizeObserver(entries =>{
  //   console.log(entries)
  // })
  

  // console.log('report: ', props.RF);
  const table1Data = {
    "amyloid": utils.reportAmyloidtable1({ settingOfProduct:setting['amyloid'], typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
    "dat": utils.reportDATtable1({     settingOfProduct:setting['dat'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
    "fdg": utils.reportAmyloidtable1({     settingOfProduct:setting['fdg'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
    "tau": utils.reportAmyloidtable1({     settingOfProduct:setting['tau'],     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  }
  // const table1Data = {
  //   "amyloid": utils.reportAmyloidtable1({ subRegions:setting['amyloid'].list, typeSelect: productCoreItem.type1Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, settingOfProduct: setting[productName], RF:props.RF }),
  //   "dat": utils.reportDATtable1({     subRegions:setting['dat'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "fdg": utils.reportAmyloidtable1({     subRegions:setting['fdg'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['fdg']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  //   "tau": utils.reportAmyloidtable1({     subRegions:setting['tau'].list,     typeSelect: productCoreItem.type1Selector, tableItems:coreItem['tau']['analysis'].bottom[1].cards[0], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas , RF:props.RF}),
  // }
  const table2Data = {
    "amyloid":utils.reportAmyloidDATtable2({ settingOfProduct:setting['amyloid'], typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
    "dat":utils.reportAmyloidDATtable2({     settingOfProduct:setting['dat'],     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas, RF:props.RF }),
  }
  // const table2Data = {
  //   "amyloid":utils.reportAmyloidDATtable2({ subRegions:setting['amyloid'].list, typeSelect: coreItem['amyloid'].type2Selector, tableItems:coreItem['amyloid']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas }),
  //   "dat":utils.reportAmyloidDATtable2({     subRegions:setting['dat'].list,     typeSelect: coreItem['dat'].type2Selector, tableItems:coreItem['dat']['analysis'].bottom[1].cards[1], selectedFile: props.selectedFile, defaultAtlas:defaultAtlas }),
  // }
  const size = utils.getSizebyProduct(Direction);
  const ratio = size.height/size.width;
  const width = 15;
  const height = width*ratio;

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const containerRef = useRef(null);
  const ref = useRef(null);
  const svg0Ref = useRef(null);
  const svg1Ref = useRef(null);
  const svg2Ref = useRef(null);
  useEffect(()=>{
    if (productName === 'amyloid' || productName === 'tau' || productName === 'fdg'){
      setData1([
        {region:"Global / Composite",               value:  (props.selectedFile.Global                                                              )*props.RF},
        {region:"Frontal",                          value: ((props.selectedFile.Frontal_L          + props.selectedFile.Frontal_R)/2                )*props.RF},
        {region:"PCC-Precuneus",                    value: ((props.selectedFile.PCC_L              + props.selectedFile.PCC_R)/2                    )*props.RF},
        {region:"Lateral temporal",                 value: ((props.selectedFile.Lateral_temporal_L + props.selectedFile.Lateral_temporal_R)/2       )*props.RF},
        {region:"Lateral parietal",                 value: ((props.selectedFile.Lateral_parietal_L + props.selectedFile.Lateral_parietal_R)/2       )*props.RF},
        {region:"Medial temporal",                  value: ((props.selectedFile.Medial_temporal_L  + props.selectedFile.Medial_temporal_R)/2        )*props.RF},
        {region:"Occipital",                        value: ((props.selectedFile.Occipital_L        + props.selectedFile.Occipital_R)/2              )*props.RF},
        {region:"Basal ganglia",                    value: ((props.selectedFile.Basal_ganglia_L    + props.selectedFile.Basal_ganglia_R)/2          )*props.RF},
      ])
      setData2([
        {region:"Global / Composite",               value: ( props.selectedFile.Composite_C                                                      )*props.RF },
        {region:"Frontal",                          value: ((props.selectedFile.Frontal_L_C          + props.selectedFile.Frontal_R_C)/2         )*props.RF },
        {region:"PCC-Precuneus",                    value: ((props.selectedFile.PCC_L_C              + props.selectedFile.PCC_R_C)/2             )*props.RF },
        {region:"Lateral temporal",                 value: ((props.selectedFile.Lateral_temporal_L_C + props.selectedFile.Lateral_temporal_R_C)/2)*props.RF },
        {region:"Lateral parietal",                 value: ((props.selectedFile.Lateral_parietal_L_C + props.selectedFile.Lateral_parietal_R_C)/2)*props.RF },
        {region:"Medial temporal",                  value: ((props.selectedFile.Medial_temporal_L_C  + props.selectedFile.Medial_temporal_R_C)/2 )*props.RF },
        {region:"Occipital",                        value: ((props.selectedFile.Occipital_L_C        + props.selectedFile.Occipital_R_C)/2       )*props.RF },
        {region:"Basal ganglia",                    value: ((props.selectedFile.Basal_ganglia_L_C    + props.selectedFile.Basal_ganglia_R_C)/2   )*props.RF },
      ]);
    } else if (productName === "dat") {
      // debugger;
      setData1([
        {
          "Category": "Dorsal striatum",
          "Left": Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Dorsal_striatum_R),
        },
        {
          "Category": "Caudate",
          "Left": Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Caudate_nucleus_R),
        },
        {
          "Category": "Putamen A",
          "Left": Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Ant_Putamen_R),
        },
        {
          "Category": "Putamen P",
          "Left": Math.max(0.01, props.selectedFile.DAT_Post_Putamen_L),
          "Right": Math.max(0.01, props.selectedFile.DAT_Post_Putamen_R),
        },
      ])
    }
  },[props.selectedFile])

  const wrap=(text, width)=> {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
      }
    });
  }
  useEffect(() => {
    if (productName === "amyloid" || productName === "fdg" || productName === "tau" && data1.length !== 0){
      const val1Max = 2.2;
      const val2Max = 100;
      const svgLabelWidth = ref.current.offsetWidth*0.2;
      const svgWidth = ref.current.offsetWidth*0.30;
      const svgHeight = ref.current.offsetHeight*0.9;
      const gWidth = svgWidth*0.9;
      const gHeight = svgHeight*0.8;
      const gSize = {width:gWidth, height:gHeight, leftMargin:svgWidth*0.05, topMargin:svgHeight*0.1, bottomMargin:svgHeight*0.01}
      const svg0Size = {width:svgLabelWidth, height:svgHeight};
      const svg1Size = {width:svgWidth, height:svgHeight};
      const svg2Size = {width:svgWidth, height:svgHeight};
      
      // 데이터 스케일 변환
      const x1 = d3.scaleLinear()
        .domain([0, val1Max])
        .range([0, gSize.width])
  
      const x2 = d3.scaleLinear()
        .domain([0, val2Max])
        .range([0, gSize.width])
  
      const y = d3.scaleBand()
        .domain(data1.map((el)=> {return el.region}))
        .range([0, gSize.height])
        .paddingInner(0.6)
  
      // Y축 라벨
      const yAxis = d3.axisLeft(y);
      const svg0 = d3.select(svg0Ref.current)
      svg0.selectAll("*").remove();
      svg0
        .attr("width", svg0Size.width)
        .attr("height", svg0Size.height)
        // .attr("style", "border:3px red solid;")
        .append("g")
        .attr("transform", "translate(" + svg0Size.width + ", " + (gSize.topMargin) + ")")
        .call(yAxis)
        .selectAll('text')
        .attr("style", "font-size: 14px")
      svg0
        .selectAll('path')
        .attr("style", "stroke-width: 0px")
      
      // 타이틀
      const svg1Title = d3.select(svg1Ref.current)
      svg1Title.selectAll("*").remove();
      svg1Title
        .append("text")
        .attr("transform", "translate("+(gSize.width*0.45)+", " + (gSize.topMargin*0.7) + ")")
        .attr("style", "font-size:16px; font-weight:600")
        .text("SUVR");
  
      const svg2Title = d3.select(svg2Ref.current)
      svg2Title.selectAll("*").remove();
      svg2Title
        .append("text")
        .attr("transform", "translate("+(gSize.width*0.4)+", " + (gSize.topMargin*0.7) + ")")
        .attr("style", "font-size:16px; font-weight:600")
        .text("Centiloid");
  
      // X축 라벨
      const x1Axis = d3.axisBottom(x1).tickFormat(v=>`${v.toFixed(1)}`)
        .ticks(5);
      const svg1XAxis = d3.select(svg1Ref.current)
      svg1XAxis
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+(gSize.topMargin+gSize.height+gSize.bottomMargin)+")")
        .call(x1Axis)
        .selectAll('text')
        .attr("style", "font-size: 14px")
      
      const x2Axis = d3.axisBottom(x2).tickFormat(v=>`${v.toFixed(0)}`)
        .ticks(5);
      const svg2XAxis = d3.select(svg2Ref.current)
      svg2XAxis
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+(gSize.topMargin+gSize.height+gSize.bottomMargin)+")")
        .call(x2Axis)
        .selectAll('text')
        .attr("style", "font-size: 14px")
        
      // SUVR 그래프
      const svg1Bg = d3.select(svg1Ref.current)
      svg1Bg
        .attr("width", svg1Size.width)
        .attr("height", svg1Size.height)
        // .attr("style", "border:3px black solid")
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+gSize.topMargin+")")
        .selectAll("rect")
        .data(data1)
        .join("rect")
        .attr("x", 0)
        .attr("y", (d)=>{return y(d.region)})
        .attr("style", "fill:lightgray; rx:5; ry:5")
        .attr("width", (d)=>{return x1(val1Max)})
        .attr("height", y.bandwidth())
      const svg1 = d3.select(svg1Ref.current)
      svg1
        .attr("width", svg1Size.width)
        .attr("height", svg1Size.height)
        // .attr("style", "border:3px black solid")
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+gSize.topMargin+")")
        .selectAll("rect")
        .data(data1)
        .join("rect")
        .attr("x", 0)
        .attr("y", (d)=>{return y(d.region)})
        .attr("style", "fill:red; rx:5; ry:5")
        .attr("height", y.bandwidth())
        .transition()
        .duration(500)
        .attr("width", (d)=>{return x1(utils.clamp(d.value,0,val1Max))})
  
      // Centiloid 그래프
      const svg2Bg = d3.select(svg2Ref.current)
      svg2Bg
        .attr("width", svg2Size.width)
        .attr("height", svg2Size.height)
        // .attr("style", "border:3px black solid")
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+gSize.topMargin+")")
        .selectAll("rect")
        .data(data2)
        .join("rect")
        .attr("x", 0)
        .attr("y", (d)=>{return y(d.region)})
        .attr("style", "fill:lightgray; rx:5; ry:5")
        .attr("width", (d)=>{return x2(val2Max)})
        .attr("height", y.bandwidth())
      
      const svg2 = d3.select(svg2Ref.current)
      svg2
        .attr("width", svg2Size.width)
        .attr("height", svg2Size.height)
        // .attr("style", "border:3px black solid")
        .append("g")
        .attr("transform", "translate("+gSize.leftMargin+", "+gSize.topMargin+")")
        .selectAll("rect")
        .data(data2)
        .join("rect")
        .attr("x", 0)
        .attr("y", (d)=>{return y(d.region)})
        .attr("style", "fill:red; rx:5; ry:5")
        .attr("height", y.bandwidth())
        .transition()
        .duration(500)
        .attr("width", (d)=>{return x2(utils.clamp(d.value,0,val2Max))})
    } else if (productName === "dat" && data1.length !== 0) {
      // debugger;
      var econ2 = data1

      const svgWidth = ref.current.offsetWidth;
      const svgHeight = ref.current.offsetHeight;
      //chart setup
      var svg = d3.select(svg0Ref.current);
      svg.selectAll("g").remove();
      var margin = {top: 50, right: 120, bottom: 40, left: 120},
      width = svgWidth - margin.left - margin.right,
      height = svgHeight - margin.top - margin.bottom,
      g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

          // debugger;
      //y position calculation function
      const tempMax = Math.max(...econ2.map(el=>Math.max(el.Left, el.Right)))
      const tempMin = Math.min(...econ2.map(el=>Math.min(el.Left, el.Right)))
      const dataMax = Math.max(tempMax, 1);
      // debugger;
      // const dataMin = Math.max(0.01, tempMin)
      var y = d3.scaleLinear()
        .domain([0, dataMax])
        .range([height, 0]);

      var x0 = d3.scaleBand()  // domain defined below
        .rangeRound([0, width])
        .paddingInner(0.3)
        .paddingOuter(0.5);

      var x1 = d3.scaleBand()  // domain and range defined below
        .paddingInner(0.3)
        .paddingOuter(0.5);

      //blue, tan, red colors
      var z = d3.scaleOrdinal()
          .range(["#597AB5", "#64C1D6"]);

      //reference to the y axis
      //axisLeft put labels on left side
      //ticks(n) refers to # of increment marks on the axis
      const yAxis = d3.axisLeft(y).ticks(3);

      //examine first object, retrieve the keys, and discard the first key
      //return resulting array of keys
      // [ "2017 Q1", "2017 Q2", "2017 Q3 First Estimate"]
      var subCategories = Object.keys(econ2[0]).slice(1);

      //use new array from just the Category values for the bottom x-axis
      x0.domain(econ2.map( d =>  d.Category ));

      //array of quarterly value names, fitted in the available bottom categories (x0.bandwidth())
      x1.domain(subCategories).rangeRound([0, x0.bandwidth()])

      // Add bar chart
      var selection = g.selectAll("g")
        .data(econ2)
        .enter().append("g")
        .attr("transform", d => "translate(" + x0(d.Category) + ",0)" )
      selection.selectAll("rect")
        //Use map function with the subCategories array and the Econ2 array
        .data(function(d) { return subCategories.map(function(key) { return {key: key, value: d[key]}; }); })
        .enter().append("rect")
        .attr("x", d => x1(d.key) )
        //If the value is negative, put the top left corner of the rect bar on the zero line
        // .attr("y", d => (d.value<0 ? y(0) : y(d.value)) )
        .attr("y", d => (y(dataMax*0)) )

        .attr("width", x1.bandwidth())
        .attr("fill", d => z(d.key) )
        .attr("rx","5px")
        // .transition()
        // .duration(500)
        // .attr("height", d => Math.abs(y(d.value) - y(0)) )
        // .attr("height", d => y(dataMax*0.5))
        .attr("height", d => y(dataMax*1))
        .transition()
        .duration(500)
        .attr("y", (d,i)=>y(d.value))
        .attr("height", (d,i)=>y(dataMax-d.value))
      
      // selection
      //   .transition()
      //   .duration(500)
      //   .attr("y", (d,i)=>y(d.value))
      //   .attr("height", (d, i) => d.value);

        //can not nest the text element inside the rect element !
      selection.selectAll("text")
        .data(function(d) { return subCategories.map(function(key) { return {key: key, value: d[key]}; }); })
        .enter().append("text")
        .attr("x", d => x1(d.key) )
        //offset the position of the y value (positive / negative) to have the text over/under the rect bar
        .attr("y", d => d.value<=0 ? y(0) - (y(4) - (Math.abs(y(d.value) - y(0)) + 20)) : y(d.value) - 10)
        .style('fill', d => z(d.key))
        .style('font-size', '1em')
        //make sure one just decimal place is displayed
        .text(d => Number.parseFloat(d.value).toFixed(1))

      //add the x-axis
      g.append("g")
        // .attr("class", "axis")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x0))
        .selectAll(".tick text")
        .attr("style","font-size:15px")
        //use wrap function to wrap long lines in labels
        // .call(wrap, x0.bandwidth());

      //add the y-axis - notice it does not have css class 'axis'
      g.append('g')
        // .attr("style","border:1px red solid")
        .call(yAxis)

      //idenitfy zero line on the y axis.
      g.append("line")
        .attr("y1", y(0))
        .attr("y2", y(0))
        .attr("x1", 0)
        .attr("x2", width)
        .attr("stroke", "gray");

      var legend = g.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", "13px")
        .attr("text-anchor", "end")
        .selectAll("g")
        .data(subCategories)
        .enter().append("g")
        .attr("transform", function(d, i) { return "translate(100," + (i * 24) + ")"; });
      legend.append("rect")
        .attr("x", width - 142)
        .attr("width", 8)
        .attr("height", 8)
        .attr("fill", z);
      legend.append("text")
        .attr("x", d => d.length > 7 ? (width + 5) : (width - 80))
        .attr("y", 5.5)
        .attr("dy", "0.22em")
        .text(d => (d));
    }
  }, [data1]);
  
  // useEffect(()=>{
  //   // const contentHeight = containerRef.target.offsetHeight
  //   // console.log("height", contentHeight)
  //   console.log(containerRef.current.parentNode.offsetHeight, containerRef.current.offsetHeight)
  //   alert(containerRef.current.parentNode.offsetHeight + ', '+ containerRef.current.offsetHeight)
  //   // if (containerRef.current !== null){
  //   //   alert('ready')
  //   // }
  // },[containerRef])
  return (
    <>
      {/* <div ref={el=>{debugger;if(props.innerRef) props.innerRef=el}} className={styles.A4pages}> */}
        <ReportLayout {...props} PatientID={props.selectedFile.PatientID}>
          <div ref={containerRef} className={styles.QuantContainer}
            // onMouseEnter={(e)=>{
            //   const contentHeight = e.target.offsetHeight
            //   console.log("height", contentHeight)
            // }}
          >
            <div className={styles.ReportTitle}>
              <img src={media.reportIcon2} alt=""/>&nbsp;{'Quantification Results'}
            </div>
            <div ref={ref} className={styles.d3Graph}>
              {(()=>{
                if (productName==="amyloid" || productName==="tau" || productName==="fdg") {
                  return <>
                    <svg ref={svg0Ref}>
                    </svg>
                    <svg ref={svg1Ref}>
                    </svg>
                    {(productName==="amyloid") &&
                      <svg ref={svg2Ref}>
                      </svg>
                    }
                  </>
                }
                else if (productName==="dat") {
                  return <>
                    <svg  ref={svg0Ref} class="chart" width="960" height="590" aria-labelledby="graph-title" aria-describedby="graph-desc">
                      {/* <title>GDP Growth Remains Broad Based</title> */}
                      {/* <desc id="graph-desc">GDP Growth Remains Broad Based, with values for 2017 quarters 1-3.</desc> */}
                      <text style={{fontSize:"20px", fontWeight:"600"}} transform="translate(100, 20)" class="chartDisplayTitle">Striatal SBR</text>
                      {/* <text id="graph-title" transform="translate(10, 45)" class="chartDisplayTitle">GDP Growth Remains Broad Based</text> */}
                      {/* <text transform="translate(10, 70)" class="yUnits">Percentage points*</text> */}
                      {/* <text transform="translate(10, 570)" class="caption">*Contribution to total gross domestic product (GDP) growth; seasonally adjusted annualized rate.</text> */}
                      {/* <text transform="translate(10, 585)" class="caption">SOURCE: Bureau of Economic Analysis.</text> */}
                    </svg>
                  </>
                }
              })()}
            </div>
            <div className={styles.typeTableWrapper}>
              <div className={styles.tableTitle}>
                {(()=>{
                  switch(productName){
                    case "amyloid":
                      return "Regional SUVR"
                    case "dat":
                      return ""
                    case "fdg":
                      return "Regional SUVR"
                    case "tau":
                      return "Regional SUVR"
                    default:
                      return "Regional SUVR"
                  }
                })()}
              </div>
              <div className={styles.tableContainer}>
                {(()=>{
                  // console.log('report: ', table1Data[productName].items)
                  return <QuantTable eventEnabled={false} tableHead={table1Data[productName].tableHead} items={table1Data[productName].items} trClick={trClick} setTrClick={setTrClick} sortClick={sortClick} setSortClick={setSortClick}/>
                })()}
              </div>
            </div>
            <div className={styles.typeTableWrapper}>
              <div className={styles.tableTitle}>
                {(()=>{
                  switch(productName){
                    case "amyloid":
                      return "Regional Centiloid"
                    case "dat":
                      return "Semiquantification"
                      case "fdg":
                        return ""
                      case "tau":
                        return ""
                    default:
                      return ""
                  }
                })()}
              </div>
              <div className={styles.tableContainer}>
                {(productName === 'amyloid' || productName === 'dat') && <QuantTable eventEnabled={false} tableHead={table2Data[productName].tableHead} items={table2Data[productName].items} trClick={trClick} setTrClick={setTrClick} sortClick={sortClick} setSortClick={setSortClick}/>}
              </div>
            </div>
          </div>
        </ReportLayout>
      {/* </div> */}
    </>
  )
}
