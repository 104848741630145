import React, { useEffect, useState } from "react";
import styles from "./MainConfig.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {coreItem} from "0_variables/coreItem";
import { DropDownDiv } from "5_components";
import * as media from "6_media";
// import * as actionSetting from "1_reduxs/actions/settingAction";
import * as actionSetting from "1_reduxs/reducers/settingReducer";
import * as actionModal from "1_reduxs/actions/modalAction";
import {subRegions} from "0_variables/subRegions";
import * as utils from "0_variables/utils";


export default function MainConfig(props) {
  const location = useLocation();
  const params = useParams();
  const setting = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const productName = params.product.toLowerCase();
  const productCoreItem = coreItem[productName];
  const pageName = location.pathname.split('/')[2];
  const atlasItem = productCoreItem[pageName].bottom[0].cards[0].contents[0]; //control.defaultAtlas
  // const selectAtlas = props.mainConfigProps.selectAtlas;
  // debugger;
  const refItem = productCoreItem[pageName].bottom[0].cards[0].contents[1];
  // console.log(refItem);
  // refItem.subContent.forEach(el=>console.log(el));
  const regionItem = productCoreItem[pageName].bottom[0].cards[0].contents[2];
  // console.log(productCoreItem[pageName].bottom[0].cards[0])

  const tracerColor = productCoreItem.tracers.reduce((obj, item)=>{obj[item.shortname]=item.color; return obj;},{});
  const atlasList = atlasItem.atlasList;
  const refList = refItem.refList;
  const atlasDropdown = {
    selectItem: props.mainConfigProps.selectAtlas, 
    options:atlasList, 
    setItem:(val)=>{
      return props.mainConfigProps.setSelectAtlas(val)
    }
  }

  // debugger;
  const refDropdown = Object.entries(props.mainConfigProps.selectRef).map(arr=>{
    return {
      selectItem:arr[1],
      options:refList,
      setItem:(val)=>{
        return props.mainConfigProps.setSelectRef({...props.mainConfigProps.selectRef, [arr[0]]:val})
      }
    }
  })

  // const striatum_pair = [
  //   {multiAtlas:"Melbourne Subcortical", ExtrastriatumAtlas:"AAL3, ATAG"},
  //   {multiAtlas:"AAL3", ExtrastriatumAtlas:"ATAG"},
  //   {multiAtlas:"Harvard-Oxford", ExtrastriatumAtlas:"AAL3, ATAG"},
  // ]
  const striatum_pair = {
    "Melbourne Subcortical" : {
      extrastriatumAtlas:"AAL3, ATAG", 
      firstRegion:["Dorsal striatum","Dorsal raphe nucleus","Substantia nigra"]
    },
    "AAL3" :{
      extrastriatumAtlas:"ATAG", 
      firstRegion:["Dorsal raphe nucleus", "Substantia nigra"]
    },
    "Harvard-Oxford" : {
      extrastriatumAtlas:"AAL3, ATAG", 
      firstRegion:["Caudate","Substantia nigra"]
    },
  }



  // const typeSelector = {
  //   'amyloid': 'suvr',
  //   'dat': 'sbr',
  // }

  // const tempAddCustomROI = () => {
  //   const maxIndex = props.mainConfigProps.subRegionList.length;
  //   const addItem = [
  //     {static: false, primaryKey:maxIndex+0,   belongToForeignKey: maxIndex+0, level: 0, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "Frontal"   +(maxIndex+0),                  varname:{Left:"Cingulate_Ant_Sub_L", Right:"Cingulate_Ant_Sub_R"}},
  //     {static: false, primaryKey:maxIndex+1,   belongToForeignKey: maxIndex+0, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal"+(maxIndex+1),                  varname:{Left:"Cingulate_Ant_Pre_L", Right:"Cingulate_Ant_Pre_R"}},
  //     {static: false, primaryKey:maxIndex+2,   belongToForeignKey: maxIndex+0, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal"+(maxIndex+2),                  varname:{Left:"Cingulate_Ant_Sup_L", Right:"Cingulate_Ant_Sup_R"}},
  //     {static: false, primaryKey:maxIndex+3,   belongToForeignKey: maxIndex+0, level: 1, type: 'suvr',      atlas:"AAL3",            belongTo: "Frontal1",          fullname: "subFrontal"+(maxIndex+3),                  varname:{Left:"Cingulate_Mid_L",     Right:"Cingulate_Mid_R"    }},
  //   ];
  //   // debugger;
  //   props.mainConfigProps.setSubRegionList([
  //     ...props.mainConfigProps.subRegionList,
  //     ...addItem,
  //   ])
  //   // dispatch(actionSetting.add_customROI(productName, addItem))
  // }

  const tempDeleteCustomROI = (selected) => {
    // const dynamicList = props.mainConfigProps.subRegionList.filter(item=>!item.static)
    // debugger;
    const primaryKeySUVR = selected.primaryKey;
    // const type = utils.type2Selector[productName];
    const primaryKeyCENTIL = props.mainConfigProps.subRegionList.find(el=>el.identifier === selected.identifier && el.type === utils.type2Selector[productName])?.primaryKey;
    const filteredList = props.mainConfigProps.subRegionList.filter(item=> (item.belongToForeignKey !== primaryKeySUVR) && (item.belongToForeignKey !== primaryKeyCENTIL));
    // console.log(props.mainConfigProps.subRegionList.length, filteredList.length)
    // const filteredList = props.mainConfigProps.subRegionList.filter(item=> item.belongToForeignKey !== selected.belongToForeignKey);
    props.mainConfigProps.setSubRegionList([
      ...filteredList
    ])
  }

  return (
    <>
      <div className={styles.container}>
        {productName!=='dat' &&<>
          <div className={styles.brain_atlas}>
            {atlasItem.subTitle}
            <DropDownDiv style={{background:styles.dropdivColor}} item={atlasDropdown} styleName={'settingStyle'}/>
          </div>

          <div className={styles.reference_region}>
            <div>{refItem.subTitle}</div>
            <div className={styles.referece_tracer}>
              {refItem.subContent.map((item, idx)=>
                <div key={idx}>
                  <div style={{borderLeft: `6px solid ${tracerColor[item.tracer]}`}}>{item.fullname.replace('18','\u00B9\u2078' )}</div>
                  <DropDownDiv style={{background:styles.dropdivColor}} item={refDropdown[idx]} styleName={'settingStyle'}/>
                </div>
              )}
            </div>
          </div>
        </>
        }
        
        {productName==='dat' && <div className={styles.container_dat}>
          <div className={styles.reference_region_dat}>
            <div>{refItem.subTitle}</div>
            <div className={styles.referece_tracer_dat}>
              {refItem.subContent.map((item, idx)=>
                <div key={idx}>
                  <div style={{borderLeft: `6px solid ${tracerColor[item.tracer]}`}}>{item.fullname.replace('18','\u00B9\u2078' )}</div>
                  <DropDownDiv style={{background:styles.dropdivColor}} item={refDropdown[idx]} styleName={'settingStyle'}/>
                </div>
              )}
            </div>
          </div>
          <div className={styles.atlas_dat_continer}>
            <div className={styles.brain_atlas_dat}>
              {"Striatum Atlas"}
              <DropDownDiv style={{background:styles.dropdivColor}} item={atlasDropdown} styleName={'settingStyle'}/>
            </div>
            <div className={styles.brain_atlas_dat}>
              {"Extrastriatum Atlas"}
              <div className={styles.extraAtlas}>{striatum_pair[atlasDropdown.selectItem].extrastriatumAtlas}</div>
            </div>
          </div>
        </div>}

        {productName!=='dat' &&  <div className={styles.brain_regions_analyze}>
          {regionItem.subTitle}
            <div className={styles.brain_regions_analyze_subContent}>
              {props.mainConfigProps.subRegionList.filter(({type, atlas, level})=> level === 0 && atlas===props.mainConfigProps.selectAtlas && type===utils.type1Selector[productName]).map(item=>(
                <div>
                  <div className={styles.brain_regions_analyze_subContent_title}>
                    <div><img onClick={()=>{
                      const update = props.mainConfigProps.subRegionList.map(el=>el.identifier===item.identifier ? {...el, isFolded:!el.isFolded}:el)
                      props.mainConfigProps.setSubRegionList(update)
                    }} src={item.isFolded ? media.unCheck:media.Check} alt=""/></div>
                    <div>{item.fullname} &nbsp;
                      <span onClick={()=>{
                              tempDeleteCustomROI(item);
                              // dispatch(actionSetting.delete_customROI(productName, item))
                            }} style={{userSelect:"none", cursor:"pointer"}}>
                        {item.static ? "":"\u229D"}
                      </span>
                    </div>
              </div>
              <div style={{maxHeight:`${item.isFolded ? "0px":""}`}}>
                {props.mainConfigProps.subRegionList.filter(({belongToForeignKey, level})=> level === 1 && belongToForeignKey === item.primaryKey).map(subItem=>(
                  <div className={styles[`${subItem.fullname.length>16 ? "brain_regions_analyze_subContent_subregion_overflow": "brain_regions_analyze_subContent_subregion"}`]}>
                    <div>{subItem.fullname}</div>
                    <div><img src={media.justCheck} alt=""/></div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div
            onClick={()=>{
              // tempAddCustomROI()
                dispatch(actionModal.open_customROI(true));
            }}
          >
              <div className={styles.ROIButton}>
                <span style={{fontSize:"2vw", userSelect:"none"}}>{"+"}</span>
                <span style={{fontSize:"0.9vw", fontWeight:"600"}}>Add a new ROI</span>
              </div>
            
            {/* <span style={{fontSize:"2vw", color:"white", cursor:"pointer", userSelect:"none"}}
              onClick={()=>{
                // tempAddCustomROI()
                if (props.mainConfigProps.userDefinedROI === ''){
                  alert('Please enter a ROI name.');
                } else {
                  dispatch(actionModal.open_customROI(true));
                }
              }}>{"+"}
            </span> */}
            {/* <input type='text'/> */}
            {/* <input name="userDefinedRoi" type="text" placeholder="My ROI name" 
                // onKeyPress={onEnterPress} 
                value={props.mainConfigProps.userDefinedROI} 
                onChange={(e)=>{
                  if (e.target.value === ''){
                    props.mainConfigProps.setUserDefinedROI(e.target.value);
                  } else {
                    if (isNaN(e.target.value)){
                      props.mainConfigProps.setUserDefinedROI(e.target.value);
                    } else {
                      alert('A ROI name must start with a letter.');
                    }
                  }
                }}
            /> */}
          </div>
        </div>
      </div>
        }
        {productName==='dat' && <div className={styles.brain_regions_analyze}>
          {regionItem.subTitle}

          <div className={styles.brain_regions_analyze_subContent_dat}>
            {props.mainConfigProps.subRegionList.filter(({type, atlas, level, multiAtlas})=> level === 0 && multiAtlas===props.mainConfigProps.selectAtlas && type===utils.type1Selector[productName]).map(item=>(
              <div>
                <div className={styles.brain_regions_analyze_subContent_atlasTitle}
                  style={{display:`${striatum_pair[item.multiAtlas].firstRegion.includes(item.fullname) ? "":"none"}`}}
                >
                  {item.atlas}
                </div>
                <div className={styles.brain_regions_analyze_subContent_title_dat}>
                  <div><img onClick={()=>{
                    const update = props.mainConfigProps.subRegionList.map(el=>el.identifier===item.identifier ? {...el, isFolded:!el.isFolded}:el)
                    props.mainConfigProps.setSubRegionList(update)
                  }} src={item.isFolded ? media.unCheck:media.Check} alt=""/></div>
                  <div>{item.fullname} &nbsp;
                    <span onClick={()=>{
                            tempDeleteCustomROI(item);
                            // dispatch(actionSetting.delete_customROI(productName, item))
                          }} style={{userSelect:"none", cursor:"pointer"}}>
                      {item.static ? "":"\u229D"}
                    </span>
                  </div>
                </div>
                <div style={{maxHeight:`${item.isFolded ? "0px":""}`, marginLeft:"2%"}}>
                  {props.mainConfigProps.subRegionList.filter(({belongToForeignKey, level})=> level === 1 && belongToForeignKey === item.primaryKey).map(subItem=>(
                    <div className={styles[`${subItem.fullname.length>16 ? "brain_regions_analyze_subContent_subregion_overflow": "brain_regions_analyze_subContent_subregion"}`]}>
                      <div>{subItem.fullname}</div>
                      <div><img src={media.justCheck} alt=""/></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div
              onClick={()=>{
                // tempAddCustomROI()
                if(atlasDropdown.selectItem==='Melbourne Subcortical'){
                  dispatch(actionModal.open_customROI(true));
                }
              }}
            >
                <div className={styles[`${atlasDropdown.selectItem==='Melbourne Subcortical'?"ROIButton":"ROIButton_DAT"}`]}>
                  <span style={{fontSize:"2vw", userSelect:"none"}}>{"+"}</span>
                  <span style={{fontSize:"0.9vw", fontWeight:"600"}}>Add a new ROI</span>
                </div>
            </div>
          </div>
        </div>
      }
        
      </div>
    </>
  )
}


