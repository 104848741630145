import {coreItem} from "0_variables/coreItem";
import {createAction, handleActions} from 'redux-actions';
import * as sliceApi from "2_services/sliceApi";
// import { useIndexedDB } from 'react-indexed-db';
import * as actionsFiles from "1_reduxs/actions/filesAction";
import { BTXDB } from '5_components/DBConfig';
import Dexie from 'dexie';
import * as utils from "0_variables/utils";

export const ADD_SLICES = "controller/ADD_SLICES";
export const ADD_REPORT_ITEMS = "controller/ADD_REPORT_ITEMS";
export const SELECTED_FILEID = "controller/SELECTED_FILEID";
export const ADD_OPEN_FILE = "controller/ADD_OPEN_FILE";
export const CLOSE_FILE = "controller/CLOSE_FILE";
export const ADD_GROUP_OPEN_FILES = "controller/ADD_GROUP_OPEN_FILES";

export const add_report_items = createAction(ADD_REPORT_ITEMS, (productName, slicesObj)=>({
  obj:{productName, slicesObj},
}));

export const selectedFileID = createAction(SELECTED_FILEID, (productName, selectedFile)=>({
  obj:{productName, selectedFile},
}));

export const add_slices = createAction(ADD_SLICES, (productName, slicesObj)=>({
  obj:{productName, slicesObj},
}))

export const closeFile = createAction(CLOSE_FILE, (productName, singleFile)=>({
  obj:{productName, singleFile},
}))

export const addGroupOpenFiles = createAction(ADD_GROUP_OPEN_FILES, (productName, selectedFiles)=>({
  obj:{productName, selectedFiles},
}))

export const openFile_FetchSlices_Jump = (productName, selectedFiles, singleFile) => {
  return async (dispatch, getState) => {
    // const slicesDB = useIndexedDB('slices');
    dispatch(addGroupOpenFiles(productName, selectedFiles));
    if (singleFile !== null) dispatch(selectedFileID(productName, singleFile));
    const controlOfProduct = getState().control[productName];
    try{
      const emptyOpenedFiles = controlOfProduct.openedFiles.filter((item)=>item.Slices.length === 0);
      let promises = [];
      emptyOpenedFiles.forEach((item)=>{
        if (item.Slices.length === 0) {
          promises.push(
            dispatch(fetchSlices({productName, data:{ fileID: item.fileID }, slicesDB:null}))
          )
        } else {
          console.log(item.fileID, " slices exist")
        }
      });
      Promise.all(promises).then((responses)=>{
        return Promise.all(
          responses.map((response)=>{
            return response;
          })
        )
      })
    }catch(e){
      // 통신 실패했을때 실행시킬 부분
      alert('slices fetching 통신 실패')
      throw e;
    }
  }
}

export const closeFileAndChangingTab = (productName, singleFile, history, currentPage) => {
  return async (dispatch, getState) => {
    dispatch(closeFile(productName, singleFile));
    const nextFileID = getState().control[productName].selectedFileID;
    if (nextFileID === null){
      history.push(`/${productName}/upload`)
    } else {
      switch(currentPage){
        case 'view':
          history.push(`/${productName}/${currentPage}/${nextFileID}`)
          break;
        case 'analysis':
          history.push(`/${productName}/${currentPage}/${nextFileID}`)
          break;
        default:
          break;
      }
    }
  }
}

export function fetchSlices ({productName, data, slicesDB=null}={}){
  return async dispatch => {
    try {
      const token = sessionStorage.getItem("token");
      let res = {};

      if (data.Cached) {
        // const foundCase = await BTXDB.slices.where({
        //   CaseID: Number(data.fileID),
        // }).toArray();
        // res.data = foundCase;  
        // dispatch(add_slices(productName, {fileID: data.fileID, slices:res.data}));
        // console.log('foundCase.length is 792 on ', data.fileID)
      } else {
        res = await sliceApi.getSlices({ token: token, fileID: data.fileID });
        dispatch(add_slices(productName, {fileID: data.fileID, slices:res.data}));
        
        // Promise.all(
        //   res.data.map(async el=>{
        //     await BTXDB.slices.add(el)
        //     return true;
        //   })
        // )
        // .then(result => console.log(data.fileID, ": Done adding slices", result))
        // .catch(e=>console.error(data.fileID, ": Some slices are failed."))

      }
    } catch (err) {
      alert("Failed to load slice images. Please check your connection to the server, and try logging in again. If the issue persists, please contact support from Brightonix Imaging (support@brtnx.com). (controllerAction)");
    } finally {
      // dispatch(actionModal.open_spinner({status:false}));
    }
  }
}

export const initialStates = {
  ...Object.keys(coreItem).reduce((obj, productName)=>{
    obj[productName]={
      // ctrlX: 0,
      // ctrlY: 0,
      selectedFileID:null,
      openedFiles:[
        // { id: 0, fileID: "100", Tracer:"[18F]FBB" },
        // { id: 1, fileID: "101", Tracer:"[18F]FBP" },
      ],
      reportItems:{

      }
    };
    return obj;
  },{}),
};

const controllerReducer = handleActions(
  {
    [ADD_REPORT_ITEMS]:(state, {payload}) => {
      const {obj} = payload;
      const newState = {
        ...state,
        [obj.productName]:{
          ...state[obj.productName],
          reportItems:{
            ...state[obj.productName].reportItems,
            ...obj.slicesObj
          }
        }
      }
      return {
        ...newState,
      }
    },
    [ADD_SLICES]:(state, {payload}) => {
      const {obj} = payload;
      const targetFile = state[obj.productName].openedFiles.find(item=>item.fileID===obj.slicesObj.fileID);
      targetFile.Slices = obj.slicesObj.slices;
      return {...state}
    },
    [SELECTED_FILEID]:(state, {payload}) => {
      const {obj} = payload;
      return {
        ...state,
        [obj.productName]:{
          ...state[obj.productName],
          selectedFileID: obj.selectedFile.fileID,
        }
      };
    },
    [CLOSE_FILE]:(state, {payload}) => {
      const {obj} = payload;
      const clickedIdx = state[obj.productName].openedFiles.findIndex(item=>item.fileID === obj.singleFile.fileID);
      const filteredFiles = state[obj.productName].openedFiles.filter(
        (item) => item.fileID !== obj.singleFile.fileID
      ).map((item, idx)=>({...item, id:idx}));

      const updatedFileID = (()=>{
        if (filteredFiles.length === 0){
          return null;
        } else {
          const currentFileID = state[obj.productName].selectedFileID;
          const clickedFileID = obj.singleFile.fileID;
          const updatedIdx = Math.max(0, Math.min(clickedIdx, filteredFiles.length-1));
          if (currentFileID===clickedFileID) return filteredFiles[updatedIdx].fileID;
          else return currentFileID;
        }
      })()
      return {
        ...state,
        [obj.productName]:{
          ...state[obj.productName],
          selectedFileID: updatedFileID,
          openedFiles: filteredFiles,
        }
      };
    },
    [ADD_GROUP_OPEN_FILES]:(state, {payload}) => {
      const {obj} = payload;
      // 기존에 열려있던 파일 목록(opendFiles)에서
      // 새롭게 갱신된 열린파일목록(selectedFile)에 있는것만 추려냄
      // 닫기 이벤트에 대한 처리
      // 닫기 이벤트가 발생하지 않으면, openedFiles와 filteredOpenedFiles 가 서로 같음
      // debugger;
      const filteredOpenedFiles = state[obj.productName].openedFiles
        .filter((el) => obj.selectedFiles.find(({ fileID }) => el.fileID === fileID))
        .map((item, index) => ({ ...item, id: index }));
      const { length } = filteredOpenedFiles;
      const newID = length;

      // openedFiles: 기존에 열려있던 파일 목록들
      // selectedFiles: fileLists 에서 Open 상태인 파일 목록
      // selectedFiles 에서 openedFiles에 없는것들만 필터링함
      const filteredFiles = obj.selectedFiles
        .filter(
          (el) => !filteredOpenedFiles.find(({ fileID }) => el.fileID === fileID)
        ).map((el) => ({
          fileID: el.fileID,
          Tracer: el.Tracer,
          PatientID: el.PatientID,
          PatientName: el.PatientName,
          BirthDate: el.Age,
          Sex: el.Sex,
          out_suvr_max: el.out_suvr_max,
          out_suvr_min: el.out_suvr_min,
          in_suvr_max: el.in_suvr_max,
          in_suvr_min: el.in_suvr_min,
          currentIdx:[null, null, null, null],
          Slices: [],
        }));

      const indexFilteredFiles = filteredFiles.map((el, idx) => ({
        id: newID + idx,
        ...el,
      }));
      // debugger;
      const newOpenedFiles = [...filteredOpenedFiles, ...indexFilteredFiles];
      // console.log(selectedFile, filteredFiles, newOpenedFiles);
      // 여기서는 ctrlX 상태를 갱신해줘야함
      if (indexFilteredFiles.length !== 0){
        return {
          ...state,
          [obj.productName]: {
            ...state[obj.productName],
            // ctrlX: newOpenedFiles.length - 1,
            selectedFileID: newOpenedFiles.at(-1).fileID,
            openedFiles: newOpenedFiles,
          },
        };
      } else {
        return state
      }
    },
  },
  initialStates,
)

// const controllerReducer = (state = initialStates, action) => {
//   // const { ctrlX, ctrlY, openedFiles, sortInfo, reportSlices } = state;
//   const { type, obj } = action;

//   switch (type) {
//     case controllerActions.ADD_SLICES: {
//       const targetFile = state[obj.productName].openedFiles.find(item=>item.fileID===obj.slicesObj.fileID);
//       targetFile.Slices = obj.slicesObj.slices;
//       return {
//         ...state
//       }
//     }

//     case controllerActions.SELECTED_FILEID: 
//       return {
//         ...state,
//         [obj.productName]:{
//           ...state[obj.productName],
//           selectedFileID: obj.selectedFile.fileID,
//         }
//       };
    
//     case controllerActions.CLOSE_FILE: {
//       const clickedIdx = state[obj.productName].openedFiles.findIndex(item=>item.fileID === obj.singleFile.fileID);
//       const filteredFiles = state[obj.productName].openedFiles.filter(
//         (item) => item.fileID !== obj.singleFile.fileID
//       ).map((item, idx)=>({...item, id:idx}));

//       const updatedFileID = (()=>{
//         if (filteredFiles.length === 0){
//           return null;
//         } else {
//           const currentFileID = state[obj.productName].selectedFileID;
//           const clickedFileID = obj.singleFile.fileID;
//           const updatedIdx = Math.max(0, Math.min(clickedIdx, filteredFiles.length-1));
//           if (currentFileID===clickedFileID) return filteredFiles[updatedIdx].fileID;
//           else return currentFileID;
//         }
//       })()
//       return {
//         ...state,
//         [obj.productName]:{
//           ...state[obj.productName],
//           selectedFileID: updatedFileID,
//           openedFiles: filteredFiles,
//         }
//       };
//     }

//     case controllerActions.ADD_GROUP_OPEN_FILES: {
//       // 기존에 열려있던 파일 목록(opendFiles)에서
//       // 새롭게 갱신된 열린파일목록(selectedFile)에 있는것만 추려냄
//       // 닫기 이벤트에 대한 처리
//       // 닫기 이벤트가 발생하지 않으면, openedFiles와 filteredOpenedFiles 가 서로 같음
//       // debugger;
//       const filteredOpenedFiles = state[obj.productName].openedFiles
//         .filter((el) => obj.selectedFiles.find(({ fileID }) => el.fileID === fileID))
//         .map((item, index) => ({ ...item, id: index }));
//       const { length } = filteredOpenedFiles;
//       const newID = length;

//       // openedFiles: 기존에 열려있던 파일 목록들
//       // selectedFiles: fileLists 에서 Open 상태인 파일 목록
//       // selectedFiles 에서 openedFiles에 없는것들만 필터링함
//       const filteredFiles = obj.selectedFiles
//         .filter(
//           (el) => !filteredOpenedFiles.find(({ fileID }) => el.fileID === fileID)
//         ).map((el) => ({
//           fileID: el.fileID,
//           Tracer: el.Tracer,
//           PatientID: el.PatientID,
//           PatientName: el.PatientName,
//           BirthDate: el.Age,
//           Sex: el.Sex,
//           currentIdx:[null, null, null, null],
//           Slices: [],
//         }));

//       const indexFilteredFiles = filteredFiles.map((el, idx) => ({
//         id: newID + idx,
//         ...el,
//       }));
//       // debugger;
//       const newOpenedFiles = [...filteredOpenedFiles, ...indexFilteredFiles];
//       // console.log(selectedFile, filteredFiles, newOpenedFiles);
//       // 여기서는 ctrlX 상태를 갱신해줘야함
//       if (indexFilteredFiles.length !== 0){
//         return {
//           ...state,
//           [obj.productName]: {
//             ...state[obj.productName],
//             // ctrlX: newOpenedFiles.length - 1,
//             selectedFileID: newOpenedFiles.at(-1).fileID,
//             openedFiles: newOpenedFiles,
//           },
//         };
//       } else {
//         return state
//       }
//     }

//     default: 
//       return state;
//   }
// };

export default controllerReducer;
