import React, { useEffect, useState, useRef } from "react";
import styles from "./UploadingModal.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actionModal from "1_reduxs/actions/modalAction";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {UploadingStep1, UploadingStep2, UploadingStep3} from "./components";
import {coreItem} from "0_variables/coreItem";
import {subRegions} from "0_variables/subRegions";
import * as media from "6_media";
import * as services from '2_services/uploadApi';
import { BiTrash } from 'react-icons/bi';

export default function PacsModal(props) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  const params = useParams();
  const productName = params.product.toLowerCase();
  const [selectTracer, setSelectTracer] = useState(setting[productName].defaultTracer);
  const [findResult, setFindResult] = useState([]);
  const [getResult, setGetResult] = useState([]);
  const [selectedIdx, setSelecteIdx] = useState(0);
  const stepRefArr = useRef([]);

  useEffect(()=>{
    deleteFiles();
  },[])

  const handleRef = (idx, el) => {
    stepRefArr.current[idx] = el;
  };

  const executeScroll = (idx) => {
    if (idx >= 0 && idx < 3){
      setSelecteIdx(idx);
      stepRefArr.current[idx].scrollIntoView({behavior: "smooth", inline: "nearest"})
    } else {
      alert('no works, ref index is out of range');
    }
  };
  

  const deleteFiles = async () =>{
    const token = sessionStorage.getItem('token');
    let res;
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Downloading...'}));
      res = await services.deleteFilefromPacs({'token':token})
    } catch (err) {
      // TODO: pacs 검색결과 삭제(delete) 실패했을 때 오류처리 필요
    } finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
    }
  }

  const findHandler = async (inputs) =>{
    // debugger;
    const token = sessionStorage.getItem('token')
    let res;
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Downloading...'}));
      res = await services.postPacs({'Method':'find','Tracer':selectTracer.shortname, 'PatientID':inputs.PatientID, 'StudyDate':inputs.StudyDate, 'StudyDescription':inputs.StudyDescription, 'token':token})
    } catch (err) {
      // TODO: PACS 검색(find) 실패했을 때 오류처리 필요
    } finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}))
    }
    if (res.data.length === 0) {
      alert('Failed to find any matching data. Please try other search terms.');
    } else {
      const structuredFindResult = res.data.map((item,idx)=> ({
        ...item,
        id: idx,
        seriesItem:item.SeriesInfo[0].map((el,idx)=>({
          id:               idx,
          Marker:           "\u2937",//"↳",
          PatientID:        item.SeriesInfo[0][idx],//"↳",U+3008
          PatientName:      item.SeriesInfo[3][idx],
          StudyDescription: item.SeriesInfo[1][idx],
          Modality:         item.SeriesInfo[2][idx],
          Delete:           <BiTrash size={"0.8vw"}/>,
          // StudyDate:    item.SeriesInfo[3][idx],
        }))
      }))
      setFindResult(structuredFindResult)
    }
  }

  const _deleteFindHandler = (item, studyID, seriesID) => {
    if (seriesID === -1) {
      const filterResult = findResult.filter(el1=>el1.id !== studyID)
      setFindResult(filterResult)
    } else {
      const targetStudy = findResult.find(el1=>el1.id===studyID);
      const filteredSeriesItem = targetStudy.seriesItem.filter(el2=>el2.id !== seriesID)
      if (filteredSeriesItem.length === 0) {
        const filterResult = findResult.filter(el1=>el1.id !== studyID)
        setFindResult(filterResult)
      } else {
        const targetIdx = targetStudy.seriesItem.findIndex(el2=>el2.id===seriesID);
        const filteredSeriesInfo = targetStudy.SeriesInfo.map((item1, idx1)=>item1.filter((item2, idx2)=>idx2 !== targetIdx));
        targetStudy.seriesItem = filteredSeriesItem;
        targetStudy.SeriesInfo = filteredSeriesInfo;
        setFindResult([...findResult])
      }
    }
  }
  
  const getHandler = async () =>{
    const Array_PatientID = findResult.map(v=>v.PatientID)
    const Array_PatientName = findResult.map(v=>v.PatientName)
    const Array_StudyDate = findResult.map(v=>v.StudyDate)
    const Array_StudyDescription = findResult.map(v=>v.StudyDescription)
    const Array_StudyInstanceUID = findResult.map(v=>v.StudyInstanceUID)
    const Array_SeriesInfo = findResult.map(v=>v.SeriesInfo)

    const token = sessionStorage.getItem('token');
    let res;
    try{
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Downloading...'}));
      res = await services.postPacs({ 'Method':'get', 'Tracer':selectTracer.shortname, 'PatientID':Array_PatientID, 'PatientName':Array_PatientName, 'StudyInstanceUID':Array_StudyInstanceUID, 'SeriesInfo':Array_SeriesInfo, 'StudyDate':Array_StudyDate, 'StudyDescription':Array_StudyDescription, 'token':token });
    } catch (err) {
      // TODO: PACS 다운로드(get)명령 에러 처리 필요
      alert('downloading failed, try check Pacs system again');
    } finally {
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
    }
    if (res.data.length === 0) {
      alert('Failed to find any matching data. Please try other search terms.');
    } else {
      const structuredGetResult = res.data.map((item,idx)=> ({
        ...item,
        seriesItem:[]
      }))
      setGetResult(structuredGetResult);
    }
  }

  const _deleteGetHandler = (item, seriesID, _) => {
    const filteredGetResult = getResult.filter(el1=>el1.id !== seriesID)
    setGetResult(filteredGetResult)
  }

  const runHandler = async (worklistChecker) => {
    // debugger;
    const token = sessionStorage.getItem('token');
    // let res;
    try {
      dispatch(actionModal.open_spinner({status:true, percent:0, message:'Downloading...'}));
      const res = await services.runFile({'token':token, 'obj':getResult, 'Tracer':selectTracer.shortname, 'addToWorklist':worklistChecker});
    } catch (e) {
      debugger;
      // TODO: 파일 분석 시작 (run) 명령에 대한 에러처리 필요
      alert('runing failed, please check api server again.')
    } finally {
      // TODO: 이때 전체 fileList를 한번 받아서 갱신시켜줄 필요 있음
      // ***주기적으로 upload에서 request를 보내면서 갱신하는것은 통신효율적으로 좀 문제가 있음***
      dispatch(actionModal.open_spinner({status:false, percent:0, message:''}));
      props.closeModal();
    }
  }

  const UploadingStep1Props = {
    selectTracer, setSelectTracer,
    handleRef,
    executeScroll,
  }
  const UploadingStep2Props = {
    findResult, setFindResult,
    handleRef,
    executeScroll,
    findHandler, _deleteFindHandler,
    getHandler, 
  }
  const UploadingStep3Props = {
    getResult, 
    handleRef,
    executeScroll,
    _deleteGetHandler,
    runHandler,
  }

  return (
    <div className={`${styles['container']}`}
      onClick={()=>{ 
        if (window.confirm("Are you sure you want to cancel the upload?")){
          props.closeModal();deleteFiles();
        }
      }}
    >
      <div className={styles.stepContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <UploadingStep1 idx={0} selected={true} uploadingStepProps={UploadingStep1Props} />
        <UploadingStep2 idx={1} selected={true} uploadingStepProps={UploadingStep2Props} />
        <UploadingStep3 idx={2} selected={true} uploadingStepProps={UploadingStep3Props} />
      </div>
    </div>
  )
}