import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import './ImageViewer.module.scss';
import { connect } from "react-redux";
// import * as actionsSlices from 'reduxs/actions/slicesAction';
// import * as actionsControl from 'reduxs/actions/controllerAction';
// import { COLORMAP } from 'variables/viewInfo';
import {coreItem} from "0_variables/coreItem";
import * as services from "2_services/sliceApi";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

class ImageViewer extends Component {
  state = {
    // imageId: 'example://1',
  };
  element = createRef();
  componentDidMount(){
    // debugger;
    const {element} = this;
    // console.log('cdm')
    const options = {
      // opacity: 1,
      // visible: true,
      viewport: {
        colormap: this.props.colormap,
      },
      // name: 'PET'
    }
    cornerstone.enable(element);
    cornerstoneTools.mouseInput.enable(element);
    // cornerstoneTools.mouseWheelInput.enable(element);
    // const ch = cornerstone.colors.getColormap('gray');
    // const temp = [...Array(256)].map((el,idx)=>ch.getColor(idx));
    // debugger;
    // ch.setColor(0, [255,255,255,255]);
    cornerstone.loadImage(this.props.imageId).then(function(image) {
      // console.log(image)
      // debugger;
      cornerstone.displayImage(element, image);
      cornerstoneTools.wwwc.activate(element, 4);
      // cornerstoneTools.stackScroll.activate(element, 1);
      const vp = cornerstone.getViewport(element);
      cornerstone.setViewport(element, {...vp, ...options.viewport});
      cornerstone.updateImage(element);
    });
  }
  componentDidUpdate(){
    const {element} = this;
    const options = {
      // opacity: 1,
      // visible: true,
      viewport: {
        colormap: this.props.colormap,
      },
      // name: 'PET'
    }
    debugger;
    cornerstone.loadImage(this.props.imageId).then(function(image) {
      // console.log(image)
      // debugger;
      cornerstone.displayImage(element, image);
      cornerstoneTools.wwwc.activate(element, 4);
      // cornerstoneTools.stackScroll.activate(element, 1);
      const vp = cornerstone.getViewport(element);
      cornerstone.setViewport(element, {...vp, ...options.viewport});
      cornerstone.updateImage(element);
    });
  }

  render() {
    return (
      <>
        <div style={{border:"1px blue solid", width:this.props.width, height:this.props.height}} ref={input => this.element=input}></div>
      </>
    );
  }
}
ImageViewer.defaultProps = {
  width: "15vmin",
  height: "15vmin",
  colormap: "gray",
}
ImageViewer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
const mapStateToProps = (state) => ({
  fileList: state.fileList,
  control: state.control,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchSlices: (obj)=>dispatch(actionsSlices.fetchSlices(obj)),
  // update_currentIdx: (obj)=>dispatch(actionsControl.update_currentIdx(obj)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer);