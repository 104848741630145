import React, { Component } from 'react';
import "./ImageLoader.scss";
import { connect } from "react-redux";
import MNI_Axial from "0_variables/MNI152_T1_2mm_z";
import MNI_Coronal from "0_variables/MNI152_T1_2mm_y";
import MNI_Sagittal from "0_variables/MNI152_T1_2mm_x";
import {Container} from './components';
// import { COLORMAP } from 'variables/viewInfo';

// import * as actionsSlices from 'reduxs/actions/slicesAction';
// import * as actionsControl from 'reduxs/actions/controllerAction';
// import * as actionModal from 'reduxs/actions/modalAction';

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

class ImageLoader extends Component {
  state = {
    isImageLoaded:false,
  }

  componentDidMount() {
    // const {control, open_spinner} = this.props;
    try{
      // open_spinner({status:true, percent:100});
      // const promise1 = this.imageLoader();
      // const promise2 = this.metaDataLoader();
      this.setState({isImageLoaded:true});
    } catch (e) {
      console.log(e);
    } finally {
      // setTimeout(()=>open_spinner({status:false}), 1000);
    }
  }

  // componentDidUpdate(prevProps, prevState) {

  // }
  render() {
    const {control, match} = this.props;
    const {isImageLoaded} = this.state;
    const productName = match.params.product;
    // console.log(control[productName])
    const controlOfProduct = control[productName];
    return (
      <div style={{border:"10px blue solid", boxSizing:"border-box", position:"relative", height:"100%", width:"100%"}}>
        {isImageLoaded && 
          controlOfProduct.openedFiles.map((item, index)=>
            <div className="ImageLoader" key={Number(item.fileID)} style={{marginBottom:"20px", background:"black", border:"1px red solid", height:"100%"}}>
              {item.fileID}
              <Container fileID={item.fileID} Tracer={item.Tracer} PatientName={item.PatientName} PatientID={item.PatientID} BirthDate={item.BirthDate} Sex={item.Sex}/>
            </div>
        )}

        {/* {isImageLoaded && 
        control.openedFiles.map((item, index)=>
          <div className="ImageLoader" key={Number(item.fileID)}>
            <Container fileID={item.fileID} Tracer={item.Tracer} PatientName={item.PatientName} PatientID={item.PatientID} BirthDate={item.BirthDate} Sex={item.Sex}/>
          </div>
        )} */}
      </div>
    );
  }

  // imageLoader = async () => {
  //   "use strict";

  //   function pixelData2str(buf) {
  //     var bufView = new Uint16Array(buf);
  //     var len = bufView.length;
  //     var str = '';

  //     for(var i = 0; i < len; i++) {


  //       var word = bufView[i];
  //       var lower = word & 0xFF;
  //       var upper = (word >> 8) & 0xFF;
  //       str += String.fromCharCode(lower) + String.fromCharCode(upper);
  //     }

  //     return str;
  //   }

  //   function encodePixelData(pixelData) {
  //     var pixelDataAsString = pixelData2str(pixelData);
  //     var base64str = window.btoa(pixelDataAsString);
  //     return base64str;
  //   }

  //   function str2pixelData(str) {
  //     var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  //     var bufView = new Int16Array(buf);
  //     var index = 0;
  //     for (var i=0, strLen=str.length; i<strLen; i+=2) {
  //       var lower = str.charCodeAt(i);
  //       var upper = str.charCodeAt(i+1);
  //       // debugger;
  //       bufView[index] = lower + (upper <<8);
  //       index++;
  //     }
  //     return bufView;
  //   }
  //   function str2InvertedpixelData(str) {
  //     var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
  //     var bufView = new Int16Array(buf);
  //     var index = 0;
  //     for (var i=0, strLen=str.length; i<strLen; i+=2) {
  //       var lower = str.charCodeAt(i);
  //       var upper = str.charCodeAt(i+1);
  //       bufView[index] = 32767-Number(lower + (upper <<8));
  //       index++;
  //     }
  //     return bufView;
  //   }

  //   var min1 = 0;
  //   var max1 = 0;
  //   function getPixelData(base64PixelData)
  //   {
  //     var pixelDataAsString = window.atob(base64PixelData);
  //     var pixelData = str2pixelData(pixelDataAsString);
  //     return pixelData;
  //   }
  //   function getInvertedPixelData(base64PixelData)
  //   {
  //     var pixelDataAsString = window.atob(base64PixelData);
  //     var pixelData = str2InvertedpixelData(pixelDataAsString);
  //     return pixelData;
  //   }
  //   // const IdxSlice = this.props.sliceList.findIndex(v=>v.fileID==this.props.counter.fileID)
  //   var petAxialOutputData = [];
  //   var petCoronalOutputData = [];
  //   var petSagittalOutputData = [];
  //   var petMipOutputData = [];

  //   var petAxialInputData = [];
  //   var petCoronalInputData = [];
  //   var petSagittalInputData = [];
  //   var petMipInputData = [];

  //   var mniAxialOutputData = [];
  //   var mniCoronalOutputData = [];
  //   var mniSagittalOutputData = [];
  //   var mniMipOutputData = [];

  //   var mniAxialInputData = [];
  //   var mniCoronalInputData = [];
  //   var mniSagittalInputData = [];
  //   var mniMipInputData = [];

  //   this.props.control.openedFiles.forEach((file,idx)=>{
  //     // debugger;
  //     const fileID = Number(file.fileID);

  //     if (file.Slices.length !== 0){

  //       petAxialOutputData[fileID] = [...Array(91).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='axial' && v.Type=="output")[i].B64Data)));
  //       petCoronalOutputData[fileID] = [...Array(109+20).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='coronal' && v.Type=="output")[i].B64Data)));
  //       petSagittalOutputData[fileID] = [...Array(91+40).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='sagittal' && v.Type=="output")[i].B64Data)));
  //       petMipOutputData[fileID] = [...Array(45).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='mip' && v.Type=="output")[i].B64Data)));
  
  //       petAxialInputData[fileID] = [...Array(91).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='axial' && v.Type=="input")[i].B64Data)));
  //       petCoronalInputData[fileID] = [...Array(109+20).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='coronal' && v.Type=="input")[i].B64Data)));
  //       petSagittalInputData[fileID] = [...Array(91+40).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='sagittal' && v.Type=="input")[i].B64Data)));
  //       petMipInputData[fileID] = [...Array(45).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='mip' && v.Type=="input")[i].B64Data)));
  
  //       mniAxialOutputData[fileID] = MNI_Axial.map((v,i)=>(getPixelData(v)));
  //       mniCoronalOutputData[fileID] = MNI_Coronal.map((v,i)=>(getPixelData(v)));
  //       mniSagittalOutputData[fileID] = MNI_Sagittal.map((v,i)=>(getPixelData(v)));
  //       mniMipOutputData[fileID] = [...Array(45).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='mip' && v.Type=="output")[i].B64Data)));
  
  //       mniAxialInputData[fileID] = MNI_Axial.map((v,i)=>(getPixelData(v)));
  //       mniCoronalInputData[fileID] = MNI_Coronal.map((v,i)=>(getPixelData(v)));
  //       mniSagittalInputData[fileID] = MNI_Sagittal.map((v,i)=>(getPixelData(v)));
  //       mniMipInputData[fileID] = [...Array(45).keys()].map((v,i)=>(getPixelData(file.Slices.filter(v=>v.Direction=='mip' && v.Type=="output")[i].B64Data)));
        
  //     }

  //   })
  //   function getMNIImage(imageId) {
  //     let identifier=imageId.split(/[:,/]+/)
  //     let device = identifier[0]//pet
  //     let inout = identifier[1]//output
  //     let fileID = identifier[2] //0
  //     let direction=identifier[3]
  //     let Inverter=identifier[4]
  //     let colormap=identifier[5]
  //     let id=Number(identifier[6])
  //     var width = 91;
  //     var height = 91;
  //     if(direction === 'coronal') {width=91+40; height=91}
  //     else if(direction === 'sagittal') {width=109+20; height=91}
  //     else if(direction === 'axial') {width=91+40; height=109+20}
  //     else if (direction === "mip"){width = 109+20; height = 91}

  //     var image = {
  //       imageId: imageId,

  //       minPixelValue : 0,
  //       maxPixelValue : 32767,
  //       slope: 1.0,
  //       intercept: 0,
  //       windowCenter : 16384,
  //       windowWidth : 32767,
  //       getPixelData: getPixelData,
  //       rows: height,
  //       columns: width,
  //       height: height,
  //       width: width,
  //       color: false,
  //       columnPixelSpacing: 2,
  //       rowPixelSpacing: 2,
  //       sizeInBytes: width * height * 2,
  //     };

  //     function getPixelData()
  //     {
  //       // if (colormap == "gray"){
  //       //   Inverter = "invert";
  //       // }
  //       if (inout === "output"){
  //         if (Inverter === "right"){
  //           if (direction === "coronal"){return mniCoronalOutputData[fileID][id]}
  //           else if (direction === "sagittal"){return mniSagittalOutputData[fileID][id]}
  //           else if (direction === "axial"){return mniAxialOutputData[fileID][id]}
  //           else if (direction === "mip"){return mniMipOutputData[fileID][id]}
  //         } else {
  //           // if (direction === "coronal"){return Inv_mniCoronalOutputData[id]}
  //           // else if (direction === "sagittal"){return Inv_mniSagittalOutputData[id]}
  //           // else if (direction === "axial"){return Inv_mniAxialOutputData[id]}
  //           // else if (direction === "mip"){return Inv_mniMipOutputData[id]}
  //         }
  //         // else if (direction === "mip" && inout === "output"){return petMipOutputData[id]}
  //       } else {
  //         if (Inverter === "right"){
  //           if (direction === "coronal"){return mniCoronalInputData[fileID][id]}
  //           else if (direction === "sagittal"){return mniSagittalInputData[fileID][id]}
  //           else if (direction === "axial"){return mniAxialInputData[fileID][id]}
  //           else if (direction === "mip"){return mniMipInputData[fileID][id]}
  //         } else {
  //           // if (direction === "coronal"){return Inv_mniCoronalOutputData[id]}
  //           // else if (direction === "sagittal"){return Inv_mniSagittalOutputData[id]}
  //           // else if (direction === "axial"){return Inv_mniAxialOutputData[id]}
  //           // else if (direction === "mip"){return Inv_mniMipOutputData[id]}
  //         }
  //       }

  //       throw "unknown imageId";
  //     }

  //     return {
  //       promise: new Promise((resolve) => {
  //         resolve(image);
  //       }),
  //       cancelFn: undefined
  //     };
  //   }
  //   function getPETImage(imageId) {
  //     let identifier=imageId.split(/[:,/]+/)
  //     let device = identifier[0]//pet
  //     let inout = identifier[1]//output
  //     let fileID = identifier[2] //0
  //     let direction=identifier[3]
  //     let Inverter=identifier[4]
  //     let colormap=identifier[5]
  //     let id=Number(identifier[6])
  //     var width = 91;
  //     var height = 91;
  //     if(direction === 'coronal') {width=91+40; height=91}
  //     else if(direction === 'sagittal') {width=109+20; height=91}
  //     else if(direction === 'axial') {width=91+40; height=109+20}
  //     else if (direction === "mip"){width = 109+20; height = 91}

  //     var image = {
  //       imageId: imageId,

  //       minPixelValue : 0,
  //       maxPixelValue : 32767,
  //       slope: 1.0,
  //       intercept: 0,
  //       windowCenter : 16384,
  //       windowWidth : 32767,
  //       getPixelData: getPixelData,
  //       rows: height,
  //       columns: width,
  //       height: height,
  //       width: width,
  //       color: false,
  //       columnPixelSpacing: 2,
  //       rowPixelSpacing: 2,
  //       sizeInBytes: width * height * 2,
  //     };

  //     function getPixelData()
  //     {
  //       if (inout === "output"){
  //         if (Inverter === "right"){
  //           if (direction === "coronal"){return petCoronalOutputData[fileID][id]}
  //           else if (direction === "sagittal"){return petSagittalOutputData[fileID][id]}
  //           else if (direction === "axial"){return petAxialOutputData[fileID][id]}
  //           else if (direction === "mip"){return petMipOutputData[fileID][id]}
  //         } else {
  //           // if (direction === "coronal"){return Inv_petCoronalOutputData[id]}
  //           // else if (direction === "sagittal"){return Inv_petSagittalOutputData[id]}
  //           // else if (direction === "axial"){return Inv_petAxialOutputData[id]}
  //           // else if (direction === "mip"){return Inv_petMipOutputData[id]}
  //         }
  //       } else {
  //         if (Inverter === "right"){
  //           if (direction === "coronal"){return petCoronalInputData[fileID][id]}
  //           else if (direction === "sagittal"){return petSagittalInputData[fileID][id]}
  //           else if (direction === "axial"){return petAxialInputData[fileID][id]}
  //           else if (direction === "mip"){return petMipInputData[fileID][id]}
  //         } else {
  //           // if (direction === "coronal"){return Inv_petCoronalOutputData[id]}
  //           // else if (direction === "sagittal"){return Inv_petSagittalOutputData[id]}
  //           // else if (direction === "axial"){return Inv_petAxialOutputData[id]}
  //           // else if (direction === "mip"){return Inv_petMipOutputData[id]}
  //         }
  //       }

  //       throw "unknown imageId";
  //     }

  //     return {
  //       promise: new Promise((resolve) => {
  //         resolve(image);
  //       }),
  //       cancelFn: undefined
  //     };
  //   }

  //   cornerstone.registerImageLoader('mni', getMNIImage);
  //   cornerstone.registerImageLoader('pet', getPETImage);
  //   // console.log('pass2');
  // };

  // metaDataLoader = async () => {

  //   "use strict";

  //   function metaDataProvider(type, imageId) {
  //     let identifier=imageId.split(/[:,/]+/)
  //     let device = identifier[0]//pet
  //     let inout = identifier[1]//output
  //     let slice = identifier[2] //0
  //     let direction=identifier[3]
  //     let Inverter=identifier[4]
  //     let colormap=identifier[5]
  //     let id=Number(identifier[6])
  //     let scale = 1;
  //     let cX = 1;
  //     let cY = 1;
  //     let cZ = 1;

  //     let width = 91;
  //     let height = 91;
  //     if (direction === "coronal"){width = 91+40; height = 91}
  //     else if (direction === "sagittal"){width = 109+20; height = 91}
  //     else if (direction === "axial"){width = 91+40; height = 109+20}
  //     else if (direction === "mip"){width = 109+20; height = 91}

  //     if(type === 'imagePlaneModule') {
  //       if (direction === 'coronal'){
  //         return {
  //           frameOfReferenceUID: '1.2.3.4.5',
  //           rows: 91,
  //           columns: 91+40,
  //           rowCosines:     [ 0,  1,  0], 
  //           columnCosines:  [ 0,  0,  1],
  //           imagePositionPatient: [scale*id+cY, cX, cZ], //coronal plane에서 [xxx, sagittal line, axial line]
  //           columnPixelSpacing: 1,
  //           rowPixelSpacing: 1,
  //         }
  //       }
  //       else if (direction === 'sagittal'){
  //         return {
  //           frameOfReferenceUID: '1.2.3.4.5',
  //           rows: 91,
  //           columns: 109+20,
  //           rowCosines:     [1,  0,  0], 
  //           columnCosines:  [ 0,  0, 1],
  //           imagePositionPatient: [cY, scale*id+cX, cZ], //sagittal plane에서 [coroanl line, xxx, axial line]
  //           columnPixelSpacing: 1,
  //           rowPixelSpacing: 1,
  //         }
  //       }
  //       else if (direction === 'axial'){
  //         return {
  //           frameOfReferenceUID: '1.2.3.4.5',
  //           rows: 109+20,
  //           columns: 91+40,
  //           rowCosines:     [ 0, 1,  0], 
  //           columnCosines:  [ -1,  0,  0],
  //           imagePositionPatient: [cY+109+20, cX, -scale*id+cZ+91], //axial plane에서  [coronal line, sagittal line, xxx]
  //           columnPixelSpacing: 1,
  //           rowPixelSpacing: 1,
  //         }
  //       }
  //     }
  //   }
  //   cornerstone.metaData.addProvider(metaDataProvider);
  //   // console.log('pass3');
  // };

} const mapStateToProps = (state) => ({
  // sliceList: state.sliceList,
  fileList: state.fileList,
  control: state.control,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchSlices: (obj)=>dispatch(actionsSlices.fetchSlices(obj)),
  // update_currentIdx: (obj)=>dispatch(actionsControl.update_currentIdx(obj)),
  // open_spinner: (obj)=>dispatch(actionModal.open_spinner(obj)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ImageLoader);