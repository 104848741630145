import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import styles from './Analysis_card1.module.scss';
import { BrainTable } from '5_components';
import {coreItem} from "0_variables/coreItem";

export default function Analysis_card1(props) {
  const params = useParams();
  const fileList = useSelector((state) => state.fileList);
  const [selectedFile, setSelectedFile] = useState({});
  console.log(props.RF)

  useEffect(()=>{
    const selectedFile = fileList.find(({fileID})=>fileID == params.fileID);
    setSelectedFile(selectedFile);
  },[params])

  return <div className={styles.container}>
    <BrainTable legend={props.legend} colorcode={props.colorcode} items={props.items} refName={props.refName} RF={props.RF}/>
  </div>;
}
